import { makeStyles, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import getValue from '../../helpers/getValue';

export enum Status {
    Critical,
    ActionRequired,
    InProgress,
    NoAction,
    GreyedOut,
}

export interface StatusBadgeProps {
    status: Status;
    tooltip: string;
    className?: string;
}

const useStyles = makeStyles(theme => ({
    badge: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        borderRadius: '4px',
        fontWeight: 700,
        padding: theme.spacing(1, 2),
    },
    critical: { background: '#ef9a9a' },
    actionRequired: { background: '#FAEACC' },
    noAction: { background: '#EAF4FC' },
    inProgress: { background: '#CFE9DA' },
    greyedOut: { background: '#E0E0E0' },
}));

const StatusBadge: FunctionComponent<StatusBadgeProps> = (
    { className, children, status, tooltip }
) => {
    const classes = useStyles();

    const colorClass = getValue(() => {
        switch (status) {
            case Status.Critical:
                return classes.critical;
            case Status.ActionRequired:
                return classes.actionRequired;
            case Status.InProgress:
                return classes.inProgress;
            case Status.NoAction:
                return classes.noAction;
            case Status.GreyedOut:
                return classes.greyedOut;
            default:
                throw new Error('Unhandled status');
        }
    });

    return (
        <Tooltip title={tooltip}>
            <div
                className={clsx(colorClass, classes.badge, className)}
            >
                {children}
            </div>
        </Tooltip>
    );
};

export default StatusBadge;
