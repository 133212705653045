import { Modal } from '@get-e/react-components';
import { Grid, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import InviteUserForm from './InviteUserForm';

const InviteUserModal: FunctionComponent<{
    open: boolean;
    onClose: () => void;
}> = ({ open, onClose }) => {
    const { t } = useTranslation();

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Grid container>
                <Grid item xs>
                    <Typography variant="h2" component="h2">
                        {t('pages.users.invite.modalHeading')}
                    </Typography>

                    <InviteUserForm
                        onCancel={onClose}
                        onInvited={onClose}
                    />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default InviteUserModal;
