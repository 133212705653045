import gql from 'graphql-tag';

export const CREATE_DRIVER = gql`
    mutation CREATE_DRIVER($input: CreateDriverInput!) {
        createDriver(
            input: $input
        ) {
            id
        }
    }
`;

export interface CreateDriverInput {
    input: {
        supplierId: string;
        name: string;
        phoneNumber: string;
    };
}

export interface CreateDriverResult {
    createDriver: {
        id: string;
    };
}
