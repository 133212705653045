import gql from 'graphql-tag';

export const GET_DRIVER_POSITION_FROM_RIDE = gql`
    query getDriverPosition(
        $id: ID!
    ) {
        ride(id: $id) {
            id
            positionUpdates(first: 1, orderBy: {field: CAPTURED_AT, direction: DESC }) {
                nodes {
                    position {
                        coordinates {
                            longitude
                            latitude
                        }
                        accuracyInMeters
                    }
                    speed {
                        metersPerSecond
                        accuracyInMeters
                    }
                    bearing {
                        degrees
                        accuracyInDegrees
                    }
                    altitude {
                        meters
                        accuracyInMeters
                    }
                    capturedAt
                }
            }
        }
    }
`;

export interface GetRidePositionUpdateInput {
    id: string;
}

export interface GetRidePositionUpdateResult {
    ride: {
        id: string;
        positionUpdates: {
            nodes: Array<{
                position: {
                    coordinates: {
                        longitude: number;
                        latitude: number;
                    };
                    accuracyInMeters: number;
                };
                speed: {
                    metersPerSecond: number;
                    accuracyInMeters: number;
                };
                bearing: {
                    degrees: number;
                    accuracyInDegrees: number;
                };
                altitude: {
                    meters: number;
                    accuracyInMeters: number;
                };
                capturedAt: string;
            }>;
        };
    };
}
