import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { initSentry } from './helpers/report';

/*
 * TODO: Re-enable <React.StrictMode> once Material UI useRef bug is solved
 * https://github.com/mui-org/material-ui/issues/13394
 */

initSentry();

ReactDOM.render(
    <App />,
    document.getElementById('root')
);
