import gql from 'graphql-tag';

export const GET_CUSTOMER_USERS = gql`
query getCustomerUsers(
    $id: ID!
    $first: Int,
    $last: Int,
    $after: String,
    $before: String
    $filter: CustomerUserNameFilter,
) {
    customer(id: $id) {
        id
        users(
                first: $first,
                after: $after,
                last: $last,
                before: $before,
                filter: $filter
            ) {
            nodes {
                id
                name
                email
                joinedAt
            }
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
    }
}
`;

export type GetCustomerUsersInput = {
    id: string;
    first: number;
    after?: string;
    filter: null | { search: string };
} | {
    id: string;
    last: number;
    before?: string;
    filter: null | { search: string };
};

export interface GetCustomerUsersResult {
    customer: {
        id: string;
        users: {
            nodes: Array<{
                id: string;
                name: string;
                email: string;
                joinedAt: string;
            }>;
            totalCount: number;
            pageInfo: {
                hasPreviousPage: boolean;
                hasNextPage: boolean;
                startCursor: string | null;
                endCursor: string | null;
            };
        };
    };
}

export const GET_CUSTOMER_INVITES = gql`
query getCustomerInvites(
    $id: ID!
    $first: Int,
    $last: Int,
    $after: String,
    $before: String,
    $filter: CustomerUserInviteNameFilter
) {
    customer(id: $id) {
        id
        userInvites(
                first: $first,
                after: $after,
                last: $last,
                before: $before,
                filter: $filter
            ) {
            nodes {
                id
                name
                email
                createdAt
            }
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
    }
}
`;

export type GetCustomerInvitesInput = {
    id: string;
    first: number;
    after?: string;
    filter: null | { search: string };
} | {
    id: string;
    last: number;
    before?: string;
    filter: null | { search: string };
};

export interface GetCustomerInvitesResult {
    customer: {
        id: string;
        userInvites: {
            nodes: Array<{
                id: string;
                name: string;
                email: string;
                createdAt: string;
            }>;
            totalCount: number;
            pageInfo: {
                hasPreviousPage: boolean;
                hasNextPage: boolean;
                startCursor: string | null;
                endCursor: string | null;
            };
        };
    };
}
