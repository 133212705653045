import gql from 'graphql-tag';

export const UPDATE_USER = gql`
    mutation updateUser($input: UpdateUserInput!) {
        updateUser(input: $input) {
            id
            name
        }
    }
`;

export interface UpdateUserInput {
    input: {
        id: string;
        name: string;
    };
}

export interface UpdateUserResult {
    user: {
        id: string;
        name: string;
    };
}
