import { makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import StatusBadge, { Status } from '../../StatusBadge';

const useStyles = makeStyles(() => ({
    statusBadge: {
        boxSizing: 'border-box',
        width: '140px',
    },
}));

const AvailabilityStatus: FunctionComponent = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <StatusBadge
            className={classes.statusBadge}
            status={Status.NoAction}
            tooltip={t('pages.availability.availabilityStatus.tooltip')}
        >
            {t('pages.availability.availabilityStatus.text')}
        </StatusBadge>
    );
};

export default AvailabilityStatus;
