import { useMutation } from '@apollo/react-hooks';
import {
    Heading, Modal, PrimaryButton,
    SecondaryButton, SelectOption, Select, Alert,
} from '@get-e/react-components';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import moment, { Moment } from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UtcDateTimeField from '../../../components/UtcDateTimeField';
import getValue from '../../../helpers/getValue';
import nonNull from '../../../helpers/nonNull';
import FormError from '../../../helpers/validation/FormError';
import getFormErrorMessage from '../../../helpers/validation/getFormErrorMessage';
import useModalStyles from '../../../styles/useModalStyles';
import { RideRowProps } from '../../Requests/Request/Coaches/RideRow';
import { Ride } from '../useCoachRoutes';
import {
    OverrideDriverStatusInput,
    OverrideDriverStatusResult,
    OVERRIDE_DRIVER_STATUS,
} from './DriverStatusModal.graphql';

const EditUserModal: FunctionComponent<{
    ride: Ride | RideRowProps;
    onClose: () => void;
    onSuccess: () => void;
}> = ({ onClose, ride, onSuccess }) => {
    // TODO: Stopover logic

    const options = [
        {
            value: 'startedAt',
            title: 'Ride started',
            time: ride.events.driverDispatchedAt,
        },
        {
            value: 'arrivedAt',
            title: 'At pickup',
            time: ride.pickupPoint.trackingTimes.actualArrival,
        },
        {
            value: 'departedAt',
            title: 'En route',
            time: ride.pickupPoint.trackingTimes.actualDeparture,
        },
        {
            value: 'arrivedAtDropOff',
            title: 'Dropped off',
            time: ride.dropOffPoint.trackingTimes.actualArrival,
        },
    ];

    interface Status {
        rideId: string;
        startedAt: string | null;
        points: Array<{
            arrivedAt: string | null;
            departedAt?: string | null;
        }>;
    }

    const originalStatus = {
        rideId: ride.id,
        startedAt: ride.events.driverDispatchedAt?.format() ?? null,
        points: [
            {
                arrivedAt: ride.pickupPoint.trackingTimes
                    .actualArrival?.format() ?? null,
                departedAt: ride.pickupPoint.trackingTimes
                    .actualDeparture?.format() ?? null,
            },
            {
                arrivedAt: ride.dropOffPoint.trackingTimes
                    .actualArrival?.format() ?? null,
            },
        ],
    };

    const modalClasses = useModalStyles();
    const { t } = useTranslation();

    const latestStatus = options
        .find(opt => opt.time === null) ?? options[options.length - 1];

    const [currentStatus, setCurrentStatus] = useState(latestStatus);

    const [
        currentTime,
        setCurrentTime,
    ] = useState<Moment | null>(latestStatus.time);

    const [formError, setFormError] = useState<FormError | null>(null);

    const currentStatusIndex = options.map(opt => opt.value).indexOf(currentStatus.value);
    const latestStatusIndex = options.indexOf(latestStatus);

    const disableSkipping = currentStatusIndex > latestStatusIndex;

    const disableClear = currentStatusIndex < latestStatusIndex - 1;

    const updateStatusTime = (time: Moment | null): Status => {
        const updatedStatus = { ...originalStatus };

        switch (currentStatus.value) {
            case 'startedAt':
                updatedStatus.startedAt
                    = time?.format() ?? null;
                break;
            case 'arrivedAt':
                updatedStatus.points[0].arrivedAt
                    = time?.format() ?? null;
                break;
            case 'departedAt':
                updatedStatus.points[0].departedAt
                    = time?.format() ?? null;
                break;
            case 'arrivedAtDropOff':
                updatedStatus.points[1].arrivedAt
                    = time?.format() ?? null;
                break;
            default:
                throw new Error('Wrong status provided');
        }

        return updatedStatus;
    };

    const [
        overrideDriverStatus,
        { loading: overriding },
    ] = useMutation<OverrideDriverStatusResult, OverrideDriverStatusInput>(
        OVERRIDE_DRIVER_STATUS
    );

    const [newStatus, setNewStatus] = useState(originalStatus);

    async function submitForm(): Promise<void> {
        try {
            await overrideDriverStatus({ variables: { input: newStatus } });
            onClose();
            onSuccess();
        } catch (error) {
            setFormError(FormError.UnexpectedError);
        }
    }

    return (
        <Modal
            open={true}
            onClose={onClose}
        >
            <div>
                <div>
                    <Heading
                        level={2}
                    >
                        Update driver status
                    </Heading>
                </div>

                <div>
                    <Select
                        label="Driver status"
                        value={currentStatus.value}
                        onChange={value => {
                            setNewStatus(originalStatus);

                            const selected
                                = nonNull(options.find(opt => opt.value === value));

                            setCurrentStatus(selected);
                            setCurrentTime(selected.time);
                        }}
                    >
                        {options.map(option => (
                            <SelectOption
                                key={option.title}
                                value={option.value}
                            >
                                {
                                    `${option.title} ${option.time === null
                                        ? ''
                                        : ' [Completed]'}`
                                }
                            </SelectOption>
                        ))}

                    </Select>
                </div>
                <Grid container justify="center" alignItems="center">
                    <Grid item xs={10}>
                        <UtcDateTimeField
                            value={currentTime}
                            label="Time"
                            disableClear
                            disabled={disableSkipping}
                            onChange={time => {
                                const updatedStatus = updateStatusTime(time);

                                setCurrentTime(time);
                                setNewStatus(updatedStatus);
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Tooltip
                            title={getValue(() => {
                                if (disableClear || disableSkipping) {
                                    return 'Skipping is disabled';
                                }

                                return 'Clear status';
                            })}
                        >
                            <span>
                                <IconButton
                                    disabled={disableClear || disableSkipping}
                                    className={modalClasses.centerButton}
                                    onClick={() => {
                                        setCurrentTime(null);
                                        setNewStatus(updateStatusTime(null));
                                    }}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                </Grid>

                {(() => {
                    if (formError === null && !disableSkipping) {
                        return null;
                    }

                    const message = getValue(() => {
                        if (formError) {
                            return getFormErrorMessage(formError, t);
                        }

                        return t('errors.forms.skipStatus');
                    });

                    return (
                        <Alert severity="error">
                            {message}
                        </Alert>
                    );
                })()}

                <Grid
                    container
                    className={modalClasses.buttonContainer}
                >
                    <Grid item>
                        <PrimaryButton
                            onClick={submitForm}
                            loading={overriding}
                            disabled={disableSkipping}
                            className={modalClasses.primaryButton}
                        >
                            Update
                        </PrimaryButton>
                        <SecondaryButton
                            onClick={onClose}
                            disabled={overriding}
                        >
                            Close
                        </SecondaryButton>
                    </Grid>
                </Grid>
            </div>
        </Modal>
    );
};

export default EditUserModal;
