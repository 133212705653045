import { makeStyles } from '@material-ui/core';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import getValue from '../../helpers/getValue';

const useLabelStyles = makeStyles({
    root: { textAlign: 'center' },
    arrow: { fontSize: '1.5rem' },
    text: { fontWeight: 600 },
    inactive: { color: '#767676' },
    icon: {
        verticalAlign: 'middle',
        marginRight: '.3rem',
        fontSize: '1.3rem',
    },
    coachCount: { verticalAlign: 'middle' },
});

const CoachesStatusLabel: FunctionComponent<{
    status: string;
    coaches: number;
    inactive?: boolean;
}> = ({ status, coaches, inactive }) => {
    const classes = useLabelStyles();

    return (
        <div
            className={clsx(
                classes.root,
                { [classes.inactive]: inactive }
            )}
        >
            <div>
                <ExpandLessIcon className={classes.arrow} />
            </div>

            <div className={classes.text}>
                {status}
            </div>

            {
                inactive || coaches === 0
                    ? null
                    : (
                        <div>
                            <AirportShuttleIcon className={classes.icon} />

                            <span className={classes.coachCount}>
                                {coaches}
                            </span>
                        </div>
                    )
            }
        </div>
    );
};

export default CoachesStatusLabel;
