import { LoadingPage } from '@get-e/react-components';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import Retry from '../../../../components/Retry';
import {
    BreadcrumbHeadNode,
    PageDefinition,
} from '../../../../createRoutes';
import useLoaderDebounce from '../../../../helpers/useLoaderDebounce';
import Page from '../../../../layouts/Page';
import {
    GetSupplierNameInput,
    GetSupplierNameResult,
    GET_SUPPLIER_NAME,
} from '../../../../queries/GetSupplierName.graphql';
import Content from './Content';
import {
    GetSupplierUserInput,
    GetSupplierUserResult,
    GET_SUPPLIER_USER,
} from './index.graphql';

const editSupplierUserPage: PageDefinition = {
    path: '/suppliers/:supplierId/users/:userId',
    Component({ getParam }) {
        const { t } = useTranslation();
        const supplierId = getParam('supplierId');
        const userId = getParam('userId');

        const {
            data: userData,
            loading: userLoading,
            error: userError,
            refetch: userRefetch,
        } = useQuery<GetSupplierUserResult, GetSupplierUserInput>(
            GET_SUPPLIER_USER,
            {
                variables: { id: userId },
                notifyOnNetworkStatusChange: true,
            }
        );

        const {
            data,
            loading,
            error,
        } = useQuery<GetSupplierNameResult, GetSupplierNameInput>(
            GET_SUPPLIER_NAME,
            { variables: { id: supplierId } }
        );

        const supplierName = ((): BreadcrumbHeadNode['name'] => {
            if (loading || error) {
                // Note: In case of an error the user will see the error boundary instead
                return <Skeleton width="7ch" />;
            }

            if (!data || !data.supplier) {
                return t('breadcrumbs.unknownSupplier');
            }

            return data.supplier.name;
        })();

        const showLoader = useLoaderDebounce(loading);

        if (userLoading && !userData) {
            return showLoader
                ? <LoadingPage />
                : null;
        }

        return (
            <Page
                breadcrumbs={{
                    name: t('breadcrumbs.editSupplierUser'),
                    previous: {
                        name: supplierName,
                        path: `/suppliers/${supplierId}/users`,
                        previous: {
                            name: t('breadcrumbs.suppliers'),
                            path: '/suppliers',
                        },
                    },
                }}
            >
                {(userError || !userData)
                    ? (
                        <Retry onRetry={() => userRefetch()} loading={userLoading} />
                    )
                    : (
                        <Content
                            supplierId={supplierId}
                            user={userData.user}
                        />
                    )
                }
            </Page>
        );
    },
};

export default editSupplierUserPage;
