import { createStyles, makeStyles } from '@material-ui/core';
import LocationOffIcon from '@material-ui/icons/LocationOff';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import StatusBadge, { Status } from '../../StatusBadge';

const useStyles = makeStyles(() => createStyles({
    statusBadge: {
        boxSizing: 'border-box',
        width: '140px',
    },
    noTrackingIcon: {
        marginLeft: '.5rem',
        alignSelf: 'center',
    },
}));

const RideStatus: FunctionComponent<{
    cancelled: boolean;
    driverAssigned: boolean;
    confirmed: boolean;
    pickupTime: Date;
    dropOffTime: Date | null;
    points: Array<{
        arrived: boolean;
        departed: boolean;
    }>;
    driverDispatched: boolean;
}> = props => {
    const classes = useStyles();
    const { t } = useTranslation();

    if (props.cancelled) {
        return (
            <StatusBadge
                className={classes.statusBadge}
                tooltip={t('pages.availability.rides.statusTooltips.cancelled')}
                status={Status.GreyedOut}
            >
                {t('pages.availability.rides.statuses.cancelled')}
            </StatusBadge>
        );
    }

    if (!props.driverAssigned) {
        return (
            <StatusBadge
                className={classes.statusBadge}
                tooltip={t('pages.availability.rides.statusTooltips.toAssignDriver')}
                status={Status.ActionRequired}
            >
                {t('pages.availability.rides.statuses.toAssignDriver')}
            </StatusBadge>
        );
    }

    if (!props.confirmed) {
        return (
            <StatusBadge
                className={classes.statusBadge}
                tooltip={t('pages.availability.rides.statusTooltips.toConfirm')}
                status={Status.ActionRequired}
            >
                {t('pages.availability.rides.statuses.toConfirm')}
            </StatusBadge>
        );
    }

    const atDestination = props.points[props.points.length - 1].arrived;

    if (atDestination) {
        return (
            <StatusBadge
                className={classes.statusBadge}
                tooltip={t('pages.availability.rides.statusTooltips.completed')}
                status={Status.NoAction}
            >
                {t('pages.availability.rides.statuses.completed')}
            </StatusBadge>
        );
    }

    const enRouteToDestination = props.points[props.points.length - 2].departed;

    if (enRouteToDestination) {
        return (
            <StatusBadge
                className={classes.statusBadge}
                tooltip={t('pages.availability.rides.statusTooltips.passengerOnBoard')}
                status={Status.InProgress}
            >
                {t('pages.availability.rides.statuses.passengerOnBoard')}
            </StatusBadge>
        );
    }

    // TODO: Support stopovers

    const driverAtPickup = props.points[0].arrived;

    if (driverAtPickup) {
        return (
            <StatusBadge
                className={classes.statusBadge}
                tooltip={t('pages.availability.rides.statusTooltips.atPickup')}
                status={Status.InProgress}
            >
                {t('pages.availability.rides.statuses.atPickup')}
            </StatusBadge>
        );
    }

    if (props.driverDispatched) {
        return (
            <StatusBadge
                className={classes.statusBadge}
                tooltip={t('pages.availability.rides.statusTooltips.driverOnTheWay')}
                status={Status.InProgress}
            >
                {t('pages.availability.rides.statuses.driverOnTheWay')}
            </StatusBadge>
        );
    }

    if (props.dropOffTime && props.dropOffTime <= new Date()) {
        return (
            <StatusBadge
                className={classes.statusBadge}
                tooltip={t('pages.availability.rides.statusTooltips.completedNoTracking')}
                status={Status.NoAction}
            >
                {t('pages.availability.rides.statuses.completed')}
                <LocationOffIcon className={classes.noTrackingIcon}/>
            </StatusBadge>
        );
    }

    if (props.pickupTime <= new Date()) {
        return (
            <StatusBadge
                className={classes.statusBadge}
                tooltip={t('pages.availability.rides.statusTooltips.inProgressNoTracking')}
                status={Status.Critical}
            >
                {t('pages.availability.rides.statuses.inProgress')}
                <LocationOffIcon className={classes.noTrackingIcon}/>
            </StatusBadge>
        );
    }

    return (
        <StatusBadge
            className={classes.statusBadge}
            tooltip={t('pages.availability.rides.statuses.confirmed')}
            status={Status.NoAction}
        >
            {t('pages.availability.rides.statuses.confirmed')}
        </StatusBadge>
    );
};

export default RideStatus;
