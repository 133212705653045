import {
    Alert, Form, PrimaryButton,
    SignedOutLayout, TertiaryButtonLink, TextField,
} from '@get-e/react-components';
import {
    IconButton, InputAdornment,
    makeStyles, Paper, Typography,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useApolloClient } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import report from '../helpers/report';
import useUrlQuery from '../helpers/useUrlQuery';
import chooseInputError from '../helpers/validation/chooseInputError';
import FormError from '../helpers/validation/FormError';
import getFormErrorMessage from '../helpers/validation/getFormErrorMessage';
import getHelperText from '../helpers/validation/getHelperText';
import InputError from '../helpers/validation/InputError';
import parseInputErrorCode from '../helpers/validation/parseInputErrorCode';
import signIn from '../services/signIn';
import useFormStyles from '../styles/useFormStyles';

const useStyles = makeStyles({
    container: {
        padding: '2em',
        maxWidth: '320px',
        margin: '0 auto',
    },
    heading: { marginBottom: '1rem' },
    forgotPasswordContainer: {
        marginTop: '1rem',
        textAlign: 'center',
    },
    forgotPasswordLink: {
        textDecoration: 'none',
        color: '#828282',
        fontWeight: 600,
    },
    signInButtonContainer: { marginTop: '1rem' },
});

const SignIn: FunctionComponent = () => {
    const classes = useStyles();
    const formClasses = useFormStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const redirect = useUrlQuery().get('from');
    const autoFocusRef = useRef<HTMLInputElement>();
    const apolloClient = useApolloClient();

    useEffect(() => {
        autoFocusRef.current?.focus();
    }, [autoFocusRef]);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState<InputError | null>(null);

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState<InputError | null>(null);

    const [showingPassword, setShowingPassword] = useState(false);
    const [signingIn, setSigningIn] = useState(false);

    const [formError, setFormError] = useState<
        FormError | 'INVALID_CREDENTIALS' | null
    >(null);

    async function submitForm(): Promise<void> {
        setSigningIn(true);

        try {
            const result = await signIn({
                email,
                password,
                apolloClient,
            });

            setSigningIn(false);

            switch (result.code) {
                case 'COOKIES_SET':
                    history.push(redirect ?? '/');
                    break;
                case 'INVALID_CREDENTIALS':
                    setFormError('INVALID_CREDENTIALS');
                    break;
                case 'INVALID_INPUT':
                    setFormError(FormError.UserError);

                    if (result.errors.email.length) {
                        const errors = result.errors.email
                            .map(error => parseInputErrorCode(error.code));

                        setEmailError(chooseInputError(errors));
                    }

                    if (result.errors.password.length) {
                        const errors = result.errors.password
                            .map(error => parseInputErrorCode(error.code));

                        setPasswordError(chooseInputError(errors));
                    }

                    break;
                default:
                    throw new Error('Unexpected result code');
            }
        } catch (error) {
            report(error);
            setFormError(FormError.UnexpectedError);
            setSigningIn(false);
        }
    }

    const showPasswordButton = (
        <InputAdornment position="end">
            <IconButton
                aria-label={
                    showingPassword
                        ? t('pages.signIn.aria.hidePasswordButton')
                        : t('pages.signIn.aria.showPasswordButton')
                }
                onClick={() => setShowingPassword(!showingPassword)}
                onMouseDown={event => event.preventDefault()}
            >
                {showingPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
        </InputAdornment>
    );

    return (
        <SignedOutLayout>
            <Paper elevation={0} className={classes.container}>
                <Form onSubmit={submitForm}>
                    <Typography variant="h2" component="h2" className={classes.heading}>
                        {t('pages.signIn.heading')}
                    </Typography>

                    <TextField
                        value={email}
                        onChange={event => {
                            setEmail(event.target.value);
                            setEmailError(null);
                        }}
                        label={t('pages.signIn.inputs.email')}
                        type="email"
                        autoFocus
                        autoComplete="username"
                        name="email"
                        inputRef={autoFocusRef}
                        error={emailError !== null}
                        helperText={getHelperText(emailError, t)}
                        required
                    />

                    <TextField
                        label={t('pages.signIn.inputs.password')}
                        type={showingPassword ? 'text' : 'password'}
                        value={password}
                        onChange={event => {
                            setPassword(event.target.value);
                            setPasswordError(null);
                        }}
                        autoComplete="current-password"
                        InputProps={{ endAdornment: showPasswordButton }}
                        error={passwordError !== null}
                        helperText={getHelperText(passwordError, t)}
                        required
                    />

                    {
                        formError
                            ? (
                                <Alert
                                    severity="error"
                                    className={formClasses.mainError}
                                >
                                    {
                                        formError === 'INVALID_CREDENTIALS'
                                            ? t(
                                                'pages.signIn.formErrors'
                                                + '.invalidCredentials'
                                            )
                                            : getFormErrorMessage(formError, t)
                                    }
                                </Alert>
                            )
                            : null
                    }

                    <div className={classes.signInButtonContainer}>
                        <PrimaryButton
                            onClick={() => submitForm()}
                            loading={signingIn}
                            fullWidth
                            submitsForm
                        >
                            {t('pages.signIn.signInButton')}
                        </PrimaryButton>
                    </div>

                    <div className={classes.forgotPasswordContainer}>
                        <TertiaryButtonLink to="/forgot-password">
                            {t('pages.signIn.forgotPasswordLink')}
                        </TertiaryButtonLink>
                    </div>

                </Form>
            </Paper>
        </SignedOutLayout>
    );
};

export default SignIn;
