import { LoadingPage, PrimaryButton, Tab, TabPanel, Tabs } from '@get-e/react-components';
import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { FunctionComponent } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Retry from '../../components/Retry';
import { useCurrentUserContext } from '../../context/CurrentUserContext';
import getValue from '../../helpers/getValue';
import report from '../../helpers/report';
import useTablePageStyles from '../useTablePageStyles';
import ActiveTab from './ActiveTab';
import ArchiveTab from './ArchiveTab';
import { GetAddDisruptionPermissionInput, GetAddDisruptionPermissionResult, GET_ADD_DISRUPTION_PERMISSION } from './Content.graphql';

export enum DisruptionsTab {
    Active,
    Archive
}

const Content: FunctionComponent<{ tab: DisruptionsTab }> = ({ tab }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const tablePageClasses = useTablePageStyles();

    const currentUser = useCurrentUserContext();

    const customerId = currentUser.managedCustomer === 'MULTIPLE'
        ? null
        : currentUser.managedCustomer?.id ?? null;

    const {
        data: permissionData,
        error,
        refetch,
        loading,
    } = useQuery<GetAddDisruptionPermissionResult, GetAddDisruptionPermissionInput>(
        GET_ADD_DISRUPTION_PERMISSION,
        {
            skip: !customerId,
            variables: { customerId: customerId ?? 'SHOULD_BE_SKIPPED' },
            onError(apolloError) {
                report(apolloError);
            },
        }
    );

    if (loading) {
        return <LoadingPage />;
    }

    if (error) {
        return <Retry onRetry={() => refetch()} loading={loading} />;
    }

    const canCreateDisruption = getValue(() => {
        if (currentUser.isBackOffice) {
            return true;
        }

        if (!permissionData?.customer) {
            /*
             * TODO: This permission check does not work yet if the user belongs
             * to multiple customers.
             */
            return false;
        }

        return permissionData.customer.accessibleMutations.createArrivalDisruption;
    });

    return (
        <>
            <Grid
                item
                xs={12}
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item>
                    <div className="root">
                        <Tabs value={tab}>
                            <Tab
                                label={t('pages.disruptions.tabs.active')}
                                url="/disruptions/active"
                                index={DisruptionsTab.Active}
                                selectedIndex={tab}
                            />

                            <Tab
                                label={t('pages.disruptions.tabs.archive')}
                                url="/disruptions/archive"
                                index={DisruptionsTab.Archive}
                                selectedIndex={tab}
                            />
                        </Tabs>
                    </div>
                </Grid>

                {canCreateDisruption
                    ? (
                        <Grid item className={tablePageClasses.addButton}>
                            <PrimaryButton
                                onClick={() => history.push('/disruptions/add-arrival')}
                                icon={<AddIcon />}
                            >
                                {t('pages.disruptions.buttons.addArrivalDisruption')}
                            </PrimaryButton>
                        </Grid>
                    )
                    : null
                }

            </Grid>

            {tab === DisruptionsTab.Active && (
                <TabPanel value={DisruptionsTab.Active} selectedValue={tab}>
                    <ActiveTab />
                </TabPanel>
            )}

            {tab === DisruptionsTab.Archive && (
                <TabPanel value={DisruptionsTab.Archive} selectedValue={tab}>
                    <ArchiveTab />
                </TabPanel>
            )}
        </>
    );
};

export default Content;
