import {
    LoadingPage,
    MenuButtonItem, OptionsTableCell, TableBody,
    TableContainer, TableFooter, TableHead, TableHeader,
} from '@get-e/react-components';
import { Table, TableCell, TableRow } from '@material-ui/core';
import moment, { Moment } from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Retry from '../../../components/Retry';
import formatPastDate from '../../../helpers/formatPastDate';
import report from '../../../helpers/report';
import useDebounce from '../../../helpers/useDebounce';
import useLoaderDebounce from '../../../helpers/useLoaderDebounce';
import useUrlQuery from '../../../helpers/useUrlQuery';
import useTablePageStyles from '../../useTablePageStyles';
import {
    GetCustomerInvitesInput,
    GetCustomerInvitesResult,
    GET_CUSTOMER_INVITES,
} from './CustomerUsers.graphql';

const Row: FunctionComponent<{
    invite: RowDetails;
    onDeleteClick: () => void;
}> = ({ invite, onDeleteClick }) => {
    const { t } = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);
    const invitedOn = formatPastDate(moment(invite.createdAt), t);

    function handleDeleteClick(): void {
        setMenuOpen(false);
        onDeleteClick();
    }

    return (
        <TableRow>
            <TableCell>{invite.name}</TableCell>
            <TableCell>{invite.email}</TableCell>
            <TableCell>{invitedOn}</TableCell>
        </TableRow>
    );
};

export interface UserInvitesTabProps {
    onUpdate?: (event: { total: number }) => void;
    onDeleteInvite: (invite: { id: string; userName: string }) => void;
    customerId: string;
}

interface RowDetails {
    id: string;
    name: string;
    email: string;
    createdAt: string;
}

const UserInvitesTab: FunctionComponent<UserInvitesTabProps> = ({ onUpdate, onDeleteInvite, customerId }) => {
    const { t } = useTranslation();
    const classes = useTablePageStyles();
    const history = useHistory();
    const query = useUrlQuery();
    const after = query.get('after');
    const before = query.get('before');
    const paginationLimit = 10;

    const [search, setSearch] = useState('');
    const debouncedSearchValue = useDebounce(search, 500);

    const variables = ((): GetCustomerInvitesInput => {
        const usersFilter: null | { search: string }
            = debouncedSearchValue
                ? { search: debouncedSearchValue }
                : null;

        if (before !== null) {
            return {
                id: customerId,
                last: paginationLimit,
                before: before || undefined,
                filter: usersFilter,
            };
        }

        return {
            id: customerId,
            first: paginationLimit,
            after: after ?? undefined,
            filter: usersFilter,
        };
    })();

    const {
        data,
        loading,
        error,
        refetch,
    } = useQuery<GetCustomerInvitesResult>(
        GET_CUSTOMER_INVITES,
        {
            variables,
            notifyOnNetworkStatusChange: true,
            onError(apolloError) {
                report(apolloError);
            },
            fetchPolicy: 'cache-and-network',
        }
    );

    useEffect(() => {
        onUpdate?.({ total: data?.customer.userInvites.nodes.length ?? 0 });
    }, [data?.customer.userInvites.nodes.length, onUpdate]);

    const showLoader = useLoaderDebounce(loading);

    if (loading && !data) {
        return showLoader
            ? <LoadingPage />
            : null;
    }

    if (error || !data) {
        return <Retry onRetry={() => refetch()} loading={loading} />;
    }

    const handleBackToStartClick = (): void => {
        history.push({ search: '' });
    };

    const handlePreviousClick = (): void => {
        const { startCursor } = data.customer.userInvites.pageInfo;

        if (startCursor === null) {
            throw new Error('Page info does not contain start cursor');
        }

        history.push({ search: `?before=${startCursor}` });
    };

    const handleNextClick = (): void => {
        const { endCursor } = data.customer.userInvites.pageInfo;

        if (endCursor === null) {
            throw new Error('Page info does not contain end cursor');
        }

        history.push({ search: `?after=${endCursor}` });
    };

    const handleSkipToEndClick = (): void => {
        history.push({ search: '?before' });
    };

    const invites = data.customer.userInvites.nodes;

    return (
        <TableContainer className={classes.table}>
            <TableHeader
                title="Invited users"
                onSearchChange={searchValue => setSearch(searchValue)}
            />

            <Table aria-label={t('pages.users.aria.invitesTable')}>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('pages.users.column.name')}</TableCell>
                        <TableCell>{t('pages.users.column.email')}</TableCell>
                        <TableCell>{t('pages.users.column.invitedOn')}</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody loading={loading}>
                    {invites.map(invite => (
                        <Row
                            invite={invite}
                            key={invite.email}
                            onDeleteClick={() => onDeleteInvite({
                                id: invite.id,
                                userName: invite.name,
                            })}
                        />
                    ))}
                </TableBody>
            </Table>

            <TableFooter
                count={{
                    total: invites.length,
                    currentPage: invites.length,
                }}
                hasPrevious={data.customer.userInvites.pageInfo.hasPreviousPage}
                hasNext={data.customer.userInvites.pageInfo.hasNextPage}
                onBackToStartClick={handleBackToStartClick}
                onPreviousClick={handlePreviousClick}
                onNextClick={handleNextClick}
                onSkipToEndClick={handleSkipToEndClick}
                loading={loading}
            />
        </TableContainer>
    );
};

export default UserInvitesTab;
