import { makeStyles } from '@material-ui/core';

const useTableStyles = makeStyles(() => ({
    buttonColumn: {
        width: 1,
        textAlign: 'center',
        padding: '0 16px',
    },
    collapsableCell: { padding: 0 },
    collapsedCell: { border: 'none' },
    expandedBackground: { backgroundColor: '#f8fafd' },
    noWrap: { whiteSpace: 'nowrap' },
    clickableRow: { '&:hover': { cursor: 'pointer' } },
    tableEmptyCell: {
        color: '#767676',
        textAlign: 'center',
    },
    tableEmptyIcon: {
        height: '1.5em',
        marginRight: '.5em',
        verticalAlign: 'middle',
    },
    tableEmptyText: { verticalAlign: 'middle' },
}));

export default useTableStyles;
