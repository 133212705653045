import { Moment } from 'moment';
import { createContext, useContext } from 'react';

export interface CurrentUserContextValue {
    id: string;
    name: string;
    email: string;
    joinedOn: Moment;
    isBackOffice: boolean;
    managedCustomer: null | 'MULTIPLE' | {
        id: string;
        name: string;
        customerPermissions: {
            manageUsers: boolean;
        }
    };
    managedSupplier: null | {
        id: string;
        manageUsers: boolean;
    };
}

export const CurrentUserContext = createContext<CurrentUserContextValue | null>(null);

export const useCurrentUserContext = (): CurrentUserContextValue => {
    const currentUserContext = useContext(CurrentUserContext);

    if (currentUserContext === null) {
        throw new Error('CurrentUserContext should not be null');
    }

    return currentUserContext;
};
