/* eslint-disable max-lines */
import { Heading, Spinner } from '@get-e/react-components';
import {
    Grid, Collapse, Box,
    Paper, createStyles, makeStyles, Tooltip, IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import ScheduleIcon from '@material-ui/icons/Schedule';
import clsx from 'clsx';
import moment, { Moment } from 'moment';
import React, { useState, FunctionComponent } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import GoogleMap, { DriverPosition } from '../../../../components/GoogleMap';
import LocalTime from '../../../../components/LocalTime';
import Retry from '../../../../components/Retry';
import { useCurrentUserContext } from '../../../../context/CurrentUserContext';
import getValue from '../../../../helpers/getValue';
import report from '../../../../helpers/report';
import useLoaderDebounce from '../../../../helpers/useLoaderDebounce';
import {
    GetRidePositionUpdateResult,
    GetRidePositionUpdateInput,
    GET_DRIVER_POSITION_FROM_RIDE,
} from '../../../../queries/GetDriverPositionFromRide.graphql';
import useTableStyles from '../../../../styles/useTableStyles';
import BusNumberForm from '../../../Disruption/BusNumberForm';
import DriverStatusModal from '../../../Disruption/CoachRows/DriverStatusModal';
import { DriverStatus } from '../../../Disruption/useCoachRoutes';
import DriverForm from '../DriverForm';
import {
    GetRideDataInput,
    GetRideDataResult,
    GET_RIDE_DATA,
} from './CoachEdit.graphql';
import { RideRowProps } from './RideRow';
import useRowStyles from './useRowStyles';

const useStyles = makeStyles(theme => createStyles({
    rideSection: { '&:not(:last-child)': { marginBottom: '1.5rem' } },
    paper: {
        height: '100%',
        marginLeft: '2rem',
    },
    statusBlock: { margin: theme.spacing(4) },
    done: { fontWeight: 500 },
    active: { color: theme.palette.success.main },
    incomplete: { opacity: '0.6' },
    statusItem: { '&:not(:last-child)': { marginBottom: theme.spacing(1) } },
    incompleteStatusIcon: {
        height: '.9em',
        verticalAlign: 'middle',
    },
    statusText: { verticalAlign: 'middle' },
    statusTime: {
        verticalAlign: 'middle',
        marginRight: '.25rem',
    },
}));

interface StatusItem {
    title: string;
    time: Moment | null;
    isEstimate: boolean;
}

enum StatusCompletion {
    Incomplete,
    Active,
    Complete,
}

const Status: FunctionComponent<{
    status: StatusItem;
    currentStep: number;
    activeStep: number;
    timeZone: string;
}> = ({ status, activeStep, currentStep, timeZone }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const currentStatus = getValue(() => {
        if (currentStep < activeStep) {
            return StatusCompletion.Complete;
        }

        if (currentStep === activeStep) {
            return StatusCompletion.Active;
        }

        return StatusCompletion.Incomplete;
    });

    const displayTime = getValue(() => {
        if (status.time === null) {
            return (
                <Tooltip title={<>{t('pages.availability.statusUpdates.waiting')}</>}>
                    <ScheduleIcon className={classes.incompleteStatusIcon} />
                </Tooltip>
            );
        }

        return (
            <LocalTime
                time={status.time}
                timeZone={timeZone}
                estimate={status.isEstimate}
            />
        );
    });

    return (
        <div
            className={
                clsx(
                    classes.statusItem,
                    {
                        [classes.done]: currentStatus === StatusCompletion.Complete,
                        [classes.active]: currentStatus === StatusCompletion.Active,
                        [classes.incomplete]:
                            currentStatus === StatusCompletion.Incomplete,
                    }
                )
            }
        >
            <strong className={classes.statusTime}>{displayTime}</strong>
            <span className={classes.statusText}>{status.title}</span>
        </div >
    );
};

const StatusList: FunctionComponent<{
    statuses: StatusItem[];
    activeStep: number;
    timeZone: string;
    onRefetch: () => void;
    ride: RideRowProps;
}> = ({ statuses, activeStep, timeZone, onRefetch, ride }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [driverStatusModal, setDriverStatusModal] = useState(false);
    const currentUser = useCurrentUserContext();
    const rowClasses = useRowStyles();

    return (
        <div className={classes.statusBlock}>
            {driverStatusModal
                && (
                    <DriverStatusModal
                        onClose={() => setDriverStatusModal(false)}
                        onSuccess={() => onRefetch()}
                        ride={ride}
                    />
                )
            }

            <Heading level={4}>
                {t('pages.availability.statusUpdates.title')}
                {currentUser.isBackOffice && (
                    <IconButton
                        className={rowClasses.rideStatusButton}
                        onClick={() => setDriverStatusModal(true)}
                    >
                        <EditIcon />
                    </IconButton>
                )}
            </Heading>
            {statuses.map((status, index) => (
                <Status
                    key={index}
                    status={status}
                    currentStep={index}
                    activeStep={activeStep}
                    timeZone={timeZone}
                />
            ))}
        </div>
    );
};

const CoachRideEdit: FunctionComponent<{
    open: boolean;
    ride: RideRowProps;
    bookedAutomatically: boolean;
    requestCreatedAt: Moment | null;
}> = ({ open, ride, bookedAutomatically, requestCreatedAt }) => {
    const classes = useStyles();
    const tableClasses = useTableStyles();
    const { t } = useTranslation();

    const {
        data,
        loading,
        error,
        refetch,
    } = useQuery<GetRidePositionUpdateResult, GetRidePositionUpdateInput>(
        GET_DRIVER_POSITION_FROM_RIDE,
        {
            variables: { id: ride.id },
            onError(apolloError) {
                report(apolloError);
            },
            pollInterval: open ? 5000 : 0,
        }
    );

    const {
        data: rideData,
        error: rideError,
        loading: rideLoading,
        refetch: refetchRide,
    } = useQuery<GetRideDataResult, GetRideDataInput>(
        GET_RIDE_DATA,
        { variables: { id: ride.id } }
    );

    const showLoader = useLoaderDebounce(loading);

    if (loading || rideLoading) {
        return showLoader
            ? <Spinner size={20} />
            : null;
    }

    if (!rideData || rideError) {
        return <Retry onRetry={() => refetchRide()} loading={rideLoading} />;
    }

    const driverSteps: StatusItem[] = [
        {
            title: t('pages.availability.statusUpdates.onTheWay'),
            time: ride.events.driverDispatchedAt,
            isEstimate: false,
        },
        {
            title: t('pages.availability.statusUpdates.atPickup'),
            time: ride.pickupPoint.trackingTimes.actualArrival
                ?? ride.pickupPoint.trackingTimes.estimatedArrival,
            isEstimate: ride.pickupPoint.trackingTimes.actualArrival === null,
        },
        {
            title: t('pages.availability.statusUpdates.onBoard'),
            time: ride.pickupPoint.trackingTimes.actualDeparture
                ?? ride.pickupPoint.trackingTimes.estimatedDeparture,
            isEstimate: ride.pickupPoint.trackingTimes.actualDeparture === null,
        },
        {
            title: t('pages.availability.statusUpdates.arrived'),
            time: ride.dropOffPoint.trackingTimes.actualArrival
                ?? ride.dropOffPoint.trackingTimes.estimatedArrival,
            isEstimate: ride.dropOffPoint.trackingTimes.actualArrival === null,
        },
    ];

    const statuses: StatusItem[] = bookedAutomatically
        ? [
            {
                title: t('pages.availability.statusUpdates.requested'),
                time: requestCreatedAt,
                isEstimate: false,
            },
            {
                title: t('pages.availability.statusUpdates.booked'),
                time: ride.events.createdAt,
                isEstimate: false,
            },
            ...driverSteps,
        ]
        : [
            {
                title: t('pages.availability.statusUpdates.requested'),
                time: requestCreatedAt,
                isEstimate: false,
            },
            {
                title: t('pages.availability.statusUpdates.added'),
                time: ride.events.availableAt,
                isEstimate: false,
            },
            {
                title: t('pages.availability.statusUpdates.booked'),
                time: ride.events.createdAt,
                isEstimate: false,
            },
            {
                title: t('pages.availability.statusUpdates.driverAdded'),
                time: ride.events.confirmedAt,
                isEstimate: false,
            },
            ...driverSteps,
        ];

    const getActiveStep = getValue(() => {
        if (ride.events.createdAt !== null) {
            return 3;
        }

        switch (ride.driverStatus) {
            case null:
                return 3;
            case DriverStatus.OnTheWay:
                return 4;
            case DriverStatus.AtPickup:
                return 5;
            case DriverStatus.OnBoard:
                return 5;
            case DriverStatus.DroppedOff:
                return 6;
            default:
                throw new Error('Unhandled driver status');
        }
    });

    const points = ride.points.nodes.map(point => point.location.coordinates);

    if (error || !data?.ride) {
        return <Retry onRetry={() => refetch()} loading={loading} />;
    }

    const { positionUpdates } = data.ride;
    const latestPosition = positionUpdates.nodes[0];

    let driverPosition: DriverPosition = null;

    if (latestPosition) {
        driverPosition = {
            timestamp: moment(latestPosition.capturedAt),
            coordinates: {
                latitude: latestPosition.position.coordinates.latitude,
                longitude: latestPosition.position.coordinates.longitude,
            },
            coordinatesAccuracyMeters: latestPosition.position.accuracyInMeters,
            bearingDegrees: latestPosition.bearing.degrees,
        };
    }

    return (
        <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            className={tableClasses.expandedBackground}
        >
            <Box margin={2}>
                <Grid container>
                    <Grid item xs={6}>
                        <div className={classes.rideSection}>
                            <Heading level={4}>
                                {t('pages.availability.labels.driver')}
                            </Heading>
                            <DriverForm
                                supplierId={ride.supplier.id}
                                rideId={ride.id}
                                initialValue={rideData.ride.driver ?? null}
                                onSaved={refetchRide}
                                onUnassigned={refetchRide}
                            />
                        </div>
                        <div className={classes.rideSection}>
                            <Heading level={4}>
                                {t('pages.availability.labels.registration')}
                            </Heading>
                            <BusNumberForm
                                rideId={ride.id}
                                initialValue={rideData.ride.busNumber ?? null}
                                onSaved={refetchRide}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        {getValue(() => {
                            if (ride.points.nodes) {
                                return (
                                    <Paper className={classes.paper}>
                                        <GoogleMap
                                            points={points}
                                            driverPosition={driverPosition}
                                        />
                                    </Paper>
                                );
                            }
                        })}
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={6}>
                        <StatusList
                            statuses={statuses}
                            activeStep={getActiveStep}
                            timeZone={ride.pickupPoint.timeZone}
                            onRefetch={() => refetchRide()}
                            ride={ride}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Collapse>
    );
};

export default CoachRideEdit;
