import { Checkbox, TableCell, TableRow } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useLocaleContext } from '../../../context/LocaleContext';
import formatNumber from '../../../helpers/formatNumber';
import useTableStyles from '../../../styles/useTableStyles';

const AvailabilityRow: FunctionComponent<{
    seats: number;
    supplierName: string;
    estimatedArrivalTimeMinutes: number;
    selected: boolean;
    onSelect: () => void;
    onUnselect: () => void;
}> = ({
    seats, supplierName, estimatedArrivalTimeMinutes,
    selected, onSelect, onUnselect,
}) => {
    const tableClasses = useTableStyles();
    const { locale } = useLocaleContext();

    return (
        <>
            <TableRow>
                <TableCell className={tableClasses.buttonColumn}>
                    <Checkbox
                        checked={selected}
                        onChange={(_event, checked) => {
                            checked
                                ? onSelect()
                                : onUnselect();
                        }}
                    />
                </TableCell>
                <TableCell>
                    {
                        estimatedArrivalTimeMinutes === 1
                            ? `Lead time ${estimatedArrivalTimeMinutes} minute`
                            : `Lead time ${estimatedArrivalTimeMinutes} minutes`
                    }
                </TableCell>
                <TableCell>
                    {supplierName}
                </TableCell>
                <TableCell>
                    {formatNumber(seats, locale)} persons
                </TableCell>
                <TableCell>-</TableCell>
            </TableRow>
        </>
    );
};

export default AvailabilityRow;
