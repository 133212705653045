import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import config from '../config';
import errorHandler from './errorHandler';

const cache = new InMemoryCache();
const errorLink = onError(errorHandler);

const httpLink = createHttpLink({
    uri: `${config.apiUrl}/graphql`,
    credentials: 'include',
});

const link = ApolloLink.from([
    errorLink,
    httpLink,
]);

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache,
    link,
});

export default client;
