import { Skeleton } from '@material-ui/lab';
import React, { FunctionComponent } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useCurrentUserContext } from '../../../context/CurrentUserContext';
import { BreadcrumbHeadNode } from '../../../createRoutes';
import report from '../../../helpers/report';
import Page from '../../../layouts/Page';
import {
    GetSupplierNameInput,
    GetSupplierNameResult,
    GET_SUPPLIER_NAME,
} from '../../../queries/GetSupplierName.graphql';
import Content, { UserTab } from './Content';

interface InvitesPageProps {
    supplierId: string;
}

const SupplierInvitesPage: FunctionComponent<InvitesPageProps> = ({ supplierId }) => {
    const { t } = useTranslation();
    const currentUser = useCurrentUserContext();

    const {
        data,
        loading,
        error,
    } = useQuery<GetSupplierNameResult, GetSupplierNameInput>(
        GET_SUPPLIER_NAME,
        {
            variables: { id: supplierId },
            onError(apolloError) {
                report(apolloError);
                throw apolloError;
            },
        }
    );

    const supplierName = ((): BreadcrumbHeadNode['name'] => {
        if (loading || error) {
            // Note: In case of an error the user will see the error boundary instead
            return <Skeleton width="7ch" />;
        }

        if (!data || !data.supplier) {
            return t('breadcrumbs.unknownSupplier');
        }

        return data.supplier.name;
    })();

    const breadcrumbs = ((): BreadcrumbHeadNode => {
        if (currentUser.isBackOffice) {
            return {
                name: supplierName,
                previous: {
                    name: t('breadcrumbs.suppliers'),
                    path: '/suppliers',
                },
            };
        }

        return { name: t('breadcrumbs.supplierUsers') };
    })();

    return (
        <Page breadcrumbs={breadcrumbs}>
            <Content
                supplierId={supplierId}
                tab={UserTab.Invites}
            />
        </Page>
    );
};

export default SupplierInvitesPage;

