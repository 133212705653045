import {
    CompactButton, Form, PrimaryButton, SecondaryButton,
    TextField,
} from '@get-e/react-components';
import { makeStyles, Tooltip } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import useFormStyles from '../../../styles/useFormStyles';
import {
    SetMeetingPointInput, SetMeetingPointResult,
    SET_MEETING_POINT,
} from './MeetingPointForm.graphql';

const useStyles = makeStyles({ changeButton: { marginTop: '.5rem' } });

const MeetingPointForm: FunctionComponent<{
    requestId: string;
    initialValue?: string | null;
    disabled?: boolean;
    tooltip?: string;
}> = ({ requestId, initialValue, disabled, tooltip }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const formClasses = useFormStyles();

    const [value, setValue] = useState(initialValue ?? '');
    const [changing, setChanging] = useState(false);
    const [unsavedValue, setUnsavedValue] = useState(value ?? '');

    const [
        setMeetingPoint,
        { loading: saving },
    ] = useMutation<SetMeetingPointResult, SetMeetingPointInput>(
        SET_MEETING_POINT
    );

    const submitForm = async (): Promise<void> => {
        const result = await setMeetingPoint({
            variables: {
                input: {
                    requestId,
                    meetingPoint: unsavedValue,
                },
            },
        });

        if (!result.data) {
            throw new Error('Mutation result has no data');
        }

        const newValue = result.data.setRideAvailabilityRequestMeetingPoint.meetingPoint;

        setValue(newValue);
        setUnsavedValue(newValue);
        setChanging(false);
    };

    if (!changing) {
        if (value === null || value.trim().length === 0) {
            return (
                <Tooltip
                    title={tooltip ?? ''}
                >
                    <span>
                        <CompactButton
                            disabled={disabled}
                            onClick={() => setChanging(true)}
                        >
                            Add meeting point
                        </CompactButton>
                    </span>
                </Tooltip>
            );
        }

        return (
            <>
                <div>{value}</div>

                <CompactButton
                    onClick={() => setChanging(true)}
                    className={classes.changeButton}
                >
                    Change
                </CompactButton>
            </>
        );
    }

    return (
        <Form onSubmit={submitForm}>
            <TextField
                label="New meeting point"
                multiline
                rows={2}
                rowsMax={5}
                value={unsavedValue}
                onChange={event => setUnsavedValue(event.target.value)}
            />

            <div className={formClasses.compactButtons}>
                <PrimaryButton
                    onClick={submitForm}
                    submitsForm
                    loading={saving}
                    small
                >
                    {t('buttons.save')}
                </PrimaryButton>

                <SecondaryButton
                    onClick={() => {
                        setChanging(false);
                        setUnsavedValue(value ?? '');
                    }}
                    disabled={saving}
                    small
                >
                    {t('buttons.cancel')}
                </SecondaryButton>
            </div>
        </Form>
    );
};

export default MeetingPointForm;
