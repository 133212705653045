import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { PageDefinition } from '../../createRoutes';
import Page from '../../layouts/Page';
import Content, { Tab } from './Content';

const Component: FunctionComponent<{ tab: Tab }> = ({ tab }) => {
    const { t } = useTranslation();

    return (
        <Page breadcrumbs={{ name: t('breadcrumbs.reports') }} >
            <Content tab={tab} />
        </Page>
    );
};

export const reportsPage: PageDefinition = {
    path: '/reports',
    Component() {
        return <Component tab={Tab.DateRange} />;
    },
};

export const dateRangeReportsPage: PageDefinition = {
    path: '/reports/date-range',
    Component() {
        return <Component tab={Tab.DateRange} />;
    },
};

export const singleFlightReportsPage: PageDefinition = {
    path: '/reports/single-flight',
    Component() {
        return <Component tab={Tab.SingleFlight} />;
    },
};
