/* eslint-disable max-lines */
import {
    Heading, SecondaryButton,
    TableSkeleton, PrimaryButton, Alert, MenuButton, MenuButtonItem,
} from '@get-e/react-components';
import { Card, Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment-timezone';
import React, { FunctionComponent, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import GoogleMap from '../../../components/GoogleMap';
import RelativeTime from '../../../components/RelativeTime';
import { useCurrentUserContext } from '../../../context/CurrentUserContext';
import getValue from '../../../helpers/getValue';
import report from '../../../helpers/report';
import FormError from '../../../helpers/validation/FormError';
import getFormErrorMessage from '../../../helpers/validation/getFormErrorMessage';
import useFormStyles from '../../../styles/useFormStyles';
import RequestStatus from '../RequestStatus';
import CoachAddForm from './Coaches/CoachAddForm';
import CoachesTable from './Coaches/CoachesTable';
import {
    COMPLETE_AVAILABILITY_REQUEST,
    INCOMPLETE_AVAILABILITY_REQUEST,
} from './Content.graphql';
import { AvailabilityRequestProps, Ride, RideAvailability } from './index.graphql';
import RequestDeclineModal from './modals/RequestDeclineModal';
import AvailabilityRequestData from './RequestData';

const useStyles = makeStyles(theme => createStyles({
    alertContainer: {
        overflow: 'hidden',
        marginBottom: theme.spacing(2),
        '& > *': {
            justifyContent: 'center',
            fontWeight: 'bold',
        },
    },
    addCoachHeader: {
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        '& h2': { margin: theme.spacing(0, 2, 0, 0) },
    },
    coachAlert: { marginLeft: '1em' },
    headerRow: {
        '& h2': { margin: theme.spacing(0, 2, 0, 0) },
        paddingBottom: theme.spacing(2),
    },
    heading: {
        marginBottom: '0',
        whiteSpace: 'nowrap',
        marginRight: theme.spacing(2),
    },
    borderBox: { '& > *': { boxSizing: 'border-box' } },
    cardContainer: {
        marginBottom: '2.25em',
        padding: theme.spacing(2),
    },
    loaderContainer: {
        marginBottom: '10em',
        padding: theme.spacing(2),
    },
    createdAt: {
        fontSize: '1em',
        lineHeight: '1.5em',
    },
    createdAtSmall: {
        fontSize: '0.75em',
        fontWeight: 700,
        color: theme.palette.grey[200],
    },
    completeButton: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        background: theme.palette.success.main,
        '&:hover': { background: theme.palette.success.dark },
    },
    declineButton: { marginTop: theme.spacing(2) },
    loaderSpace: { marginTop: theme.spacing(4) },
    ridesHasNextPage: { marginTop: theme.spacing(2) },
    googleMap: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '320px',
        height: '300px',
    },
    menuButton: { marginLeft: '.5rem' },
}));

interface ContentProps {
    availabilityRequest: AvailabilityRequestProps | undefined;
    rideAvailabilities: undefined | {
        nodes: RideAvailability[];
        pageInfo: {
            hasNextPage: boolean;
        };
    };
    rides: undefined | {
        nodes: Ride[];
        pageInfo: {
            hasNextPage: boolean;
        };
    };
    refetchRideAvailabilities: () => void;
    refetchAvailabilityRequest: () => void;
    loading: boolean;
}

const Content: FunctionComponent<ContentProps> = ({
    availabilityRequest,
    rideAvailabilities,
    rides,
    loading: loadingAvailabilityRequest,
    refetchRideAvailabilities,
    refetchAvailabilityRequest,
}) => {
    const classes = useStyles();
    const formClasses = useFormStyles();
    const { t } = useTranslation();
    const [openDeclineModal, setOpenDeclineModal] = useState(false);
    const [requestError, setRequestError] = useState<FormError | null>(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const currentUser = useCurrentUserContext();
    const history = useHistory();

    const [
        completeAvailabilityRequest,
        { loading: completing },
    ] = useMutation(
        COMPLETE_AVAILABILITY_REQUEST
    );

    const [
        incompleteAvailabilityRequest,
        { loading: declining },
    ] = useMutation(
        INCOMPLETE_AVAILABILITY_REQUEST
    );

    if (!availabilityRequest || loadingAvailabilityRequest) {
        if (!loadingAvailabilityRequest) {
            // TODO: UI and translation
            return <>Request not found</>;
        }

        return (
            <>
                <Card variant="outlined" className={classes.loaderContainer}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Heading level={2}>
                                <Skeleton width="12ch" />
                            </Heading>
                            <Skeleton height="2rem" />
                            <Skeleton height="2rem" />
                            <Skeleton height="2rem" />
                        </Grid>
                        <Grid container justify="flex-end">
                            <Skeleton variant="rect" width={320} height={300} />
                        </Grid>
                    </Grid>
                </Card>
                <TableSkeleton columns={6} />
            </>
        );
    }

    const points = availabilityRequest.points.nodes.map(
        point => point.location.coordinates
    ) ?? [];

    const variables = { input: { availabilityRequestId: availabilityRequest.id } };

    const completeRequest = async (): Promise<void> => {
        setRequestError(null);

        try {
            await completeAvailabilityRequest({ variables });
            refetchAvailabilityRequest();
            setOpenDeclineModal(false);
        } catch (error) {
            report(error);
            setRequestError(FormError.UnexpectedError);
        }
    };

    const incompleteRequest = async (): Promise<void> => {
        setRequestError(null);

        try {
            await incompleteAvailabilityRequest({ variables });
            refetchAvailabilityRequest();
        } catch (error) {
            report(error);
            setRequestError(FormError.UnexpectedError);
        }
    };

    // eslint-disable-next-line max-len
    const hasRideAvailabilities = (rideAvailabilities && rideAvailabilities.nodes.length > 0) ?? false;
    const hasRides = (rides && rides?.nodes.length > 0) ?? false;

    return (
        <>
            <div className={classes.alertContainer}>
                {
                    getValue(() => {
                        if (availabilityRequest.customerCancelledAt) {
                            return;
                        }

                        if (!availabilityRequest.supplierCompletedAt) {
                            return (
                                <Alert severity="info" className={formClasses.alert}>
                                    <strong>
                                        {t('pages.availability.alerts.open')}
                                    </strong>
                                </Alert>
                            );
                        }

                        const toAddDrivers = rides?.nodes.some(ride => !ride.driver);

                        const toAddMeetingPoint = !availabilityRequest?.meetingPoint;

                        if (toAddDrivers || toAddMeetingPoint) {
                            return (
                                <Alert severity="info" className={formClasses.alert}>
                                    <strong>
                                        {t('pages.availability.alerts.confirmed')}
                                    </strong>
                                </Alert>
                            );
                        }

                        if (hasRideAvailabilities) {
                            return (
                                <Alert severity="info" className={formClasses.alert}>
                                    <strong>
                                        {t('pages.availability.alerts.waiting')}
                                    </strong>
                                </Alert>
                            );
                        }
                    })
                }
            </div>
            <Card variant="outlined" className={classes.cardContainer}>
                <Grid container direction="column">
                    <Grid container className={classes.headerRow}>
                        <Grid
                            container
                            item
                            xs={8}
                            alignItems="center"
                        >
                            <Grid item>
                                <Heading level={2}>
                                    {t('pages.availability.requestHeader')}
                                </Heading>
                            </Grid>

                            <Grid item>
                                <RequestStatus
                                    cancelled={availabilityRequest.customerCancelledAt !== null}
                                    completed={availabilityRequest.supplierCompletedAt !== null}
                                    rides={{
                                        booked: availabilityRequest.rides.totalCount,
                                        assigned: availabilityRequest.assignedRides.totalCount,
                                        confirmed: availabilityRequest.confirmedRides.totalCount,
                                        completed: availabilityRequest.completedRides.totalCount,
                                        inProgress: availabilityRequest.ridesInProgress.totalCount,
                                        inProgressWithoutTracking: availabilityRequest.ridesInProgressWithoutTracking.totalCount,
                                        withPositionUpdates: availabilityRequest.ridesWithPositionUpdates.totalCount,
                                    }}
                                    availability={{ unbooked: availabilityRequest.rideAvailabilities.totalCount }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container>
                                <Grid item xs>
                                    <Grid
                                        container
                                        direction="column"
                                        alignItems="flex-end"
                                    >
                                        <span className={classes.createdAt}>
                                            {t('pages.availability.labels.created')}
                                            {' '}
                                            <RelativeTime
                                                date={
                                                    moment(availabilityRequest.createdAt)
                                                }
                                                timeZone={
                                                    availabilityRequest
                                                        .pickupPoint.location.timeZone
                                                }
                                                disableTooltip
                                            />
                                        </span>
                                        <span className={classes.createdAtSmall}>
                                            {moment.tz(
                                                availabilityRequest.createdAt,
                                                availabilityRequest
                                                    .pickupPoint.location.timeZone
                                            ).format(t('dateFormat.timeDateShortMonth'))}
                                        </span>
                                    </Grid>
                                </Grid>

                                {getValue(() => {
                                    if (!currentUser.isBackOffice) {
                                        return null;
                                    }

                                    return (
                                        <Grid item>
                                            <MenuButton
                                                className={classes.menuButton}
                                                open={menuOpen}
                                                onClick={() => setMenuOpen(true)}
                                                onClose={() => setMenuOpen(false)}
                                                menuItems={[
                                                    <MenuButtonItem
                                                        key="open-disruption"
                                                        onClick={() => history.push(`/disruptions/${availabilityRequest.disruption.id}`)}
                                                    >
                                                    Show disruption
                                                    </MenuButtonItem>,
                                                ]}
                                            >
                                                <MoreVertIcon />
                                            </MenuButton>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={8}>
                            <AvailabilityRequestData
                                availabilityRequest={availabilityRequest}
                                hasRides={Boolean(rides?.nodes.length)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <div className={classes.googleMap}>
                                <GoogleMap
                                    points={points}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
            <Grid className={classes.addCoachHeader} container>
                <Heading level={2}>
                    {t('pages.availability.addCoaches')}
                </Heading>
                {availabilityRequest.disruption.coachesAuthorized && (
                    <Alert severity="warning">
                        {t('pages.availability.alerts.preAuthorized')}
                    </Alert>
                )}
            </Grid>
            <CoachAddForm
                availabilityRequestId={availabilityRequest.id}
                onAdded={refetchRideAvailabilities}
            />
            <CoachesTable
                refetchRideAvailabilities={refetchRideAvailabilities}
                rideAvailabilities={rideAvailabilities?.nodes}
                rides={rides?.nodes}
                total={availabilityRequest.disruption.flight.passengerCount.total}
                bookedAutomatically={availabilityRequest.disruption.coachesAuthorized}
                requestCreatedAt={moment(availabilityRequest.createdAt)}
            />
            {getValue(() => {
                if (
                    rides?.pageInfo.hasNextPage
                    || rideAvailabilities?.pageInfo.hasNextPage
                ) {
                    return (
                        <Alert
                            className={classes.ridesHasNextPage}
                            severity="error"
                        >
                            {t('pages.availability.errors.ridesHasNextPage')}
                        </Alert>
                    );
                }
            })}
            <PrimaryButton
                className={classes.completeButton}
                onClick={completeRequest}
                loading={completing}
                disabled={!hasRideAvailabilities && !hasRides}
                icon={
                    getValue(() => {
                        if (!hasRideAvailabilities && !hasRides) {
                            return undefined;
                        }

                        return (
                            availabilityRequest.supplierCompletedAt === null
                                ? undefined
                                : <DoneIcon fontSize="small" />
                        );
                    })
                }
            >
                {t('pages.availability.buttons.completeRequest')}
            </PrimaryButton>
            {availabilityRequest.supplierCompletedAt
                ? (
                    <SecondaryButton
                        className={classes.declineButton}
                        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                        disabled={hasRideAvailabilities || hasRides}
                        onClick={incompleteRequest}
                        loading={declining}
                    >
                        {t('pages.availability.buttons.reopenRequest')}
                    </SecondaryButton>
                )
                : (
                    <SecondaryButton
                        variation="danger"
                        className={classes.declineButton}
                        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                        disabled={hasRideAvailabilities || hasRides}
                        onClick={() => setOpenDeclineModal(true)}
                    >
                        {t('pages.availability.buttons.declineRequest')}
                    </SecondaryButton>
                )
            }

            {(() => {
                if (requestError === null) {
                    return null;
                }

                return (
                    <Alert severity="error" className={formClasses.mainError}>
                        {getFormErrorMessage(requestError, t)}
                    </Alert>
                );
            })()}

            <RequestDeclineModal
                onClose={() => setOpenDeclineModal(false)}
                open={openDeclineModal}
                declining={completing}
                onDecline={completeRequest}
            />
        </>
    );
};

export default Content;
