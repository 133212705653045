import { ApolloError } from 'apollo-client';
import { GetAirportInput, GetAirportResult, GET_AIRPORT } from './GetAirport.graphql';
import useCustomQuery, { CustomQueryResult } from './useCustomQuery';

type Result = null | {
    id: string;
    name: string;
    iata: string;
};

export default function useAirport(
    id: string,
    { onError, skip }: {
        skip: boolean;
        onError: (error: ApolloError) => void;
    }
): CustomQueryResult<Result> {
    return useCustomQuery<GetAirportResult, GetAirportInput, Result>(
        GET_AIRPORT,
        {
            variables: { id },
            mapResult: queryResult => queryResult.airport,
            onError,
            skip,
        }
    );
}
