import { Tab, TabPanel, Tabs } from '@get-e/react-components';
import { makeStyles, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCurrentUserContext } from '../../context/CurrentUserContext';
import DisruptionsTab from './DisruptionsTab';
import OrganizationTab from './OrganizationTab';
import UsersTab, { UserTab } from './UsersTab';

export enum CustomerTab {
    Organization,
    Disruptions,
    Users
}

const useStyles = makeStyles({
    companyHeading: { marginBottom: '1rem' },
    tabs: { marginBottom: '2rem' },
});

const Content: FunctionComponent<{
    id: string;
    tab: CustomerTab;
    usersTab?: UserTab;
    customerName: string | JSX.Element;
    // eslint-disable-next-line max-lines-per-function, max-statements
}> = ({ id, tab, usersTab, customerName }) => {
    const classes = useStyles();
    const currentUser = useCurrentUserContext();
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <>
            <Typography variant="h2" component="h2" className={classes.companyHeading}>
                {customerName}
            </Typography>

            {
                currentUser.isBackOffice
                    ? (
                        <Tabs value={tab} className={classes.tabs}>
                            <Tab
                                label={t('pages.customer.tabs.organization')}
                                url={`/customers/${id}/organization`}
                                index={CustomerTab.Organization}
                                selectedIndex={tab}
                            />

                            <Tab
                                label={t('pages.customer.tabs.disruptions')}
                                url={`/customers/${id}/disruptions`}
                                index={CustomerTab.Disruptions}
                                selectedIndex={tab}
                            />

                            <Tab
                                label={t('pages.customer.tabs.users')}
                                url={`/customers/${id}/users`}
                                index={CustomerTab.Users}
                                selectedIndex={tab}
                            />
                        </Tabs>
                    )
                    : null
            }

            <TabPanel value={CustomerTab.Organization} selectedValue={tab}>
                <OrganizationTab />
            </TabPanel>

            <TabPanel value={CustomerTab.Disruptions} selectedValue={tab}>
                <DisruptionsTab />
            </TabPanel>

            <TabPanel value={CustomerTab.Users} selectedValue={tab}>
                <UsersTab tab={usersTab ?? UserTab.Users} customerId={id} />
            </TabPanel>
        </>
    );
};

export default Content;
