import gql from 'graphql-tag';
import { DrivingPoint } from '../Disruption/useCoachRoutes';

const LOCATION_ATTRIBUTES = gql`
  fragment locationAttributes on DisruptionRideAvailabilityRequestPoint {
    location {
        id
        coordinates {
            latitude
            longitude
        }
        timeZone
        ... on Airport {
            id
            name
            iata
        }
    }
  }
`;

export const GET_AVAILABILITYREQUESTS = gql`
    query GetRequests(
        $supplierId: ID!
        $filter: SupplierRequestFilter
        $first: Int
        $after: String
        $last: Int
        $before: String
    ) {
        supplier(id: $supplierId) {
            id
            name
            rideAvailabilityRequests(
                filter: $filter
                first: $first
                after: $after
                last: $last
                before: $before
            ) {
                nodes {
                    id
                    createdAt
                    pickupTime
                    disruption {
                        id
                        flight {
                            number
                            passengerCount {
                                total
                            }
                        }
                    }
                    pickupPoint {
                        ...locationAttributes
                    }
                    dropOffPoint {
                        ...locationAttributes
                    }
                    supplierCompletedAt
                    customerCancelledAt
                    rides(first: 0) {
                        totalCount
                    }
                    assignedRides: rides(
                        first: 0
                        filter: {
                            assigned: true
                        }
                    ) {
                        totalCount
                    }
                    confirmedRides: rides(
                        first: 0
                        filter: {
                            confirmed: true
                        }
                    ) {
                        totalCount
                    }
                    completedRides: rides(
                        first: 0
                        filter: {
                            completed: true
                        }
                    ) {
                        totalCount
                    }
                    ridesInProgress: rides(
                        first: 0
                        filter: {
                            inProgress: true
                        }
                    ) {
                        totalCount
                    }
                    ridesInProgressWithoutTracking: rides(
                        first: 0
                        filter: {
                            inProgress: true
                            hasPositionUpdates: false
                        }
                    ) {
                        totalCount
                    }
                    ridesWithPositionUpdates: rides(
                        first: 0
                        filter: {
                            hasPositionUpdates: true
                        }
                    ) {
                        totalCount
                    }
                    rideAvailabilities(first: 0) {
                        totalCount
                    }
                }
                totalCount
                pageInfo {
                    hasPreviousPage
                    hasNextPage
                    startCursor
                    endCursor
                }
            }
            incompleteRequests: rideAvailabilityRequests(
                first: 0
                filter: {
                    completed: false
                }
            ) {
                totalCount
            }
        }
    }
    ${LOCATION_ATTRIBUTES}
`;

interface Disruption {
    id: string;
    flight: {
        number: string;
        passengerCount: {
            total: number;
        };
    };
}

export interface AvailabilityRequest {
    id: string;
    createdAt: string;
    pickupTime: string;
    disruption: Disruption;
    pickupPoint: DrivingPoint;
    dropOffPoint: DrivingPoint;
    supplierCompletedAt: string | null;
    customerCancelledAt: string | null;
    rides: {
        totalCount: number;
    };
    assignedRides: {
        totalCount: number;
    };
    confirmedRides: {
        totalCount: number;
    };
    completedRides: {
        totalCount: number;
    };
    ridesInProgress: {
        totalCount: number;
    };
    ridesInProgressWithoutTracking: {
        totalCount: number;
    };
    ridesWithPositionUpdates: {
        totalCount: number;
    };
    rideAvailabilities: {
        totalCount: number;
    };
}

export type GetAvailabilitiesInput = {
    supplierId: string;
    filter: null | { search: string };
} & ({
    first: number;
    after?: string;
} | {
    last: number;
    before?: string;
});

export interface GetAvailabilitiesResult {
    supplier: null | {
        name: string;
        rideAvailabilityRequests: {
            nodes: AvailabilityRequest[];
            totalCount: number;
            pageInfo: {
                hasPreviousPage: boolean;
                hasNextPage: boolean;
                startCursor: string | null;
                endCursor: string | null;
            };
        };
        incompleteRequests: {
            totalCount: number;
        };
    };
}
