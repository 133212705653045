import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { PageDefinition } from '../../createRoutes';
import Page from '../../layouts/Page';
import Content, { DisruptionsTab } from './Content';

function createComponent(tab: DisruptionsTab): FunctionComponent {
    return function Component() {
        const { t } = useTranslation();

        return (
            <Page breadcrumbs={{ name: t('breadcrumbs.disruptions') }}>
                <Content tab={tab} />
            </Page>
        );
    };
}

const disruptionsPage: PageDefinition = {
    path: '/disruptions',
    Component: createComponent(DisruptionsTab.Active),
};

const activeDisruptionsPage: PageDefinition = {
    path: '/disruptions/active',
    Component: createComponent(DisruptionsTab.Active),
};

const disruptionsArchivePage: PageDefinition = {
    path: '/disruptions/archive',
    Component: createComponent(DisruptionsTab.Archive),
};

export {
    disruptionsPage,
    activeDisruptionsPage,
    disruptionsArchivePage,
};
