import {
    NumberedTab, NumberedTabs,
    PrimaryButton, TabPanel,
} from '@get-e/react-components';
import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useTablePageStyles from '../../useTablePageStyles';
import DeleteUserInviteDialog from './DeleteUserInviteDialog';
import RemoveUserDialog from './RemoveUserDialog';
import UserInvitesTab from './UserInvitesTab';
import UsersTab from './UsersTab';

export enum UserTab {
    Users,
    Invites,
}

export interface CustomerUsersProps {
    supplierId: string;
    tab: UserTab;
}

const Content: FunctionComponent<CustomerUsersProps> = ({ supplierId, tab }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const classes = useTablePageStyles();

    const [totalUsers, setTotalUsers] = useState<number | null>(null);
    const [totalInvites, setTotalInvites] = useState<number | null>(null);

    const [removingUser, setRemovingUser] = useState<{
        id: string;
        name: string;
    } | null>(null);

    const [
        deletingInvite,
        setDeletingInvite,
    ] = useState<{ id: string; userName: string } | null>(null);

    return (
        <>
            {removingUser !== null && (
                <RemoveUserDialog
                    user={removingUser}
                    onClose={() => setRemovingUser(null)}
                />
            )}

            {deletingInvite !== null && (
                <DeleteUserInviteDialog
                    invite={deletingInvite}
                    onClose={() => setDeletingInvite(null)}
                />
            )}
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item className={classes.tabs}>
                    <div className="root">
                        <NumberedTabs value={tab}>
                            <NumberedTab
                                loading={totalUsers === null}
                                number={totalUsers}
                                label={t('pages.users.tabs.users')}
                                url={`/suppliers/${supplierId}/users`}
                                index={UserTab.Users}
                                selectedIndex={tab}
                            />

                            <NumberedTab
                                loading={totalInvites === null}
                                number={totalInvites}
                                label={t('pages.users.tabs.invites')}
                                url={`/suppliers/${supplierId}/invites`}
                                index={UserTab.Invites}
                                selectedIndex={tab}
                            />
                        </NumberedTabs>
                    </div>
                </Grid>

                <Grid item className={classes.addButton}>
                    <PrimaryButton
                        onClick={() => {
                            history.push(`/suppliers/${supplierId}/users/invite`);
                        }}
                        icon={<AddIcon />}
                    >
                        {t('pages.users.button.invite')}
                    </PrimaryButton>
                </Grid>
            </Grid>

            <TabPanel value={UserTab.Users} selectedValue={tab}>
                <UsersTab
                    supplierId={supplierId}
                    onUpdate={({ total }) => setTotalUsers(total)}
                    onEditUser={user => {
                        history.push(`/suppliers/${supplierId}/users/${user.id}`);
                    }}
                    onRemoveUser={user => setRemovingUser(user)}
                />
            </TabPanel>

            <TabPanel value={UserTab.Invites} selectedValue={tab}>
                <UserInvitesTab
                    supplierId={supplierId}
                    onUpdate={({ total }) => setTotalInvites(total)}
                    onDeleteInvite={invite => setDeletingInvite(invite)}
                />
            </TabPanel>
        </>
    );
};

export default Content;
