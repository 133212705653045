import { Fade, FadeProps } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

const LoadingFade: FunctionComponent<{
    children: FadeProps['children'];
}> = ({ children }) => (
    <Fade in timeout={400}>
        {children}
    </Fade>
);

export default LoadingFade;
