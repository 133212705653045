import gql from 'graphql-tag';

export const GET_SUPPLIERS = gql`
    query getSuppliers(
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $maxIataPreviews: Int,
        $filter: SupplierFilter
    ) {
        suppliers(
            first: $first,
            last: $last,
            before: $before,
            after: $after,
            filter: $filter,
        ) {
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            nodes {
                id
                name
                servicedAirports(first: $maxIataPreviews) {
                    totalCount
                    nodes {
                        id
                        coordinates {
                            latitude
                            longitude
                        }
                        iata
                    }
                }
            }
        }
    }
`;

export interface Supplier {
    id: string;
    name: string;
    servicedAirports: {
        totalCount: number;
        nodes: Array<{
            id: string;
            coordinates: {
                latitude: number;
                longitude: number;
            };
            iata: string;
        }>;
    } | null;
}

export type GetSuppliersInput = {
    first: number;
    after?: string;
    filter: null | { search: string };
    maxIataPreviews: number;
} | {
    last: number;
    before?: string;
    filter: null | { search: string };
    maxIataPreviews: number;
};

export interface GetSuppliersResult {
    suppliers: {
        totalCount: number;
        pageInfo: {
            hasPreviousPage: boolean;
            hasNextPage: boolean;
            startCursor: string | null;
            endCursor: string | null;
        };
        nodes: Supplier[];
    };
}
