import {
    NavigationBarFooter,
    NavigationBarLayout,
    SupportButton,
} from '@get-e/react-components';
import { makeStyles } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import config from '../../config';
import { useCurrentUserContext } from '../../context/CurrentUserContext';
import { BreadcrumbHeadNode } from '../../createRoutes';
import ErrorBoundary from '../../ErrorBoundary';
import getValue from '../../helpers/getValue';
import onEnvironment, { Environment, getCurrentEnvironment } from '../../helpers/onEnvironment';
import Breadcrumbs from '../Breadcrumbs';
import BackOfficeNavigationList from './BackOfficeNavigationList';
import DefaultNavigationList from './DefaultNavigationList';
import SupportDialog from './SupportDialog';
import UserMenuButton from './UserMenuButton';

const useStyles = makeStyles({ userMenuButton: { marginLeft: '.25rem' } });

const Page: FunctionComponent<{ breadcrumbs: BreadcrumbHeadNode }> = (
    { children, breadcrumbs }
) => {
    const [menuClosed, setMenuClosed] = useState(false);
    const currentUser = useCurrentUserContext();
    const [supportModalOpen, setSupportModalOpen] = useState(false);
    const { t } = useTranslation();
    const classes = useStyles();

    const navigationList = (() => {
        if (currentUser.isBackOffice) {
            return <BackOfficeNavigationList />;
        }

        return (
            <DefaultNavigationList
                customer={currentUser.managedCustomer}
                supplier={currentUser.managedSupplier}
            />
        );
    })();

    return (
        <NavigationBarLayout
            closed={menuClosed}
            onMenuClose={() => setMenuClosed(true)}
            onMenuOpen={() => setMenuClosed(false)}
            navigationList={navigationList}
            breadcrumbs={<Breadcrumbs node={breadcrumbs} />}
            environmentBadge={getValue(() => {
                switch (getCurrentEnvironment()) {
                    case Environment.Local:
                        return 'Local';

                    case Environment.Test:
                        return 'Test';

                    case Environment.Staging:
                        return 'Staging';

                    case Environment.Production:
                        return undefined;

                    default:
                        throw new Error('Unhandled environment');
                }
            })}
            actions={<>
                <SupportButton onClick={() => setSupportModalOpen(true)}>
                    {t('topBarActions.supportButton')}
                </SupportButton>
                <UserMenuButton className={classes.userMenuButton} />
            </>}
            navigationBarFooter={<NavigationBarFooter closed={menuClosed} />}
        >
            {/* TODO: Proper error UI */}
            <ErrorBoundary error={<>Error</>}>
                {
                    supportModalOpen
                        ? <SupportDialog onClose={() => setSupportModalOpen(false)} />
                        : null
                }

                {children}
            </ErrorBoundary>
        </NavigationBarLayout>
    );
};

export default Page;
