import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageDefinition } from '../../createRoutes';
import Page from '../../layouts/Page';
import Content from './Content';

const requestsPage: PageDefinition = {
    path: '/suppliers/:id/requests',
    Component({ getParam }) {
        const { t } = useTranslation();

        return (
            <Page breadcrumbs={{ name: t('breadcrumbs.requests') }}>
                <Content supplierId={getParam('id')} />
            </Page>
        );
    },
};

export { requestsPage };
