import { TFunction } from 'i18next';
import report from '../../../helpers/report';

export enum DriverError {
    DriverExists
}

// TODO: Add driver name to driver exists message

export default function getDriverErrorMessage(
    error: DriverError,
    t: TFunction,
    message?: unknown,
): string {
    switch (error) {
        case DriverError.DriverExists:
            return t('components.driverForm.driverExists', { driverName: message });
        default:
            report(new Error('Unhandled disruption error'));
            return t('errors.forms.unexpected');
    }
}
