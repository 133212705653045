import gql from 'graphql-tag';

export const GET_AIRPORT = gql`
    query getAirport($id: ID!) {
        airport(id: $id) {
            id
            iata
            name
        }
    }
`;

export interface GetAirportInput {
    id: string;
}

export interface GetAirportResult {
    airport: null | {
        id: string;
        iata: string;
        name: string;
    };
}
