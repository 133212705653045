/* eslint-disable max-statements */
/* eslint-disable max-lines */
import {
    TableBody, TableContainer,
    TableFooter, TableHead,
    TableHeader, Alert,
    TableSkeleton, PrimaryButton,
} from '@get-e/react-components';
import { Table, TableCell, TableRow, Fade } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FunctionComponent, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import LoadingFade from '../../components/LoadingFade';
import { useCurrentUserContext } from '../../context/CurrentUserContext';
import getValue from '../../helpers/getValue';
import report from '../../helpers/report';
import useDebounce from '../../helpers/useDebounce';
import useLoaderDebounce from '../../helpers/useLoaderDebounce';
import useUrlQuery from '../../helpers/useUrlQuery';
import {
    GET_AVAILABILITYREQUESTS,
    GetAvailabilitiesInput,
    GetAvailabilitiesResult,
} from './Content.graphql';
import Row from './RequestRow';

const useStyles = makeStyles(theme => createStyles({
    alert: {
        marginBottom: '1.5em',
        justifyContent: 'center',
        fontWeight: 700,
    },
    retryButton: { padding: theme.spacing(2, 0) },
}));

const Content: FunctionComponent<{
    supplierId: string;
}> = ({ supplierId }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const query = useUrlQuery();
    const history = useHistory();
    const after = query.get('after');
    const before = query.get('before');
    const paginationLimit = 10;

    // Filter
    const [search, setSearch] = useState('');
    const debouncedSearchValue = useDebounce(search, 500);
    const currentUser = useCurrentUserContext();

    const variables = ((): GetAvailabilitiesInput => {
        const requestsFilter: null | { search: string }
            = debouncedSearchValue ? { search: debouncedSearchValue } : null;

        if (before !== null) {
            return {
                supplierId,
                last: paginationLimit,
                before: before || undefined,
                filter: requestsFilter,
            };
        }

        return {
            supplierId,
            first: paginationLimit,
            after: after ?? undefined,
            filter: requestsFilter,
        };
    })();

    const {
        data,
        loading,
        error,
        refetch,
    } = useQuery<GetAvailabilitiesResult, GetAvailabilitiesInput>(
        GET_AVAILABILITYREQUESTS,
        {
            variables,
            onError(apolloError) {
                report(apolloError);
            },
            fetchPolicy: 'cache-and-network',
        }
    );

    const showLoader = useLoaderDebounce(loading);

    if (loading && !data) {
        return showLoader
            ? (
                <Fade in>
                    <div>
                        <TableSkeleton columns={6} />
                    </div>
                </Fade>
            )
            : null;
    }

    if (error || !data) {
        return (
            <>
                {t('pages.availabilities.error.errorText')}
                <div className={classes.retryButton}>
                    <PrimaryButton
                        onClick={() => refetch()}
                        loading={loading}
                    >
                        {t('pages.availabilities.error.retryButton')}
                    </PrimaryButton>
                </div>
            </>
        );
    }

    const { supplier } = data;

    if (!supplier) {
        // TODO: UI
        return <>Supplier not found</>;
    }

    const {
        pageInfo,
        nodes: requests,
        totalCount,
    } = supplier.rideAvailabilityRequests;

    const showAlert = Boolean(supplier?.incompleteRequests.totalCount);

    const handleBackToStartClick = (): void => {
        history.push({ search: '' });
    };

    const handlePreviousClick = (): void => {
        const { startCursor } = pageInfo;

        if (startCursor === null) {
            throw new Error('Page info does not contain start cursor');
        }

        history.push({ search: `?before=${startCursor}` });
    };

    const handleNextClick = (): void => {
        const { endCursor } = pageInfo;

        if (endCursor === null) {
            throw new Error('Page info does not contain end cursor');
        }

        history.push({ search: `?after=${endCursor}` });
    };

    const handleSkipToEndClick = (): void => {
        history.push({ search: '?before' });
    };

    const colSpan = currentUser.isBackOffice ? 7 : 6;

    // TODO: Get correct name and id from API when user is back office
    const supplierName = supplier.name;

    return (
        <>
            {showAlert && (
                <Alert className={classes.alert} severity="info">
                    {t('pages.availabilities.availabilityAlert')}
                </Alert>
            )}
            <LoadingFade>
                <TableContainer>
                    <TableHeader
                        title={t('pages.availabilities.headerTitle')}
                        onSearchChange={searchValue => setSearch(searchValue)}
                    />
                    <Table
                        aria-label={t('pages.availabilities.aria.availabilitiesTable')}
                    >
                        <TableHead
                            loading={showLoader}
                            columns={colSpan}
                        >
                            <TableRow>
                                <TableCell>
                                    {t('pages.availabilities.columns.status')}
                                </TableCell>
                                <TableCell>
                                    {t('pages.availabilities.columns.createdAt')}
                                </TableCell>
                                <TableCell>
                                    {t('pages.availabilities.columns.pickupTime')}
                                    {' '}
                                    {t('pages.availabilities.columns.localTime')}
                                </TableCell>
                                <TableCell>
                                    {t('pages.availabilities.columns.route')}
                                </TableCell>
                                <TableCell>
                                    {t('pages.availabilities.columns.seats')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody loading={loading}>
                            {requests.length === 0
                                ? (
                                    <TableRow>
                                        <TableCell
                                            colSpan={colSpan}
                                        >
                                            {getValue(() => {
                                                if (debouncedSearchValue.length > 0) {
                                                    return (
                                                        <strong>
                                                            {t('pages.availabilities.empty.emptySearch')}
                                                        </strong>
                                                    );
                                                }

                                                return (
                                                    <>
                                                        <strong>
                                                            {t(
                                                                'pages.availabilities.empty.emptyList'
                                                            )}
                                                        </strong>
                                                        <div>
                                                            {t(
                                                                'pages.availabilities.empty.emptySubText'
                                                            )}
                                                        </div>
                                                    </>
                                                );
                                            })}

                                        </TableCell>
                                    </TableRow>
                                )
                                : (
                                    requests.map(availability => (
                                        <Row
                                            request={availability}
                                            key={availability.id}
                                        />
                                    ))
                                )

                            }

                        </TableBody>
                    </Table>
                    <TableFooter
                        hasPrevious={pageInfo.hasPreviousPage}
                        hasNext={pageInfo.hasNextPage}
                        count={{
                            total: totalCount,
                            currentPage: requests.length,
                        }}
                        onBackToStartClick={handleBackToStartClick}
                        onPreviousClick={handlePreviousClick}
                        onNextClick={handleNextClick}
                        onSkipToEndClick={handleSkipToEndClick}
                        loading={loading}
                    />
                </TableContainer>
            </LoadingFade>
        </>
    );
};

export default Content;
