import { Spinner, TextField } from '@get-e/react-components';
import { makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { FunctionComponent, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import report from '../helpers/report';
import useDebounce from '../helpers/useDebounce';
import { GetAirportsInput, GetAirportsResult, GET_AIRPORTS } from './AirportField.graphql';

export interface Airport {
    id: string;
    iata: string;
    name: string;
}

const useStyles = makeStyles({ spinner: { marginBottom: '1rem' } });

const AirportField: FunctionComponent<{
    value: Airport | null;
    onChange: (newValue: Airport | null) => void;
    label: string;
    required?: boolean;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
    // eslint-disable-next-line max-lines-per-function
}> = ({ value, onChange, label, required, error: errorProp, helperText, disabled }) => {
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 500);

    const classes = useStyles();
    const { t } = useTranslation();

    const { data, loading: queryLoading, error } = useQuery<GetAirportsResult, GetAirportsInput>(
        GET_AIRPORTS,
        {
            variables: {
                filter: debouncedSearchValue.trim()
                    ? { search: debouncedSearchValue }
                    : null,
            },
            onError(apolloError) {
                report(apolloError);
            },
        }
    );

    const loading = queryLoading || searchValue !== debouncedSearchValue;

    if (error) {
        // TODO
        return <>Error</>;
    }

    return (
        <Autocomplete
            id="airport-field"
            value={value}
            onChange={(_event, newValue) => onChange(newValue)}
            inputValue={searchValue}
            onInputChange={(_event, newValue) => setSearchValue(newValue)}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionSelected={(option, newValue) => option.id === newValue.id}
            getOptionLabel={option => `${option.iata} - ${option.name}`}
            options={data?.airports.nodes ?? []}
            loading={loading}
            loadingText={t('components.airportField.loading')}
            noOptionsText={t('components.airportField.noResults')}
            disabled={disabled}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    required={required}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: <>
                            {loading && open ? <Spinner size={20} className={classes.spinner} /> : null}
                            {params.InputProps.endAdornment}
                        </>,
                    }}
                    helperText={helperText}
                    error={errorProp}
                />
            )}
        />
    );
};

export default AirportField;
