import { Heading, Modal, PrimaryButton, SecondaryButton } from '@get-e/react-components';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import delay from '../../../helpers/delay';
import useModalStyles from '../../../styles/useModalStyles';

async function removeUser(userId: string): Promise<void> {
    // TODO: Implement
    // eslint-disable-next-line no-console
    console.log(`Removing user ${userId}`);

    await delay(2000);
}

const RemoveUserDialog: FunctionComponent<{
    user: {
        id: string;
        name: string;
    };
    onClose: () => void;
}> = ({ user, onClose }) => {
    const { t } = useTranslation();
    const [removing, setRemoving] = useState(false);
    const modalClasses = useModalStyles();

    async function handleClick(): Promise<void> {
        setRemoving(true);
        await removeUser(user.id);
        setRemoving(false);
    }

    return (
        <Modal
            open={true}
            onClose={onClose}
        >
            <div className={modalClasses.header}>
                <Heading level={2}>
                    {t(
                        'pages.supplierUsers.removeDialog.title',
                        { userName: user.name }
                    )}
                </Heading>
            </div>

            <p>{t('pages.supplierUsers.removeDialog.description')}</p>

            <div className={modalClasses.buttonContainer}>
                <span className={modalClasses.primaryButton}>
                    <PrimaryButton
                        onClick={handleClick}
                        loading={removing}
                        variation="danger"
                    >
                        {t('pages.users.button.removeUser')}
                    </PrimaryButton>
                </span>

                <SecondaryButton
                    onClick={onClose}
                    disabled={removing}
                >
                    {t('buttons.cancel')}
                </SecondaryButton>
            </div>
        </Modal>
    );
};

export default RemoveUserDialog;
