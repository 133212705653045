import { Spinner, TextField } from '@get-e/react-components';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocaleContext } from '../context/LocaleContext';
import formatLocaleNumber from '../helpers/formatNumber';

const useStyles = makeStyles(theme => createStyles({
    cardHeader: { padding: theme.spacing(1, 2) },
    list: {
        height: 300,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    spinnerContainer: {
        textAlign: 'center',
        paddingTop: '1rem',
    },
}));

export interface TransferListItem {
    id: string;
    title: string;
    subtitle: string;
}

const TransferListSide: FunctionComponent<{
    title: string;
    loading: boolean;
    items: TransferListItem[];
    totalItems: number;
    checked: TransferListItem[] | 'ALL';
    setChecked: (checked: TransferListItem[] | 'ALL') => void;
    canCheckAll: boolean;
    disableIndividualItems?: boolean;
    search: string;
    onSearch: (value: string) => void;
    searching: boolean;
    canLoadMore: boolean;
    onLoadMore: () => void;
    // eslint-disable-next-line max-lines-per-function
}> = ({
    title, items, checked, setChecked,
    loading, search, onSearch, canLoadMore, onLoadMore,
    totalItems, canCheckAll, disableIndividualItems, searching,
}) => {
    const classes = useStyles();
    const allChecked = canCheckAll && (checked === 'ALL' || checked.length === totalItems && totalItems !== 0);
    const { locale } = useLocaleContext();
    const { t } = useTranslation();

    const handleToggle = (item: TransferListItem): void => {
        if (checked === 'ALL') {
            setChecked([]);
            return;
        }

        const currentIndex = checked.findIndex(checkedItem => checkedItem.id === item.id);
        const newCheckedIds = [...checked];

        if (currentIndex === -1) {
            newCheckedIds.push(item);
        } else {
            newCheckedIds.splice(currentIndex, 1);
        }

        setChecked(newCheckedIds);
    };

    const handleScroll = (event: React.UIEvent<HTMLDivElement>): void => {
        if (!canLoadMore) {
            return;
        }

        const eventTarget = event.target as HTMLDivElement;
        const distanceToBottom = eventTarget.scrollHeight - eventTarget.scrollTop - eventTarget.clientHeight;

        if (distanceToBottom <= 1) {
            onLoadMore();
        }
    };

    const formatNumber = (number: number): string => formatLocaleNumber(number, locale);

    return (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={() => setChecked(allChecked ? [] : 'ALL')}
                        checked={allChecked}
                        indeterminate={
                            !allChecked
                                && checked.length !== items.length
                                && checked.length !== 0
                        }
                        disabled={items.length === 0 || !canCheckAll}
                        inputProps={{
                            'aria-label': allChecked
                                ? t('components.transferList.aria.deselectAll')
                                : t('components.transferList.aria.selectAll'),
                        }}
                    />
                }
                title={title}
                subheader={
                    allChecked
                        ? t('components.transferList.allSelected')
                        : t('components.transferList.selectedAmount', {
                            selected: formatNumber(checked.length),
                            total: formatNumber(totalItems),
                        })
                }
            />

            <Divider />

            <TextField
                value={search}

                // TODO: Debounce
                onChange={event => onSearch(event.target.value)}
                label={t('components.transferList.fields.search')}
                noHelperTextSpace
                InputProps={{ endAdornment: searching ? <Spinner size={20} /> : null }}
            />

            <List className={classes.list} dense component="div" role="list" onScroll={handleScroll}>
                {
                    items.map(item => {
                        const labelId = `transfer-list-item-label-${item.id}`;
                        const isChecked = checked === 'ALL' || checked.some(checkedItem => checkedItem.id === item.id);

                        return (
                            <ListItem
                                key={item.id}
                                role="listitem"
                                disabled={checked === 'ALL' || disableIndividualItems}
                                button
                                onClick={() => handleToggle(item)}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        checked={isChecked}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>

                                <ListItemText id={labelId} primary={item.title} secondary={item.subtitle} />
                            </ListItem>
                        );
                    })
                }

                {
                    canLoadMore || loading
                        ? (
                            <div className={classes.spinnerContainer}>
                                <Spinner size={32} disableShrink />
                            </div>
                        )
                        : null
                }
                <ListItem />
            </List>
        </Card>
    );
};

export default TransferListSide;
