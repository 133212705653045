import gql from 'graphql-tag';

export const GET_DISRUPTION_NAME = gql`
    query getDisruptionName($id: ID!) {
        disruption(id: $id) {
            id
            flight {
                number
                departure {
                    scheduledDepartureTime
                }
                arrival {
                    scheduledArrivalTime
                }
            }
        }
    }
`;

export interface GetDisruptionNameInput {
    id: string;
}

export interface GetDisruptionNameResult {
    disruption: null | {
        id: string;
        flight: {
            number: string;
            departure: {
                scheduledDepartureTime: string;
            };
            arrival: {
                scheduledArrivalTime: string;
            };
        };
    };
}
