import gql from 'graphql-tag';

export const PUT_ARRIVAL_DISRUPTION = gql`
    mutation putArrivalDisruption($input: PutArrivalDisruptionInput!) {
        putArrivalDisruption(
            input: $input
        ) {
            id
        }
    }
`;

export interface PutArrivalDisruptionInput {
    input: {
        id: string;
        flight: {
            number: string;
            departure: {
                airportId: string;
                scheduledDepartureTime: string;
            };
            arrival: {
                airportId: string;
                scheduledArrivalTime: string;
            };
            passengerCount: {
                total: number;
                infants: number;
                wheelchair: number;
            };
            alternates: Array<{
                airportId: string;
                estimatedTime: string | null;
            }>;
        };
        coachesAuthorized: boolean;
    };
}

export interface PutArrivalDisruptionResult {
    putArrivalDisruption: {
        id: string;
    };
}

export const FIND_FLIGHT = gql`
    query FIND_FLIGHT($departureDate: DateTime!, $flightNumber: String!) {
        scheduledFlight(
            departureDate: $departureDate,
            flightNumber: $flightNumber
        ) {
            departure {
                airport {
                    id
                    iata
                    name
                }
                departureTime
            }
            arrival {
                airport {
                    id
                    iata
                    name
                }
                arrivalTime
            }
        }
    }
`;

export interface FindFlightInput {
    departureDate: string;
    flightNumber: string;
}

export interface FindFlightResult {
    scheduledFlight: null | {
        departure: {
            airport: {
                id: string;
                iata: string;
                name: string;
            };
            departureTime: string;
        };
        arrival: {
            airport: {
                id: string;
                iata: string;
                name: string;
            };
            arrivalTime: string;
        };
    };
}
