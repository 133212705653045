import gql from 'graphql-tag';

export const INVITE_CUSTOMER_USER = gql`
    mutation INVITE_CUSTOMER_USER(
        $name: String!,
        $email: Email!,
        $roleId: ID!,
        $airportsWhitelist: [ID!]
    ) {
        inviteUserForCustomer(input: {
            name: $name,
            email: $email,
            customerRoleId: $roleId,
            airportsWhitelist: $airportsWhitelist,
        }) {
            id
        }
    }
`;

export interface InviteCustomerUserInput {
    name: string;
    email: string;
    roleId: string;
    airportsWhitelist: string[] | null;
}

export interface InviteCustomerUserResult {
    id: string;
}
