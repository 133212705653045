import { Alert, Form, PrimaryButton } from '@get-e/react-components';
import { Grid } from '@material-ui/core';
import moment, { Moment } from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UtcDateField from '../../components/UtcDateField';
import hasProperty from '../../helpers/hasProperty';
import allValid from '../../helpers/validation/allValid';
import FormError from '../../helpers/validation/FormError';
import getFormErrorMessage from '../../helpers/validation/getFormErrorMessage';
import getHelperText from '../../helpers/validation/getHelperText';
import getInputError from '../../helpers/validation/getInputError';
import InputError from '../../helpers/validation/InputError';
import and from '../../helpers/validation/validators/and';
import isAfter from '../../helpers/validation/validators/isAfter';
import isNotNull from '../../helpers/validation/validators/isNotNull';
import useFormStyles from '../../styles/useFormStyles';

const CreateDateRangeReportForm: FunctionComponent = () => {
    const formClasses = useFormStyles();
    const { t } = useTranslation();

    const [startDate, setStartDate] = useState<Moment | null>(
        moment()
            .subtract(1, 'month')
            .startOf('month')
    );

    const [endDate, setEndDate] = useState<Moment | null>(
        moment()
            .subtract(1, 'month')
            .endOf('month')
    );

    const [startDateError, setStartDateError] = useState<InputError | null>(null);

    const [
        endDateError,
        setEndDateError,
    ] = useState<InputError | 'BEFORE_START' | null>(null);

    const [formError, setFormError] = useState<FormError | { message: string } | null>(null);
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = async (): Promise<void> => {
        setFormError(null);

        const validated = {
            startDate: isNotNull(startDate, InputError.Empty),
            endDate: and(
                isNotNull(endDate, InputError.Empty),
                value => isAfter(value, startDate, 'BEFORE_START' as const)
            ),
        };

        if (!allValid(validated)) {
            setFormError(FormError.UserError);

            setStartDateError(getInputError(validated.startDate));
            setEndDateError(getInputError<InputError | 'BEFORE_START'>(validated.endDate));

            return;
        }

        setSubmitting(true);

        // TODO: From API
        const url = 'https://gete-files.s3.eu-central-1.amazonaws.com/accounts/75/8f1660c0e149c079c565644f391827aee8af75e7646f4a9ba689799e166d9a63.xlsx';

        window.open(url, '_blank');

        setSubmitting(false);
    };

    return (
        <Form onSubmit={handleSubmit}>
            <p>{t('pages.reports.dateRange.description')}</p>

            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <UtcDateField
                        label={t('pages.reports.dateRange.inputs.startDate')}
                        value={startDate}
                        onChange={newDate => {
                            setStartDate(newDate);
                            setStartDateError(null);

                            if (endDateError === 'BEFORE_START') {
                                setEndDateError(null);
                            }
                        }}
                        required
                        error={startDateError !== null}
                        helperText={getHelperText(startDateError, t)}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <UtcDateField
                        label={t('pages.reports.dateRange.inputs.endDate')}
                        value={endDate}
                        onChange={newDate => {
                            setEndDate(newDate);
                            setEndDateError(null);
                        }}
                        required
                        error={endDateError !== null}
                        helperText={
                            endDateError === 'BEFORE_START'
                                ? t('pages.reports.dateRange.inputErrors.endDateBeforeStart')
                                : getHelperText(endDateError, t)
                        }
                    />
                </Grid>
            </Grid>

            {
                formError === null
                    ? null
                    : (
                        <Alert
                            severity="error"
                            className={formClasses.mainErrorLimitedWidth}
                        >
                            {
                                hasProperty(formError, 'message')
                                    ? formError.message
                                    : getFormErrorMessage(formError, t)
                            }
                        </Alert>
                    )
            }

            <div className={formClasses.buttons}>
                <PrimaryButton
                    onClick={() => handleSubmit()}
                    loading={submitting}
                    submitsForm
                >
                    {t('pages.reports.buttons.submit')}
                </PrimaryButton>
            </div>
        </Form>
    );
};

export default CreateDateRangeReportForm;
