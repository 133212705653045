import { Alert, Heading, Modal, PrimaryButton, SecondaryButton } from '@get-e/react-components';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import React, { FunctionComponent, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import report from '../../../../helpers/report';
import FormError from '../../../../helpers/validation/FormError';
import getFormErrorMessage from '../../../../helpers/validation/getFormErrorMessage';
import useFormStyles from '../../../../styles/useFormStyles';
import useModalStyles from '../../../../styles/useModalStyles';
import { AvailabilityRowProps } from '../Coaches/AvailabilityRow';
import { DELETE_AVAILABILITY } from './AvailabilityDeleteModal.graphql';

const useStyles = makeStyles(theme => createStyles({
    textBlock: { padding: theme.spacing(0, 0, 1) },
    textLine: { padding: '2px 0' },
}));

const AvailabilityDeleteModal: FunctionComponent<{
    open: boolean;
    onClose: () => void;
    availability: AvailabilityRowProps;
    onDeleted: () => void;
}> = ({ open, onClose, availability, onDeleted }) => {
    const { t } = useTranslation();
    const modalClasses = useModalStyles();
    const formClasses = useFormStyles();
    const classes = useStyles();
    const [formError, setFormError] = useState<FormError | null>(null);

    const [
        deleteRideAvailability,
        { loading: deleting },
    ] = useMutation(
        DELETE_AVAILABILITY
    );

    async function deleteAvailability(): Promise<void> {
        try {
            await deleteRideAvailability({ variables: { id: availability.id } });

            onClose();
            onDeleted();
        } catch (error) {
            report(error);
            setFormError(FormError.UnexpectedError);
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Heading level={2}>
                {t('pages.availability.modals.deleteAvailability.header')}
            </Heading>
            <p className={classes.textBlock}>
                {t('pages.availability.modals.deleteAvailability.confirm')}
            </p>

            <div>
                <div className={classes.textLine}>
                    <strong>
                        {t('pages.availability.modals.deleteAvailability.leadTime')}
                    </strong>{' '}
                    {t(
                        'pages.availability.modals.deleteAvailability.leadTimeValue',
                        { count: availability.timeInMinutes }
                    )}
                </div>
                <div className={classes.textLine}>
                    <strong>
                        {t('pages.availability.modals.deleteAvailability.seats')}
                    </strong>{' '}
                    {t(
                        'pages.availability.modals.deleteAvailability.seatsValue',
                        { count: availability.seats }
                    )}
                </div>
                {availability.description?.trim()
                    ? (
                        <div className={classes.textLine}>
                            <strong>
                                {t(
                                    'pages.availability.modals.deleteAvailability.description'
                                )}
                            </strong>{' '}
                            {availability.description}
                        </div>
                    )
                    : null
                }
            </div>

            {
                formError === null
                    ? null
                    : (
                        <Alert
                            severity="error"
                            className={formClasses.mainErrorLimitedWidth}
                        >
                            {getFormErrorMessage(formError, t)}
                        </Alert>
                    )
            }

            <div className={modalClasses.buttonContainer}>
                <span className={modalClasses.primaryButton}>
                    <PrimaryButton
                        onClick={deleteAvailability}
                        variation="danger"
                        loading={deleting}
                    >
                        {t('buttons.delete')}
                    </PrimaryButton>
                </span>

                <SecondaryButton
                    onClick={onClose}
                >
                    {t('buttons.cancel')}
                </SecondaryButton>
            </div>
        </Modal>
    );
};

export default AvailabilityDeleteModal;
