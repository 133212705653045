import { Validated } from '../Validator';

const isNull = <TValue, TError>(
    value: TValue | null,
    error: TError,
): Validated<null, TError> => (
    value === null
        ? {
            isValid: true,
            value: null,
        }
        : {
            isValid: false,
            error,
        }
);

export default isNull;
