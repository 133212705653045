import { fromPromise } from 'apollo-link';
import { ErrorHandler } from 'apollo-link-error';
import history from '../history';
import refreshCookies from '../services/refreshCookies';

const errorHandler: ErrorHandler = ({
    graphQLErrors,
    operation,
    forward,
}): ReturnType<ErrorHandler> => {
    if (!graphQLErrors) {
        return;
    }

    for (const error of graphQLErrors) {
        switch (error?.extensions?.code) {
            case 'INVALID_TOKEN':
            case 'UNAUTHENTICATED':
                return fromPromise(
                    refreshCookies().then(result => {
                        switch (result.code) {
                            case 'UNAUTHENTICATED': {
                                const encodedLocation = window.encodeURIComponent(
                                    window.location.pathname
                                    + window.location.search
                                );

                                return history.push(`/sign-in?from=${encodedLocation}`);
                            }

                            case 'SUCCESS':
                                return;
                            default:
                                throw new Error('Unhandled result code');
                        }
                    })
                ).flatMap(() => forward(operation));
            default:
                continue;
        }
    }
};

export default errorHandler;
