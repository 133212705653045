import { MenuButtonItem, OptionsTableCell } from '@get-e/react-components';
import {
    Box, Checkbox, Collapse, makeStyles,
    TableCell, TableRow,
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import clsx from 'clsx';
import React, { FunctionComponent, useState } from 'react';
import Currency from '../../../components/Currency';
import { useLocaleContext } from '../../../context/LocaleContext';
import formatNumber from '../../../helpers/formatNumber';
import getValue from '../../../helpers/getValue';
import useTableStyles from '../../../styles/useTableStyles';

const useStyles = makeStyles({
    stars: { whiteSpace: 'nowrap' },
    star: {
        color: '#fbc02d',
        width: '.7em',
        verticalAlign: 'middle',
        margin: -1,
    },
    hotelName: {
        marginRight: '.3em',
        verticalAlign: 'middle',
    },
});

const AvailableRoomRow: FunctionComponent<{
    roomType: {
        id: string;
        name: string;
        availableCount: number;
        price: {
            amount: number;
            currencyCode: string;
        };
        hotel: {
            name: string;
            stars: 1 | 2 | 3 | 4 | 5;
            distanceKm: number;
        };
    };
    open: boolean;
    onOpen: () => void;
    onClose: () => void;
    onSelect: () => void;
    onUnselect: () => void;
    selected: boolean;
}> = ({ roomType, open, onOpen, onClose, onSelect, onUnselect, selected }) => {
    const tableClasses = useTableStyles();
    const classes = useStyles();
    const { locale } = useLocaleContext();

    const [menuOpen, setMenuOpen] = useState(false);

    const stars = getValue(() => {
        const result = [];

        for (let iteration = 0; iteration < roomType.hotel.stars; iteration++) {
            result.push(<StarIcon key={iteration} className={classes.star} />);
        }

        return (
            <span className={classes.stars}>
                {result}
            </span>
        );
    });

    return (
        <>
            <TableRow>
                <TableCell className={tableClasses.buttonColumn}>
                    <Checkbox
                        checked={selected}
                        onChange={(_event, checked) => (
                            checked ? onSelect() : onUnselect()
                        )}
                    />
                </TableCell>

                <TableCell align="right">
                    {/* TODO: Support miles */}
                    {formatNumber(
                        roomType.hotel.distanceKm,
                        locale,
                        {
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 1,
                        }
                    )} km
                </TableCell>

                <TableCell>
                    <span className={classes.hotelName}>
                        {roomType.hotel.name}
                    </span>

                    {stars}
                </TableCell>

                <TableCell>
                    {roomType.name}
                </TableCell>

                <TableCell align="right">
                    {roomType.availableCount}
                </TableCell>

                <TableCell align="right">
                    <Currency
                        amount={roomType.price.amount}
                        code={roomType.price.currencyCode}
                        noDecimals
                    />
                </TableCell>

                <OptionsTableCell
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                >
                    {/* TODO: Supplier only */}

                    <MenuButtonItem
                        onClick={() => {
                            // TODO: Handle
                        }}
                    >
                        Edit
                    </MenuButtonItem>

                    <MenuButtonItem
                        onClick={() => {
                            // TODO: Handle
                        }}
                        dangerous
                    >
                        Delete
                    </MenuButtonItem>
                </OptionsTableCell>
            </TableRow>

            <TableRow>
                <TableCell
                    colSpan={8}
                    className={clsx(
                        tableClasses.collapsableCell,
                        { [tableClasses.collapsedCell]: !open }
                    )}
                >
                    <Collapse
                        in={open}
                        timeout="auto"
                        unmountOnExit
                        className={tableClasses.expandedBackground}
                    >
                        <Box margin={2}>
                            More details coming soon
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default AvailableRoomRow;
