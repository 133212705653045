import { Grid } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import DriversContent from '../Drivers/Content';
import { Supplier } from './Content.graphql';

const DriversTab: FunctionComponent<{ supplier: Supplier }> = ({ supplier }) => (
    <>
        <Grid
            item
            xs={12}
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
        >
            <DriversContent
                supplierId={supplier.id}
            />
        </Grid>
    </>
);

export default DriversTab;
