import gql from 'graphql-tag';

export const UPDATE_SUPPLIER = gql`
    mutation updateSupplier($input: UpdateSupplierInput!) {
        updateSupplier(input: $input) {
            id
        }
    }
`;

export interface UpdateSupplierInput {
    input: {
        supplierId: string;
        name: string;
        address: string | null;
        procedure: string | null;
        vatRegistrationNumber: string | null;
        cocRegistrationNumber: string | null;
        dispatchEmails: string[] | null;
        receivableEmails: string[] | null;
        dispatchPhoneNumbers?: Array<{
            phoneNumber: string;
        }> | null;
        receivablePhoneNumbers?: Array<{
            phoneNumber: string;
        }> | null;
        servicedAirportIds: string[] | null;
        countryId: string;
        languageId: string;
    };
}

export interface UpdateSupplierResult {
    updateSupplier: {
        id: string;
    };
}
