import gql from 'graphql-tag';

export const ACCEPT_SUPPLIER_USER_INVITE = gql`
    mutation acceptSupplierUserInvite($input: AcceptSupplierUserInviteInput!) {
        acceptSupplierUserInvite(input: $input) {
            name
            email
        }
    }
`;

export interface AcceptSupplierUserInviteInput {
    input: {
        password: string;
        token: string;
    };
}

export interface AcceptSupplierUserInviteResult {
    acceptSupplierUserInvite: {
        name: string;
        email: string;
    };
}
