import { Heading, TableBody, TableContainer, TableHead } from '@get-e/react-components';
import { Table, TableCell, TableRow } from '@material-ui/core';
import moment, { Moment } from 'moment';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Airport } from '../../components/AirportField';
import UtcDate from '../../components/UtcDate';
import UtcDateTime from '../../components/UtcDateTime';
import createUrlSearchParams from '../../helpers/createUrlSearchParams';
import getValue from '../../helpers/getValue';
import useTableStyles from '../../styles/useTableStyles';
import SearchBar from './SearchBar';

const recentSearches: Array<{
    createdAt: Moment;
    airport: Airport;
    checkIn: Moment;
    checkOut: Moment;
}> = [
    {
        createdAt: moment(),
        airport: {
            id: '2421',
            iata: 'LGW',
            name: 'London Gatwick Airport',
        },
        checkIn: moment().subtract(2, 'days'),
        checkOut: moment().add(3, 'days'),
    },
    {
        createdAt: moment().subtract(1, 'day'),
        airport: {
            id: '162',
            iata: 'AMS',
            name: 'Amsterdam Schiphol Airport',
        },
        checkIn: moment(),
        checkOut: moment().add(1, 'day'),
    },
];

const FindHotelsPage: FunctionComponent = () => {
    const history = useHistory();
    const tableClasses = useTableStyles();

    const search = (input: {
        airportId: string;
        checkIn: Moment;
        checkOut: Moment;
    }): void => {
        const searchString = createUrlSearchParams({
            airportId: input.airportId,
            checkIn: input.checkIn.format('YYYY-MM-DD'),
            checkOut: input.checkOut.format('YYYY-MM-DD'),
        });

        history.push(`/hotels/search/results?${searchString}`);
    };

    return (
        <>
            <Heading level={1}>Find hotels</Heading>

            <SearchBar
                initialValues={null}
                onChange={newInput => {
                    if (newInput === null) {
                        return;
                    }

                    search({
                        airportId: newInput.airport.id,
                        checkIn: newInput.checkIn,
                        checkOut: newInput.checkOut,
                    });
                }}
            />

            {getValue(() => {
                if (recentSearches.length === 0) {
                    return null;
                }

                return (
                    <>
                        <Heading level={2}>
                            Recent searches
                        </Heading>

                        <TableContainer noFooter>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Searched</TableCell>
                                        <TableCell>Hotels near</TableCell>
                                        <TableCell>Check-in</TableCell>
                                        <TableCell>Check-out</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        recentSearches.map((recentSearch, index) => (
                                            <TableRow
                                                key={index}
                                                className={tableClasses.clickableRow}
                                                hover
                                                onClick={() => search({
                                                    airportId: recentSearch.airport.id,
                                                    checkIn: recentSearch.checkIn,
                                                    checkOut: recentSearch.checkOut,
                                                })}
                                            >
                                                <TableCell>
                                                    <UtcDateTime
                                                        date={recentSearch.createdAt}
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    {recentSearch.airport.iata} - {recentSearch.airport.name}
                                                </TableCell>

                                                <TableCell>
                                                    <UtcDate date={recentSearch.checkIn} />
                                                </TableCell>

                                                <TableCell>
                                                    <UtcDate date={recentSearch.checkOut} />
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                );
            })}
        </>
    );
};

export default FindHotelsPage;
