import gql from 'graphql-tag';

export const SEARCH_COUNTRY = gql`
    query GetCountries($filter: CountryFilter) {
        countries(first: 10, filter: $filter) {
            nodes {
                id
                name
            }
        }
    }
`;

export interface SearchCountryInput {
    filter: null | {search: string};
}

export interface SearchCountryResult {
    countries: {
        nodes: Array<{
            id: string;
            name: string;
        }>;
    };
}
