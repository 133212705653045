import { Form, PrimaryButton, TextField } from '@get-e/react-components';
import { Grid, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import delay from '../../helpers/delay';

interface Country {
    id: string;
    name: string;
}

interface Currency {
    id: string;
    name: string;
    iso: string;
}

interface ExactRelation {
    id: string;
    number: string;
    name: string;
}

const useStyles = makeStyles({ buttonContainer: { marginTop: '1rem' } });

const OrganizationTab: FunctionComponent = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [saving, setSaving] = useState(false);

    // TODO: Load default values from API

    const [companyName, setCompanyName] = useState('Ryanair');
    const [accountsPayableEmail, setAccountsPayableEmail] = useState('');
    const [accountsPayablePhone, setAccountsPayablePhone] = useState('');
    const [vatRegistrationNumber, setVatRegistrationNumber] = useState('');
    const [companyRegistrationNumber, setCompanyRegistrationNumber] = useState('');
    const [invoiceAddress, setInvoiceAddress] = useState('');
    const [country, setCountry] = useState<Country | null>(null);
    const [currency, setCurrency] = useState<Currency | null>(null);
    const [exactRelation, setExactRelation] = useState<ExactRelation | null>(null);

    const countries: Country[] = [
        {
            id: '1',
            name: 'Netherlands',
        }, {
            id: '2',
            name: 'United Kingdom',
        },
    ];

    const currencies: Currency[] = [
        {
            id: '1',
            name: 'Euros',
            iso: 'EUR',
        }, {
            id: '2',
            name: 'British Pounds',
            iso: 'GBP',
        },
    ];

    const exactRelations: ExactRelation[] = [
        {
            id: '1',
            number: '123',
            name: 'Get-e International B.V.',
        },
        {
            id: '1',
            number: '321',
            name: 'Ryanair',
        },
    ];

    const submitForm = async (): Promise<void> => {
        setSaving(true);

        await delay(2000);

        // TODO: Show notification

        setSaving(false);
    };

    return (
        <Form onSubmit={() => submitForm()}>
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <TextField
                        value={companyName}
                        onChange={event => setCompanyName(event.target.value)}
                        label={t('pages.customer.fields.companyName')}
                        required
                    />

                    <TextField
                        value={accountsPayableEmail}
                        onChange={event => setAccountsPayableEmail(event.target.value)}
                        label={t('pages.customer.fields.accountsPayableEmail')}
                        type="email"
                        required
                    />

                    <TextField
                        value={accountsPayablePhone}
                        onChange={event => setAccountsPayablePhone(event.target.value)}
                        label={t('pages.customer.fields.accountsPayablePhone')}
                        type="tel"
                        required
                    />

                    <TextField
                        value={vatRegistrationNumber}
                        onChange={event => setVatRegistrationNumber(event.target.value)}
                        label={t('pages.customer.fields.vatRegistrationNumber')}
                        required
                    />

                    <TextField
                        value={companyRegistrationNumber}
                        onChange={event => setCompanyRegistrationNumber(event.target.value)}
                        label={t('pages.customer.fields.companyRegistrationNumber')}
                        required
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        value={invoiceAddress}
                        onChange={event => setInvoiceAddress(event.target.value)}
                        label={t('pages.customer.fields.invoiceAddress')}
                        required
                        multiline
                        rowsMax={3}
                    />

                    <Autocomplete
                        id="country-field"
                        value={country}
                        onChange={(_event, newValue) => setCountry(newValue)}
                        options={countries}
                        getOptionLabel={option => option.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={t('pages.customer.fields.country')}
                                required
                            />
                        )}
                    />

                    <Autocomplete
                        id="currency-field"
                        value={currency}
                        onChange={(_event, newValue) => setCurrency(newValue)}
                        options={currencies}
                        getOptionLabel={option => `${option.name} (${option.iso})`}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={t('pages.customer.fields.currency')}
                                required
                            />
                        )}
                    />

                    <Autocomplete
                        id="exact-relation-field"
                        value={exactRelation}
                        onChange={(_event, newValue) => setExactRelation(newValue)}
                        options={exactRelations}
                        getOptionLabel={option => `${option.number} - ${option.name}`}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={t('pages.customer.fields.exactRelation')}
                                required
                            />
                        )}
                    />
                </Grid>
            </Grid>

            <div className={classes.buttonContainer}>
                <PrimaryButton
                    onClick={() => submitForm()}
                    loading={saving}
                    submitsForm
                >
                    {t('buttons.save')}
                </PrimaryButton>
            </div>
        </Form>
    );
};

export default OrganizationTab;
