import {
    TableBody, TableContainer, TableFooter,
    TableHead, TableHeader,
} from '@get-e/react-components';
import { makeStyles, Table, TableCell, TableRow } from '@material-ui/core';
import HotelIcon from '@material-ui/icons/Hotel';
import React, { FunctionComponent, useState } from 'react';
import getValue from '../../../helpers/getValue';
import useTableStyles from '../../../styles/useTableStyles';
import BookedRoomRow, { BookedRoomType } from './BookedRoomRow';

const useStyles = makeStyles({ container: { marginTop: '1rem' } });

const BookedRoomsTable: FunctionComponent<{
    rooms: BookedRoomType[];
}> = props => {
    const classes = useStyles();
    const tableClasses = useTableStyles();

    const [openRoomId, setOpenRoomId] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    return (
        <TableContainer
            className={classes.container}
            noFooter={props.rooms.length === 0}
        >
            <TableHeader
                title="Booked rooms"
                onSearchChange={searchValue => {
                    setLoading(Boolean(searchValue.trim()));
                }}
            />

            <Table aria-label="Booked rooms">
                <TableHead loading={loading} columns={8}>
                    <TableRow>
                        <TableCell />
                        <TableCell align="right">Distance</TableCell>
                        <TableCell>Hotel</TableCell>
                        <TableCell className={tableClasses.noWrap}>Room type</TableCell>
                        <TableCell align="right">Rooms</TableCell>

                        <TableCell
                            align="right"
                            className={tableClasses.noWrap}
                        >
                            Price per room
                        </TableCell>

                        <TableCell>Status</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>

                <TableBody loading={loading}>
                    {
                        props.rooms.length === 0
                            ? (
                                <TableRow>
                                    <TableCell
                                        colSpan={8}
                                        className={tableClasses.tableEmptyCell}
                                    >
                                        <HotelIcon className={tableClasses.tableEmptyIcon} />

                                        <span className={tableClasses.tableEmptyText}>
                                            Nothing booked yet
                                        </span>
                                    </TableCell>
                                </TableRow>
                            )
                            : getValue(() => props.rooms.map(room => (
                                <BookedRoomRow
                                    key={room.id}
                                    open={openRoomId === room.id}
                                    onOpen={() => setOpenRoomId(room.id)}
                                    onClose={() => setOpenRoomId(null)}
                                    roomType={room}
                                />
                            )))
                    }
                </TableBody>
            </Table>

            {
                props.rooms.length === 0
                    ? null
                    : (
                        <TableFooter
                            hasNext={true}
                            hasPrevious={false}
                            onBackToStartClick={() => console.log('Back to start')}
                            onNextClick={() => console.log('Next')}
                            onPreviousClick={() => console.log('Previous')}
                            onSkipToEndClick={() => console.log('Skip to end')}
                            count={{
                                total: 42,
                                currentPage: 5,
                            }}
                            loading={loading}
                        />
                    )
            }
        </TableContainer>
    );
};

export default BookedRoomsTable;
