// TODO: Split up
/* eslint-disable max-lines */
import { ApolloError } from 'apollo-client';
import moment, { Moment } from 'moment';
import { useMemo } from 'react';
import { useQuery } from 'react-apollo';
import getValue from '../../helpers/getValue';
import offsetCoordinate from '../../helpers/offsetCoordinate';
import { stringToMoment } from '../../helpers/stringToMoment';
import Location from './Location';
import { GET_COACHES, GetCoachesInput, GetCoachesResult } from './useCoachRoutes.graphql';

export interface DrivingPoint {
    location: Location;

    // TODO: Pickup & drop off times
}

export interface AvailabilityRequest {
    __typename: 'AvailabilityRequest';
    id: string;
    points: DrivingPoint[];
    supplier: {
        id: string;
        name: string;
    };
    createdAt: string;
    cancelledAt: string | null;
    completedAt: string | null;
    estimatedPickupTime: Moment;
    hasAvailabilities: boolean;
}

export interface Availability {
    __typename: 'Availability';
    id: string;
    points: DrivingPoint[];
    supplier: {
        id: string;
        name: string;
    };
    estimatedArrivalTimeMinutes: number;
    vehicle: {
        seats: number;
    };
    createdAt: string;
}

export enum DriverStatus {
    OnTheWay,
    AtPickup,
    OnBoard,
    DroppedOff,
}

export interface Ride {
    __typename: 'Ride';
    id: string;
    number: string;
    vehicle: {
        seats: number;
        busNumber: string | null;
    };
    supplier: {
        id: string;
        name: string;
    };
    events: {
        driverDispatchedAt: Moment | null;
        cancelledAt: Moment | null;
        declinedAt: Moment | null;
        confirmedAt: Moment | null;
        createdAt: Moment;
        availableAt: Moment;
    };
    meetingPoint: string | null;
    driver: null | {
        name: string;
        phoneNumber: string;
    };
    driverStatus: DriverStatus | null;
    lastDriverPosition: null | {
        timestamp: Moment;
        coordinates: {
            latitude: number;
            longitude: number;
        };
        coordinatesAccuracyMeters: number | null;
        bearingDegrees: number | null;
    };
    pickupPoint: {
        trackingTimes: {
            estimatedArrival: Moment;
            actualArrival: Moment | null;
            estimatedDeparture: Moment | null;
            actualDeparture: Moment | null;
        };
    };
    dropOffPoint: {
        trackingTimes: {
            estimatedArrival: Moment | null;
            actualArrival: Moment | null;
        };
    };
    points: DrivingPoint[];
}

export interface CoachRoute {
    points: DrivingPoint[];
    availabilityRequests: AvailabilityRequest[];
    availabilities: Availability[];
    rides: Ride[];
}

export default function useCoachRoutes(
    disruptionId: string,
    { onError }: { onError: (error: ApolloError) => void }
): {
    coachRoutes?: CoachRoute[] | null;
    loading: boolean;
    error?: ApolloError;
    reload: () => void;
} {
    const { data, loading, error, refetch } = useQuery<GetCoachesResult, GetCoachesInput>(
        GET_COACHES,
        {
            variables: {
                disruptionId,
                limitPerType: 100,
            },
            onError,
            fetchPolicy: 'cache-and-network',
            pollInterval: 60_000,
        }
    );

    const coachRoutes = useMemo(() => (data ? createRoutes(data) : null), [data]);

    if (loading || error) {
        return {
            coachRoutes,
            loading,
            error,
            reload: refetch,
        };
    }

    return {
        loading: false,
        coachRoutes,
        reload: refetch,
    };
}

function createRouteKey(route: Array<{ __typename: string; id: string }>): string {
    return route.reduce((result, location) => `${result};${location.__typename}(${location.id})`, '');
}

function createRoutes(queryResult: GetCoachesResult): CoachRoute[] {
    if (queryResult.disruption === null) {
        return [];
    }

    const routes = new Map<string, CoachRoute>();

    const availabilityRequests = queryResult.disruption.rideAvailabilityRequests;

    if (availabilityRequests.pageInfo.hasNextPage) {
        /*
         * Note: This is a workaround to prevent implementing pagination in the MVP,
         * which is quite time-consuming. Once this error occurs we should implement a
         * proper paginated endpoint for the coach routes.
         */
        throw new Error('Too many availability requests');
    }

    availabilityRequests.nodes.forEach(request => {
        if (request.points.pageInfo.hasNextPage) {
            throw new Error('Too many points');
        }

        const key = createRouteKey(request.points.nodes.map(node => node.location));
        const existingRoute = routes.get(key);

        const mappedRequest: AvailabilityRequest = {
            __typename: 'AvailabilityRequest',
            id: request.id,
            points: request.points.nodes,
            supplier: request.supplier,
            completedAt: request.supplierCompletedAt,
            cancelledAt: request.customerCancelledAt,
            createdAt: request.createdAt,
            hasAvailabilities: request.rideAvailabilities.totalCount > 0,

            // TODO: The API should have an AvailabilityRequest.estimatedPickupTime field
            estimatedPickupTime: getValue((): Moment => {
                const { disruption } = queryResult;

                if (disruption === null || disruption.flight.alternates.totalCount !== 1) {
                    return moment();
                }

                const estimatedTimeString = disruption.flight.alternates.nodes[0].estimatedTime;

                return estimatedTimeString === null
                    ? moment()
                    : moment(estimatedTimeString);
            }),
        };

        if (existingRoute) {
            existingRoute.availabilityRequests.push(mappedRequest);
            return;
        }

        routes.set(key, {
            points: request.points.nodes,
            availabilityRequests: [mappedRequest],
            availabilities: [],
            rides: [],
        });
    });

    const { rides } = queryResult.disruption;

    if (rides.pageInfo.hasNextPage) {
        /*
         * Note: This is a workaround to prevent implementing pagination in the MVP,
         * which is quite time-consuming. Once this error occurs we should implement a
         * proper paginated endpoint for the coach routes.
         */
        throw new Error('Too many availability requests');
    }

    rides.nodes.forEach(ride => {
        if (ride.points.pageInfo.hasNextPage) {
            throw new Error('Too many points');
        }

        const key = createRouteKey(ride.points.nodes.map(node => node.location));
        const existingRoute = routes.get(key);

        const mappedRide: Ride = {
            __typename: 'Ride',
            id: ride.id,
            number: ride.number,

            vehicle: {
                seats: ride.vehicle.seats,
                busNumber: ride.busNumber,
            },
            supplier: ride.supplier,
            meetingPoint: ride.meetingPoint,
            driver: ride.driver,
            driverStatus: getDriverStatus({
                onTheWay: ride.events.driverDispatchedAt !== null,
                arrivedAtPickup: ride.pickupPoint.trackingTimes.actualArrival !== null,
                departedFromPickup: ride.pickupPoint.trackingTimes.actualDeparture !== null,
                arrivedAtDropOff: ride.dropOffPoint.trackingTimes.actualArrival !== null,
            }),

            events: {
                availableAt: moment(ride.events.availableAt),
                createdAt: moment(ride.events.createdAt),
                cancelledAt: stringToMoment(ride.events.customerCancelledAt),
                confirmedAt: stringToMoment(ride.events.supplierConfirmedAt),
                declinedAt: stringToMoment(ride.events.supplierDeclinedAt),
                driverDispatchedAt: stringToMoment(ride.events.driverDispatchedAt),
            },

            // TODO: From API
            lastDriverPosition: {
                timestamp: moment().subtract(5, 'minutes'),
                coordinates: offsetCoordinate(
                    ride.points.nodes[0].location.coordinates,
                    {
                        horizontal: -6000,
                        vertical: -8000,
                    }
                ),
                coordinatesAccuracyMeters: 200,
                bearingDegrees: 40,
            },
            points: ride.points.nodes,
            pickupPoint: {
                trackingTimes: {
                    estimatedArrival: moment(ride.pickupPoint.trackingTimes.estimatedArrival),
                    actualArrival: stringToMoment(ride.pickupPoint.trackingTimes.actualArrival),
                    estimatedDeparture: stringToMoment(ride.pickupPoint.trackingTimes.estimatedDeparture),
                    actualDeparture: stringToMoment(ride.pickupPoint.trackingTimes.actualDeparture),
                },
            },
            dropOffPoint: {
                trackingTimes: {
                    estimatedArrival: stringToMoment(ride.dropOffPoint.trackingTimes.estimatedArrival),
                    actualArrival: stringToMoment(ride.dropOffPoint.trackingTimes.actualArrival),
                },
            },
        };

        if (existingRoute) {
            existingRoute.rides.push(mappedRide);
            return;
        }

        routes.set(key, {
            points: ride.points.nodes,
            availabilityRequests: [],
            availabilities: [],
            rides: [mappedRide],
        });
    });

    const { rideAvailabilities: availabilities } = queryResult.disruption;

    if (availabilities.pageInfo.hasNextPage) {
        /*
         * Note: This is a workaround to prevent implementing pagination in the MVP,
         * which is quite time-consuming. Once this error occurs we should implement a
         * proper paginated endpoint for the coach routes.
         */
        throw new Error('Too many availabilities');
    }

    availabilities.nodes.forEach(availability => {
        if (availability.points.pageInfo.hasNextPage) {
            throw new Error('Too many points');
        }

        const key = createRouteKey(availability.points.nodes.map(node => node.location));
        const existingRoute = routes.get(key);

        const mapped: Availability = {
            __typename: 'Availability',
            id: availability.id,
            points: availability.points.nodes,
            createdAt: availability.createdAt,
            estimatedArrivalTimeMinutes: availability.estimatedArrivalTimeMinutes,
            supplier: availability.supplier,
            vehicle: { seats: availability.seats },
        };

        if (existingRoute) {
            existingRoute.availabilities.push(mapped);
            return;
        }

        routes.set(key, {
            points: availability.points.nodes,
            availabilityRequests: [],
            availabilities: [mapped],
            rides: [],
        });
    });

    return Array.from(routes.values());
}

function getDriverStatus(events: {
    onTheWay: boolean;
    arrivedAtPickup: boolean;
    departedFromPickup: boolean;
    arrivedAtDropOff: boolean;
}): DriverStatus | null {
    if (!events.onTheWay) {
        return null;
    }

    if (!events.arrivedAtPickup) {
        return DriverStatus.OnTheWay;
    }

    if (!events.departedFromPickup) {
        return DriverStatus.AtPickup;
    }

    if (!events.arrivedAtDropOff) {
        return DriverStatus.OnBoard;
    }

    return DriverStatus.DroppedOff;
}
