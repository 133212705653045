import { createStyles, makeStyles } from '@material-ui/core';
import LocationOffIcon from '@material-ui/icons/LocationOff';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import StatusBadge, { Status } from './StatusBadge';

const useStyles = makeStyles(() => createStyles({
    statusBadge: { maxWidth: '100px' },
    noTrackingIcon: {
        marginLeft: '.5rem',
        alignSelf: 'center',
    },
}));

const RequestStatus: FunctionComponent<{
    cancelled: boolean;
    completed: boolean;
    rides: {
        booked: number;
        assigned: number;
        confirmed: number;
        inProgress: number;
        completed: number;
        inProgressWithoutTracking: number;
        withPositionUpdates: number;
    };
    availability: {
        unbooked: number;
    };
}> = props => {
    const classes = useStyles();
    const { t } = useTranslation();

    if (props.cancelled) {
        return (
            <StatusBadge
                className={classes.statusBadge}
                status={Status.GreyedOut}
                tooltip={t('pages.availabilities.statuses.tooltips.cancelled')}
            >
                {t('pages.availabilities.statuses.messages.cancelled')}
            </StatusBadge>
        );
    }

    if (!props.completed) {
        return (
            <StatusBadge
                className={classes.statusBadge}
                status={Status.ActionRequired}
                tooltip={t('pages.availabilities.statuses.tooltips.toAddCoaches')}
            >
                {t('pages.availabilities.statuses.messages.toAddCoaches')}
            </StatusBadge>
        );
    }

    if (props.availability.unbooked === 0 && props.rides.booked === 0) {
        return (
            <StatusBadge
                className={classes.statusBadge}
                status={Status.GreyedOut}
                tooltip={t('pages.availabilities.statuses.tooltips.declined')}
            >
                {t('pages.availabilities.statuses.messages.declined')}
            </StatusBadge>
        );
    }

    if (props.rides.inProgressWithoutTracking) {
        return (
            <StatusBadge
                className={classes.statusBadge}
                status={Status.Critical}
                tooltip={t('pages.availabilities.statuses.tooltips.inProgressWithoutTracking')}
            >
                {t('pages.availabilities.statuses.messages.inProgress')}

                <LocationOffIcon className={classes.noTrackingIcon}/>
            </StatusBadge>
        );
    }

    const allRidesAssigned = props.rides.assigned === props.rides.booked;

    if (!allRidesAssigned) {
        return (
            <StatusBadge
                className={classes.statusBadge}
                status={Status.ActionRequired}
                tooltip={t('pages.availabilities.statuses.tooltips.toAssignDriver')}
            >
                {t('pages.availabilities.statuses.messages.toAssignDriver')}
            </StatusBadge>
        );
    }

    const allRidesConfirmed = props.rides.confirmed === props.rides.booked;

    if (!allRidesConfirmed) {
        return (
            <StatusBadge
                className={classes.statusBadge}
                status={Status.ActionRequired}
                tooltip={t('pages.availabilities.statuses.tooltips.toConfirm')}
            >
                {t('pages.availabilities.statuses.messages.toConfirm')}
            </StatusBadge>
        );
    }

    if (props.availability.unbooked) {
        return (
            <StatusBadge
                className={classes.statusBadge}
                status={Status.NoAction}
                tooltip={t('pages.availabilities.statuses.tooltips.waitingForConfirmation')}
            >
                {t('pages.availabilities.statuses.messages.waitingForConfirmation')}
            </StatusBadge>
        );
    }

    const allRidesCompleted = props.rides.completed === props.rides.booked;

    if (allRidesCompleted) {
        return props.rides.withPositionUpdates
            ? (
                <StatusBadge
                    className={classes.statusBadge}
                    status={Status.NoAction}
                    tooltip={t('pages.availabilities.statuses.tooltips.completed')}
                >
                    {t('pages.availabilities.statuses.messages.completed')}
                </StatusBadge>
            )
            : (
                <StatusBadge
                    className={classes.statusBadge}
                    status={Status.NoAction}
                    tooltip={t('pages.availabilities.statuses.tooltips.completedWithoutTracking')}
                >
                    {t('pages.availabilities.statuses.messages.completed')}

                    <LocationOffIcon className={classes.noTrackingIcon}/>
                </StatusBadge>
            );
    }

    if (props.rides.inProgress) {
        return (
            <StatusBadge
                className={classes.statusBadge}
                status={Status.InProgress}
                tooltip={t('pages.availabilities.statuses.tooltips.inProgress')}
            >
                {t('pages.availabilities.statuses.messages.inProgress')}
            </StatusBadge>
        );
    }

    return (
        <StatusBadge
            className={classes.statusBadge}
            status={Status.NoAction}
            tooltip={t('pages.availabilities.statuses.tooltips.confirmed')}
        >
            {t('pages.availabilities.statuses.messages.confirmed')}
        </StatusBadge>
    );
};

export default RequestStatus;

