import {
    Modal, PrimaryButton, SecondaryButton,
    Form, Heading,
} from '@get-e/react-components';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import useModalStyles from '../../../styles/useModalStyles';

const EditDisruptionModal: FunctionComponent<{
    onClose: () => void;
    onSubmit: () => void;
}> = ({ onClose, onSubmit }) => {
    const { t } = useTranslation();
    const modalClasses = useModalStyles();

    const editDisruption = (): void => {
        onSubmit();
        onClose();
    };

    return (
        <Modal
            open
            onClose={onClose}
        >
            <div className={modalClasses.header}>
                <Heading
                    level={2}
                >
                    {t('pages.editDisruption.modals.confirmEdit.heading')}
                </Heading>
            </div>
            <Form onSubmit={onSubmit}>
                <p>
                    {t('pages.editDisruption.modals.confirmEdit.message')}
                </p>
                <div className={modalClasses.buttonContainer}>
                    <span className={modalClasses.primaryButton}>
                        <PrimaryButton
                            onClick={editDisruption}
                            submitsForm
                        >
                            {t('buttons.save')}
                        </PrimaryButton>
                    </span>

                    <SecondaryButton
                        onClick={onClose}
                    >
                        {t('buttons.cancel')}
                    </SecondaryButton>
                </div>
            </Form>
        </Modal>
    );
};

export default EditDisruptionModal;
