import { SupportedLocale } from '../context/LocaleContext';
import findZendeskLocale from './findZendeskLocale';

export function prefillZendeskUser(
    name: string,
    email: string,
): void {
    if (!window.zE) {
        throw new Error('Zendesk chat should be loaded');
    }

    window.zE('webWidget', 'prefill', {
        name: {
            value: name,
            readOnly: true,
        },
        email: {
            value: email,
            readOnly: true,
        },
    });
}

export function setZendeskLocale(locale: SupportedLocale): void {
    if (!window.zE) {
        throw new Error('Zendesk chat should be loaded');
    }

    window.zE('webWidget', 'setLocale', findZendeskLocale(locale));
}

export function openZendeskLiveChat(): void {
    if (!window.zE) {
        throw new Error('Zendesk chat should be loaded');
    }

    window.zE('webWidget', 'open');
}

export function initializeZendeskStyles(): void {
    if (!window.zE) {
        throw new Error('Zendesk chat should be loaded');
    }

    window.zE('webWidget', 'updateSettings', {
        webWidget: {
            zIndex: 1100,
            offset: {
                mobile: {
                    // Prevents widget from covering mobile pagination
                    vertical: '0px',
                },
            },
        },
    });
}

export function setZendeskVerticalOffset(offset: number): void {
    if (!window.zE) {
        throw new Error('Zendesk chat should be loaded');
    }

    window.zE('webWidget', 'updateSettings', {
        webWidget: {
            offset: {
                mobile: {
                    // Prevents widget from covering mobile pagination
                    vertical: `${offset}px`,
                },
            },
        },
    });
}
