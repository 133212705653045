import { TFunction } from 'i18next';
import InputError from './InputError';

export default function getHelperText(error: InputError | null, t: TFunction): string {
    switch (error) {
        case null:
            return '';
        case InputError.Empty:
            return t('errors.forms.inputs.empty');
        case InputError.InvalidEmail:
            return t('errors.forms.inputs.invalidEmail');
        case InputError.NoMatch:
            return t('errors.forms.inputs.noMatch');
        case InputError.InvalidPassword:
            return t('errors.forms.inputs.invalidPassword');
        default:
            throw new Error('Unhandled error');
    }
}
