import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import UtcTime from '../../../components/UtcTime';
import getValue from '../../../helpers/getValue';
import { DriverStatus, Ride } from '../useCoachRoutes';
import liveTrackingIcon from './liveTracking.svg';
import useCoachRowStyles from './useCoachRowStyles';

const useStyles = makeStyles(theme => ({
    liveTracking: {
        color: theme.palette.secondary.main,
        fontSize: 14,
        fontWeight: 600,
    },
    liveTrackingIcon: {
        display: 'inline-block',
        verticalAlign: 'middle',
        width: 12,
        height: 12,
        marginRight: '.3rem',
        animationName: '$blinker',
        animationDuration: '3s',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
    },
}));

const RideStatus: FunctionComponent<{
    ride: Ride;
}> = ({ ride }) => {
    const classes = useStyles();
    const rowClasses = useCoachRowStyles();

    const liveTracking = getValue(() => {
        if (ride.lastDriverPosition === null) {
            return null;
        }

        return (
            <div className={classes.liveTracking}>
                <img
                    alt="Live tracking"
                    src={liveTrackingIcon}
                    className={classes.liveTrackingIcon}
                />

                <span>Live tracking</span>
            </div>
        );
    });

    if (ride.events.cancelledAt !== null) {
        return (
            <span className={clsx(rowClasses.status, rowClasses.inactiveStatus)}>
                Ride cancelled
            </span>
        );
    }

    if (ride.events.declinedAt !== null) {
        return (
            <span className={rowClasses.status}>
                No longer available
            </span>
        );
    }

    if (ride.events.confirmedAt === null) {
        return (
            <span className={rowClasses.status}>
                Waiting for confirmation
            </span>
        );
    }

    switch (ride.driverStatus) {
        case null:
            return (
                <>
                    <div className={rowClasses.status}>
                        Expected at pickup
                        {' '}
                        <UtcTime time={ride.pickupPoint.trackingTimes.estimatedArrival} />
                    </div>

                    <div className={rowClasses.statusSubtext}>
                        Waiting for supplier dispatching
                    </div>
                </>
            );

        case DriverStatus.OnTheWay:
            return (
                <>
                    <div className={clsx(rowClasses.status, rowClasses.inProgressStatus)}>
                        Expected at pickup
                        {' '}
                        <UtcTime time={ride.pickupPoint.trackingTimes.estimatedArrival} />
                    </div>

                    {liveTracking}
                </>
            );

        case DriverStatus.AtPickup: {
            const arrivalTime = ride.pickupPoint.trackingTimes.actualArrival;

            if (arrivalTime === null) {
                throw new Error('Arrival time should be known');
            }

            return (
                <span className={clsx(rowClasses.status, rowClasses.inProgressStatus)}>
                    Arrived at pickup <UtcTime time={arrivalTime} />
                    {liveTracking}
                </span>
            );
        }

        case DriverStatus.OnBoard: {
            const pickupTime = ride.pickupPoint.trackingTimes.actualDeparture;

            if (pickupTime === null) {
                throw new Error('Pickup time should be known');
            }

            return (
                <span className={clsx(rowClasses.status, rowClasses.inProgressStatus)}>
                    {
                        ride.dropOffPoint.trackingTimes.estimatedArrival === null
                            ? <>En route at <UtcTime time={pickupTime} /></>
                            : (
                                <>
                                    Expected drop off
                                    {' '}
                                    <UtcTime time={ride.dropOffPoint.trackingTimes.estimatedArrival} />
                                </>
                            )
                    }

                    {liveTracking}
                </span>
            );
        }

        case DriverStatus.DroppedOff:
            return (
                <span className={rowClasses.status}>
                    Passengers dropped off
                </span>
            );

        default:
            throw new Error('Unhandled driver status');
    }
};

export default RideStatus;
