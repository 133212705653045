import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import Retry from '../../../../components/Retry';
import { useCurrentUserContext } from '../../../../context/CurrentUserContext';
import {
    BreadcrumbHeadNode, BreadcrumbNode,
    PageDefinition,
} from '../../../../createRoutes';
import report from '../../../../helpers/report';
import Page from '../../../../layouts/Page';
import {
    GetCustomerNameInput,
    GetCustomerNameResult, GET_CUSTOMER_NAME,
} from '../../../../queries/GetCustomerName.graphql';
import Content from './Content';
import {
    GetCustomerUserInput,
    GetCustomerUserResult,
    GET_CUSTOMER_USER,
} from './index.graphql';

const editCustomerUserPage: PageDefinition = {
    path: '/customers/:customerId/users/:userId',
    // eslint-disable-next-line max-lines-per-function
    Component({ getParam }) {
        const { t } = useTranslation();
        const currentUser = useCurrentUserContext();

        const customerId = getParam('customerId');
        const userId = getParam('userId');

        const {
            data: userData,
            loading: userLoading,
            error: userError,
            refetch: userRefetch,
        } = useQuery<GetCustomerUserResult, GetCustomerUserInput>(
            GET_CUSTOMER_USER,
            {
                variables: {
                    customerId,
                    userId,
                },
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'network-only',
            }
        );

        const {
            data: customerNameData,
            loading: customerNameLoading,
            error: customerNameError,
        } = useQuery<GetCustomerNameResult, GetCustomerNameInput>(
            GET_CUSTOMER_NAME,
            {
                variables: { id: customerId },
                onError(apolloError) {
                    report(apolloError);
                    throw apolloError;
                },
            }
        );

        const customerName = ((): BreadcrumbNode['name'] => {
            if (customerNameLoading || customerNameError) {
                // Note: In case of an error the user will see the error boundary instead
                return <Skeleton width="7ch" />;
            }

            if (!customerNameData || !customerNameData.customer) {
                return t('breadcrumbs.unknownCustomer');
            }

            return customerNameData.customer.name;
        })();

        const userName = userData?.customer.userEdge.node.name ?? 'unknown';

        // eslint-disable-next-line max-lines-per-function
        const breadcrumbs = ((): BreadcrumbHeadNode => {
            const multipleCustomers = currentUser.isBackOffice
                || currentUser.managedCustomer === 'MULTIPLE';

            if (multipleCustomers) {
                return {
                    name: userName,
                    previous: {
                        name: customerName,
                        path: `/customers/${customerId}/users`,
                        previous: {
                            name: t('breadcrumbs.customers'),
                            path: '/customers',
                        },
                    },
                };
            }

            return {
                name: userName,
                previous: {
                    name: t('breadcrumbs.customerUsers'),
                    path: `/customers/${customerId}/users`,
                },
            };
        })();

        return (
            <Page breadcrumbs={breadcrumbs}>
                {(userError || !userData)
                    ? (
                        <Retry onRetry={() => userRefetch()} loading={userLoading} />
                    )
                    : (
                        <Content
                            customerId={customerId}
                            userId={userId}
                            user={{
                                id: userId,
                                name: userData.customer.userEdge.node.name,
                                email: userData.customer.userEdge.node.email,
                                customerUserRole:
                                    userData.customer.userEdge.customerRole.id,
                                customerRoles: userData.customer.roles.nodes,
                                airportWhitelist:
                                    userData.customer.userEdge.airportWhitelist?.nodes ?? null,
                                joinedOn: moment(
                                    userData.customer.userEdge.node.joinedAt
                                ),
                            }}
                        />
                    )
                }
            </Page>
        );
    },
};

export default editCustomerUserPage;
