import {
    TableBody, TableContainer,
    TableFooter, TableHead,
    TableHeader, PrimaryButton, LoadingPage,
} from '@get-e/react-components';
import { makeStyles, Table, TableCell, TableRow, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { FunctionComponent, useState } from 'react';
import { useQuery } from 'react-apollo';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Retry from '../../components/Retry';
import report from '../../helpers/report';
import useDebounce from '../../helpers/useDebounce';
import useLoaderDebounce from '../../helpers/useLoaderDebounce';
import useOpenNewTab from '../../helpers/useOpenNewTab';
import useUrlQuery from '../../helpers/useUrlQuery';
import useTablePageStyles from '../useTablePageStyles';
import { GET_DRIVERS, GetDriversInput, GetDriversResult } from './Content.graphql';

const useStyles = makeStyles({ row: { '&:hover': { cursor: 'pointer' } } });

interface DriverRow {
    id: string;
    name: string;
    phoneNumber: string;
}

interface RowProps {
    driver: DriverRow;
    supplierId: string;
}

const Row: FunctionComponent<RowProps> = ({ driver, supplierId }) => {
    const classes = useStyles();
    const handleClick = useOpenNewTab(`/suppliers/${supplierId}/drivers/${driver.id}`);

    return (
        <TableRow
            hover
            className={classes.row}
            onClick={handleClick}
        >
            <TableCell>{driver.name}</TableCell>
            <TableCell>{driver.phoneNumber}</TableCell>
        </TableRow>
    );
};

const Content: FunctionComponent<{ supplierId: string }> = ({ supplierId }) => {
    const { t } = useTranslation();
    const classes = useTablePageStyles();
    const history = useHistory();
    const query = useUrlQuery();
    const after = query.get('after');
    const before = query.get('before');
    const paginationLimit = 20;

    const [search, setSearch] = useState('');
    const debouncedSearchValue = useDebounce(search, 500);

    const variables = ((): GetDriversInput => {
        const driversFilter: null | { search: string }
            = debouncedSearchValue
                ? { search: debouncedSearchValue }
                : null;

        if (before !== null) {
            return {
                id: supplierId,
                last: paginationLimit,
                before: before || undefined,
                filter: driversFilter,
            };
        }

        return {
            id: supplierId,
            first: paginationLimit,
            after: after ?? undefined,
            filter: driversFilter,
        };
    })();

    const {
        data,
        loading,
        error,
        refetch,
    } = useQuery<GetDriversResult>(
        GET_DRIVERS,
        {
            variables,
            notifyOnNetworkStatusChange: true,
            onError(apolloError) {
                report(apolloError);
            },
            fetchPolicy: 'cache-and-network',
        }
    );

    const showLoader = useLoaderDebounce(loading);

    if (loading && !data) {
        return showLoader
            ? <LoadingPage />
            : null;
    }

    if (error || !data) {
        return <Retry onRetry={() => refetch()} loading={loading} />;
    }

    const handleBackToStartClick = (): void => {
        history.push({ search: '' });
    };

    const handlePreviousClick = (): void => {
        const { startCursor } = data.supplier.drivers.pageInfo;

        if (startCursor === null) {
            throw new Error('Page info does not contain start cursor');
        }

        history.push({ search: `?before=${startCursor}` });
    };

    const handleNextClick = (): void => {
        const { endCursor } = data.supplier.drivers.pageInfo;

        if (endCursor === null) {
            throw new Error('Page info does not contain end cursor');
        }

        history.push({ search: `?after=${endCursor}` });
    };

    const handleSkipToEndClick = (): void => {
        history.push({ search: '?before' });
    };

    const drivers = data.supplier.drivers.nodes;

    return (
        <>
            <Grid container justify="flex-end">
                <PrimaryButton
                    icon={<AddIcon />}
                    onClick={() => {
                        history.push(`/suppliers/${supplierId}/drivers/add`);
                    }}
                >
                    {t('pages.drivers.buttons.addDriver')}

                </PrimaryButton>
            </Grid>
            <TableContainer
                className={classes.table}

            >
                <TableHeader
                    title={t('pages.drivers.headings.drivers')}
                    onSearchChange={searchValue => setSearch(searchValue)}
                />

                <Table aria-label={t('pages.drivers.aria.table')}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('pages.drivers.columns.name')}</TableCell>
                            <TableCell>{t('pages.drivers.columns.mobile')}</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {drivers.length
                            ? (drivers
                                .map(driver => (
                                    <Row
                                        supplierId={supplierId}
                                        driver={driver}
                                        key={driver.id}
                                    />
                                )))
                            : (
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <Trans i18nKey="pages.drivers.noDrivers">
                                            <strong></strong><br/>
                                        </Trans>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>

                <TableFooter
                    hasPrevious={data.supplier.drivers.pageInfo.hasPreviousPage}
                    hasNext={data.supplier.drivers.pageInfo.hasNextPage}
                    onBackToStartClick={handleBackToStartClick}
                    onPreviousClick={handlePreviousClick}
                    onNextClick={handleNextClick}
                    onSkipToEndClick={handleSkipToEndClick}
                    count={{
                        total: data.supplier.drivers.totalCount,
                        currentPage: data.supplier.drivers.nodes.length,
                    }}
                    loading={loading}
                />
            </TableContainer>
        </>
    );
};

export default Content;
