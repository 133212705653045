import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useCurrentUserContext } from '../../context/CurrentUserContext';
import { BreadcrumbHeadNode, PageDefinition } from '../../createRoutes';
import Page from '../../layouts/Page';
import {
    GetSupplierNameInput,
    GetSupplierNameResult,
    GET_SUPPLIER_NAME,
} from '../../queries/GetSupplierName.graphql';
import Content, { SupplierTab } from './Content';
import { SupplierInvitesPage, SupplierUsersPage } from './Users';
import { UsersTabs } from './UsersTab';

function createComponent(
    tab: SupplierTab,
    usersTab?: UsersTabs
): PageDefinition['Component'] {
    return function Component({ getParam }) {
        const { t } = useTranslation();
        const supplierId = getParam('id');
        const currentUser = useCurrentUserContext();

        const {
            data,
            loading,
            error,
        } = useQuery<GetSupplierNameResult, GetSupplierNameInput>(
            GET_SUPPLIER_NAME,
            { variables: { id: supplierId } }
        );

        const supplierName = ((): BreadcrumbHeadNode['name'] => {
            if (loading || error) {
                // Note: In case of an error the user will see the error boundary instead
                return <Skeleton width="7ch" />;
            }

            if (!data || !data.supplier) {
                return t('breadcrumbs.unknownSupplier');
            }

            return data.supplier.name;
        })();

        if (!currentUser.isBackOffice) {
            switch (usersTab) {
                case UsersTabs.Active:
                    return <SupplierUsersPage supplierId={supplierId} />;
                case UsersTabs.Invites:
                    return <SupplierInvitesPage supplierId={supplierId} />;
                default:
                    break;
            }
        }

        return (
            <Page
                breadcrumbs={{
                    name: supplierName,
                    previous: {
                        name: t('breadcrumbs.suppliers'),
                        path: '/suppliers',
                    },
                }}
            >
                <Content
                    supplierId={supplierId}
                    tab={tab}
                    usersTab={usersTab}
                />
            </Page>
        );
    };
}

const supplierPage: PageDefinition = {
    path: '/suppliers/:id',
    Component: createComponent(SupplierTab.EditsTab),
};

const supplierEditPage: PageDefinition = {
    path: '/suppliers/:id/edit',
    Component: createComponent(SupplierTab.EditsTab),
};

const supplierUsersPage: PageDefinition = {
    path: '/suppliers/:id/users',
    Component: createComponent(SupplierTab.UsersTab, UsersTabs.Active),
};

const supplierUsersInvitesPage: PageDefinition = {
    path: '/suppliers/:id/invites',
    Component: createComponent(SupplierTab.UsersTab, UsersTabs.Invites),
};

export {
    supplierPage,
    supplierEditPage,
    supplierUsersPage,
    supplierUsersInvitesPage,
};
