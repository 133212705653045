import { FullScreenLoader } from '@get-e/react-components';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useCurrentUserContext } from './context/CurrentUserContext';
import createRoutes from './createRoutes';
import CurrentUserProvider from './CurrentUserProvider';
import Page from './layouts/Page';
import ForgotPassword from './pages/ForgotPassword';
import SetPassword from './pages/SetPassword';
import SignIn from './pages/SignIn';

const InitialRoute: FunctionComponent = () => {
    const currentUser = useCurrentUserContext();
    const history = useHistory();

    const redirect = currentUser.managedCustomer === null && currentUser.managedSupplier
        ? `/suppliers/${currentUser.managedSupplier.id}/requests`
        : '/disruptions';

    useEffect(() => {
        history.push(redirect);
    }, []);

    return (
        <FullScreenLoader />
    );
};

const Routes: FunctionComponent = () => {
    const { t } = useTranslation();

    // TODO: Authorization
    const routes = useMemo(() => {
        const routesConfigs = createRoutes();

        // eslint-disable-next-line @typescript-eslint/naming-convention
        return routesConfigs.map(({ path, Component }) => (
            <Route exact path={path} key={path}>
                <CurrentUserProvider>
                    <Component />
                </CurrentUserProvider>
            </Route>
        ));
    }, []);

    return (
        <Switch>
            <Route exact path="/forgot-password">
                <ForgotPassword />
            </Route>

            <Route exact path="/sign-in">
                <SignIn />
            </Route>

            <Route exact path="/set-password">
                <SetPassword />
            </Route>

            {routes}

            <Route exact path="/">
                <CurrentUserProvider>
                    <InitialRoute />
                </CurrentUserProvider>
            </Route>

            <Route path="*">
                <CurrentUserProvider>
                    <Page
                        breadcrumbs={{ name: t('breadcrumbs.notFound') }}
                    >
                        {/* TODO: UI and translation */}
                        Page not found
                    </Page>
                </CurrentUserProvider>
            </Route>
        </Switch>
    );
};

export default Routes;
