import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import history from '../history';
import getEnv from './getEnv';
import getValue from './getValue';
import { Environment, getCurrentEnvironment } from './onEnvironment';

export function initSentry(): void {
    Sentry.init({
        dsn: getEnv('SENTRY_DSN'),
        // eslint-disable-next-line max-len
        integrations: [new Integrations.BrowserTracing({ routingInstrumentation: Sentry.reactRouterV5Instrumentation(history) })],
        tracesSampleRate: 1.0,
        environment: getValue(() => {
            const environment = getCurrentEnvironment();

            switch (environment) {
                case Environment.Production:
                    return 'production';

                case Environment.Test:
                    return 'test';

                case Environment.Staging:
                    return 'staging';

                case Environment.Local:
                    return 'local';

                default:
                    // eslint-disable-next-line max-len
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    throw new Error(`Unhandled environment "${environment}"`);
            }
        }),
    });
}

export type ErrorNumber = string;

export function setUserContext(context: UserContext): void {
    Sentry.setUser({
        id: context.userId,
        email: context.emailAddress,
    });

    const onlyOrganization = getValue(() => {
        if (context.supplier) {
            return context.customers.length === 0
                ? context.supplier
                : null;
        }

        return context.customers.length === 1
            ? context.customers[0]
            : null;
    });

    Sentry.setTags({
        email: context.emailAddress,
        organizationId: onlyOrganization?.id,
        companyName: onlyOrganization?.name,
        userType: getValue((): string => {
            if (context.isBackOffice) {
                return 'back-office';
            }

            const isSupplier = Boolean(context.supplier);
            const isCustomer = Boolean(context.customers.length);

            if (isSupplier && isCustomer) {
                return 'customer-and-supplier';
            }

            if (isSupplier) {
                return 'supplier';
            }

            if (isCustomer) {
                return 'customer';
            }

            report(new Error('User belongs to no organization'));

            return 'none';
        }),
    });
}

export function clearUserContext(): void {
    Sentry.setUser(null);
}

export default function report(error: Error): ErrorNumber {
    const errorNumber = Sentry.captureException(error);

    // eslint-disable-next-line no-console
    console.error(`Sentry error ${errorNumber}:`, error);

    return errorNumber;
}

export interface UserContext {
    userId: string;
    emailAddress: string;
    isBackOffice: boolean;
    supplier: null | {
        id: string;
        name: string;
    };
    customers: Array<{
        id: string;
        name: string;
    }>;
}
