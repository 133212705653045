import { useEffect, useState } from 'react';

const useTitle = (title: string | null): void => {
    const [originalTitle] = useState(document.title);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        document.title = title || originalTitle;

        return () => {
            document.title = originalTitle;
        };
    }, [title, originalTitle]);
};

export default useTitle;
