import {
    Alert, Form, PrimaryButton,
    SignedOutLayout, TertiaryButtonLink, TextField,
} from '@get-e/react-components';
import { makeStyles, Paper, Typography } from '@material-ui/core';
import { ApolloError } from 'apollo-client';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import getProperty from '../helpers/getProperty';
import isArray from '../helpers/isArray';
import report from '../helpers/report';
import FormError from '../helpers/validation/FormError';
import getFormErrorMessage from '../helpers/validation/getFormErrorMessage';
import getHelperText from '../helpers/validation/getHelperText';
import InputError from '../helpers/validation/InputError';
import {
    ResetPasswordInput,
    ResetPasswordResult,
    RESET_PASSWORD,
} from './ForgotPassword.graphql';

const useStyles = makeStyles({
    container: {
        padding: '2em',
        maxWidth: '320px',
        margin: '0 auto',
    },
    heading: { marginBottom: '1rem' },
    formError: { marginTop: '1rem' },
    backButtonContainer: {
        marginTop: '1rem',
        textAlign: 'center',
    },
    forgotPasswordLink: {
        textDecoration: 'none',
        color: '#828282',
        fontWeight: 600,
    },
    submitButtonContainer: { marginTop: '1rem' },
});

const ForgotPassword: FunctionComponent = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const autoFocusRef = useRef<HTMLInputElement>();

    useEffect(() => {
        autoFocusRef.current?.focus();
    }, [autoFocusRef]);

    const [email, setEmail] = useState('');
    const [resetFinished, setResetFinished] = useState(false);
    const [emailError, setEmailError] = useState<InputError | null>(null);
    const [formError, setFormError] = useState<FormError | null>(null);

    const [
        resetPassword,
        { loading: submittingReset },
    ] = useMutation<ResetPasswordResult, ResetPasswordInput>(
        RESET_PASSWORD
    );

    async function submitForm(): Promise<void> {
        setEmailError(null);
        setFormError(null);

        try {
            await resetPassword({ variables: { input: { email } } });
            setResetFinished(true);
        } catch (error) {
            if (!(error instanceof ApolloError)) {
                report(error);
                setFormError(FormError.UnexpectedError);
                return;
            }

            const containsInvalidEmail = error.graphQLErrors.some(graphErr => {
                const emailErrors
                    = getProperty(graphErr.extensions, 'inputErrors', 'input', 'email');

                if (isArray(emailErrors)) {
                    return emailErrors
                        .some(emErr => getProperty(emErr, 'code') === 'INVALID_EMAIL');
                }

                return false;
            });

            if (containsInvalidEmail) {
                setEmailError(InputError.InvalidEmail);
                setFormError(FormError.UserError);
            } else {
                setFormError(FormError.UnexpectedError);
            }
        }
    }

    if (resetFinished) {
        return (
            <SignedOutLayout>
                <Paper elevation={0} className={classes.container}>
                    <Typography variant="h2" component="h2" className={classes.heading}>
                        {t('pages.forgotPassword.resetFinished')}
                    </Typography>
                    <p>{t('pages.forgotPassword.resetFinishedExplanation', { email })}</p>
                    <div className={classes.backButtonContainer}>
                        <TertiaryButtonLink to="/sign-in">
                            {t('pages.forgotPassword.backLink')}
                        </TertiaryButtonLink>
                    </div>
                </Paper>
            </SignedOutLayout>
        );
    }

    return (
        <SignedOutLayout>
            <Paper elevation={0} className={classes.container}>
                <Form onSubmit={submitForm}>
                    <Typography variant="h2" component="h2" className={classes.heading}>
                        {t('pages.forgotPassword.heading')}
                    </Typography>

                    <p>{t('pages.forgotPassword.explanation')}</p>

                    <TextField
                        value={email}
                        onChange={event => {
                            setEmail(event.target.value);
                        }}
                        label={t('pages.forgotPassword.inputs.email')}
                        type="email"
                        autoFocus
                        autoComplete="username"
                        error={emailError !== null}
                        helperText={getHelperText(emailError, t)}
                        name="email"
                        inputRef={autoFocusRef}
                        required
                    />

                    {
                        formError === null
                            ? null
                            : (
                                <Alert
                                    severity="error"
                                    className={classes.formError}
                                >
                                    {getFormErrorMessage(formError, t)}
                                </Alert>
                            )
                    }

                    <div className={classes.submitButtonContainer}>
                        <PrimaryButton
                            onClick={() => submitForm()}
                            loading={submittingReset}
                            fullWidth
                            submitsForm
                        >
                            {t('pages.forgotPassword.submitButton')}
                        </PrimaryButton>
                    </div>

                    <div className={classes.backButtonContainer}>
                        <TertiaryButtonLink to="/sign-in">
                            {t('pages.forgotPassword.backLink')}
                        </TertiaryButtonLink>
                    </div>
                </Form>
            </Paper>
        </SignedOutLayout>
    );
};

export default ForgotPassword;
