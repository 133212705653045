import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageDefinition } from '../../createRoutes';
import Page from '../../layouts/Page';
import Content from './Content';

const customersPage: PageDefinition = {
    path: '/customers',
    Component() {
        const { t } = useTranslation();

        return (
            <Page breadcrumbs={{ name: t('breadcrumbs.customers') }}>
                <Content />
            </Page>
        );
    },
};

export default customersPage;
