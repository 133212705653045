import { Stepper, StepLabel, Spinner, PrimaryButton } from '@get-e/react-components';
import {
    makeStyles, Collapse, Box, Grid,
    Typography, Paper, Step,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import moment from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { useQuery } from 'react-apollo';
import GoogleMap from '../../../components/GoogleMap';
import Retry from '../../../components/Retry';
import UtcTime from '../../../components/UtcTime';
import { useLocaleContext } from '../../../context/LocaleContext';
import getValue from '../../../helpers/getValue';
import report from '../../../helpers/report';
import useLoaderDebounce from '../../../helpers/useLoaderDebounce';
import {
    GetRidePositionUpdateResult,
    GetRidePositionUpdateInput,
    GET_DRIVER_POSITION_FROM_RIDE,
} from '../../../queries/GetDriverPositionFromRide.graphql';
import useTableStyles from '../../../styles/useTableStyles';
import { Ride } from '../useCoachRoutes';
import { RideStep } from './RideRow';

const useStyles = makeStyles({
    rideSection: { '&:not(:last-child)': { marginBottom: '1.5rem' } },
    driverStatusButton: { marginBottom: '1rem' },
    map: {
        height: '100%',
        overflow: 'hidden',
        minHeight: 200,
        marginLeft: '2rem',
        position: 'relative',
    },
    mapWrapper: {
        height: '100%',
        transition: 'opacity .5s',
    },
    mapSkeleton: {
        height: '100%',
        width: '100%',
        position: 'absolute',
    },
    invisible: {
        opacity: '0',
        transition: 'opacity 0s',
    },
    stepper: {
        marginTop: '3rem',
        padding: 0,
    },
    statusTime: { fontSize: '.875rem' },

});

const RideRowCollapse: FunctionComponent<{
    open: boolean;
    ride: Ride;
    activeStep: number;
    statuses: RideStep[];
}> = ({ open, ride, activeStep, statuses }) => {
    const classes = useStyles();
    const tableClasses = useTableStyles();
    const [mapLoaded, setMapLoaded] = useState(false);
    const { locale } = useLocaleContext();

    const ridePoints = ride.points.map(point => point.location.coordinates);

    const {
        data,
        loading,
        error,
        refetch,
    } = useQuery<GetRidePositionUpdateResult, GetRidePositionUpdateInput>(
        GET_DRIVER_POSITION_FROM_RIDE,
        {
            variables: { id: ride.id },
            onError(apolloError) {
                report(apolloError);
            },
            pollInterval: open ? 5000 : 0,
        }
    );

    const showLoader = useLoaderDebounce(loading);

    if (loading) {
        return showLoader
            ? <Spinner size={20} />
            : null;
    }

    let driverPosition = null;

    // TODO: Do we break entire view if driver position is not available?
    if (error || !data?.ride) {
        return <Retry onRetry={() => refetch()} loading={loading} />;
    }

    const { positionUpdates } = data.ride;
    const latestPosition = positionUpdates.nodes[0];

    if (latestPosition) {
        driverPosition = {
            timestamp: moment(latestPosition.capturedAt),
            coordinates: {
                latitude: latestPosition.position.coordinates.latitude,
                longitude: latestPosition.position.coordinates.longitude,
            },
            coordinatesAccuracyMeters: latestPosition.position.accuracyInMeters,
            bearingDegrees: latestPosition.bearing.degrees,
        };
    }

    return (
        <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            className={tableClasses.expandedBackground}
        >

            <Box margin={2}>
                <Grid container>
                    <Grid item xs={6}>
                        <div className={classes.rideSection}>
                            <Typography variant="h4" component="h4">
                                Meeting point
                            </Typography>

                            <div>
                                {
                                    (ride.meetingPoint === null
                                        || !ride.meetingPoint.trim())
                                        ? <em>Unknown</em>
                                        : ride.meetingPoint
                                }
                            </div>
                        </div>

                        <div className={classes.rideSection}>
                            <Typography variant="h4" component="h4">
                                Driver
                            </Typography>

                            {(() => {
                                if (ride.driver === null) {
                                    return <em>Unknown</em>;
                                }

                                return (
                                    <div>
                                        <div>{ride.driver.name}</div>
                                        <div>{ride.driver.phoneNumber}</div>
                                    </div>
                                );
                            })()}
                        </div>

                        <div className={classes.rideSection}>
                            <Typography variant="h4" component="h4">
                                Bus Number
                            </Typography>

                            {(() => {
                                if (ride.vehicle === null) {
                                    return <em>Unknown</em>;
                                }

                                return (
                                    <div>
                                        <div>{ride.vehicle.busNumber}</div>
                                    </div>
                                );
                            })()}
                        </div>

                    </Grid>
                    <Grid item xs={6}>
                        <Paper className={classes.map}>
                            <Skeleton variant="rect" className={classes.mapSkeleton} />
                            <div
                                className={
                                    clsx(
                                        classes.mapWrapper,
                                        { [classes.invisible]: !mapLoaded }
                                    )
                                }
                            >
                                <GoogleMap
                                    points={ridePoints}
                                    onLoaded={() => setMapLoaded(true)}
                                    driverPosition={driverPosition}
                                />
                            </div>
                        </Paper>
                    </Grid>
                </Grid>

                <Stepper
                    activeStep={activeStep}
                    className={classes.stepper}
                >
                    {statuses.map((status, index) => (
                        <Step key={index}>
                            <StepLabel>
                                <strong>{status.title}</strong>
                                {getValue(() => {
                                    if (status.time === null) {
                                        return null;
                                    }

                                    if (status.isEstimate) {
                                        return (
                                            <div className={classes.statusTime}>
                                                Expected at <UtcTime time={status.time} />

                                                <div>
                                                    {
                                                        status.time.isAfter(moment())
                                                            ? <>{status.time.locale(locale).fromNow(true)} remaining</>
                                                            : status.time.locale(locale).fromNow()
                                                    }
                                                </div>
                                            </div>
                                        );
                                    }

                                    return (
                                        <div className={classes.statusTime}>
                                            <UtcTime time={status.time} />
                                        </div>
                                    );
                                })}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
        </Collapse>
    );
};

export default RideRowCollapse;
