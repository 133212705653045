import { createRandomFloatBetween, getRandomElement } from '../../../helpers/random';

const demoHotels: Array<{
    name: string;
    stars: 1 | 2 | 3 | 4 | 5;
    distanceKm: number;
    currency: 'EUR' | 'USD' | 'GBP';
}> = [
        {
            name: 'Sheraton Amsterdam Airport Schiphol',
            stars: 5,
            distanceKm: createRandomFloatBetween(0.1, 20),
            currency: getRandomElement(['EUR', 'USD', 'GBP']),
        },
        {
            name: 'CitizenM Schiphol Airport',
            stars: 3,
            distanceKm: createRandomFloatBetween(0.1, 20),
            currency: getRandomElement(['EUR', 'USD', 'GBP']),
        },
        {
            name: 'Hilton Amsterdam Airport Schiphol',
            stars: 4,
            distanceKm: createRandomFloatBetween(0.1, 20),
            currency: getRandomElement(['EUR', 'USD', 'GBP']),
        },
        {
            name: 'Corendon Village Hotel Amsterdam',
            stars: 3,
            distanceKm: createRandomFloatBetween(0.1, 20),
            currency: getRandomElement(['EUR', 'USD', 'GBP']),
        },
        {
            name: 'Ibis budget Amsterdam Airport',
            stars: 2,
            distanceKm: createRandomFloatBetween(0.1, 20),
            currency: getRandomElement(['EUR', 'USD', 'GBP']),
        },
        {
            name: 'Ibis Schiphol Amsterdam Airport',
            stars: 3,
            distanceKm: createRandomFloatBetween(0.1, 20),
            currency: getRandomElement(['EUR', 'USD', 'GBP']),
        },
        {
            name: 'Ramada by Wyndham Amsterdam Airport Schiphol',
            stars: 5,
            distanceKm: createRandomFloatBetween(0.1, 20),
            currency: getRandomElement(['EUR', 'USD', 'GBP']),
        },
    ];

export default demoHotels;
