import React, { FunctionComponent } from 'react';
import { useLocaleContext } from '../context/LocaleContext';

const Currency: FunctionComponent<{
    amount: number;

    // ISO 4217 code
    code: string;

    noDecimals?: boolean;
}> = ({ amount, code, noDecimals }) => {
    const { locale } = useLocaleContext();

    const formatted = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: code,
        maximumFractionDigits: noDecimals ? 0 : undefined,
    }).format(amount);

    return <>{formatted}</>;
};

export default Currency;
