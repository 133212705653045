import { DateField, DateFieldProps } from '@get-e/react-components';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

const UtcDateField: FunctionComponent<Omit<DateFieldProps, 'format' | 'utc'>> = props => {
    const { t } = useTranslation();

    return (
        <DateField
            {...props}
            utc
            format={t('dateFormat.date')}
        />
    );
};

export default UtcDateField;
