import ApolloClient from 'apollo-client';
import config from '../config';
import hasProperty from '../helpers/hasProperty';
import parseInvalidInputResponse from '../helpers/validation/parseInvalidInputResponse';

type SignInResult = {
    code: 'COOKIES_SET' | 'INVALID_CREDENTIALS';
} | {
    code: 'INVALID_INPUT';
    errors: {
        email: Array<{ code: string }>;
        password: Array<{ code: string }>;
    };
};

export default async function signIn({ email, password, apolloClient }: {
    email: string;
    password: string;
    apolloClient: ApolloClient<unknown>;
}): Promise<SignInResult> {
    const response = await fetch(`${config.apiUrl}/auth/sign-in`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
            email,
            password,
        }),
    });

    const isSuccess = response.status >= 200 && response.status < 300;

    if (isSuccess) {
        await apolloClient.resetStore();
        return { code: 'COOKIES_SET' };
    }

    const body = await response.json() as unknown;

    if (hasProperty(body, 'code') && body.code === 'INVALID_CREDENTIALS') {
        return { code: 'INVALID_CREDENTIALS' };
    }

    const invalidInput = parseInvalidInputResponse(body, ['email', 'password']);

    if (invalidInput !== null) {
        return {
            code: 'INVALID_INPUT',
            errors: invalidInput,
        };
    }

    throw new Error(`Unexpected response code ${response.status}`);
}
