import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useCurrentUserContext } from '../../context/CurrentUserContext';
import { BreadcrumbHeadNode, PageDefinition } from '../../createRoutes';
import Page from '../../layouts/Page';
import {
    GetSupplierNameResult,
    GetSupplierNameInput,
    GET_SUPPLIER_NAME,
} from '../../queries/GetSupplierName.graphql';
import SuppliersContent, { SupplierTab } from '../Supplier/Content';
import Content from './Content';

const driversPage: PageDefinition = {
    path: '/suppliers/:supplierId/drivers',
    Component({ getParam }) {
        const { t } = useTranslation();
        const supplierId = getParam('supplierId');
        const currentUser = useCurrentUserContext();

        const {
            data,
            loading,
            error,
        } = useQuery<GetSupplierNameResult, GetSupplierNameInput>(
            GET_SUPPLIER_NAME,
            { variables: { id: supplierId } }
        );

        const supplierName = ((): BreadcrumbHeadNode['name'] => {
            if (loading || error) {
                // Note: In case of an error the user will see the error boundary instead
                return <Skeleton width="7ch" />;
            }

            if (!data || !data.supplier) {
                return t('breadcrumbs.unknownSupplier');
            }

            return data.supplier.name;
        })();

        if (currentUser.isBackOffice) {
            return (
                <Page
                    breadcrumbs={{
                        name: t('breadcrumbs.drivers'),
                        previous: {
                            name: supplierName,
                            path: `/suppliers/${supplierId}`,
                            previous: {
                                name: t('navigation.suppliers'),
                                path: '/suppliers',
                            },
                        },
                    }}
                >
                    <SuppliersContent
                        tab={SupplierTab.DriversTab}
                        supplierId={supplierId}
                    />
                </Page>
            );
        }

        return (
            <Page
                breadcrumbs={{
                    name: t('breadcrumbs.drivers'),
                }}
            >
                <Content supplierId={supplierId} />
            </Page>
        );
    },
};

export default driversPage;

