import {
    Alert,
    Form, Modal,
    PrimaryButton, SecondaryButton,
} from '@get-e/react-components';
import { makeStyles, Typography } from '@material-ui/core';
import { ApolloError } from 'apollo-client';
import moment from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import UtcTime from '../../components/UtcTime';
import getValue from '../../helpers/getValue';
import report from '../../helpers/report';
import FormError from '../../helpers/validation/FormError';
import getFormErrorMessage from '../../helpers/validation/getFormErrorMessage';
import useFormStyles from '../../styles/useFormStyles';
import useModalStyles from '../../styles/useModalStyles';
import {
    BookAvailabilitiesInput,
    BookAvailabilitiesResult,
    BOOK_AVAILABILITIES,
} from './BookModal.graphql';
import { ErrorType, SupportedErrorEnums } from './CompleteDisruptionDialog';
import disruptionErrorMessages, { DisruptionError } from './disruptionErrorMessages';

const useStyles = makeStyles({
    paragraph: { marginBottom: '.5rem !important' },
    coachesList: { margin: '1rem' },
});

const BookModal: FunctionComponent<{
    availabilities: Array<{
        id: string;
        seats: number;
        estimatedArrivalTimeMinutes: number;
    }>;
    onClose: () => void;
    onBooked: () => void;
}> = ({ availabilities, onClose, onBooked }) => {
    const classes = useStyles();
    const formClasses = useFormStyles();
    const modalClasses = useModalStyles();
    const { t } = useTranslation();

    const [
        bookAvailabilities,
        { loading: submitting },
    ] = useMutation<BookAvailabilitiesResult, BookAvailabilitiesInput>(
        BOOK_AVAILABILITIES
    );

    const [formError, setFormError] = useState<ErrorType | null>(null);

    if (availabilities.length === 0) {
        throw new Error('At least one availability required');
    }

    const submitForm = async (): Promise<void> => {
        try {
            await bookAvailabilities({
                variables: {
                    input: {
                        availabilityIds: availabilities.map(
                            availability => availability.id
                        ),
                    },
                },
            });

            onBooked();
        } catch (error) {
            if (!(error instanceof ApolloError)) {
                report(error);
                return;
            }

            if (error.networkError) {
                setFormError({
                    type: SupportedErrorEnums.FormError,
                    error: FormError.UnexpectedError,
                });
            }

            if (error.graphQLErrors) {
                for (const graphQLError of error.graphQLErrors) {
                    switch (graphQLError.extensions?.code) {
                        case 'DISRUPTION_CANCELLED':
                            setFormError({
                                type: SupportedErrorEnums.DisruptionError,
                                error: DisruptionError.DisruptionCancelled,
                            });
                            break;
                        case 'DISRUPTION_COMPLETED':
                            setFormError({
                                type: SupportedErrorEnums.DisruptionError,
                                error: DisruptionError.DisruptionCompleted,
                            });
                            break;
                        default:
                            report(error);
                        
                            setFormError({
                                type: SupportedErrorEnums.FormError,
                                error: FormError.UnexpectedError,
                            });
                    }
                }
            }
        }
    };

    const totalSeats = availabilities.reduce((result, availability) => result + availability.seats, 0);

    return (
        <Modal
            open={true}
            onClose={onClose}
            maxWidth="md"
        >
            <Form onSubmit={submitForm}>
                <div className={modalClasses.header}>
                    <Typography
                        variant="h2"
                        component="h2"
                        className={modalClasses.heading}
                    >
                        {
                            availabilities.length === 1
                                ? 'Book coach'
                                : 'Book coaches'
                        }
                    </Typography>
                </div>

                <div>
                    {
                        availabilities.length === 1
                            ? (
                                <>
                                    <p className={classes.paragraph}>
                                        You are about to book a coach with {totalSeats} seats.

                                        The driver is expected to arrive at
                                        {' '}
                                        <UtcTime time={moment().add(availabilities[0].estimatedArrivalTimeMinutes, 'minutes')} />.
                                    </p>

                                    <p>Once booked, the driver will receive a message to start GPS tracking.</p>
                                </>
                            )
                            : (
                                <>
                                    <p>You are about to book {availabilities.length} coaches with a total capacity of {totalSeats} seats.</p>

                                    <div className={classes.coachesList}>
                                        {availabilities.map((availability, index) => (
                                            <div key={availability.id}>
                                                {/* TODO: Update every minute */}
                                                <strong>Coach {index + 1}:</strong> Expected at <UtcTime time={moment().add(availability.estimatedArrivalTimeMinutes, 'minutes')} />
                                            </div>
                                        ))}
                                    </div>

                                    <p>Once booked, all drivers will receive a message to start GPS tracking.</p>
                                </>
                            )
                    }
                </div>

                {
                    formError
                        ? (
                            <Alert
                                severity="error"
                                className={formClasses.mainErrorLimitedWidth}
                            >
                                {getValue(() => {
                                    if (formError.type === SupportedErrorEnums.FormError) {
                                        return getFormErrorMessage(formError.error, t);
                                    }

                                    return disruptionErrorMessages(formError.error, t);
                                })}
                            </Alert>
                        )
                        : null
                }

                <div className={modalClasses.buttonContainer}>
                    <span className={modalClasses.primaryButton}>
                        <PrimaryButton
                            onClick={submitForm}
                            loading={submitting}
                            submitsForm
                        >
                            Book
                        </PrimaryButton>
                    </span>

                    <SecondaryButton
                        onClick={onClose}
                        disabled={submitting}
                    >
                        {t('buttons.cancel')}
                    </SecondaryButton>
                </div>
            </Form>
        </Modal>
    );
};

export default BookModal;
