import { Moment } from 'moment';
import { Validated } from '../Validator';

const isAfter = <TError>(
    value: Moment | null,
    minimum: Moment | null,
    error: TError,
): Validated<Moment, TError> => (
    value === null || (minimum !== null && value.isSameOrBefore(minimum))
        ? {
            isValid: false,
            error,
        }
        : {
            isValid: true,
            value,
        }
);

export default isAfter;
