import {
    Alert, Form, PrimaryButton,
    SecondaryButton, TextField, Heading,
} from '@get-e/react-components';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import report from '../../../../helpers/report';
import allValid from '../../../../helpers/validation/allValid';
import FormError from '../../../../helpers/validation/FormError';
import getFormErrorMessage from '../../../../helpers/validation/getFormErrorMessage';
import getHelperText from '../../../../helpers/validation/getHelperText';
import getInputError from '../../../../helpers/validation/getInputError';
import InputError from '../../../../helpers/validation/InputError';
import isFilledString from '../../../../helpers/validation/validators/isFilledString';
import useFormStyles from '../../../../styles/useFormStyles';
import {
    InviteUserToSupplierResult,
    InviteUserToSupplierInput,
    INVITE_SUPPLIER_USER,
} from './Content.graphql';

const InviteUserForm: FunctionComponent<{ supplierId: string }> = ({ supplierId }) => {
    const formClasses = useFormStyles();
    const { t } = useTranslation();
    const history = useHistory();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const [nameError, setNameError] = useState<InputError | null>(null);
    const [emailError, setEmailError] = useState<InputError | null>(null);

    const [formError, setFormError] = useState<FormError | null>(null);
    const [manageUsers, setManageUsers] = useState(false);

    const [
        inviteUserForSupplier,
        { loading: submitting },
    ] = useMutation<InviteUserToSupplierResult, InviteUserToSupplierInput>(
        INVITE_SUPPLIER_USER
    );

    async function handleSubmitButtonClick(): Promise<void> {
        const validated = {
            name: isFilledString(name, InputError.Empty),
            email: isFilledString(email, InputError.Empty),
        };

        if (!allValid(validated)) {
            setFormError(FormError.UserError);

            setNameError(getInputError(validated.name));
            setEmailError(getInputError(validated.email));
            return;
        }

        try {
            const result = await inviteUserForSupplier({
                variables: {
                    input: {
                        supplierId,
                        name: validated.name.value,
                        email: validated.email.value,
                        permissions: { manageUsers },
                    },
                },
            });

            if (!result.data) {
                throw new Error('Mutation did not have result');
            }

            history.push(`/suppliers/${supplierId}/invites`);
        } catch (apolloError) {
            report(apolloError);
            setFormError(FormError.UnexpectedError);
        }
    }

    const formErrorElement = (() => {
        if (formError === null) {
            return null;
        }

        return (
            <Alert severity="error" className={formClasses.mainError}>
                {getFormErrorMessage(formError, t)}
            </Alert>
        );
    })();

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
        >
            <Grid item xs={12} md={6}>
                <Heading
                    level={2}
                >
                    {t('pages.supplierUsers.inviteUser.heading')}
                </Heading>

                <Form
                    autoComplete="off"
                    className={formClasses.form}
                    noValidate
                    onSubmit={() => handleSubmitButtonClick()}
                >
                    <TextField
                        value={name}
                        onChange={event => {
                            setName(event.target.value);
                            setNameError(null);
                        }}
                        error={nameError !== null}
                        helperText={getHelperText(nameError, t)}
                        label={t('pages.users.fields.name')}
                        required
                    />

                    <TextField
                        value={email}
                        onChange={event => {
                            setEmail(event.target.value);
                            setEmailError(null);
                        }}
                        error={emailError !== null}
                        helperText={getHelperText(emailError, t)}
                        inputProps={{ autoCapitalize: 'none' }}
                        label={t('pages.users.fields.email')}
                        type="email"
                        required
                    />

                    <div className={formClasses.fullWidth}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={manageUsers}
                                    onChange={
                                        (_event, newValue) => setManageUsers(newValue)
                                    }
                                />
                            }
                            label={t('pages.supplierUsers.inviteUser.manageUsers')}
                        />
                    </div>

                    {formErrorElement}

                    <div className={formClasses.buttons}>
                        <PrimaryButton
                            loading={submitting}
                            onClick={event => {
                                event.preventDefault();

                                // eslint-disable-next-line max-len
                                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                                handleSubmitButtonClick();
                            }}
                        >
                            {t('pages.inviteCustomerUser.buttons.submit')}
                        </PrimaryButton>

                        <SecondaryButton
                            onClick={() => history.push(`/suppliers/${supplierId}/invites`)}
                            disabled={submitting}
                        >
                            {t('buttons.cancel')}
                        </SecondaryButton>
                    </div>
                </Form>
            </Grid>
        </Grid>
    );
};

export default InviteUserForm;
