import GoogleMapReact from 'google-map-react';
import { Moment } from 'moment';
import React, { FunctionComponent, useState } from 'react';
import config from '../config';
import { useLocaleContext } from '../context/LocaleContext';
import ErrorBoundary from '../ErrorBoundary';
import createDriverIconUrl from '../helpers/maps/createDriverIconUrl';
import fetchDirections from '../helpers/maps/fetchDirections';
import onMapIdle from '../helpers/maps/onMapIdle';
import useEffectAsync from '../helpers/useEffectAsync';

export interface Point {
    longitude: number;
    latitude: number;
}

export type DriverPosition =
    null | {
        timestamp: Moment;
        coordinates: {
            latitude: number;
            longitude: number;
        };
        coordinatesAccuracyMeters: number | null;
        bearingDegrees: number | null;
    };

const Marker: FunctionComponent<{
    driverPosition: DriverPosition;
    lat: number;
    lng: number;
}> = ({ driverPosition }) => {
    const driverIconSize = 28;
    const [icon, setIcon] = useState<string | null>(null);

    useEffectAsync(async () => {
        const driverIcon = await createDriverIconUrl({
            rotationDegrees: driverPosition?.bearingDegrees ?? 45,
            sizePixels: driverIconSize,
        });

        setIcon(driverIcon);
    }, [icon, driverPosition]);

    if (!icon) {
        return null;
    }

    return (
        <img src={icon} alt="Driver" />
    );
};

const GoogleMap: FunctionComponent<{
    points: Point[];
    driverPosition?: DriverPosition;
    onLoaded?: () => void;
}> = ({ points, driverPosition, onLoaded }) => {
    const { locale } = useLocaleContext();

    return (
        <ErrorBoundary error={<>Error</>}>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: config.googleMapsKey,
                    language: locale,
                }}
                // TODO: Base center and zoom on alternates
                defaultCenter={(() => {
                    const pickupCoordinates = points[0];

                    return {
                        lat: pickupCoordinates.latitude,
                        lng: pickupCoordinates.longitude,
                    };
                })()}
                defaultZoom={10}
                yesIWantToUseGoogleMapApiInternals
                shouldUnregisterMapOnUnmount={false}
                onGoogleApiLoaded={
                    // eslint-disable-next-line max-statements, max-lines-per-function
                    async (
                        { map, maps }: { map: google.maps.Map; maps: typeof google.maps }
                    ): Promise<void> => {
                        // TODO: Handle data changing
                        onLoaded?.();

                        const createLatLng = (
                            coordinates: Point
                        ): google.maps.LatLng => new maps.LatLng(
                            coordinates.latitude,
                            coordinates.longitude
                        );

                        const route = points.map(
                            point => createLatLng(point)
                        );

                        const driverCoordinates = driverPosition
                            ? createLatLng(driverPosition.coordinates)
                            : null;

                        const bounds = new maps.LatLngBounds();

                        route.forEach(latLng => bounds.extend(latLng));

                        if (driverCoordinates !== null) {
                            bounds.extend(driverCoordinates);
                        }

                        map.fitBounds(bounds);

                        // TODO: Handle error
                        const directionsPromise
                            = fetchDirections(route).then(directions => {
                                const directionsDisplay = new maps.DirectionsRenderer();

                                directionsDisplay.setDirections(directions);
                                directionsDisplay.setMap(map);
                            })
                                .catch(() => {
                                    route.forEach((latLng, index) => {
                                        const labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

                                        // eslint-disable-next-line no-new
                                        new maps.Marker({
                                            position: latLng,
                                            label: labels[index % labels.length],
                                            map,
                                        });
                                    });
                                });

                        if (!driverCoordinates) {
                            return directionsPromise;
                        }

                        const positionAccuracyMeters
                            = driverPosition?.coordinatesAccuracyMeters;

                        if (positionAccuracyMeters) {
                            // eslint-disable-next-line no-new
                            new google.maps.Circle({
                                strokeColor: '#073690',
                                strokeOpacity: 0.8,
                                strokeWeight: 1,
                                fillColor: '#073690',
                                fillOpacity: 0.25,
                                map,
                                center: driverCoordinates,
                                radius: positionAccuracyMeters,
                            });
                        }

                        await directionsPromise;
                        await onMapIdle(map);
                        map.fitBounds(bounds);
                    }
                }
            >
                {driverPosition
                    ? (
                        <Marker
                            driverPosition={driverPosition}
                            lat={driverPosition.coordinates.latitude}
                            lng={driverPosition.coordinates.longitude}
                        />
                    )
                    : null
                }
            </GoogleMapReact>
        </ErrorBoundary>
    );
};

export default GoogleMap;
