import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageDefinition } from '../../createRoutes';
import report from '../../helpers/report';
import useTitle from '../../helpers/useTitle';
import Page from '../../layouts/Page';
import useDisruptionName from '../../queries/useDisruptionName';
import Content from './Content';

const disruptionPage: PageDefinition = {
    path: '/disruptions/:id',
    Component({ getParam }) {
        const id = getParam('id');
        const { t } = useTranslation();

        const { result: data, loading } = useDisruptionName(id, {
            onError(apolloError) {
                report(apolloError);
            },
        });

        useTitle(data?.disruptionFlightNumber ?? null);

        return (
            <Page
                breadcrumbs={{
                    name: loading
                        ? <Skeleton width="7ch" />
                        : data?.disruptionName ?? t('breadcrumbs.unknownDisruption'),
                    previous: {
                        name: t('breadcrumbs.disruptions'),
                        path: '/disruptions',
                    },
                }}
            >
                <Content id={id} />
            </Page>
        );
    },
};

export default disruptionPage;
