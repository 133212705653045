import gql from 'graphql-tag';

export const GET_SUPPLIER_USER = gql`
    query getSupplierUser($id: ID!) {
        user(id: $id) {
            id
            name
            email
            supplierEdge {
                permissions {
                    manageUsers
                }
            }
        }
    }
`;

export interface GetSupplierUserInput {
    id: string;
}

export interface GetSupplierUserResult {
    user: {
        id: string;
        name: string;
        email: string;
        supplierEdge: {
            permissions: {
                manageUsers: boolean;
            };
        };
    };
}