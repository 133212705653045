import {
    FilterChip,
    PopoverFilterChip,
    TableBody, TableContainer, TableFooter,
    TableHead, TableHeader,
} from '@get-e/react-components';
import {
    Checkbox, FormControlLabel,
    makeStyles, Table, TableCell, TableRow,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { FunctionComponent, useState } from 'react';
import getValue from '../../../helpers/getValue';
import useTableStyles from '../../../styles/useTableStyles';
import AvailableRoomRow from './AvailableRoomRow';

const useStyles = makeStyles({ container: { marginTop: '1rem' } });

export interface AvailableRoomType {
    id: string;
    availableCount: number;
    name: string;
    price: {
        amount: number;
        currencyCode: string;
    };
    hotel: {
        name: string;
        distanceKm: number;
        stars: 1 | 2 | 3 | 4 | 5;
    };
}

const AvailableRoomsTable: FunctionComponent<{
    rooms: AvailableRoomType[];
}> = props => {
    const classes = useStyles();
    const tableClasses = useTableStyles();

    const [openRoomId, setOpenRoomId] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [filtersEnabled, setFiltersEnabled] = useState(false);

    const [selectedAvailabilityIds, setSelectedAvailabilityIds] = useState<string[]>([]);

    const allSelected = props.rooms.every(
        ({ id }) => selectedAvailabilityIds.includes(id)
    );

    return (
        <TableContainer className={classes.container} noFooter={props.rooms.length === 0}>
            <TableHeader
                title="Available rooms"
                onSearchChange={searchValue => {
                    setLoading(Boolean(searchValue.trim()));
                }}
                filters={{
                    enabled: filtersEnabled,
                    onDisable: () => setFiltersEnabled(false),
                    onEnable: () => setFiltersEnabled(true),
                    chips: [
                        <PopoverFilterChip
                            key="room-type"
                            label="Room type"
                            onSave={() => { }}
                            onClear={() => { }}
                        >
                            <div>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Single rooms"
                                />
                            </div>

                            <div>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Double rooms"
                                />
                            </div>

                            <div>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Triple rooms"
                                />
                            </div>
                        </PopoverFilterChip>,

                        <PopoverFilterChip
                            key="amenities"
                            label="Amenities"
                            onSave={() => { }}
                            onClear={() => { }}
                        >
                            <div>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Breakfast included"
                                />
                            </div>

                            <div>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Air-conditioned"
                                />
                            </div>

                            <div>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Free Wi-Fi"
                                />
                            </div>

                            <div>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Child-friendly"
                                />
                            </div>

                            <div>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Wheelchair accessible"
                                />
                            </div>
                        </PopoverFilterChip>,

                        <FilterChip
                            key="stars"
                            enabled={true}
                            onChange={() => { }}
                        >
                            2-5 stars
                        </FilterChip>,

                        <FilterChip
                            key="soft-lock"
                            enabled={true}
                            onChange={() => { }}
                        >
                            Soft-lock
                        </FilterChip>,

                        <FilterChip
                            key="price-range"
                            enabled={true}
                            onChange={() => { }}
                        >
                            Price € 38 - 90
                        </FilterChip>,
                    ],
                }}
            />

            <Table aria-label="Available rooms">
                <TableHead loading={loading} columns={7}>
                    <TableRow>
                        <TableCell className={tableClasses.buttonColumn}>
                            {
                                props.rooms.length === 0
                                    ? null
                                    : (
                                        <Checkbox
                                            checked={allSelected}
                                            onChange={(_, checked) => {
                                                setSelectedAvailabilityIds(
                                                    checked
                                                        ? props.rooms.map(({ id }) => id)
                                                        : []
                                                );
                                            }}
                                        />
                                    )
                            }
                        </TableCell>

                        <TableCell align="right">Distance</TableCell>
                        <TableCell>Hotel</TableCell>
                        <TableCell className={tableClasses.noWrap}>Room type</TableCell>
                        <TableCell align="right">Rooms</TableCell>
                        <TableCell
                            align="right"
                            className={tableClasses.noWrap}
                        >
                            Price per room
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>

                <TableBody loading={loading}>
                    {
                        props.rooms.length === 0
                            ? (
                                <TableRow>
                                    <TableCell
                                        colSpan={8}
                                        className={tableClasses.tableEmptyCell}
                                    >
                                        <SearchIcon className={tableClasses.tableEmptyIcon} />

                                        <span className={tableClasses.tableEmptyText}>
                                            No availability found.
                                            Please contact our support team.
                                        </span>
                                    </TableCell>
                                </TableRow>
                            )
                            : getValue(() => props.rooms.map(room => (
                                <AvailableRoomRow
                                    key={room.id}
                                    open={openRoomId === room.id}
                                    onOpen={() => setOpenRoomId(room.id)}
                                    onClose={() => setOpenRoomId(null)}
                                    roomType={room}
                                    selected={selectedAvailabilityIds.includes(room.id)}
                                    onSelect={() => setSelectedAvailabilityIds(
                                        [...selectedAvailabilityIds, room.id]
                                    )}
                                    onUnselect={() => {
                                        setSelectedAvailabilityIds(
                                            selectedAvailabilityIds.filter(
                                                selectedId => selectedId !== room.id
                                            )
                                        );
                                    }}
                                />
                            )))
                    }
                </TableBody>
            </Table>

            {
                props.rooms.length === 0
                    ? null
                    : (
                        <TableFooter
                            hasNext={true}
                            hasPrevious={false}
                            onBackToStartClick={() => console.log('Back to start')}
                            onNextClick={() => console.log('Next')}
                            onPreviousClick={() => console.log('Previous')}
                            onSkipToEndClick={() => console.log('Skip to end')}
                            count={{
                                total: 42,
                                currentPage: 5,
                            }}
                            loading={loading}
                        />
                    )
            }

        </TableContainer>
    );
};

export default AvailableRoomsTable;
