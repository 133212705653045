import { LoadingPage } from '@get-e/react-components';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { useQuery } from 'react-apollo';
import report from '../../../helpers/report';
import useLoaderDebounce from '../../../helpers/useLoaderDebounce';
import { GetDisruptionToEditInput, GetDisruptionToEditResult, GET_DISRUPTION_TO_EDIT } from './Content.graphql';
import EditDisruptionForm from './EditDisruptionForm';

const Content: FunctionComponent<{ id: string }> = ({ id }) => {
    const {
        data,
        loading,
        error,
    } = useQuery<GetDisruptionToEditResult, GetDisruptionToEditInput>(
        GET_DISRUPTION_TO_EDIT,
        {
            variables: {
                id,
                alternatesLimit: 10,
            },
            onError(apolloError) {
                report(apolloError);
            },
            fetchPolicy: 'network-only',
        }
    );

    const showLoader = useLoaderDebounce(loading);

    if (loading) {
        return showLoader
            ? <LoadingPage />
            : null;
    }

    if (error) {
        // TODO: Add UI
        return <>Error</>;
    }

    if (!data) {
        throw new Error('Query did not return response');
    }

    if (data.disruption === null) {
        // TODO: Add UI
        return <>Not found</>;
    }

    if (data.disruption.flight.alternates.pageInfo.hasNextPage) {
        throw new Error('Too many alternates');
    }

    return (
        <EditDisruptionForm
            id={id}
            currentValues={{
                flight: {
                    number: data.disruption.flight.number,
                    scheduled: {
                        departure: {
                            airport: data.disruption.flight.departure.airport,
                            time: moment(data.disruption.flight.departure.scheduledDepartureTime),
                        },
                        arrival: {
                            airport: data.disruption.flight.arrival.airport,
                            time: moment(data.disruption.flight.arrival.scheduledArrivalTime),
                        },
                    },
                    alternates: data.disruption.flight.alternates.nodes.map(alternate => ({
                        airport: alternate.airport,
                        estimatedTime: alternate.estimatedTime === null ? null : moment(alternate.estimatedTime),
                    })),
                    passengers: {
                        total: data.disruption.flight.passengerCount.total,
                        infants: data.disruption.flight.passengerCount.infants,
                        restrictedMobility: data.disruption.flight.passengerCount.wheelchair,
                    },
                },
                suppliersAuthorized: data.disruption.coachesAuthorized,
            }}
        />
    );
};

export default Content;
