import InputError from './InputError';

/**
 * Chooses most important input error code to display in case there are multiple.
 */
export default function chooseInputError(errors: InputError[]): InputError {
    if (errors.length === 0) {
        throw new Error('No error provided');
    }

    // TODO: Improve
    if (errors.includes(InputError.Empty)) {
        return InputError.Empty;
    }

    return errors[0];
}
