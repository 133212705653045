import { LoadingPage, MenuButtonItem, OptionsTableCell, TableBody, TableContainer, TableFooter, TableHead, TableHeader } from '@get-e/react-components';
import { Table, TableCell, TableRow } from '@material-ui/core';
import moment, { Moment } from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Retry from '../../../components/Retry';
import formatPastDate from '../../../helpers/formatPastDate';
import report from '../../../helpers/report';
import useDebounce from '../../../helpers/useDebounce';
import useLoaderDebounce from '../../../helpers/useLoaderDebounce';
import useUrlQuery from '../../../helpers/useUrlQuery';
import useTablePageStyles from '../../useTablePageStyles';
import {
    GET_CUSTOMER_USERS,
    GetCustomerUsersInput,
    GetCustomerUsersResult,
} from './CustomerUsers.graphql';

interface User {
    id: string;
    name: string;
    email: string;
    joinedAt: string;
}

const Row: FunctionComponent<{
    user: User;
    onEditClick: () => void;
    onRemoveClick: () => void;
}> = ({ user, onEditClick, onRemoveClick }) => {
    const { t } = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);
    const joinedAt = formatPastDate(moment(user.joinedAt), t);

    const handleEditClick = (): void => {
        setMenuOpen(false);
        onEditClick();
    };

    const handleRemoveClick = (): void => {
        setMenuOpen(false);
        onRemoveClick();
    };

    // TODO: Prevent removing yourself

    return (
        <TableRow>
            <TableCell>{user.name}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>{joinedAt}</TableCell>

            <OptionsTableCell menuOpen={menuOpen} setMenuOpen={setMenuOpen}>
                <MenuButtonItem onClick={handleEditClick}>
                    {t('pages.users.button.editUser')}
                </MenuButtonItem>
            </OptionsTableCell>
        </TableRow>
    );
};

const UsersTab: FunctionComponent<{
    onUpdate?: (event: { total: number }) => void;
    onEditUser: (user: { id: string }) => void;
    onRemoveUser: (user: { id: string; name: string }) => void;
    customerId: string;
}> = ({ onUpdate, onEditUser, onRemoveUser, customerId }) => {
    const { t } = useTranslation();
    const classes = useTablePageStyles();
    const history = useHistory();
    const query = useUrlQuery();
    const after = query.get('after');
    const before = query.get('before');
    const paginationLimit = 10;

    const [search, setSearch] = useState('');
    const debouncedSearchValue = useDebounce(search, 500);

    const variables = ((): GetCustomerUsersInput => {
        const usersFilter: null | { search: string }
            = debouncedSearchValue
                ? { search: debouncedSearchValue }
                : null;

        if (before !== null) {
            return {
                id: customerId,
                last: paginationLimit,
                before: before || undefined,
                filter: usersFilter,
            };
        }

        return {
            id: customerId,
            first: paginationLimit,
            after: after ?? undefined,
            filter: usersFilter,
        };
    })();

    const {
        data,
        loading,
        error,
        refetch,
    } = useQuery<GetCustomerUsersResult>(
        GET_CUSTOMER_USERS,
        {
            variables,
            notifyOnNetworkStatusChange: true,
            onError(apolloError) {
                report(apolloError);
            },
            fetchPolicy: 'cache-and-network',
        }
    );

    useEffect(() => {
        onUpdate?.({ total: data?.customer.users.nodes.length ?? 0 });
    }, [data?.customer.users.nodes.length, onUpdate]);

    const showLoader = useLoaderDebounce(loading);

    if (loading && !data) {
        return showLoader
            ? <LoadingPage />
            : null;
    }

    if (error || !data) {
        return <Retry onRetry={() => refetch()} loading={loading} />;
    }

    const handleBackToStartClick = (): void => {
        history.push({ search: '' });
    };

    const handlePreviousClick = (): void => {
        const { startCursor } = data.customer.users.pageInfo;

        if (startCursor === null) {
            throw new Error('Page info does not contain start cursor');
        }

        history.push({ search: `?before=${startCursor}` });
    };

    const handleNextClick = (): void => {
        const { endCursor } = data.customer.users.pageInfo;

        if (endCursor === null) {
            throw new Error('Page info does not contain end cursor');
        }

        history.push({ search: `?after=${endCursor}` });
    };

    const handleSkipToEndClick = (): void => {
        history.push({ search: '?before' });
    };

    const users = data.customer.users.nodes;

    return (
        <TableContainer className={classes.table}>
            <TableHeader
                title="Users"
                onSearchChange={searchValue => setSearch(searchValue)}
            />

            <Table aria-label={t('pages.users.aria.usersTable')}>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('pages.users.column.name')}</TableCell>
                        <TableCell>{t('pages.users.column.email')}</TableCell>
                        <TableCell>{t('pages.users.column.joinedAt')}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody loading={loading}>
                    {users.map(user => (
                        <Row
                            user={user}
                            key={user.email}
                            onEditClick={() => onEditUser(user)}
                            onRemoveClick={() => onRemoveUser(user)}
                        />
                    ))}
                </TableBody>
            </Table>

            <TableFooter
                count={{
                    total: users.length,
                    currentPage: users.length,
                }}
                hasPrevious={data.customer.users.pageInfo.hasPreviousPage}
                hasNext={data.customer.users.pageInfo.hasNextPage}
                onBackToStartClick={handleBackToStartClick}
                onPreviousClick={handlePreviousClick}
                onNextClick={handleNextClick}
                onSkipToEndClick={handleSkipToEndClick}
                loading={loading}
            />
        </TableContainer>
    );
};

export default UsersTab;
