import gql from 'graphql-tag';

export const COMPLETE_DISRUPTION = gql`
    mutation completeDisruption($input: CompleteDisruptionInput!) {
        completeDisruption(
            input: $input
        ) {
            id
        }
    }
`;

export interface CompleteDisruptionInput {
    input: {
        disruptionId: string;
    };
}

export interface CompleteDisruptionResult {
    completeDisruption: {
        id: string;
    };
}
