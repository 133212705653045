import { Grid, TableCell, TableRow } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import FlashIcon from '@material-ui/icons/FlashOn';
import PeopleIcon from '@material-ui/icons/People';
import moment from 'moment-timezone';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import RelativeTime from '../../components/RelativeTime';
import useOpenNewTab from '../../helpers/useOpenNewTab';
import useTableStyles from '../../styles/useTableStyles';
import createLocationName from '../Disruption/helpers/createLocationName';
import { AvailabilityRequest } from './Content.graphql';
import RequestStatus from './RequestStatus';

const useStyles = makeStyles(() => createStyles({
    smallDate: {
        fontSize: '.75em',
        color: '#788B9B',
        fontWeight: 700,
    },
    routeLabel: { fontWeight: 700 },
    routeString: {
        maxWidth: '18rem',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    dateCell: { maxWidth: '7em' },
    peopleIcon: { marginRight: '5px' },
}));

const DateCell: FunctionComponent<{
    date: string | null;
    timeZone: string;
    icon?: React.ReactNode;
}> = ({ date, icon, timeZone }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    if (!date) {
        return null;
    }

    return (
        <Grid item className={classes.dateCell}>
            <Grid container alignItems="center">
                {icon}
                <div>
                    <RelativeTime
                        date={moment(date)}
                        disableTooltip
                    />
                </div>
            </Grid>
            <div className={classes.smallDate}>
                {moment.tz(date, timeZone).format(t('dateFormat.timeDateShortMonth'))}
            </div>
        </Grid>
    );
};

const RouteCell: FunctionComponent<{
    availability: AvailabilityRequest;
}> = ({ availability }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { pickupPoint, dropOffPoint } = availability;
    const from = pickupPoint.location;
    const to = dropOffPoint.location;

    return (
        <>
            <div className={classes.routeString}>
                <span className={classes.routeLabel}>
                    {t('pages.availability.labels.from')}:
                </span>{' '}
                {createLocationName(from)}
                { }
            </div>
            <div className={classes.routeString}>
                <span className={classes.routeLabel}>
                    {t('pages.availability.labels.to')}:
                </span>{' '}
                {createLocationName(to)}
            </div>
        </>
    );
};

const Row: FunctionComponent<{
    request: AvailabilityRequest;
}> = ({ request }) => {
    const tableClasses = useTableStyles();
    const classes = useStyles();
    const { total } = request.disruption.flight.passengerCount;

    const handleClick = useOpenNewTab(
        `/requests/${request.id}`
    );

    return (
        <TableRow
            className={tableClasses.clickableRow}
            onClick={handleClick}
            hover
        >
            <TableCell>
                <RequestStatus
                    cancelled={request.customerCancelledAt !== null}
                    completed={request.supplierCompletedAt !== null}
                    rides={{
                        booked: request.rides.totalCount,
                        assigned: request.assignedRides.totalCount,
                        confirmed: request.confirmedRides.totalCount,
                        completed: request.completedRides.totalCount,
                        inProgress: request.ridesInProgress.totalCount,
                        inProgressWithoutTracking: request.ridesInProgressWithoutTracking.totalCount,
                        withPositionUpdates: request.ridesWithPositionUpdates.totalCount,
                    }}
                    availability={{ unbooked: request.rideAvailabilities.totalCount }}
                />
            </TableCell>

            <TableCell>
                <DateCell
                    date={request.createdAt}
                    timeZone={request.pickupPoint.location.timeZone}
                />
            </TableCell>

            <TableCell>
                <DateCell
                    icon={<FlashIcon fontSize="inherit" />}
                    date={request.pickupTime}
                    timeZone={request.pickupPoint.location.timeZone}
                />
            </TableCell>

            <TableCell>
                <RouteCell availability={request} />
            </TableCell>

            <TableCell>
                {request.disruption.flight.number}

                <Grid container alignItems="center">
                    <PeopleIcon fontSize="inherit" className={classes.peopleIcon} />
                    {total}
                </Grid>
            </TableCell>
        </TableRow>
    );
};

export default Row;
