import gql from 'graphql-tag';

export const GET_CURRENT_USER = gql`
    query getCurrentUser {
        me {
            id
            name
            email
            isBackOffice
            joinedAt
            customerRoles(first: 1) {
                totalCount
                nodes {
                    permissions {
                        manageUsers
                    }
                    customer {
                        id
                        name
                    }
                }
            }
            supplierEdge {
                permissions {
                    manageUsers
                }
            }
            supplier {
                id
                name
            }
        }
    }
`;

export interface GetCurrentUserResult {
    me: {
        id: string;
        name: string;
        email: string;
        isBackOffice: boolean;
        joinedAt: string;
        customerRoles: {
            totalCount: number;
            nodes: Array<{
                permissions: {
                    manageUsers: boolean;
                };
                customer: {
                    id: string;
                    name: string;
                };
            }>;
        };
        supplier: null | {
            id: string;
            name: string;
        };
        supplierEdge: null | {
            permissions: {
                manageUsers: boolean;
            };
        };
    };
}
