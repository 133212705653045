import { useMutation } from '@apollo/react-hooks';
import {
    Alert, Heading, Modal,
    PrimaryButton, SecondaryButton,
} from '@get-e/react-components';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import report from '../../../helpers/report';
import FormError from '../../../helpers/validation/FormError';
import getFormErrorMessage from '../../../helpers/validation/getFormErrorMessage';
import useFormStyles from '../../../styles/useFormStyles';
import useModalStyles from '../../../styles/useModalStyles';
import { CancelRideResult, CancelRideInput, CANCEL_RIDE } from './CancelRideDialog.graphql';

const CancelRideModal: FunctionComponent<{
    rideId: string;
    open: boolean;
    onClose: () => void;
    onCancelled: () => void;
    canCancel: boolean;
}> = ({ open, onClose, rideId, canCancel, onCancelled }) => {
    const modalClasses = useModalStyles();
    const formClasses = useFormStyles();
    const [formError, setFormError] = useState<FormError | null>(null);
    const { t } = useTranslation();

    const [
        cancelRide,
        { loading: cancelling },
    ] = useMutation<CancelRideResult, CancelRideInput>(
        CANCEL_RIDE
    );

    const handleClick = async (): Promise<void> => {
        try {
            await cancelRide({ variables: { input: { rideId } } });
            onCancelled();
            onClose();
        } catch (error) {
            setFormError(FormError.UnexpectedError);
            report(error);
        }
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <div className={modalClasses.header}>
                <Heading level={2}>
                    Cancel ride
                </Heading>
            </div>
            <p>This will cancel your booked ride.</p>
            <Alert severity="warning" className={modalClasses.warningContainer}>
                {canCancel
                    ? (
                        <>This cannot be undone</>

                    )
                    : (
                        <>
                            Ride is already in progress or completed,
                            this ride cannot be cancelled.
                        </>
                    )
                }

            </Alert>

            {
                formError === null
                    ? null
                    : (
                        <Alert
                            severity="error"
                            className={formClasses.mainErrorLimitedWidth}
                        >
                            {getFormErrorMessage(formError, t)}
                        </Alert>
                    )
            }


            <div className={modalClasses.buttonContainer}>
                <span className={modalClasses.primaryButton}>
                    <PrimaryButton
                        onClick={handleClick}
                        loading={cancelling}
                        disabled={!canCancel}
                        variation="danger"
                    >
                        Cancel ride
                    </PrimaryButton>
                </span>

                <SecondaryButton
                    onClick={onClose}
                    disabled={cancelling}
                >
                    Keep ride
                </SecondaryButton>
            </div>
        </Modal>
    );
};

export default CancelRideModal;
