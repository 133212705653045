
// TODO: Split up
/* eslint-disable max-len */
/* eslint-disable max-lines */
import { MenuButtonItem, OptionsTableCell } from '@get-e/react-components';
import {
    IconButton, makeStyles,
    TableCell, TableRow,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import clsx from 'clsx';
import { Moment } from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { useCurrentUserContext } from '../../../context/CurrentUserContext';
import { useLocaleContext } from '../../../context/LocaleContext';
import formatNumber from '../../../helpers/formatNumber';
import getValue from '../../../helpers/getValue';
import useTableStyles from '../../../styles/useTableStyles';
import { DriverStatus, Ride } from '../useCoachRoutes';
import CancelRideDialog from './CancelRideDialog';
import DriverStatusModal from './DriverStatusModal';
import RideRowCollapse from './RideRowCollapse';
import RideStatus from './RideStatus';
import useCoachRowStyles from './useCoachRowStyles';

export interface RideStep {
    title: string;
    time: Moment | null;
    isEstimate: boolean;
}

const useStyles = makeStyles(theme => ({
    changeButton: { marginTop: '.5rem' },
    liveTracking: {
        color: theme.palette.secondary.main,
        fontSize: 14,
        fontWeight: 600,
    },
    '@keyframes blinker': {
        '60%': { opacity: 1 },
        '80%': { opacity: 0.7 },
        '100%': { opacity: 1 },
    },

}));

const RideRow: FunctionComponent<{
    onReload: () => void;
    ride: Ride;
    bookedAutomatically: boolean;
    showCustomerActions: boolean;
    // eslint-disable-next-line max-lines-per-function, complexity
}> = ({ onReload, ride, bookedAutomatically, showCustomerActions }) => {
    // TODO: Translate
    const [open, setOpen] = useState(false);
    const currentUser = useCurrentUserContext();
    const [driverStatusModal, setDriverStatusModal] = useState(false);
    const classes = useStyles();
    const tableClasses = useTableStyles();
    const rowClasses = useCoachRowStyles();
    const { locale } = useLocaleContext();

    const [menuOpen, setMenuOpen] = useState(false);
    const [uncancelDialogOpen, setUncancelDialogOpen] = useState(false);
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

    // TODO: Add uncancel dialog

    const driverSteps: RideStep[] = [
        {
            title: 'Driver on the way',
            time: ride.events.driverDispatchedAt,
            isEstimate: false,
        },
        {
            title: 'At pickup',
            time: ride.pickupPoint.trackingTimes.actualArrival
                ?? ride.pickupPoint.trackingTimes.estimatedArrival,
            isEstimate: ride.pickupPoint.trackingTimes.actualArrival === null,
        },
        {
            title: 'En route',
            time: ride.pickupPoint.trackingTimes.actualDeparture
                ?? ride.pickupPoint.trackingTimes.estimatedDeparture,
            isEstimate: ride.pickupPoint.trackingTimes.actualDeparture === null,
        },
        {
            title: 'Dropped off',
            time: ride.dropOffPoint.trackingTimes.actualArrival
                ?? ride.dropOffPoint.trackingTimes.estimatedArrival,
            isEstimate: ride.dropOffPoint.trackingTimes.actualArrival === null,
        },
    ];

    const statuses: RideStep[] = bookedAutomatically
        ? [
            {
                title: 'Booked',
                time: ride.events.createdAt,
                isEstimate: false,
            },
            ...driverSteps,
        ]
        : [
            {
                title: 'Available',
                time: ride.events.availableAt,
                isEstimate: false,
            },
            {
                title: 'Booked',
                time: ride.events.createdAt,
                isEstimate: false,
            },
            ...getValue((): RideStep[] => [
                ride.events.declinedAt === null
                    ? {
                        title: 'Confirmed',
                        time: ride.events.confirmedAt,
                        isEstimate: false,
                    }
                    : {
                        title: 'Declined',
                        time: ride.events.declinedAt,
                        isEstimate: false,
                    },
            ]),
            ...driverSteps,
        ];

    const activeStep = ((): number => {
        if (bookedAutomatically) {
            if (ride.events.confirmedAt === null || ride.events.declinedAt !== null) {
                throw new Error('Ride was pre-confirmed, but not confirmed/declined');
            }

            switch (ride.driverStatus) {
                case null:
                    return 1;

                case DriverStatus.OnTheWay:
                    return 2;

                case DriverStatus.AtPickup:
                    return 3;

                case DriverStatus.OnBoard:
                    return 4;

                case DriverStatus.DroppedOff:
                    return 5;

                default:
                    throw new Error('Unhandled driver status');
            }
        }

        if (ride.events.declinedAt !== null) {
            return 3;
        }

        if (ride.events.confirmedAt === null) {
            return 2;
        }

        const result = getValue(() => {
            switch (ride.driverStatus) {
                case null:
                    return 3;

                case DriverStatus.OnTheWay:
                    return 4;

                case DriverStatus.AtPickup:
                    return 5;

                case DriverStatus.OnBoard:
                    return 6;

                case DriverStatus.DroppedOff:
                    return 7;

                default:
                    throw new Error('Unhandled driver status');
            }
        });

        return result;
    })();

    // eslint-disable-next-line max-lines-per-function, max-statements
    const menuItems = (() => {
        if (ride.events.cancelledAt !== null) {
            if (!showCustomerActions) {
                return [];
            }

            return [
                <MenuButtonItem
                    key="uncancel"
                    onClick={() => {
                        setUncancelDialogOpen(true);
                        setMenuOpen(false);
                    }}
                    dangerous
                >
                    Uncancel ride
                </MenuButtonItem>,
            ];
        }

        if (showCustomerActions) {
            return [
                <MenuButtonItem
                    key="cancel"
                    onClick={() => {
                        setCancelDialogOpen(true);
                        setMenuOpen(false);
                    }}
                    dangerous
                >
                    Cancel ride
                </MenuButtonItem>,
            ];
        }

        return [];
    })();

    if (currentUser.isBackOffice) {
        menuItems.push(
            <MenuButtonItem
                key="update"
                onClick={() => {
                    setDriverStatusModal(true);
                    setMenuOpen(false);
                }}
            >
                Update driver status
            </MenuButtonItem>
        );
    }

    const canCancelRide = (() => {
        if (ride.driverStatus === null) {
            return true;
        }

        const cancelableStatus = [DriverStatus.OnTheWay, DriverStatus.AtPickup];

        return cancelableStatus.includes(ride.driverStatus);
    })();

    return (
        <>
            <TableRow>
                <TableCell className={tableClasses.buttonColumn}>
                    <IconButton
                        aria-label={open ? 'Collapse row' : 'Expand row'}
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell>
                    <RideStatus ride={ride} />
                </TableCell>

                <TableCell>
                    {ride.supplier.name}
                </TableCell>

                <TableCell>
                    {formatNumber(ride.vehicle.seats, locale)} persons
                </TableCell>

                <TableCell>
                    {ride.vehicle.busNumber ?? '-'}
                </TableCell>

                {menuItems.length > 0 && (
                    <OptionsTableCell menuOpen={menuOpen} setMenuOpen={setMenuOpen}>
                        {menuItems}
                    </OptionsTableCell>
                )}

            </TableRow>

            <TableRow>
                <TableCell colSpan={7} className={clsx(tableClasses.collapsableCell, { [tableClasses.collapsedCell]: !open })}>
                    {driverStatusModal
                        && (
                            <DriverStatusModal
                                onClose={() => setDriverStatusModal(false)}
                                onSuccess={() => onReload()}
                                ride={ride}
                            />
                        )
                    }
                    <RideRowCollapse
                        statuses={statuses}
                        activeStep={activeStep}
                        ride={ride}
                        open={open}
                    />
                </TableCell>
            </TableRow>
            <CancelRideDialog
                open={cancelDialogOpen}
                rideId={ride.id}
                canCancel={canCancelRide}
                onClose={() => setCancelDialogOpen(false)}
                onCancelled={() => onReload()}
            />
        </>
    );
};

export default RideRow;
