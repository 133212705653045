/* eslint-disable max-statements */
/* eslint-disable max-depth */
/* eslint-disable max-statements-per-line */
/* eslint-disable max-lines */
// TODO: Split up

import {
    Alert,
    CompactButton, Form, PrimaryButton, SecondaryButton,
} from '@get-e/react-components';
import { Grid, makeStyles } from '@material-ui/core';
import { ApolloError } from 'apollo-client';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import DriverSelector, { Driver } from '../../../components/DriverSelector';
import PhoneNumberField from '../../../components/PhoneNumberField';
import getProperty from '../../../helpers/getProperty';
import getValue from '../../../helpers/getValue';
import isArray from '../../../helpers/isArray';
import report from '../../../helpers/report';
import tryGetAsync from '../../../helpers/tryGetAsync';
import allValid from '../../../helpers/validation/allValid';
import FormError from '../../../helpers/validation/FormError';
import getFormErrorMessage from '../../../helpers/validation/getFormErrorMessage';
import getHelperText from '../../../helpers/validation/getHelperText';
import getInputError from '../../../helpers/validation/getInputError';
import InputError from '../../../helpers/validation/InputError';
import isFilledString from '../../../helpers/validation/validators/isFilledString';
import useFormStyles from '../../../styles/useFormStyles';
import { CreateDriverInput, CreateDriverResult, CREATE_DRIVER } from '../../Drivers/Add/Content.graphql';
import driverErrorMessages, { DriverError } from './driverErrorMessages';
import {
    AssignDriverInput,
    AssignDriverResult,
    ASSIGN_DRIVER,
    UnassignDriverInput,
    UnassignDriverResult,
    UNASSIGN_DRIVER,
} from './DriverForm.graphql';
import DriverFormModal from './DriverFormModal';

enum SupportedErrorEnums {
    DriverError,
    FormError
}

export type ErrorType = {
    type: SupportedErrorEnums.FormError;
    error: FormError;
} | {
    type: SupportedErrorEnums.DriverError;
    error: DriverError;
    name: string;
    id: string;
    phoneNumber: string;
};

const useStyles = makeStyles({
    changeButton: { marginTop: '.5rem' },
    primaryButton: { marginRight: '.5rem' },
    unselectButton: { marginRight: '.5rem' },
    primaryButtonInline: { margin: '.5rem' },
});

function assertString(value: unknown): string {
    if (typeof value !== 'string') {
        throw new Error('Value should be a string');
    }

    return value;
}

const DriverForm: FunctionComponent<{
    rideId: string;
    supplierId: string;
    initialValue?: Driver | null;
    onSaved?: () => void;
    onUnassigned?: () => void;
    // eslint-disable-next-line max-lines-per-function
}> = ({ rideId, supplierId, initialValue, onSaved, onUnassigned }) => {
    const classes = useStyles();
    const formClasses = useFormStyles();
    const { t } = useTranslation();

    const [existingDriverModal, setExistingDriverModal] = useState(false);

    const [
        assignedDriver,
        setAssignedDriver,
    ] = useState<Driver | null>(initialValue ?? null);

    const [changingDriver, setChangingDriver] = useState(false);
    const [driverName, setDriverName] = useState(assignedDriver?.name ?? '');

    const [
        driverPhone,
        setDriverPhone,
    ] = useState(assignedDriver?.phoneNumber ?? '');

    const [selectedDriver, setSelectedDriver] = useState<null | {
        id: string;
        name: string;
        phoneNumber: string;
    }>(initialValue ?? null);

    const [driverNameError, setDriverNameError] = useState<InputError | null>(null);
    const [driverPhoneError, setDriverPhoneError] = useState<InputError | null>(null);

    const [formError, setFormError] = useState<ErrorType | null>(null);

    const [
        createDriver,
        { loading: creatingDriver },
    ] = useMutation<CreateDriverResult, CreateDriverInput>(
        CREATE_DRIVER
    );

    const [
        assignDriver,
        { loading: assigningDriver },
    ] = useMutation<AssignDriverResult, AssignDriverInput>(
        ASSIGN_DRIVER
    );

    const [
        unassignDriver,
        { loading: unassigningDriver },
    ] = useMutation<UnassignDriverResult, UnassignDriverInput>(
        UNASSIGN_DRIVER
    );

    useEffect(() => {
        setSelectedDriver(initialValue ?? assignedDriver ?? null);
    }, [changingDriver]);

    const loading = assigningDriver || unassigningDriver || creatingDriver;

    const saveDriver = async (): Promise<void> => {
        if (selectedDriver === null) {
            const validated = {
                name: isFilledString(driverName, InputError.Empty),
                phone: isFilledString(driverPhone ?? '', InputError.Empty),
            };

            if (!allValid(validated)) {
                setFormError({
                    type: SupportedErrorEnums.FormError,
                    error: FormError.UserError,
                });

                setDriverNameError(getInputError(validated.name));
                setDriverPhoneError(getInputError(validated.phone));

                return;
            }

            const createdDriver = await tryGetAsync(async () => {
                const { data: response } = await createDriver({
                    variables: {
                        input: {
                            supplierId,
                            name: validated.name.value,
                            phoneNumber: validated.phone.value,
                        },
                    },
                });

                if (!response) {
                    throw new Error('Creating driver failed');
                }

                return response;
            });

            if (!createdDriver.success) {
                setFormError({
                    type: SupportedErrorEnums.FormError,
                    error: FormError.UnexpectedError,
                });

                if (!(createdDriver.error instanceof ApolloError)) {
                    report(createdDriver.error);
                    return;
                }

                if (createdDriver.error.graphQLErrors) {
                    for (const graphQLError of createdDriver.error.graphQLErrors) {
                        switch (graphQLError.extensions?.code) {
                            case 'INVALID_INPUT': {
                                // eslint-disable-next-line max-len
                                const phoneErrors = getProperty(graphQLError.extensions, 'inputErrors', 'input', 'phoneNumber');
                                let exists = false;
                                let existingName;
                                let existingId;
                                let existingPhone;

                                if (isArray(phoneErrors)) {
                                    // eslint-disable-next-line max-len
                                    exists = phoneErrors.some(gqlErr => getProperty(gqlErr, 'code') === 'EXISTS');
                                    existingName = assertString(getProperty(phoneErrors[0], 'details', 'takenBy', 'name'));
                                    existingId = assertString(getProperty(phoneErrors[0], 'details', 'takenBy', 'id'));
                                    existingPhone = assertString(getProperty(phoneErrors[0], 'details', 'takenBy', 'phoneNumber'));
                                }

                                if (exists) {
                                    setFormError({
                                        type: SupportedErrorEnums.DriverError,
                                        error: DriverError.DriverExists,
                                        id: existingId ?? '',
                                        name: existingName ?? '',
                                        phoneNumber: existingPhone ?? '',
                                    });
                                    setExistingDriverModal(true);
                                } else {
                                    setFormError({
                                        type: SupportedErrorEnums.FormError,
                                        error: FormError.UnexpectedError,
                                    });
                                    report(createdDriver.error);
                                }

                                break;
                            }

                            default:
                                setFormError({
                                    type: SupportedErrorEnums.FormError,
                                    error: FormError.UnexpectedError,
                                });

                                report(createdDriver.error);
                                break;
                        }
                    }

                    return;
                }

                return;
            }

            const driverId = createdDriver.value.createDriver.id;

            try {
                await assignDriver({
                    variables: {
                        rideId,
                        driverId,
                    },
                });

                setAssignedDriver({
                    id: driverId,
                    name: driverName,
                    phoneNumber: driverPhone,
                });
            } catch (error) {
                report(error);
                setFormError({
                    type: SupportedErrorEnums.FormError,
                    error: FormError.UnexpectedError,
                });

                return;
            }
        } else {
            try {
                await assignDriver({
                    variables: {
                        rideId,
                        driverId: selectedDriver.id,
                    },
                });

                setAssignedDriver({
                    id: selectedDriver.id,
                    name: driverName,
                    phoneNumber: driverPhone,
                });
            } catch (error) {
                report(error);
                setFormError({
                    type: SupportedErrorEnums.FormError,
                    error: FormError.UnexpectedError,
                });

                return;
            }
        }

        setFormError(null);
        setChangingDriver(false);
        onSaved?.();
    };

    const handleUnassignDriverClick = async (): Promise<void> => {
        try {
            await unassignDriver({ variables: { rideId } });
        } catch (error) {
            report(error);
            setFormError({
                type: SupportedErrorEnums.FormError,
                error: FormError.UnexpectedError,
            });

            return;
        }

        setDriverName('');
        setDriverPhone('');
        setAssignedDriver(null);
        setFormError(null);
        setChangingDriver(false);
        onUnassigned?.();
    };

    if (!changingDriver) {
        if (assignedDriver === null) {
            return (
                <CompactButton onClick={() => setChangingDriver(true)}>
                    {t('components.driverForm.addDriver')}
                </CompactButton>
            );
        }

        return (
            <>
                <div>{assignedDriver.name}</div>
                <div>{assignedDriver.phoneNumber}</div>

                <CompactButton
                    onClick={() => setChangingDriver(true)}
                    className={classes.changeButton}
                >
                    {t('components.driverForm.change')}
                </CompactButton>
            </>
        );
    }

    return (
        <Form onSubmit={saveDriver}>

            <DriverSelector
                label={t('components.driverForm.name')}
                required
                value={assignedDriver}
                inputValue={driverName}
                supplierId={supplierId}
                onFocus={() => {
                    if (selectedDriver === null) {
                        return;
                    }

                    setDriverName('');
                    setDriverPhone('');
                    setSelectedDriver(null);
                    setFormError(null);
                    setDriverNameError(null);
                    setDriverPhoneError(null);
                }}
                onChange={value => {
                    if (value) {
                        setDriverNameError(null);
                        setDriverPhoneError(null);
                        setDriverPhone(value.phoneNumber);
                        setDriverName(value.name);
                        setSelectedDriver(value);
                    }
                }}
                onInputChange={value => {
                    setDriverName(value);
                }}
                error={driverNameError !== null}
                disabled={selectedDriver !== null}
                helperText={getHelperText(driverNameError, t)}
            />

            <PhoneNumberField
                label={t('components.driverForm.phoneNumber')}
                required
                value={driverPhone}
                onChange={value => {
                    setDriverPhoneError(null);
                    setDriverPhone(value);
                }}
                error={driverPhoneError !== null}
                disabled={selectedDriver !== null}
                helperText={getHelperText(driverPhoneError, t)}
            />

            {
                formError
                    ? (
                        <Alert
                            severity="error"
                            className={formClasses.mainErrorLimitedWidth}
                        >
                            {getValue(() => {
                                if (formError.type === SupportedErrorEnums.FormError) {
                                    return getFormErrorMessage(formError.error, t);
                                }

                                return (
                                    <>
                                        <DriverFormModal
                                            open={existingDriverModal}
                                            driverPhone={driverPhone}
                                            driverName={driverName}
                                            existingDriver={formError.name}
                                            onAssign={async () => {
                                                try {
                                                    await assignDriver({
                                                        variables: {
                                                            rideId,
                                                            driverId: formError.id,
                                                        },
                                                    });

                                                    setDriverName(formError.name);
                                                    setDriverPhone(formError.phoneNumber);

                                                    setSelectedDriver({
                                                        id: formError.id,
                                                        name: formError.name,
                                                        phoneNumber: formError.phoneNumber,
                                                    });

                                                    setAssignedDriver({
                                                        id: formError.id,
                                                        name: formError.name,
                                                        phoneNumber: formError.phoneNumber,
                                                    });
                                                } catch (error) {
                                                    report(error);
                                                    setFormError({
                                                        type: SupportedErrorEnums.FormError,
                                                        error: FormError.UnexpectedError,
                                                    });
                                                }
                                            }}
                                            onClose={() => setExistingDriverModal(false)}
                                        />
                                        {driverErrorMessages(
                                            formError.error,
                                            t,
                                            formError.name
                                        )}
                                    </>
                                );
                            })}
                        </Alert>
                    )
                    : null
            }

            <Grid
                container
                justify="space-between"
                className={formClasses.buttons}
            >
                <Grid item>
                    <PrimaryButton
                        onClick={saveDriver}
                        submitsForm
                        loading={assigningDriver || creatingDriver}
                        disabled={
                            getValue(() => {
                                if (loading) {
                                    return true;
                                }

                                if (!assignedDriver) {
                                    return false;
                                }

                                return assignedDriver?.id === selectedDriver?.id;
                            })
                        }
                        small
                        className={classes.primaryButton}
                    >
                        {
                            selectedDriver === null
                                ? t('buttons.create')
                                : t('buttons.assign')
                        }

                    </PrimaryButton>

                    <SecondaryButton
                        onClick={() => {
                            setChangingDriver(false);
                            setDriverName(assignedDriver?.name ?? '');
                            setDriverPhone(assignedDriver?.phoneNumber ?? '');
                            setFormError(null);
                            setDriverNameError(null);
                            setDriverPhoneError(null);
                        }}
                        disabled={loading}
                        small
                    >
                        {t('buttons.cancel')}
                    </SecondaryButton>
                </Grid>

                <Grid item xs="auto">
                    <PrimaryButton
                        onClick={handleUnassignDriverClick}
                        small
                        loading={unassigningDriver}
                        disabled={loading || !assignedDriver}
                        variation="danger"
                    >
                        {t('buttons.unassign')}
                    </PrimaryButton>
                </Grid>
            </Grid>
        </Form>
    );
};

export default DriverForm;
