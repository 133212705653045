import { TableSkeleton } from '@get-e/react-components';
import moment from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import LoadingFade from '../../components/LoadingFade';
import report from '../../helpers/report';
import useDebounce from '../../helpers/useDebounce';
import useLoaderDebounce from '../../helpers/useLoaderDebounce';
import useUrlQuery from '../../helpers/useUrlQuery';
import {
    GET_ARCHIVED_DISRUPTIONS,
    GetDisruptionsInput,
    GetDisruptionsResult,
} from './ArchiveTab.graphql';
import DisruptionsList from './DisruptionsList';
import { EmptyArchive } from './EmptyList';

const paginationLimit = 5;

const ArchiveTab: FunctionComponent = () => {
    const history = useHistory();

    const query = useUrlQuery();
    const after = query.get('after');
    const before = query.get('before');
    const maxAlternates = 5;

    // Filter
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 500);

    const variables = ((): GetDisruptionsInput => {
        const disruptionFilter: { archived: boolean; search?: string }
            = debouncedSearchValue
                ? {
                    search: debouncedSearchValue,
                    archived: true,
                }
                : { archived: true };

        if (before !== null) {
            return {
                maxAlternates,
                last: paginationLimit,
                before: before || undefined,
                filter: disruptionFilter,
            };
        }

        return {
            maxAlternates,
            first: paginationLimit,
            after: after ?? undefined,
            filter: disruptionFilter,
        };
    })();

    const {
        data,
        loading,
        error,
    } = useQuery<GetDisruptionsResult, GetDisruptionsInput>(
        GET_ARCHIVED_DISRUPTIONS,
        {
            variables,
            onError(apolloError) {
                report(apolloError);
            },
            fetchPolicy: 'cache-and-network',
        }
    );

    const showLoader = useLoaderDebounce(loading);

    if (loading && !data) {
        return showLoader
            ? <TableSkeleton columns={4} />
            : null;
    }

    if (error || !data) {
        // TODO
        return <>Error</>;
    }

    const handleBackToStartClick = (): void => {
        history.push({ search: '' });
    };

    const handlePreviousClick = (): void => {
        const { startCursor } = data.disruptions.pageInfo;

        if (startCursor === null) {
            throw new Error('Page info does not contain start cursor');
        }

        history.push({ search: `?before=${startCursor}` });
    };

    const handleNextClick = (): void => {
        const { endCursor } = data.disruptions.pageInfo;

        if (endCursor === null) {
            throw new Error('Page info does not contain end cursor');
        }

        history.push({ search: `?after=${endCursor}` });
    };

    const handleSkipToEndClick = (): void => {
        history.push({ search: '?before' });
    };

    return (
        <LoadingFade>
            <div>
                <DisruptionsList
                    title="Archived disruptions"
                    noResults={<EmptyArchive />}
                    disruptions={data.disruptions.nodes.map(disruption => ({
                        ...disruption,
                        moreAvailabilityExpected: false,
                        arrivedCoaches: disruption.completedRides.totalCount,
                        latestCoachArrival: disruption.completedRides.nodes.length > 0
                            ? moment(
                                disruption.completedRides.nodes[0]
                                    .dropOffPoint.trackingTimes.actualArrival
                            )
                            : null,
                        availabilities: disruption.rideAvailabilities.totalCount,
                        rides: disruption.rides.totalCount,
                        unconfirmedRides: disruption.unconfirmedRides.totalCount,
                        ridesInProgress: disruption.ridesInProgress.totalCount,
                    }))}
                    pagination={{
                        totalCount: data.disruptions.totalCount,
                        hasPrevious: data.disruptions.pageInfo.hasPreviousPage,
                        hasNext: data.disruptions.pageInfo.hasNextPage,
                        onBackToStartClick: handleBackToStartClick,
                        onPreviousClick: handlePreviousClick,
                        onNextClick: handleNextClick,
                        onSkipToEndClick: handleSkipToEndClick,
                        loading,
                    }}
                    setSearchValue={(query: string) => setSearchValue(query)}
                />
            </div>
        </LoadingFade>
    );
};

export default ArchiveTab;
