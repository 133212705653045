import React, { FunctionComponent } from 'react';
import getValue from '../../../helpers/getValue';
import {
    createRandomFloatBetween, createRandomIntegerBetween,
    getRandomElement,
} from '../../../helpers/random';
import AvailableRoomsTable, { AvailableRoomType } from './AvailableRoomsTable';
import demoHotels from './demoHotels';
import demoRoomTypes from './demoRoomTypes';

const AvailableRoomsTab: FunctionComponent = () => (
    <AvailableRoomsTable
        rooms={getValue((): AvailableRoomType[] => {
            const result: AvailableRoomType[] = [];

            for (let iteration = 0; iteration < 5; iteration++) {
                const id = iteration.toString();
                const hotel = getRandomElement(demoHotels);

                result.push({
                    id,
                    availableCount: createRandomIntegerBetween(3, 40),
                    name: getRandomElement(demoRoomTypes),
                    price: {
                        amount: createRandomFloatBetween(40, 140),
                        currencyCode: hotel.currency,
                    },
                    hotel,
                });
            }

            return result;
        })}
    />
);

export default AvailableRoomsTab;
