import gql from 'graphql-tag';

export const GET_SUPPLIER_NAME = gql`
    query getSupplierName($id: ID!
    ) {
        supplier(id: $id) {
            id
            name
        }
    }
`;

export interface GetSupplierNameInput {
    id: string;
}

export interface GetSupplierNameResult {
    supplier: {
        id: string;
        name: string;
    };
}
