import gql from 'graphql-tag';

export const INVITE_SUPPLIER_USER = gql`
    mutation INVITE_SUPPLIER_USER($input: InviteUserToSupplierInput!) {
        inviteUserForSupplier(
            input: $input
        ) {
            id
        }
    }
`;

export interface InviteUserToSupplierInput {
    input: {
        name: string;
        email: string;
        supplierId: string;
        permissions: { manageUsers: boolean };
    };
}

export interface InviteUserToSupplierResult {
    inviteUserForSupplier: {
        id: string;
    };
}
