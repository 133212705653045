import { Spinner, TextField } from '@get-e/react-components';
import { makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { FunctionComponent, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import useDebounce from '../helpers/useDebounce';
import {
    SEARCH_COUNTRY,
    SearchCountryInput,
    SearchCountryResult,
} from './CountrySelector.graphql';

interface Country {
    id: string;
    name: string;
}

const useStyles = makeStyles({ spinner: { marginBottom: '1rem' } });

const CountrySelector: FunctionComponent<{
    value: Country | null;
    onChange: (newValue: Country | null) => void;
    label: string;
    required?: boolean;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
}> = ({ value, onChange, label, required, error: errorProp, helperText, disabled }) => {
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 500);

    const { t } = useTranslation();
    const classes = useStyles();

    const {
        data,
        loading: queryLoading,
        error,
    } = useQuery<SearchCountryResult, SearchCountryInput>(
        SEARCH_COUNTRY,
        {
            variables: {
                filter: debouncedSearchValue.trim()
                    ? { search: debouncedSearchValue }
                    : null,
            },
        }
    );

    const loading = queryLoading || searchValue !== debouncedSearchValue;

    return (
        <Autocomplete
            id="country-field"
            value={value}
            onChange={(_event, newValue) => onChange(newValue)}
            inputValue={searchValue}
            onInputChange={(_event, newValue) => setSearchValue(newValue)}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionSelected={(option, newValue) => option.id === newValue.id}
            getOptionLabel={option => option.name}
            options={data?.countries.nodes ?? []}
            loading={loading}
            loadingText={t('components.countrySearch.loading')}
            noOptionsText={error
                ? t('components.countrySearch.error')
                : t('components.countrySearch.noResults')
            }
            disabled={disabled}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    required={required}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: <>
                            {
                                loading && open
                                    ? <Spinner size={20} className={classes.spinner} />
                                    : null
                            }
                            {params.InputProps.endAdornment}
                        </>,
                    }}
                    helperText={helperText}
                    error={errorProp}
                />
            )}
        />
    );
};

export default CountrySelector;
