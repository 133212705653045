import gql from 'graphql-tag';

export const COMPLETE_AVAILABILITY_REQUEST = gql`
    mutation completeDisruptionRideAvailabilityRequestSupplier(
        $input: CompleteDisruptionRideAvailabilityRequestInput!
    ) {
        completeDisruptionRideAvailabilityRequestSupplier(input: $input) {
            id
        }
    }
`;

export const INCOMPLETE_AVAILABILITY_REQUEST = gql`
    mutation incompleteDisruptionRideAvailabilityRequestSupplier(
        $input: CompleteDisruptionRideAvailabilityRequestInput!
    ) {
        incompleteDisruptionRideAvailabilityRequestSupplier(input: $input) {
            id
        }
    }
`;
