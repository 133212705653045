import { TextField, AutocompleteChip } from '@get-e/react-components';
import { Autocomplete } from '@material-ui/lab';
import React, { FunctionComponent } from 'react';

const EmailField: FunctionComponent<{
    label: string;
    onChange: (newValue: string[]) => void;
    value: string[];
}> = ({ label, onChange, value }) => (
    <Autocomplete
        multiple
        id={`${label}-email-field`}
        freeSolo
        options={[] as string[]}
        value={value ?? []}
        onChange={(_event, newValues) => {
            onChange(newValues);
        }}
        renderTags={(tags, getTagProps) => (
            tags.map((option: string, index: number) => (
                <AutocompleteChip
                    key={index}
                    label={option}
                    {...getTagProps({ index })}
                />
            )))
        }
        renderInput={
            params => (
                <TextField
                    {...params}
                    onBlur={event => {
                        if (event.target.value.length) {
                            onChange([...value, event.target.value]);
                        }
                    }}
                    label={label}
                />
            )}
    />
)

export default EmailField;
