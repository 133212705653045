import gql from 'graphql-tag';

export const UPDATE_CUSTOMER_USER = gql`
    mutation updateCustomerUser($input: UpdateCustomerUserInput!) {
        updateCustomerUser(input: $input) {
            node {
                id
            }
        }
    }
`;

export interface UpdateCustomerUserInput {
    input: {
        userId: string;
        customerRoleId: string;
        airportWhitelist: string[] | null;
    };
}

export interface UpdateCustomerUserResult {
    user: {
        node: {
            id: string;
        };
    };
}
