import { Modal, PrimaryButton, SecondaryButton } from '@get-e/react-components';
import { Typography } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import delay from '../../helpers/delay';
import useModalStyles from '../../styles/useModalStyles';
import BackOfficeUser from './BackOfficeUser';

async function revokeBackOfficePermission(userId: string): Promise<void> {
    // TODO: Implement
    // eslint-disable-next-line no-console
    console.log(`Removing user ${userId}`);

    await delay(2000);
}

const RemoveUserDialog: FunctionComponent<{
    user: BackOfficeUser;
    onClose: () => void;
}> = ({ user, onClose }) => {
    const { t } = useTranslation();
    const [removing, setRemoving] = useState(false);
    const modalClasses = useModalStyles();

    async function handleClick(): Promise<void> {
        setRemoving(true);
        await revokeBackOfficePermission(user.id);
        setRemoving(false);
    }

    return (
        <Modal
            open={true}
            onClose={onClose}
        >
            <div className={modalClasses.header}>
                <Typography variant="h2" component="h2" className={modalClasses.heading}>
                    {
                        t(
                            'pages.backOfficeUsers.removeDialog.title',
                            { userName: user.name }
                        )
                    }
                </Typography>
            </div>

            <p>{t('pages.backOfficeUsers.removeDialog.description')}</p>

            <div className={modalClasses.buttonContainer}>
                <span className={modalClasses.primaryButton}>
                    <PrimaryButton
                        onClick={handleClick}
                        loading={removing}
                        variation="danger"
                    >
                        {t('pages.users.button.removeUser')}
                    </PrimaryButton>
                </span>

                <SecondaryButton
                    onClick={onClose}
                    disabled={removing}
                >
                    {t('buttons.cancel')}
                </SecondaryButton>
            </div>
        </Modal>
    );
};

export default RemoveUserDialog;
