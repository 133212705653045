import { createStyles, Grid, LinearProgress, withStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import getValue from '../../helpers/getValue';
import CoachesStatusLabel from './CoachesStatusLabel';
import { Availability, Ride } from './useCoachRoutes';

const StyledLinearProgress = withStyles(theme => createStyles({
    root: {
        height: 20,
        borderRadius: 10,
    },
    dashed: {
        // Component background (full bar)
        animation: 'none',
        background: '#e6e6e6',
    },
    bar: { borderRadius: 10 },
    bar1Buffer: {
        // Dark bar
        background: '#388e3c',
    },
    bar2Buffer: {
        // Buffer bar
        background: '#81c784',
    },
}))(LinearProgress);

const CoachesStatus: FunctionComponent<{
    className?: string;
    requiredSeats: number;
    availabilities: Availability[];
    rides: Ride[];
}> = ({ className, requiredSeats, availabilities, rides }) => {
    // TODO: Exclude cancelled/no longer available rides

    const availableSeats = availabilities.reduce((result, availability) => result + availability.vehicle.seats, 0);
    const bookedSeats = rides.reduce((result, ride) => result + ride.vehicle.seats, 0);

    const ridesOnTheWay = rides.filter(ride => Boolean(ride.events.driverDispatchedAt));
    const ridesAtPickup = rides.filter(ride => Boolean(ride.pickupPoint.trackingTimes.actualArrival));
    const ridesEnRoute = rides.filter(ride => Boolean(ride.pickupPoint.trackingTimes.actualDeparture));
    const ridesDroppedOff = rides.filter(ride => Boolean(ride.dropOffPoint.trackingTimes.actualArrival));

    const droppedOffCount = ridesDroppedOff.length;
    const enRouteCount = ridesEnRoute.length - droppedOffCount;
    const atPickupCount = ridesAtPickup.length - enRouteCount - droppedOffCount;
    const onTheWayCount = ridesOnTheWay.length - atPickupCount - enRouteCount - droppedOffCount;
    const bookedCount = rides.length - onTheWayCount - atPickupCount - enRouteCount - droppedOffCount;
    const availabilityCount = availabilities.length;

    const slowestStep = getValue((): number => {
        if (availableSeats === 0 && bookedSeats === 0) {
            return 0;
        }

        const enoughAvailable = availableSeats + bookedSeats >= requiredSeats;

        if (!enoughAvailable || bookedSeats === 0) {
            // Available
            return 1;
        }

        const enoughBooked = bookedSeats >= requiredSeats;

        if (!enoughBooked || ridesOnTheWay.length === 0) {
            // Booked
            return 2;
        }

        if (onTheWayCount) {
            // Driver on the way
            return 3;
        }

        if (atPickupCount) {
            // At pickup
            return 4;
        }

        if (enRouteCount) {
            // En route
            return 5;
        }

        if (droppedOffCount) {
            return ridesDroppedOff.length === rides.length

                // All done
                ? 7

                // Dropped off
                : 6;
        }

        return 0;
    });

    const fastestStep = getValue((): number => {
        if (ridesDroppedOff.length) {
            // Dropped off
            return 6;
        }

        if (ridesEnRoute.length) {
            // En route
            return 5;
        }

        if (ridesAtPickup.length) {
            // At pickup
            return 4;
        }

        if (ridesOnTheWay.length) {
            // Driver on the way
            return 3;
        }

        if (rides.length) {
            // Booked
            return 2;
        }

        if (availabilities.length) {
            // Available
            return 1;
        }

        return 0;
    });

    return (
        <div className={className}>
            <StyledLinearProgress
                variant="buffer"
                value={getPercentage(slowestStep)}
                valueBuffer={getPercentage(fastestStep)}
            />

            <Grid container>
                <Grid item xs={2}>
                    <CoachesStatusLabel
                        status="Available"
                        coaches={availabilityCount}
                        inactive={fastestStep < 1}
                    />
                </Grid>

                <Grid item xs={2}>
                    <CoachesStatusLabel
                        status="Booked"
                        coaches={bookedCount}
                        inactive={fastestStep < 2}
                    />
                </Grid>

                <Grid item xs={2}>
                    <CoachesStatusLabel
                        status="Driver on the way"
                        coaches={onTheWayCount}
                        inactive={fastestStep < 3}
                    />
                </Grid>

                <Grid item xs={2}>
                    <CoachesStatusLabel
                        status="At pickup"
                        coaches={atPickupCount}
                        inactive={fastestStep < 4}
                    />
                </Grid>

                <Grid item xs={2}>
                    <CoachesStatusLabel
                        status="En route"
                        coaches={enRouteCount}
                        inactive={fastestStep < 5}
                    />
                </Grid>

                <Grid item xs={2}>
                    <CoachesStatusLabel
                        status="Dropped off"
                        coaches={droppedOffCount}
                        inactive={fastestStep < 6}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

const totalSections = 6;
const sectionSize = 100 / totalSections;

/**
 * @param section Section between 0 (no availability) and 7 (all dropped off)
 */
function getPercentage(section: number): number {
    if (section < 0) {
        throw new Error(`Section ${section} cannot be negative`);
    }

    if (section === 0) {
        return 0;
    }

    if (section > totalSections + 1) {
        throw new Error(`Cannot show section ${section} because last section is ${totalSections + 1}`);
    }

    return section === totalSections + 1
        ? 100
        : section * sectionSize - sectionSize / 2;
}

export default CoachesStatus;
