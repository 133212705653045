/* eslint-disable max-len */
import { ApolloError } from 'apollo-client';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
    GetDisruptionNameInput,
    GetDisruptionNameResult, GET_DISRUPTION_NAME,
} from './GetDisruptionName.graphql';
import useCustomQuery, { CustomQueryResult } from './useCustomQuery';

export default function useDisruptionName(
    id: string,
    { onError }: { onError: (error: ApolloError) => void }
): CustomQueryResult<{
        disruptionName: string;
        disruptionFlightNumber: string;
    } | null> {
    const { t } = useTranslation();

    return useCustomQuery<GetDisruptionNameResult, GetDisruptionNameInput, {
        disruptionName: string;
        disruptionFlightNumber: string;
    } | null>(
        GET_DISRUPTION_NAME,
        {
            variables: { id },
            mapResult: queryResult => {
                const { disruption } = queryResult;

                if (disruption === null) {
                    return null;
                }

                // TODO: Infer from query
                const isArrivalDiversion = true;

                const dateString = isArrivalDiversion
                    ? disruption.flight.arrival.scheduledArrivalTime
                    : disruption.flight.departure.scheduledDepartureTime;

                // TODO: Consider year differing from current
                const formattedDate = moment.utc(dateString).format(
                    t('breadcrumbs.disruption.dateFormat')
                );

                return {
                    disruptionName: t('breadcrumbs.disruption.template', {
                        flightNumber: disruption.flight.number,
                        date: formattedDate,
                    }),
                    disruptionFlightNumber: disruption.flight.number,
                };
            },
            onError,
        }
    );
}
