import { Tooltip } from '@material-ui/core';
import moment, { Moment } from 'moment-timezone';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocaleContext } from '../context/LocaleContext';
import formatRelativeTime from '../helpers/formatRelativeTime';
import getValue from '../helpers/getValue';

const RelativeTime: FunctionComponent<{
    date: Moment;
    hideSuffix?: boolean;
    disableTooltip?: boolean;
    timeZone?: string;
}> = ({ date, disableTooltip, timeZone }) => {
    const { locale } = useLocaleContext();
    const { t } = useTranslation();

    if (disableTooltip) {
        return (
            <span>
                {formatRelativeTime(date, locale, t)}
            </span>
        );
    }

    return (
        <Tooltip
            title={getValue(() => (
                <>
                    {timeZone
                        && moment.tz(date, timeZone)
                            .format(t('dateFormat.local'))
                    }
                    <br />
                    {date.utc().format(t('dateFormat.utcDateTimeFull'))}

                </>
            ))}
        >
            <span>
                {formatRelativeTime(date, locale, t)}
            </span>
        </Tooltip >
    );
};

export default RelativeTime;
