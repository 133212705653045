import { Tooltip } from '@material-ui/core';
import moment, { Moment } from 'moment';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocaleContext } from '../context/LocaleContext';
import formatRelativeTime from '../helpers/formatRelativeTime';
import UtcTime from './UtcTime';

const LocalDateTime: FunctionComponent<{
    date: Moment;
}> = ({ date }) => {
    const { locale } = useLocaleContext();
    const { t } = useTranslation();

    const showTime = date.isBetween(
        moment().subtract(24, 'hours'),
        moment().add(24, 'hours'),
    );

    if (showTime) {
        return <UtcTime time={date} />;
    }

    const showYear = !date.isBetween(
        moment().subtract(1, 'year'),
        moment().add(1, 'year'),
    );

    return (
        <Tooltip title={formatRelativeTime(date, locale, t)}>
            <span>
                {
                    date.local().format(
                        showYear
                            ? t('dateFormat.date')
                            : t('dateFormat.dateWithoutYear')
                    )
                }
            </span>
        </Tooltip>
    );
};

export default LocalDateTime;
