import Location from '../Location';

export default function createLocationName(location: Location): string {
    switch (location.__typename) {
        case 'Airport':
            return `${location.iata} - ${location.name}`;

        default:
            throw new Error('Unhandled location');
    }
}
