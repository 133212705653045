import gql from 'graphql-tag';

export const GET_CUSTOMER_NAME = gql`
    query getCustomerName($id: ID!) {
        customer(id: $id) {
            id
            name
        }
    }
`;

export interface GetCustomerNameInput {
    id: string;
}

export interface GetCustomerNameResult {
    customer: null | {
        id: string;
        name: string;
    };
}
