import { MenuButton, MenuButtonItem, userButtonIcon } from '@get-e/react-components';
import { makeStyles } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import EditUserModal from '../../components/EditUserModal';
import config from '../../config';
import { useCurrentUserContext } from '../../context/CurrentUserContext';
import { Severity, useNotificationContext } from '../../context/NotificationContext';
import report, { clearUserContext } from '../../helpers/report';

const useStyles = makeStyles(theme => ({
    button: { padding: '.5rem 1rem !important' },
    icon: { [theme.breakpoints.up('sm')]: { margin: '0 0 0 .5em' } },
}));

const UserMenuButton: FunctionComponent<{
    className?: string;
}> = ({ className }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const { t } = useTranslation();
    const [editingProfile, setEditingProfile] = useState(false);
    const [signingOut, setSigningOut] = useState(false);
    const history = useHistory();
    const { showNotification } = useNotificationContext();
    const currentUser = useCurrentUserContext();
    const classes = useStyles();

    const signOut = async (): Promise<void> => {
        setSigningOut(true);

        await fetch(`${config.apiUrl}/auth/sign-out`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
        })
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    clearUserContext();
                    history.push('/sign-in');
                    return;
                }

                throw new Error(`Unexpected response status: ${response.status}`);
            })
            .catch(error => {
                report(error);
                showNotification(t('topBarActions.signOut.error'), Severity.Error);
            });

        setSigningOut(false);
        setMenuOpen(false);
    };

    return (
        <span className={className}>
            {
                editingProfile
                    ? (
                        <EditUserModal
                            user={currentUser}
                            onClose={() => setEditingProfile(false)}
                        />
                    )
                    : null
            }

            <MenuButton
                className={classes.button}
                open={menuOpen}
                onClick={() => setMenuOpen(true)}
                onClose={() => setMenuOpen(false)}
                menuItems={[
                    <MenuButtonItem
                        key="my-profile"
                        onClick={() => {
                            setEditingProfile(true);
                            setMenuOpen(false);
                        }}
                    >
                        {t('topBarActions.myProfile.button')}
                    </MenuButtonItem>,
                    <MenuButtonItem
                        key="sign-out"
                        loading={signingOut}
                        onClick={signOut}
                        dangerous
                    >
                        {t('topBarActions.signOut.button')}
                    </MenuButtonItem>,
                ]}
            >
                {currentUser.name}

                <img
                    src={userButtonIcon}
                    alt={t('topBarActions.myProfile.iconAlt')}
                    className={classes.icon}
                />
            </MenuButton>
        </span>
    );
};

export default UserMenuButton;

