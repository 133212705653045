import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageDefinition } from '../../createRoutes';
import Page from '../../layouts/Page';
import { UserTab } from '../Customer/Users/Content';
import Content from './Content';

const backOfficeUsersPage: PageDefinition = {
    path: '/back-office/users',
    Component() {
        const { t } = useTranslation();

        return (
            <Page breadcrumbs={{ name: t('breadcrumbs.backOfficeUsers') }}>
                <Content tab={UserTab.Users} />
            </Page>
        );
    },
};

const backOfficeInvitesPage: PageDefinition = {
    path: '/back-office/invites',
    Component() {
        const { t } = useTranslation();

        return (
            <Page breadcrumbs={{ name: t('breadcrumbs.backOfficeInvites') }}>
                <Content tab={UserTab.Invites} />
            </Page>
        );
    },
};

export {
    backOfficeUsersPage,
    backOfficeInvitesPage,
};
