import { Alert, Heading } from '@get-e/react-components';
import { Fade, makeStyles, Tooltip } from '@material-ui/core';
import FlightLandIcon from '@material-ui/icons/FlightLandOutlined';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoffOutlined';
import { Moment } from 'moment';
import React, { FunctionComponent } from 'react';
import UtcTime from '../../../components/UtcTime';

const useStyles = makeStyles({
    flightDetailsIcon: {
        fontSize: '1.2em',
        marginRight: '.2em',
        verticalAlign: 'middle',
    },
    flightDetailsText: { verticalAlign: 'middle' },
    flightDetectionAlert: { marginBottom: '1rem' },

});

interface Flight {
    name: string;
    iata: string;
    time: Moment;
}

const FlightFound: FunctionComponent<{
    title: string;
    from: Flight;
    to: Flight;
}> = ({ title, from, to }) => {
    const classes = useStyles();

    return (
        <Fade in>
            <div>
                <Alert
                    severity="info"
                    inline
                    className={classes.flightDetectionAlert}
                >
                    <div>
                        <Heading level={4}>
                            {title}
                        </Heading>

                        <div>
                            <div>
                                <FlightTakeoffIcon
                                    className={classes.flightDetailsIcon}
                                />
                                {' '}
                                <span className={classes.flightDetailsText}>
                                    <Tooltip
                                        title={from.name}
                                    >
                                        <span>{from.iata}</span>
                                    </Tooltip>
                                    {' departure '}
                                    <UtcTime time={from.time} />
                                </span>
                            </div>

                            <div>
                                <FlightLandIcon className={classes.flightDetailsIcon} />

                                {' '}

                                <span className={classes.flightDetailsText}>
                                    <Tooltip title={to.name}>
                                        <span>{to.iata}</span>
                                    </Tooltip>

                                    {' arrival '}

                                    <UtcTime time={to.time} />
                                </span>
                            </div>
                        </div>
                    </div>
                </Alert>
            </div>
        </Fade>
    );
};

export default FlightFound;
