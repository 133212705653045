import moment, { Moment } from 'moment';

export function stringToMoment(value: string | null): Moment | null {
    const isString = typeof value === 'string';

    if (isString && !moment(value).isValid()) {
        throw new Error('String is not a valid date');
    }

    return isString ? moment(value) : null;
}
