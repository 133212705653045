import {
    Alert, Form, PrimaryButton,
    SecondaryButton, TextField, Heading,
} from '@get-e/react-components';
import { Checkbox, FormControlLabel, Grid, makeStyles } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCurrentUserContext } from '../../../../context/CurrentUserContext';
import tryGetAsync from '../../../../helpers/tryGetAsync';
import allValid from '../../../../helpers/validation/allValid';
import FormError from '../../../../helpers/validation/FormError';
import getFormErrorMessage from '../../../../helpers/validation/getFormErrorMessage';
import getHelperText from '../../../../helpers/validation/getHelperText';
import getInputError from '../../../../helpers/validation/getInputError';
import InputError from '../../../../helpers/validation/InputError';
import isFilledString from '../../../../helpers/validation/validators/isFilledString';
import {
    UpdateUserResult,
    UpdateUserInput,
    UPDATE_USER,
} from '../../../../queries/UpdateUser.graphql';
import useFormStyles from '../../../../styles/useFormStyles';
import {
    UpdateSupplierUserResult,
    UpdateSupplierUserInput,
    UPDATE_SUPPLIER_USER,
} from './Content.graphql';

interface User {
    name: string;
    id: string;
    email: string;
    supplierEdge: {
        permissions: { manageUsers: boolean };
    };
}

const useStyles = makeStyles({
    subHeading: {
        fontSize: '1.1rem',
        marginBottom: '1.5rem',
        marginTop: '-0.5rem',
    },
});

const EditUserForm: FunctionComponent<{ supplierId: string; user: User }> = (
    {
        supplierId,
        user,
    }
) => {
    const formClasses = useFormStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();

    const currentUser = useCurrentUserContext();

    const [name, setName] = useState(user.name);
    const [nameError, setNameError] = useState<InputError | null>(null);
    const [formError, setFormError] = useState<FormError | null>(null);

    const [
        manageUsers,
        setManageUsers,
    ] = useState(user.supplierEdge.permissions.manageUsers);

    const [
        updateUser,
        { loading: submittingUser },
    ] = useMutation<UpdateUserResult, UpdateUserInput>(
        UPDATE_USER
    );

    const multipleOrganisations = currentUser.managedCustomer !== null;

    const [
        updateSupplierUser,
        { loading: submittingSupplier },
    ] = useMutation<UpdateSupplierUserResult, UpdateSupplierUserInput>(
        UPDATE_SUPPLIER_USER
    );

    async function handleSubmitButtonClick(): Promise<void> {
        const validated = {
            name: isFilledString(name, InputError.Empty),
        };

        if (!allValid(validated)) {
            setFormError(FormError.UserError);

            setNameError(getInputError(validated.name));
            return;
        }

        setFormError(null);

        const result = await tryGetAsync(async () => {
            const updateUserPromise = updateUser({
                variables: {
                    input: {
                        id: user.id,
                        name: validated.name.value,
                    },
                },
            });

            const updateSupplierUserPromise = updateSupplierUser({
                variables: {
                    input: {
                        userId: user.id,
                        permissions: { manageUsers },
                    },
                },
            });

            await Promise.all([updateUserPromise, updateSupplierUserPromise]);
        });

        if (!result.success) {
            setFormError(FormError.UnexpectedError);
            return;
        }

        history.push(`/suppliers/${supplierId}/users`);
    }

    const formErrorElement = (() => {
        if (formError === null) {
            return null;
        }

        return (
            <Alert severity="error" className={formClasses.mainError}>
                {getFormErrorMessage(formError, t)}
            </Alert>
        );
    })();

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
        >
            <Grid item xs={12} md={6}>
                <Heading
                    level={2}
                >
                    {t('pages.supplierUsers.editUser.heading', { name })}
                </Heading>

                <div className={classes.subHeading}>{user.email}</div>

                <Form
                    autoComplete="off"
                    className={formClasses.form}
                    noValidate
                    onSubmit={() => handleSubmitButtonClick()}
                >
                    <TextField
                        value={name}
                        onChange={event => {
                            setName(event.target.value);
                            setNameError(null);
                        }}
                        error={nameError !== null}
                        helperText={getHelperText(nameError, t)}
                        label={t('pages.users.fields.name')}
                        required
                        disabled={multipleOrganisations}
                    />

                    <div className={formClasses.fullWidth}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={manageUsers}
                                    onChange={
                                        (_event, newValue) => setManageUsers(newValue)
                                    }
                                />
                            }
                            label={t('pages.users.fields.manageUsers')}
                        />
                    </div>

                    {formErrorElement}

                    <div className={formClasses.buttons}>
                        <PrimaryButton
                            loading={submittingUser || submittingSupplier}
                            onClick={event => {
                                event.preventDefault();

                                // eslint-disable-next-line max-len
                                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                                handleSubmitButtonClick();
                            }}
                        >
                            {t('pages.users.button.saveUser')}
                        </PrimaryButton>

                        <SecondaryButton
                            onClick={() => history.push(`/suppliers/${supplierId}/users`)}
                            disabled={submittingUser || submittingSupplier}
                        >
                            {t('buttons.cancel')}
                        </SecondaryButton>
                    </div>
                </Form>
            </Grid>
        </Grid>
    );
};

export default EditUserForm;
