import { Skeleton } from '@material-ui/lab';
import React, { FunctionComponent } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useCurrentUserContext } from '../../context/CurrentUserContext';
import { BreadcrumbHeadNode, PageDefinition } from '../../createRoutes';
import report from '../../helpers/report';
import Page from '../../layouts/Page';
import {
    GET_CUSTOMER_NAME, GetCustomerNameInput,
    GetCustomerNameResult,
} from '../../queries/GetCustomerName.graphql';
import Content, { CustomerTab } from './Content';
import { CustomerInvitesPage, CustomerUsersPage } from './Users';
import { UserTab } from './UsersTab';

const BackOfficeCustomerPage: FunctionComponent<{
    id: string;
    tab: CustomerTab;
    usersTab?: UserTab;
}> = ({ id, tab, usersTab }) => {
    const { t } = useTranslation();

    const { data, loading, error } = useQuery<GetCustomerNameResult, GetCustomerNameInput>(
        GET_CUSTOMER_NAME,
        {
            variables: { id },
            onError(apolloError) {
                report(apolloError);
                throw apolloError;
            },
        }
    );

    const customerName = ((): BreadcrumbHeadNode['name'] => {
        if (loading || error) {
            // Note: In case of an error the user will see the error boundary instead
            return <Skeleton width="7ch" />;
        }

        if (!data || !data.customer) {
            return t('breadcrumbs.unknownCustomer');
        }

        return data.customer.name;
    })();

    return (
        <Page
            breadcrumbs={{
                name: customerName,
                previous: {
                    name: t('breadcrumbs.customers'),
                    path: '/customers',
                },
            }}
        >
            <Content id={id} tab={tab} usersTab={usersTab} customerName={customerName} />
        </Page>
    );
};

const customerPage: PageDefinition = {
    path: '/customers/:id',
    Component({ getParam }) {
        const id = getParam('id');

        return <BackOfficeCustomerPage id={id} tab={CustomerTab.Organization} />;
    },
};

const customerOrganizationPage: PageDefinition = {
    path: '/customers/:id/organization',
    Component({ getParam }) {
        const id = getParam('id');

        return <BackOfficeCustomerPage id={id} tab={CustomerTab.Organization} />;
    },
};

const customerDisruptionsPage: PageDefinition = {
    path: '/customers/:id/disruptions',
    Component({ getParam }) {
        const id = getParam('id');

        return <BackOfficeCustomerPage id={id} tab={CustomerTab.Disruptions} />;
    },
};

const customerUsersPage: PageDefinition = {
    path: '/customers/:id/users',
    Component({ getParam }) {
        const id = getParam('id');
        const currentUser = useCurrentUserContext();

        return currentUser.isBackOffice
            ? (
                <BackOfficeCustomerPage
                    id={id}
                    tab={CustomerTab.Users}
                    usersTab={UserTab.Users}
                />
            )
            : <CustomerUsersPage customerId={id} />;
    },
};

const customerInvitesPage: PageDefinition = {
    path: '/customers/:id/invites',
    Component({ getParam }) {
        const id = getParam('id');
        const currentUser = useCurrentUserContext();

        return currentUser.isBackOffice
            ? (
                <BackOfficeCustomerPage
                    id={id}
                    tab={CustomerTab.Users}
                    usersTab={UserTab.Invites}
                />
            )
            : <CustomerInvitesPage customerId={id} />;
    },
};

export {
    customerPage,
    customerOrganizationPage,
    customerDisruptionsPage,
    customerUsersPage,
    customerInvitesPage,
};

