import { Grid } from '@material-ui/core';
import moment, { Moment } from 'moment';
import React, { FunctionComponent, useState } from 'react';
import AirportField, { Airport } from '../../components/AirportField';
import UtcDateField from '../../components/UtcDateField';

export type SearchBarInput = null | {
    airport: Airport;
    checkIn: Moment;
    checkOut: Moment;
};

const SearchBar: FunctionComponent<{
    initialValues: SearchBarInput;
    onChange: (values: SearchBarInput) => void;
}> = ({ initialValues, onChange }) => {
    const [airport, setAirport] = useState<Airport | null>(initialValues?.airport ?? null);

    const [checkInDate, setCheckInDate] = useState<Moment | null>(
        initialValues?.checkIn ?? moment.utc()
    );

    const [checkOutDate, setCheckOutDate] = useState<Moment | null>(
        initialValues?.checkOut ?? moment.utc().add(1, 'day')
    );

    const handleChange = ({ newAirport, newCheckIn, newCheckOut }: {
        newAirport: Airport | null;
        newCheckIn: Moment | null;
        newCheckOut: Moment | null;
    }): void => {
        onChange(
            newAirport === null
                || newCheckIn === null
                || newCheckOut === null
                ? null
                : {
                    airport: newAirport,
                    checkIn: newCheckIn,
                    checkOut: newCheckOut,
                }
        );
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <AirportField
                    label="Hotels near"
                    value={airport}
                    onChange={newValue => {
                        setAirport(newValue);

                        handleChange({
                            newAirport: newValue,
                            newCheckIn: checkInDate,
                            newCheckOut: checkOutDate,
                        });
                    }}
                    required
                />
            </Grid>

            <Grid item xs={3}>
                <UtcDateField
                    label="Check-in"
                    value={checkInDate}
                    onChange={newValue => {
                        setCheckInDate(newValue);

                        handleChange({
                            newAirport: airport,
                            newCheckIn: newValue,
                            newCheckOut: checkOutDate,
                        });
                    }}
                    required
                />
            </Grid>

            <Grid item xs={3}>
                <UtcDateField
                    label="Check-out"
                    value={checkOutDate}
                    onChange={newValue => {
                        setCheckOutDate(newValue);

                        handleChange({
                            newAirport: airport,
                            newCheckIn: checkInDate,
                            newCheckOut: newValue,
                        });
                    }}
                    required
                    minimum={checkInDate?.clone().add(1, 'day')}
                />
            </Grid>
        </Grid>
    );
};

export default SearchBar;
