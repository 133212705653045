import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import {
    BreadcrumbHeadNode,
    PageDefinition,
} from '../../../../createRoutes';
import Page from '../../../../layouts/Page';
import {
    GetSupplierNameInput,
    GetSupplierNameResult,
    GET_SUPPLIER_NAME,
} from '../../../../queries/GetSupplierName.graphql';
import Content from './Content';

const inviteSupplierUserPage: PageDefinition = {
    path: '/suppliers/:supplierId/users/invite',
    Component({ getParam }) {
        const { t } = useTranslation();
        const supplierId = getParam('supplierId');

        const {
            data,
            loading,
            error,
        } = useQuery<GetSupplierNameResult, GetSupplierNameInput>(
            GET_SUPPLIER_NAME,
            { variables: { id: supplierId } }
        );

        const supplierName = ((): BreadcrumbHeadNode['name'] => {
            if (loading || error) {
                // Note: In case of an error the user will see the error boundary instead
                return <Skeleton width="7ch" />;
            }

            if (!data || !data.supplier) {
                return t('breadcrumbs.unknownSupplier');
            }

            return data.supplier.name;
        })();

        return (
            <Page
                breadcrumbs={{
                    name: t('breadcrumbs.inviteSupplierUser'),
                    previous: {
                        name: supplierName,
                        path: `/suppliers/${supplierId}/users`,
                        previous: {
                            name: t('breadcrumbs.suppliers'),
                            path: '/suppliers',
                        },
                    },
                }}
            >
                <Content supplierId={supplierId} />
            </Page>
        );
    },
};

export default inviteSupplierUserPage;
