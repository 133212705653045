import gql from 'graphql-tag';

export const GET_CUSTOMERS = gql`
    query getCustomers(
        $first: Int,
        $last: Int,
        $after: String,
        $before: String
    ) {
        customers(
            first: $first,
            after: $after,
            last: $last,
            before: $before,
            orderBy: {
                field: NAME,
                direction: ASC
            }
        ) {
            nodes {
                id
                name
            }
            totalCount
            pageInfo {
                hasPreviousPage
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`;

export type GetCustomersInput = {
    first: number;
    after?: string;
} | {
    last: number;
    before?: string;
};

export interface GetCustomersResult {
    customers: {
        nodes: Array<{
            id: string;
            name: string;
        }>;
        totalCount: number;
        pageInfo: {
            hasPreviousPage: boolean;
            hasNextPage: boolean;
            startCursor: string | null;
            endCursor: string | null;
        };
    };
}
