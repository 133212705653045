import {
    Heading, Modal,
    PrimaryButton, SecondaryButton,
} from '@get-e/react-components';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import useModalStyles from '../../../styles/useModalStyles';

const DriverFormModal: FunctionComponent<{
    open: boolean;
    onAssign: () => void;
    onClose: () => void;
    existingDriver: string;
    driverPhone: string;
    driverName: string;
}> = ({ onClose, onAssign, existingDriver, driverPhone, driverName, open }) => {
    const { t } = useTranslation();
    const modalClasses = useModalStyles();

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Heading level={2}>{t('components.driverForm.modal.heading')}</Heading>
            <p>
                {t(
                    'components.driverForm.modal.driverName',
                    { driverName }
                )}

            </p>
            <p>
                {t(
                    'components.driverForm.modal.alreadyTaken',
                    {
                        driverPhone,
                        existingDriver,
                    }
                )}
            </p>

            <br />

            <p>{t(
                'components.driverForm.modal.assignExistingDriver',
                { existingDriver }
            )}</p>

            <div className={modalClasses.buttonContainer}>
                <span className={modalClasses.primaryButton}>
                    <PrimaryButton
                        onClick={() => {
                            onAssign();
                            onClose();
                        }}
                    >
                        {t('buttons.assign')}
                    </PrimaryButton>
                </span>

                <SecondaryButton
                    onClick={onClose}
                >
                    {t('buttons.cancel')}
                </SecondaryButton>
            </div>
        </Modal>
    );
};

export default DriverFormModal;
