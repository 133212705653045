import gql from 'graphql-tag';

export const SET_PASSWORD = gql`
    mutation setPassword($input: SetPasswordInput!) {
        setPassword(input: $input) {
            email
        }
    }
`;

export interface SetPasswordInput {
    input: {
        password: string;
        token: string;
    };
}

export interface SetPasswordResult {
    setPassword: {
        email: string;
    };
}
