import { PrimaryButton, SecondaryTab, TabPanel } from '@get-e/react-components';
import { Grid, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useTablePageStyles from '../useTablePageStyles';
import { Supplier } from './Content.graphql';
import DeleteUserInviteDialog from './Users/DeleteUserInviteDialog';
import RemoveUserDialog from './Users/RemoveUserDialog';
import UserInvitesTab from './Users/UserInvitesTab';
import UsersSubTab from './Users/UsersTab';

const useStyles = makeStyles({ secondaryTab: { marginRight: '1rem' } });

export enum UsersTabs {
    Active,
    Invites
}

const UsersTab: FunctionComponent<{
    supplier: Supplier;
    tab: UsersTabs;
}> = ({ tab, supplier }) => {
    const classes = useStyles();
    const history = useHistory();
    const tableClasses = useTablePageStyles();

    const [removingUser, setRemovingUser] = useState<{
        id: string;
        name: string;
    } | null>(null);

    const [
        deletingInvite,
        setDeletingInvite,
    ] = useState<{ id: string; userName: string } | null>(null);

    return (
        <>
            {removingUser !== null && (
                <RemoveUserDialog
                    user={removingUser}
                    onClose={() => setRemovingUser(null)}
                />
            )}

            {deletingInvite !== null && (
                <DeleteUserInviteDialog
                    invite={deletingInvite}
                    onClose={() => setDeletingInvite(null)}
                />
            )}
            <Grid
                item
                xs={12}
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item>
                    <SecondaryTab
                        url={`/suppliers/${supplier.id}/users`}
                        index={UsersTabs.Active}
                        selectedIndex={tab}
                        className={classes.secondaryTab}
                    >
                        Active
                    </SecondaryTab>

                    <SecondaryTab
                        url={`/suppliers/${supplier.id}/invites`}
                        index={UsersTabs.Invites}
                        selectedIndex={tab}
                        className={classes.secondaryTab}
                    >
                        Invited
                    </SecondaryTab>
                </Grid>

                <Grid item className={tableClasses.addButton}>
                    <PrimaryButton
                        onClick={() => {
                            history.push(`/suppliers/${supplier.id}/users/invite`);
                        }}
                        icon={<AddIcon />}
                    >
                        Invite user
                    </PrimaryButton>
                </Grid>
            </Grid>

            <TabPanel value={UsersTabs.Active} selectedValue={tab}>
                <UsersSubTab
                    supplierId={supplier.id}
                    onEditUser={user => {
                        history.push(`/suppliers/${supplier.id}/users/${user.id}`);
                    }}
                    onRemoveUser={user => setRemovingUser(user)}
                />
            </TabPanel>

            <TabPanel value={UsersTabs.Invites} selectedValue={tab}>
                <UserInvitesTab
                    supplierId={supplier.id}
                    onDeleteInvite={invite => setDeletingInvite(invite)}
                />
            </TabPanel>
        </>
    );
};

export default UsersTab;
