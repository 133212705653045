import { MenuButtonItem, OptionsTableCell } from '@get-e/react-components';
import {
    Box, Collapse, IconButton, makeStyles,
    TableCell, TableRow,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import StarIcon from '@material-ui/icons/Star';
import clsx from 'clsx';
import React, { FunctionComponent, useState } from 'react';
import Currency from '../../../components/Currency';
import { useLocaleContext } from '../../../context/LocaleContext';
import formatNumber from '../../../helpers/formatNumber';
import getValue from '../../../helpers/getValue';
import useTableStyles from '../../../styles/useTableStyles';

const useStyles = makeStyles({
    stars: { whiteSpace: 'nowrap' },
    star: {
        color: '#fbc02d',
        width: '.7em',
        verticalAlign: 'middle',
        margin: -1,
    },
    hotelName: {
        marginRight: '.3em',
        verticalAlign: 'middle',
    },
    status: {
        fontWeight: 600,
        borderRadius: 4,
        padding: '.25rem .5rem',
        whiteSpace: 'nowrap',
        textAlign: 'center',
    },
    softLockedStatus: {
        background: '#ebeff6',
        color: '#4e4f52',
    },
    bookedStatus: {
        background: '#c8e6c9',
        color: '#17511b',
    },
});

export interface BookedRoomType {
    id: string;
    name: string;
    availableCount: number;
    price: {
        amount: number;
        currencyCode: string;
    };
    hotel: {
        name: string;
        stars: 1 | 2 | 3 | 4 | 5;
        distanceKm: number;
    };
    bookingStatus: 'SOFT_LOCKED' | 'BOOKED';
}

const BookedRoomRow: FunctionComponent<{
    roomType: BookedRoomType;
    open: boolean;
    onOpen: () => void;
    onClose: () => void;
}> = ({ roomType, open, onOpen, onClose }) => {
    const tableClasses = useTableStyles();
    const classes = useStyles();
    const { locale } = useLocaleContext();

    const [menuOpen, setMenuOpen] = useState(false);

    const stars = getValue(() => {
        const result = [];

        for (let iteration = 0; iteration < roomType.hotel.stars; iteration++) {
            result.push(<StarIcon key={iteration} className={classes.star} />);
        }

        return (
            <span className={classes.stars}>
                {result}
            </span>
        );
    });

    return (
        <>
            <TableRow>
                <TableCell className={tableClasses.buttonColumn}>
                    <IconButton
                        aria-label={open ? 'Collapse row' : 'Expand row'}
                        size="small"
                        onClick={() => (open ? onClose() : onOpen())}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell align="right">
                    {/* TODO: Support miles */}
                    {formatNumber(
                        roomType.hotel.distanceKm,
                        locale,
                        {
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 1,
                        }
                    )} km
                </TableCell>

                <TableCell>
                    <span className={classes.hotelName}>
                        {roomType.hotel.name}
                    </span>

                    {stars}
                </TableCell>

                <TableCell>
                    {roomType.name}
                </TableCell>

                <TableCell align="right">
                    {roomType.availableCount}
                </TableCell>

                <TableCell align="right">
                    <Currency
                        amount={roomType.price.amount}
                        code={roomType.price.currencyCode}
                        noDecimals
                    />
                </TableCell>

                <TableCell>
                    {getValue(() => {
                        switch (roomType.bookingStatus) {
                            case 'SOFT_LOCKED':
                                return (
                                    <div className={clsx(classes.status, classes.softLockedStatus)}>
                                        Soft-locked
                                    </div>
                                );

                            case 'BOOKED':
                                return (
                                    <div className={clsx(classes.status, classes.bookedStatus)}>
                                        Booked
                                    </div>
                                );

                            default:
                                throw new Error('Unhandled status');
                        }
                    })}
                </TableCell>

                <OptionsTableCell
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                >
                    {/* TODO: Add supplier actions (confirm/no longer available) */}

                    <MenuButtonItem
                        onClick={() => {
                            // TODO: Handle
                        }}
                    >
                        Edit
                    </MenuButtonItem>

                    <MenuButtonItem
                        onClick={() => {
                            // TODO: Handle
                        }}
                        dangerous
                    >
                        Cancel
                    </MenuButtonItem>
                </OptionsTableCell>
            </TableRow>

            <TableRow>
                <TableCell
                    colSpan={8}
                    className={clsx(
                        tableClasses.collapsableCell,
                        { [tableClasses.collapsedCell]: !open }
                    )}
                >
                    <Collapse
                        in={open}
                        timeout="auto"
                        unmountOnExit
                        className={tableClasses.expandedBackground}
                    >
                        <Box margin={2}>
                            More details coming soon
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default BookedRoomRow;
