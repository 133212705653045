import gql from 'graphql-tag';

export const GET_ADD_DISRUPTION_PERMISSION = gql`
    query getAddDisruptionPermission($customerId: ID!) {
        customer(id: $customerId) {
            id
            accessibleMutations {
                createArrivalDisruption
            }
        }
    }
`;

export interface GetAddDisruptionPermissionInput {
    customerId: string;
}

export interface GetAddDisruptionPermissionResult {
    customer: null | {
        id: string;
        accessibleMutations: {
            createArrivalDisruption: boolean;
        };
    };
}
