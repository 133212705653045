import {
    LoadingPage,
    MenuButtonItem, OptionsTableCell, TableBody,
    TableContainer, TableFooter, TableHead, TableHeader,
} from '@get-e/react-components';
import { Table, TableCell, TableRow } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Retry from '../../../components/Retry';
import report from '../../../helpers/report';
import useDebounce from '../../../helpers/useDebounce';
import useLoaderDebounce from '../../../helpers/useLoaderDebounce';
import useUrlQuery from '../../../helpers/useUrlQuery';
import useTablePageStyles from '../../useTablePageStyles';
import { GetSupplierInvitesInput, GetSupplierInvitesResult, GET_SUPPLIER_INVITES } from './SupplierUsers.graphql';

const Row: FunctionComponent<{
    invite: RowDetails;
    onDeleteClick: () => void;
}> = ({ invite, onDeleteClick }) => {
    const { t } = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);

    function handleDeleteClick(): void {
        setMenuOpen(false);
        onDeleteClick();
    }

    // TODO: Remove after MVP
    const disableForMVP = true;

    return (
        <TableRow>
            <TableCell>{invite.name}</TableCell>
            <TableCell>{invite.email}</TableCell>

            {disableForMVP
                ? null
                : (
                    <OptionsTableCell menuOpen={menuOpen} setMenuOpen={setMenuOpen}>
                        <MenuButtonItem
                            onClick={handleDeleteClick}
                            dangerous
                        >
                            {t('pages.users.button.deleteInvite')}
                        </MenuButtonItem>
                    </OptionsTableCell>
                )
            }
        </TableRow>
    );
};

export interface UserInvitesTabProps {
    onUpdate?: (event: { total: number }) => void;
    onDeleteInvite: (invite: { id: string; userName: string }) => void;
    supplierId: string;
}

interface RowDetails {
    id: string;
    name: string;
    email: string;
}

const UserInvitesTab: FunctionComponent<UserInvitesTabProps>
    = ({ onUpdate, onDeleteInvite, supplierId }) => {
        const { t } = useTranslation();
        const classes = useTablePageStyles();
        const history = useHistory();
        const query = useUrlQuery();
        const after = query.get('after');
        const before = query.get('before');
        const paginationLimit = 20;

        const [search, setSearch] = useState('');
        const debouncedSearchValue = useDebounce(search, 500);

        const variables = ((): GetSupplierInvitesInput => {
            const usersFilter: null | { search: string }
                = debouncedSearchValue
                    ? { search: debouncedSearchValue }
                    : null;

            if (before !== null) {
                return {
                    id: supplierId,
                    last: paginationLimit,
                    before: before || undefined,
                    filter: usersFilter,
                };
            }

            return {
                id: supplierId,
                first: paginationLimit,
                after: after ?? undefined,
                filter: usersFilter,
            };
        })();

        const {
            data,
            loading,
            error,
            refetch,
        } = useQuery<GetSupplierInvitesResult>(
            GET_SUPPLIER_INVITES,
            {
                variables,
                notifyOnNetworkStatusChange: true,
                onError(apolloError) {
                    report(apolloError);
                },
                fetchPolicy: 'cache-and-network',
            }
        );

        useEffect(() => {
            onUpdate?.({ total: data?.supplier.userInvites.nodes.length ?? 0 });
        }, [data?.supplier.userInvites.nodes.length, onUpdate]);

        const showLoader = useLoaderDebounce(loading);

        if (loading && !data) {
            return showLoader
                ? <LoadingPage />
                : null;
        }

        if (error || !data) {
            return <Retry onRetry={() => refetch()} loading={loading} />;
        }

        const handleBackToStartClick = (): void => {
            history.push({ search: '' });
        };

        const handlePreviousClick = (): void => {
            const { startCursor } = data.supplier.userInvites.pageInfo;

            if (startCursor === null) {
                throw new Error('Page info does not contain start cursor');
            }

            history.push({ search: `?before=${startCursor}` });
        };

        const handleNextClick = (): void => {
            const { endCursor } = data.supplier.userInvites.pageInfo;

            if (endCursor === null) {
                throw new Error('Page info does not contain end cursor');
            }

            history.push({ search: `?after=${endCursor}` });
        };

        const handleSkipToEndClick = (): void => {
            history.push({ search: '?before' });
        };

        const invites = data.supplier.userInvites.nodes;

        return (
            <TableContainer className={classes.table}>
                <TableHeader
                    title={t('pages.users.table.invite')}
                />

                <Table aria-label={t('pages.users.aria.invitesTable')}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {invites.map(invite => (
                            <Row
                                invite={invite}
                                key={invite.id}
                                onDeleteClick={() => onDeleteInvite({
                                    id: invite.id,
                                    userName: invite.name,
                                })}
                            />
                        ))}
                    </TableBody>
                </Table>

                <TableFooter
                    count={{
                        total: invites.length,
                        currentPage: invites.length,
                    }}
                    hasPrevious={false}
                    hasNext={false}
                    onBackToStartClick={handleBackToStartClick}
                    onPreviousClick={handlePreviousClick}
                    onNextClick={handleNextClick}
                    onSkipToEndClick={handleSkipToEndClick}
                    loading={loading}
                />
            </TableContainer>
        );
    };

export default UserInvitesTab;
