type LatLng = google.maps.LatLng;
type DirectionsResult = google.maps.DirectionsResult;

const cache = new Map<string, Promise<DirectionsResult>>();

function createCacheKey(route: LatLng[]): string {
    return route.reduce((result, latlng) => `${result};${latlng.toUrlValue(6)}`, '');
}

let cachedDirectionsService: google.maps.DirectionsService | null = null;

export default function fetchDirections(route: LatLng[]): Promise<DirectionsResult> {
    const cacheKey = createCacheKey(route);
    const cachedPromise = cache.get(cacheKey);

    if (cachedPromise) {
        return cachedPromise;
    }

    // TODO: Move to API

    const directionsService = (() => {
        if (cachedDirectionsService === null) {
            cachedDirectionsService = new google.maps.DirectionsService();
        }

        return cachedDirectionsService;
    })();

    const origin = route[0];
    const destination = route[route.length - 1];
    const stopovers = route.slice(1, route.length - 1);

    const promise = new Promise<DirectionsResult>((resolve, reject) => {
        directionsService.route({
            origin,
            destination,
            waypoints: stopovers.map(stopover => ({
                location: stopover,
                stopover: true,
            })),
            travelMode: google.maps.TravelMode.DRIVING,
        }, (response, status) => {
            if (status !== google.maps.DirectionsStatus.OK) {
                reject(
                    new Error(
                        'Cannot find directions from'
                        + ` ${origin.toUrlValue(6)} to ${destination.toUrlValue(6)}`
                        + ` (${status})`
                    )
                );

                return;
            }

            resolve(response);
        });
    });

    cache.set(cacheKey, promise);

    return promise;
}
