import {
    Alert, Form, PrimaryButton,
    SecondaryButton, TextField,
} from '@get-e/react-components';
import { TFunction } from 'i18next';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import delay from '../../helpers/delay';
import report from '../../helpers/report';
import useFormStyles from '../../styles/useFormStyles';

enum FormError {
    Unauthorized,
    UnexpectedError,
    UserError
}

// TODO: Move
enum InputError {
    Empty,
}

function getInputErrorMessage(error: InputError, t: TFunction): string {
    switch (error) {
        case InputError.Empty:
            return t('errors.forms.inputs.empty');
        default:
            throw new Error('Unhandled error');
    }
}

const InviteUserForm: FunctionComponent<{
    onCancel: () => void;
    onInvited: () => void;
    // eslint-disable-next-line max-statements, max-lines-per-function
}> = ({ onCancel }) => {
    const formClasses = useFormStyles();
    const { t } = useTranslation();

    const [submitting, setSubmitting] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const [formError, setFormError] = useState<FormError | null>(null);
    const [nameError, setNameError] = useState<InputError | null>(null);
    const [emailError, setEmailError] = useState<InputError | null>(null);

    const submitForm = async (): Promise<void> => {
        setSubmitting(true);

        await delay(2000);

        setFormError(FormError.UserError);
        setNameError(InputError.Empty);
        setEmailError(InputError.Empty);

        setSubmitting(false);
    };

    const formErrorElement = (() => {
        if (formError === null) {
            return null;
        }

        // TODO: Use new UI

        const message = (() => {
            switch (formError) {
                case FormError.Unauthorized:
                    return t('errors.forms.unauthorized');
                case FormError.UnexpectedError:
                    return t('errors.forms.unexpected');
                case FormError.UserError:
                    return t('errors.forms.userError');
                default:
                    report(new Error('Unhandled form error'));
                    return t('errors.forms.unexpected');
            }
        })();

        return (
            <div className={formClasses.errorContainer}>
                <span className={formClasses.error}>
                    {message}
                </span>
            </div>
        );
    })();

    return (
        <Form
            autoComplete="off"
            className={formClasses.form}
            noValidate
            onSubmit={() => submitForm()}
        >
            <TextField
                value={name}
                onChange={event => setName(event.target.value)}
                error={nameError !== null}
                helperText={
                    nameError === null
                        ? ''
                        : getInputErrorMessage(nameError, t)
                }
                label={t('pages.users.fields.name')}
                required
            />

            <TextField
                value={email}
                onChange={event => setEmail(event.target.value)}
                error={emailError !== null}
                helperText={
                    emailError === null
                        ? ''
                        : getInputErrorMessage(emailError, t)
                }
                inputProps={{ autoCapitalize: 'none' }}
                label={t('pages.users.fields.email')}
                type="email"
                required
            />

            <Alert severity="warning" className={formClasses.alert}>
                {

                    /*
                     * TODO: We might want to add a @get-e.com filter
                     * in the back-end instead
                     */
                }
                {t('pages.backOfficeUsers.inviteWarning')}
            </Alert>

            {formErrorElement}

            <div className={formClasses.buttons}>
                <PrimaryButton
                    loading={submitting}
                    onClick={event => {
                        event.preventDefault();

                        // eslint-disable-next-line max-len
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        submitForm();
                    }}
                >
                    {t('pages.users.button.invite')}
                </PrimaryButton>

                <SecondaryButton onClick={onCancel} disabled={submitting}>
                    {t('buttons.cancel')}
                </SecondaryButton>
            </div>
        </Form>
    );
};

export default InviteUserForm;
