import { NumberedTab, NumberedTabs, PrimaryButton, TabPanel } from '@get-e/react-components';
import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditUserModal from '../../components/EditUserModal';
import useTablePageStyles from '../useTablePageStyles';
import BackOfficeInvite from './BackOfficeInvite';
import BackOfficeUser from './BackOfficeUser';
import DeleteUserInviteDialog from './DeleteUserInviteDialog';
import InviteUserModal from './InviteUserModal';
import RemoveUserDialog from './RemoveUserDialog';
import UserInvitesTab from './UserInvitesTab';
import UsersTab from './UsersTab';

export enum UserTab {
    Users,
    Invites,
}

export interface BackOfficeUsersProps {
    tab: UserTab;
}

const Content: FunctionComponent<BackOfficeUsersProps> = ({ tab }) => {
    const { t } = useTranslation();
    const classes = useTablePageStyles();

    const [totalUsers, setTotalUsers] = useState<number | null>(null);
    const [totalInvites, setTotalInvites] = useState<number | null>(null);

    const [inviteModalOpen, setInviteModalOpen] = useState(false);
    const [editingUser, setEditingUser] = useState<BackOfficeUser | null>(null);
    const [removingUser, setRemovingUser] = useState<BackOfficeUser | null>(null);
    const [deletingInvite, setDeletingInvite] = useState<BackOfficeInvite | null>(null);

    return (
        <>
            <InviteUserModal
                open={inviteModalOpen}
                onClose={() => setInviteModalOpen(false)}
            />

            {editingUser !== null && (
                <EditUserModal
                    user={editingUser}
                    onClose={() => setEditingUser(null)}
                />
            )}

            {removingUser !== null && (
                <RemoveUserDialog
                    user={removingUser}
                    onClose={() => setRemovingUser(null)}
                />
            )}

            {deletingInvite !== null && (
                <DeleteUserInviteDialog
                    invite={deletingInvite}
                    onClose={() => setDeletingInvite(null)}
                />
            )}

            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item className={classes.NumberedTabs}>
                    <div className="root">
                        <NumberedTabs value={tab}>
                            <NumberedTab
                                loading={totalUsers === null}
                                number={totalUsers}
                                label={t('pages.users.tabs.users')}
                                url="/back-office/users"
                                index={UserTab.Users}
                                selectedIndex={tab}
                            />

                            <NumberedTab
                                loading={totalInvites === null}
                                number={totalInvites}
                                label={t('pages.users.tabs.invites')}
                                url="/back-office/invites"
                                index={UserTab.Invites}
                                selectedIndex={tab}
                            />
                        </NumberedTabs>
                    </div>
                </Grid>

                <Grid item className={classes.addButton}>
                    <PrimaryButton
                        onClick={() => setInviteModalOpen(true)}
                        icon={<AddIcon />}
                    >
                        {t('pages.users.button.invite')}
                    </PrimaryButton>
                </Grid>
            </Grid>

            <TabPanel value={UserTab.Users} selectedValue={tab}>
                <UsersTab
                    onUpdate={({ total }) => setTotalUsers(total)}
                    onEditUser={user => setEditingUser(user)}
                    onRemoveUser={user => setRemovingUser(user)}
                />
            </TabPanel>

            <TabPanel value={UserTab.Invites} selectedValue={tab}>
                <UserInvitesTab
                    onUpdate={({ total }) => setTotalInvites(total)}
                    onDeleteInvite={invite => setDeletingInvite(invite)}
                />
            </TabPanel>
        </>
    );
};

export default Content;
