import gql from 'graphql-tag';

export const UPDATE_SUPPLIER_USER = gql`
    mutation updateSupplierUser($input: UpdateSupplierUserInput!) {
        updateSupplierUser(input: $input) {
            id
            name
        }
    }
`;

export interface UpdateSupplierUserInput {
    input: {
        userId: string;
        permissions: {
            manageUsers: boolean;
        };
    };
}

export interface UpdateSupplierUserResult {
    user: {
        id: string;
        name: string;
    };
}
