import { makeStyles } from '@material-ui/core';

const useCoachRowStyles = makeStyles(() => ({
    status: { fontWeight: 'bold' },
    inactiveStatus: { color: '#90A4AE' },
    inProgressStatus: { color: '#388e3c' },
    hiddenCollapseButton: { visibility: 'hidden' },
    hiddenMenuButton: { visibility: 'hidden' },
    statusSubtext: { fontSize: 14 },
}));

export default useCoachRowStyles;
