import hasProperty from './hasProperty';

type Result<TObject, TProperty> = TProperty extends keyof TObject
    ? TObject[TProperty]
    : unknown;

/**
 * Get property from an object.
 * Returns undefined if nested property does not exist.
 */
function getProperty<TObject, TProperty extends string>(
    object: TObject,
    property: TProperty,
): Result<TObject, TProperty>;

/**
 * Get nested property from an object.
 * Returns undefined if nested property does not exist.
 */
function getProperty(object: unknown, ...properties: string[]): unknown;

function getProperty(object: unknown, ...properties: string[]): unknown {
    let currentValue = object;

    for (const property of properties) {
        if (!property || !hasProperty(currentValue, property)) {
            return undefined;
        }

        currentValue = currentValue[property];
    }

    return currentValue;
}

export default getProperty;
