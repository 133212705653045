import { PrimaryButton, Tab, TabPanel, Tabs } from '@get-e/react-components';
import { Grid } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import AvailableRoomsTab from './AvailableRoomsTab';
import BookedRoomsTab from './BookedRoomsTab';

enum ActiveTab {
    Available, Booked
}

const HotelRoomsList: FunctionComponent = () => {
    const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.Available);

    return (
        <>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item>
                    <div className="root">
                        <Tabs value={activeTab} onChange={newTab => setActiveTab(newTab)}>
                            {/* TODO: Add all tab */}

                            <Tab
                                label="Available"
                                index={ActiveTab.Available}
                                selectedIndex={activeTab}
                            />

                            <Tab
                                label="Booked"
                                index={ActiveTab.Booked}
                                selectedIndex={activeTab}
                            />
                        </Tabs>
                    </div>
                </Grid>

                <Grid item>
                    {
                        activeTab === ActiveTab.Available
                            ? (
                                <PrimaryButton onClick={() => console.log('Book')} >
                                    Book selected rooms
                                </PrimaryButton>
                            )
                            : null
                    }
                </Grid>
            </Grid>

            <TabPanel value={ActiveTab.Booked} selectedValue={activeTab}>
                <BookedRoomsTab />
            </TabPanel>

            <TabPanel value={ActiveTab.Available} selectedValue={activeTab}>
                <AvailableRoomsTab />
            </TabPanel>
        </>
    );
};

export default HotelRoomsList;
