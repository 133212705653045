import gql from 'graphql-tag';

export const GET_SUPPLIER = gql`
    query getSupplier(
        $id: ID!
        $first: Int,
        $last: Int,
        $after: String,
        $before: String
    ) {
        supplier(id: $id) {
            id
            name
            address
            cocRegistrationNumber
            vatRegistrationNumber
            country {
                id
                name
                alpha2
            }
            language {
                id
                name
                locale
            }
            procedure
            receivableEmails(first: 10) {
                pageInfo {
                    hasNextPage
                }
                nodes {
                    email
                }
            }
            receivablePhoneNumbers(first: 1, orderBy: {field: PRIORITY, direction: ASC}) {
                nodes {
                    phoneNumber
                }
            }
            dispatchEmails(first: 10) {
                pageInfo {
        		    hasNextPage
                }
                nodes {
                    email
                }
            }
            dispatchPhoneNumbers(first: 1, orderBy: {field: PRIORITY, direction: ASC}) {
                nodes {
                    phoneNumber
                }
            }
            servicedAirports(
                first: $first,
                after: $after,
                last: $last,
                before: $before
            ) {
                nodes {
                    id
                    iata
                    name
                }
                pageInfo {
                    hasNextPage
                    startCursor
                    endCursor
                }
            }
        }
    }
`;

export interface Supplier {
    id: string;
    name: string;
    address: string;
    cocRegistrationNumber: string;
    vatRegistrationNumber: string;
    language: {
        id: string;
        name: string;
        locale: string;
    };
    country: {
        id: string;
        name: string;
        alpha2: string;
    };
    procedure: string;
    receivableEmails: {
        pageInfo: {
            hasNextPage: boolean;
        };
        nodes: Array<{
            email: string;
        }>;
    };
    receivablePhoneNumbers: {
        nodes: Array<{
            phoneNumber: string;
        }>;
    };
    dispatchEmails: {
        pageInfo: {
            hasNextPage: boolean;
        };
        nodes: Array<{
            email: string;
        }>;
    };
    dispatchPhoneNumbers: {
        nodes: Array<{
            phoneNumber: string;
        }>;
    };
    servicedAirports: {
        nodes: Array<{
            id: string;
            iata: string;
            name: string;
        }>;
        pageInfo: {
            hasNextPage: boolean;
            startCursor: string;
            endCursor: string;
        };
    };
}

export interface GetSupplierInput {
    id: string;
    first: number;
    after?: string;
}

export interface GetSupplierResult {
    supplier: Supplier;
}
