import { FunctionComponent } from 'react';
import { matchPath } from 'react-router-dom';
import { backOfficeInvitesPage, backOfficeUsersPage } from './pages/BackOfficeUsers';
import {
    customerDisruptionsPage, customerInvitesPage,
    customerOrganizationPage, customerPage, customerUsersPage,
} from './pages/Customer';
import editCustomerUserPage from './pages/Customer/Users/Edit';
import inviteCustomerUserPage from './pages/Customer/Users/Invite';
import customersPage from './pages/Customers';
import disruptionPage from './pages/Disruption';
import {
    activeDisruptionsPage, disruptionsArchivePage,
    disruptionsPage,
} from './pages/Disruptions';
import { addArrivalDisruptionPage, addDepartureDisruptionPage } from './pages/Disruptions/Add';
import editDisruptionPage from './pages/Disruptions/Edit';
import driversPage from './pages/Drivers';
import addDriverPage from './pages/Drivers/Add';
import editDriverPage from './pages/Drivers/Edit';
import { hotelSearchPage, hotelSearchResultsPage } from './pages/HotelSearch';
import invoicesPage from './pages/Invoices';
import { reportsPage, dateRangeReportsPage, singleFlightReportsPage } from './pages/Reports';
import { requestsPage } from './pages/Requests';
import requestPage from './pages/Requests/Request';
import {
    supplierPage, supplierEditPage,
    supplierUsersPage, supplierUsersInvitesPage,
} from './pages/Supplier';
import editSupplierUserPage from './pages/Supplier/Users/Edit';
import inviteSupplierUserPage from './pages/Supplier/Users/Invite';
import suppliersPage from './pages/Suppliers';
import suppliersAddPage from './pages/Suppliers/Add';

export interface BreadcrumbHeadNode {
    name: string | JSX.Element;
    previous?: BreadcrumbNode;
}

export interface BreadcrumbNode {
    name: string | JSX.Element;
    path: string;
    previous?: BreadcrumbNode;
}

function getParams<T extends string>(path: string): { [param in T]?: string } {
    const match = matchPath<{ [param in T]?: string }>(
        window.location.pathname,
        {
            path,
            exact: true,
            strict: true,
        }
    );

    if (!match) {
        throw new Error('Not a match');
    }

    return match.params;
}

export interface PageDefinition {

    // TODO: Add page title
    path: string;
    Component: FunctionComponent<{ getParam: (key: string) => string }>;
}

const pages: PageDefinition[] = [
    disruptionsPage,
    addArrivalDisruptionPage,
    addDepartureDisruptionPage,
    activeDisruptionsPage,
    disruptionsArchivePage,
    disruptionPage,
    customerPage,
    customerOrganizationPage,
    customerDisruptionsPage,
    customersPage,
    customerUsersPage,
    customerInvitesPage,
    inviteCustomerUserPage,
    editCustomerUserPage,
    suppliersAddPage,
    suppliersPage,
    supplierPage,
    supplierEditPage,
    supplierUsersPage,
    supplierUsersInvitesPage,
    inviteSupplierUserPage,
    editSupplierUserPage,
    backOfficeUsersPage,
    backOfficeInvitesPage,
    editDisruptionPage,
    reportsPage,
    dateRangeReportsPage,
    singleFlightReportsPage,
    invoicesPage,
    hotelSearchPage,
    hotelSearchResultsPage,
    driversPage,
    addDriverPage,
    editDriverPage,
    requestsPage,
    requestPage,
];

export default function createRoutes(): Array<{
    path: string;
    Component: FunctionComponent;
}> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return pages.map(({ path, Component }) => ({
        path,
        // eslint-disable-next-line new-cap
        Component: (): ReturnType<typeof Component> => Component({
            getParam(handle) {
                const paramValue = getParams(path)[handle];

                if (typeof paramValue !== 'string') {
                    throw new Error(`Path does not contain parameter "${handle}"`);
                }

                return paramValue;
            },
        }),
    }));
}
