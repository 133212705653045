import gql from 'graphql-tag';

export const BOOK_AVAILABILITIES = gql`
    mutation bookAvailabilities($input: BookRidesFromAvailabilitiesInput!) {
        bookRidesFromAvailabilities(input: $input) {
            id
        }
    }
`;

export interface BookAvailabilitiesInput {
    input: {
        availabilityIds: string[];
    };
}

export interface BookAvailabilitiesResult {
    bookRidesFromAvailabilities: {
        id: string;
    };
}
