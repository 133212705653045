import { createStyles, makeStyles } from '@material-ui/core';

const useFormStyles = makeStyles(theme => createStyles({
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
    },
    fullWidth: { width: '100%' },
    buttons: {
        marginTop: theme.spacing(2),
        '& > *': { marginRight: theme.spacing(1) },
    },
    compactButtons: {
        marginTop: 0,
        '& > *': { marginRight: theme.spacing(1) },
    },
    errorContainer: {
        // TODO: Remove
        width: '100%',
        display: 'block',
        textAlign: 'left',
        minHeight: '1em',
        marginTop: '.75em',
    },
    error: {
        // TODO: Remove
        color: theme.palette.error.main,
        display: 'block',
    },
    mainError: {
        flexBasis: '100%',
        marginTop: '.5rem',
    },
    mainErrorLimitedWidth: {
        display: 'inline-flex',
        marginTop: '.5rem',
    },
    alert: {
        width: '100%',
        marginTop: '.5em',
    },
    autocompleteInput: { border: 0 },
}));

export default useFormStyles;
