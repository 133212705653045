import gql from 'graphql-tag';

export const SET_BUS_NUMBER = gql`
    mutation SET_BUS_NUMBER($input: SetRideBusNumberInput!) {
        setRideBusNumber(
            input: $input
        ) {
            busNumber
        }
    }
`;

export interface SetBusNumberInput {
    input: {
        rideId: string;
        busNumber: string;
    };
}

export interface SetBusNumberResult {
    setRideBusNumber: {
        busNumber: string;
    };
}
