import {
    Modal, PrimaryButton, SecondaryButton,
    TextField, IntegerField, Form, Alert, Heading,
} from '@get-e/react-components';
import { Grid, InputAdornment } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import report from '../../../../helpers/report';
import allValid from '../../../../helpers/validation/allValid';
import FormError from '../../../../helpers/validation/FormError';
import getFormErrorMessage from '../../../../helpers/validation/getFormErrorMessage';
import getHelperText from '../../../../helpers/validation/getHelperText';
import getInputError from '../../../../helpers/validation/getInputError';
import InputError from '../../../../helpers/validation/InputError';
import isNotNull from '../../../../helpers/validation/validators/isNotNull';
import useFormStyles from '../../../../styles/useFormStyles';
import useModalStyles from '../../../../styles/useModalStyles';
import { AvailabilityRowProps } from '../Coaches/AvailabilityRow';

import {
    UPDATE_AVAILABILITY,
    UpdateRideAvailabilityResult,
    UpdateRideAvailabilityInput,
} from './AvailabilityEditModal.graphql';

const AvailabilityEditModal: FunctionComponent<{
    open: boolean;
    onClose: () => void;
    onEdited: () => void;
    availability: AvailabilityRowProps;
}> = ({ open, onClose, availability, onEdited }) => {
    const { t } = useTranslation();
    const modalClasses = useModalStyles();
    const formClasses = useFormStyles();

    const [
        leadTimeMinutes,
        setLeadTimeMinutes,
    ] = useState<number | null>(availability.timeInMinutes);

    const [seats, setSeats] = useState<number | null>(availability.seats);
    const [description, setDescription] = useState(availability.description);

    const [
        leadTimeError,
        setLeadTimeError,
    ] = useState<InputError | null>(null);

    const [seatsError, setSeatsError] = useState<InputError | null>(null);
    const [formError, setFormError] = useState<FormError | null>(null);

    const [
        updateRideAvailability,
        { loading: submitting },
    ] = useMutation<UpdateRideAvailabilityResult, UpdateRideAvailabilityInput>(
        UPDATE_AVAILABILITY
    );

    const closeModal = (): void => {
        onClose();
        setLeadTimeMinutes(availability.timeInMinutes);
        setSeats(availability.seats);
        setDescription(availability.description);
    };

    async function submitForm(): Promise<void> {
        const validated = {
            seats: isNotNull(seats, InputError.Empty),
            leadTimeMinutes: isNotNull(leadTimeMinutes, InputError.Empty),
        };

        if (!allValid(validated)) {
            setFormError(FormError.UserError);
            setSeatsError(getInputError(validated.seats));
            setLeadTimeError(getInputError(validated.leadTimeMinutes));

            return;
        }

        setFormError(null);

        try {
            await updateRideAvailability({
                variables: {
                    input: {
                        availabilityId: availability.id,
                        estimatedArrivalTimeMinutes: validated.leadTimeMinutes.value,
                        seats: validated.seats.value,
                        description,
                    },
                },
            });

            onClose();
            onEdited();
        } catch (error) {
            report(error);
            setFormError(FormError.UnexpectedError);
        }
    }

    return (
        <Modal
            open={open}
            onClose={closeModal}
        >
            <div className={modalClasses.header}>
                <Heading
                    level={2}
                >
                    {t('pages.availability.modals.editAvailability.header')}
                </Heading>
            </div>
            <Form onSubmit={submitForm}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <IntegerField
                            value={leadTimeMinutes}
                            onBlur={newLeadTime => setLeadTimeMinutes(newLeadTime)}
                            label={t('pages.availability.labels.leadTime')}
                            helperText={getHelperText(leadTimeError, t)}
                            InputProps={
                                {
                                    inputProps: { min: '0' },
                                    startAdornment:
                                        <InputAdornment position="start">
                                            {t(
                                                'pages.availability.labels.leadTimeAdornment'
                                            )}
                                        </InputAdornment>,
                                }
                            }
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <IntegerField
                            value={seats}
                            onBlur={newSeats => setSeats(newSeats)}
                            label={t('pages.availability.labels.seats')}
                            helperText={getHelperText(seatsError, t)}
                            InputProps={
                                {
                                    inputProps: { min: '0' },
                                    startAdornment:
                                        <InputAdornment position="start">
                                            {t(
                                                'pages.availability.labels.seatsAdornment'
                                            )}
                                        </InputAdornment>,
                                }
                            }
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            value={description}
                            onChange={event => setDescription(event.target.value)}
                            label={t('pages.availability.labels.descriptionNotReq')}
                            type="text"
                        />
                    </Grid>
                </Grid>
                <div className={modalClasses.buttonContainer}>
                    <span className={modalClasses.primaryButton}>
                        <PrimaryButton
                            onClick={submitForm}
                            loading={submitting}
                        >
                            {t('buttons.save')}
                        </PrimaryButton>
                    </span>

                    <SecondaryButton
                        onClick={closeModal}
                    >
                        {t('buttons.cancel')}
                    </SecondaryButton>
                </div>
            </Form>
            {(() => {
                if (formError === null) {
                    return null;
                }

                return (
                    <Alert severity="error" className={formClasses.mainError}>
                        {getFormErrorMessage(formError, t)}
                    </Alert>
                );
            })()}
        </Modal>
    );
};

export default AvailabilityEditModal;
