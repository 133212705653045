import { createStyles, makeStyles } from '@material-ui/core';

const useTablePageStyles = makeStyles(theme => createStyles(
    {
        root: {
            flexGrow: 1,
            width: '100%',
            button: { backgroundColor: theme.palette.primary.main },
        },
        addButton: {
            order: 0,
            zIndex: 1,
        },
        tabs: { order: 1 },
        content: { order: 2 },
        [theme.breakpoints.up('sm')]: {
            tabs: {
                order: 0,
                marginBottom: '1em',
            },
            addButton: { order: 1 },
        },
        table: {
            minWidth: 650,
            marginTop: '1.5rem',
        },
    },
));

export default useTablePageStyles;
