import {
    Modal, PrimaryButton, SecondaryButton,
    TextField, Form, Heading, Alert,
} from '@get-e/react-components';
import { Grid } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import report from '../../../../helpers/report';
import FormError from '../../../../helpers/validation/FormError';
import getFormErrorMessage from '../../../../helpers/validation/getFormErrorMessage';
import useFormStyles from '../../../../styles/useFormStyles';
import useModalStyles from '../../../../styles/useModalStyles';
import { RideRowProps } from '../Coaches/RideRow';

import {
    UPDATE_RIDE_DESCRIPTION,
    UpdateRideDescriptionInput,
    UpdateRideDescriptionResult,
} from './AvailabilityEditDescription.graphql';

const AvailabilityEditDescriptionModal: FunctionComponent<{
    open: boolean;
    onClose: () => void;
    onEdited: () => void;
    ride: RideRowProps;
}> = ({ open, onClose, ride, onEdited }) => {
    const { t } = useTranslation();
    const formClasses = useFormStyles();
    const modalClasses = useModalStyles();
    const [description, setDescription] = useState(ride.description);
    const [formError, setFormError] = useState<FormError | null>(null);

    const [
        postDescription,
        { loading: settingDescription },
    ] = useMutation<UpdateRideDescriptionResult, UpdateRideDescriptionInput>(
        UPDATE_RIDE_DESCRIPTION
    );

    const closeModal = (): void => {
        onClose();
        setDescription(ride.description);
    };

    async function submitForm(): Promise<void> {
        setFormError(null);

        try {
            await postDescription({
                variables: {
                    input: {
                        rideId: ride.id,
                        description,
                    },
                },
            });

            onClose();
            onEdited();
        } catch (error) {
            report(error);
            setFormError(FormError.UnexpectedError);
        }
    }

    return (
        <Modal
            open={open}
            onClose={closeModal}
        >
            <div className={modalClasses.header}>
                <Heading
                    level={2}
                >
                    {t('pages.availability.modals.editRide.header')}
                </Heading>
            </div>
            <Form onSubmit={submitForm}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            value={description}
                            onChange={event => setDescription(event.target.value)}
                            label={t('pages.availability.labels.descriptionNotReq')}
                            type="text"
                        />
                    </Grid>
                </Grid>
                <div className={modalClasses.buttonContainer}>
                    <span className={modalClasses.primaryButton}>
                        <PrimaryButton
                            onClick={submitForm}
                            loading={settingDescription}
                        >
                            {t('buttons.save')}
                        </PrimaryButton>
                    </span>

                    <SecondaryButton
                        onClick={closeModal}
                        disabled={settingDescription}
                    >
                        {t('buttons.cancel')}
                    </SecondaryButton>
                </div>
            </Form>
            {(() => {
                if (formError === null) {
                    return null;
                }

                return (
                    <Alert severity="error" className={formClasses.mainError}>
                        {getFormErrorMessage(formError, t)}
                    </Alert>
                );
            })()}
        </Modal >
    );
};

export default AvailabilityEditDescriptionModal;
