/* eslint-disable max-lines */
import {
    Alert,
    Heading, PrimaryButton,
    SecondaryButton, TextField,
} from '@get-e/react-components';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { ExecutionResult, useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Airport } from '../../../components/AirportsSelector';
import AirportsWhitelistField from '../../../components/AirportsWhitelistField';
import CountrySelector from '../../../components/CountrySelector';
import EmailField from '../../../components/EmailField';
import LanguageSelector, { Language } from '../../../components/LanguageSelector';
import PhoneNumberField from '../../../components/PhoneNumberField';
import report from '../../../helpers/report';
import allValid from '../../../helpers/validation/allValid';
import FormError from '../../../helpers/validation/FormError';
import getFormErrorMessage from '../../../helpers/validation/getFormErrorMessage';
import getHelperText from '../../../helpers/validation/getHelperText';
import getInputError from '../../../helpers/validation/getInputError';
import InputError from '../../../helpers/validation/InputError';
import isFilledArray from '../../../helpers/validation/validators/isFilledArray';
import isFilledString from '../../../helpers/validation/validators/isFilledString';
import isNotNull from '../../../helpers/validation/validators/isNotNull';
import isNull from '../../../helpers/validation/validators/isNull';
import or from '../../../helpers/validation/validators/or';
import useFormStyles from '../../../styles/useFormStyles';
import {
    CreateSupplierInput,
    CreateSupplierResult,
    CREATE_SUPPLIER,
} from './Content.graphql';

const useStyles = makeStyles(theme => createStyles({
    companyName: { marginBottom: theme.spacing(2) },
}));

interface Country {
    id: string;
    name: string;
}

const Content: FunctionComponent = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const formClasses = useFormStyles();

    const [country, setCountry] = useState<Country | null>(null);
    const [language, setLanguage] = useState<Language | null>(null);
    const [companyName, setCompanyName] = useState('');
    const [cocRegistrationNumber, setCocRegistrationNumber] = useState('');
    const [address, setAddress] = useState('');
    const [vatRegistrationNumber, setVatRegistrationNumber] = useState('');
    const [supplierNotes, setSupplierNotes] = useState('');
    const [receivableEmails, setReceivableEmails] = useState<string[]>([]);
    const [dispatchEmails, setDispatchEmails] = useState<string[]>([]);
    const [receivablePhone, setReceivablePhone] = useState('');
    const [dispatchPhone, setDispatchPhone] = useState('');

    const [
        airportsServiced,
        setAirportsServiced,
    ] = useState<Airport[] | null>(null);

    const [formError, setFormError] = useState<FormError | null>(null);
    const [companyNameError, setCompanyNameError] = useState<InputError | null>(null);
    const [countryError, setCountryError] = useState<InputError | null>(null);
    const [languageError, setLanguageError] = useState<InputError | null>(null);

    const [
        airportServicedError,
        setAirportsServicedError,
    ] = useState<InputError | null>(null);

    const [
        createSupplier,
        { loading: submitting },
    ] = useMutation<CreateSupplierResult, CreateSupplierInput>(
        CREATE_SUPPLIER
    );

    const handleSubmit = async (): Promise<void> => {
        const validated = {
            companyName: isFilledString(companyName, InputError.Empty),
            country: isNotNull(country, InputError.Empty),
            language: isNotNull(language, InputError.Empty),
            airportsServiced: or(
                isNull(airportsServiced, InputError.Empty),
                () => isFilledArray(airportsServiced, InputError.Empty),
            ),
        };

        if (!allValid(validated)) {
            setFormError(FormError.UserError);
            setCompanyNameError(getInputError(validated.companyName));
            setCountryError(getInputError(validated.country));
            setLanguageError(getInputError(validated.language));
            setAirportsServicedError(getInputError(validated.airportsServiced));

            return;
        }

        setFormError(null);

        let result: ExecutionResult<CreateSupplierResult> | null = null;

        try {
            result = await createSupplier({
                variables: {
                    input: {
                        name: validated.companyName.value,
                        procedure: supplierNotes,
                        address,
                        vatRegistrationNumber,
                        cocRegistrationNumber,
                        dispatchEmails:
                            dispatchEmails.length ? dispatchEmails : [],
                        receivableEmails:
                            receivableEmails.length ? receivableEmails : [],
                        receivablePhoneNumbers: receivablePhone.length
                            ? [{ phoneNumber: receivablePhone.trim() }]
                            : [],
                        dispatchPhoneNumbers: dispatchPhone.length
                            ? [{ phoneNumber: dispatchPhone.trim() }]
                            : [],
                        servicedAirportIds: validated.airportsServiced.value?.map(
                            (air: Airport) => air.id
                        ) ?? null,
                        countryId: validated.country.value.id,
                        languageId: validated.language.value.id,
                    },
                },
            });

            if (!result.data) {
                throw new Error('Mutation did not have result');
            }

            const { id } = result.data.createSupplier;

            history.push(`/suppliers/${id}`);
        } catch (error) {
            report(error);
            setFormError(FormError.UnexpectedError);
        }
    };

    // TODO: Translations

    return (
        <>
            <Heading level={2}>
                Add supplier
            </Heading>
            <Grid
                item
                xs={12}
            >
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            className={classes.companyName}
                            label="Company name"
                            value={companyName}
                            onChange={event => {
                                setCompanyName(event.target.value);
                                setCompanyNameError(null);
                            }}
                            error={companyNameError !== null}
                            helperText={getHelperText(companyNameError, t)}
                            required
                        />
                        <TextField
                            label="Company registration number"
                            onChange={event => {
                                setCocRegistrationNumber(event.target.value);
                            }}
                            value={cocRegistrationNumber}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Address"
                            value={address}
                            onChange={event => setAddress(event.target.value)}
                            multiline
                            rows={6}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Tax registration number"
                            value={vatRegistrationNumber}
                            onChange={event => {
                                setVatRegistrationNumber(event.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CountrySelector
                            value={country}
                            onChange={newValue => {
                                setCountry(newValue);
                                setCountryError(null);
                            }}
                            label={t('pages.customer.fields.country')}
                            helperText={getHelperText(countryError, t)}
                            error={countryError !== null}
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <LanguageSelector
                            value={language}
                            onChange={newValue => {
                                setLanguage(newValue);
                                setLanguageError(null);
                            }}
                            label={t('pages.customer.fields.language')}
                            helperText={getHelperText(languageError, t)}
                            error={languageError !== null}
                            required
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12}>
                        <Heading level={3}>
                            Supplier notes
                        </Heading>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Supplier notes"
                            onChange={event => setSupplierNotes(event.target.value)}
                            value={supplierNotes}
                            multiline
                            rows={6}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Heading level={3}>
                            Accounting contacts
                        </Heading>
                    </Grid>
                    <Grid item xs={6}>
                        <EmailField
                            label="Accounts receivables email"
                            onChange={value => setReceivableEmails(value)}
                            value={receivableEmails}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PhoneNumberField
                            label="Accounts receivables phone number"
                            value={receivablePhone}
                            onChange={value => setReceivablePhone(value)}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Heading level={3}>
                            Dispatch contacts
                        </Heading>
                    </Grid>
                    <Grid item xs={6}>
                        <EmailField
                            label="Dispatch email"
                            onChange={value => setDispatchEmails(value)}
                            value={dispatchEmails}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <PhoneNumberField
                            label="Dispatch phone number"
                            value={dispatchPhone}
                            onChange={value => setDispatchPhone(value)}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Heading level={3}>
                            Airport serviced
                        </Heading>
                    </Grid>
                    <Grid item xs={12}>
                        <AirportsWhitelistField
                            label="Airports serviced"
                            modalTitle="Airports serviced"
                            choicesListHeading={
                                t('pages.inviteCustomerUser.fields.airportsWhitelist'
                                    + '.modal.choices')}
                            chosenListHeading={
                                t('pages.inviteCustomerUser.fields.airportsWhitelist'
                                    + '.modal.chosen')}
                            values={airportsServiced}
                            onChange={airports => {
                                setAirportsServiced(airports);
                                setAirportsServicedError(null);
                            }}
                            error={airportServicedError}
                        />

                    </Grid>
                </Grid>

                {
                    formError === null
                        ? null
                        : (
                            <Alert
                                severity="error"
                                className={formClasses.mainErrorLimitedWidth}
                            >
                                {getFormErrorMessage(formError, t)}
                            </Alert>
                        )
                }

                <div className={formClasses.buttons}>
                    <PrimaryButton
                        onClick={handleSubmit}
                        loading={submitting}
                    >
                        {t('buttons.save')}
                    </PrimaryButton>

                    <SecondaryButton
                        disabled={submitting}
                        onClick={() => history.push('/suppliers')}
                    >
                        {t('buttons.cancel')}
                    </SecondaryButton>
                </div>
            </Grid>
        </>
    );
};

export default Content;
