import gql from 'graphql-tag';

export const GET_DISRUPTION_TO_EDIT = gql`
    query getDisruptionToEdit($id: ID!, $alternatesLimit: Int!) {
        disruption(id: $id) {
            id
            flight {
                number
                departure {
                    airport {
                        id
                        iata
                        name
                    }
                    scheduledDepartureTime
                }
                arrival {
                    airport {
                        id
                        iata
                        name
                    }
                    scheduledArrivalTime
                }
                passengerCount {
                    total
                    infants
                    wheelchair
                }
                alternates(first: $alternatesLimit) {
                    nodes {
                        id
                        estimatedTime
                        airport {
                            id
                            iata
                            name
                        }
                    }
                    pageInfo {
                        hasNextPage
                    }
                }
            }
            coachesAuthorized
        }
    }
`;

export interface GetDisruptionToEditInput {
    id: string;
    alternatesLimit: number;
}

export interface GetDisruptionToEditResult {
    disruption: null | {
        id: string;
        flight: {
            number: string;
            departure: {
                airport: {
                    id: string;
                    iata: string;
                    name: string;
                };
                scheduledDepartureTime: string;
            };
            arrival: {
                airport: {
                    id: string;
                    iata: string;
                    name: string;
                };
                scheduledArrivalTime: string;
            };
            passengerCount: {
                total: number;
                infants: number;
                wheelchair: number;
            };
            alternates: {
                nodes: Array<{
                    id: string;
                    estimatedTime: string | null;
                    airport: {
                        id: string;
                        iata: string;
                        name: string;
                    };
                }>;
                pageInfo: {
                    hasNextPage: boolean;
                };
            };
        };
        coachesAuthorized: boolean;
    };
}
