import gql from 'graphql-tag';

export const GET_SUPPLIER_USERS = gql`
query getSupplierUsers(
    $id: ID!
    $first: Int,
    $last: Int,
    $after: String,
    $before: String
) {
    supplier(id: $id) {
        id
        users(
                first: $first,
                after: $after,
                last: $last,
                before: $before
            ) {
            nodes {
                id
                name
                email
            }
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
    }
}
`;

export type GetSupplierUsersInput = {
    id: string;
    first: number;
    after?: string;
    filter: null | { search: string };
} | {
    id: string;
    last: number;
    before?: string;
    filter: null | { search: string };
};

export interface GetSupplierUsersResult {
    supplier: {
        id: string;
        users: {
            nodes: Array<{
                id: string;
                name: string;
                email: string;
            }>;
            totalCount: number;
            pageInfo: {
                hasPreviousPage: boolean;
                hasNextPage: boolean;
                startCursor: string | null;
                endCursor: string | null;
            };
        };
    };
}

export const GET_SUPPLIER_INVITES = gql`
query getSupplierInvites(
    $id: ID!
    $first: Int,
    $last: Int,
    $after: String,
    $before: String
) {
    supplier(id: $id) {
        id
        userInvites(
                first: $first,
                after: $after,
                last: $last,
                before: $before
            ) {
            nodes {
                id
                name
                email
            }
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
    }
}
`;

export type GetSupplierInvitesInput = {
    id: string;
    first: number;
    after?: string;
    filter: null | { search: string };
} | {
    id: string;
    last: number;
    before?: string;
    filter: null | { search: string };
};

export interface GetSupplierInvitesResult {
    supplier: {
        id: string;
        userInvites: {
            nodes: Array<{
                id: string;
                name: string;
                email: string;
            }>;
            totalCount: number;
            pageInfo: {
                hasPreviousPage: boolean;
                hasNextPage: boolean;
                startCursor: string | null;
                endCursor: string | null;
            };
        };
    };
}