// TODO: Split up
/* eslint-disable max-len */
/* eslint-disable max-lines */
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FunctionComponent, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import report from '../helpers/report';
import { GetAirportsInput, GetAirportsResult, GET_AIRPORTS } from './AirportsSelector.graphql';
import TransferListSide, { TransferListItem } from './TransferListSide';

export interface Airport {
    id: string;
    iata: string;
    name: string;
}

function createListItem(airport: Airport): TransferListItem {
    return {
        id: airport.id,
        title: airport.iata,
        subtitle: airport.name,
    };
}

function createChecked(checked: Airport[] | 'ALL'): TransferListItem[] | 'ALL' {
    return checked === 'ALL'
        ? 'ALL'
        : checked.map(createListItem);
}

function revertChecked(checked: TransferListItem[] | 'ALL'): Airport[] | 'ALL' {
    if (checked === 'ALL') {
        return 'ALL';
    }

    return checked.map(listItem => ({
        id: listItem.id,
        iata: listItem.title,
        name: listItem.subtitle,
    }));
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        margin: 'auto',
        minWidth: 500,
        width: '50vw',
        maxWidth: 820,
    },
    button: { margin: theme.spacing(0.5, 0) },
}));

function excludeAirports(original: Airport[], toExclude: Airport[]): Airport[] {
    return original.filter(airportA => !toExclude.find(airportB => airportA.id === airportB.id));
}

const AirportsSelector: FunctionComponent<{
    title: string;
    choicesHeading: string;
    chosenHeading: string;
    selected: Airport[] | 'ALL';
    onChange: (selected: Airport[] | 'ALL') => void;
    // eslint-disable-next-line max-statements, max-lines-per-function
}> = ({ title, choicesHeading, chosenHeading, selected, onChange }) => {
    const classes = useStyles();
    const [leftChecked, setLeftChecked] = useState<Airport[] | 'ALL'>([]);
    const [rightChecked, setRightChecked] = useState<Airport[] | 'ALL'>([]);
    const [leftSearch, setLeftSearch] = useState<string>('');
    const [rightSearch, setRightSearch] = useState<string>('');
    const { t } = useTranslation();

    const leftFilter = leftSearch.trim()
        ? { search: leftSearch }
        : null;

    const rightFilter = rightSearch.trim()
        ? { search: rightSearch }
        : null;

    const { data: leftAirportsResult, loading: leftLoading, error: leftError, fetchMore: fetchMoreLeft } = useQuery<GetAirportsResult, GetAirportsInput>(GET_AIRPORTS, {
        variables: {
            after: null,
            filter: leftFilter,
        },
        onError(apolloError) {
            report(apolloError);
        },
        notifyOnNetworkStatusChange: true,
    });

    const { data: rightAirportsResult, loading: rightLoading, error: rightError, fetchMore: fetchMoreRight } = useQuery<GetAirportsResult, GetAirportsInput>(GET_AIRPORTS, {
        variables: {
            after: null,
            filter: rightFilter,
        },
        onError(apolloError) {
            report(apolloError);
        },
        notifyOnNetworkStatusChange: true,
    });

    if (leftError || rightError) {
        // TODO: Proper UI
        return <>Error</>;
    }

    const loadMoreAirportsLeft = async (afterCursor: string): Promise<void> => {
        await fetchMoreLeft({
            variables: {
                after: afterCursor,
                filter: leftFilter,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return previousResult;
                }

                const { nodes: newNodes, pageInfo } = fetchMoreResult.airports;

                if (leftSearch) {
                    return fetchMoreResult;
                }

                return newNodes.length
                    ? {
                        /*
                         * Put the new comments at the end of the list and update `pageInfo`
                         * so we have the new `endCursor` and `hasNextPage` values
                         */
                        airports: {
                            ...previousResult.airports,
                            nodes: [...previousResult.airports.nodes, ...newNodes],
                            pageInfo,
                        },
                    }
                    : previousResult;
            },
        });
    };

    const loadMoreAirportsRight = async (afterCursor: string): Promise<void> => {
        await fetchMoreRight({
            variables: {
                after: afterCursor,
                filter: rightFilter,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return previousResult;
                }

                const { nodes: newNodes, pageInfo } = fetchMoreResult.airports;

                if (rightSearch) {
                    return fetchMoreResult;
                }

                return newNodes.length
                    ? {
                        /*
                         * Put the new comments at the end of the list and update `pageInfo`
                         * so we have the new `endCursor` and `hasNextPage` values
                         */
                        airports: {
                            ...previousResult.airports,
                            nodes: [...previousResult.airports.nodes, ...newNodes],
                            pageInfo,
                        },
                    }
                    : previousResult;
            },
        });
    };

    const leftAirportResults = leftAirportsResult?.airports.nodes ?? [];
    const rightSearchLowerCase = rightSearch.toLocaleLowerCase();

    const rightAirports = ((): Airport[] => {
        if (selected === 'ALL') {
            return rightAirportsResult?.airports.nodes ?? [];
        }

        const filtered = rightSearch.trim().length === 0
            ? selected
            : selected.filter(airport => airport.iata.toLowerCase().includes(rightSearchLowerCase)
                || airport.name.toLowerCase().includes(rightSearchLowerCase));

        return filtered.sort(
            (airportA, airportB) => airportA.iata.localeCompare(airportB.iata)
        );
    })();

    const leftAirports = ((): Airport[] => {
        if (selected === 'ALL') {
            return [];
        }

        const rightIds = selected.map(airport => airport.id);

        return leftAirportResults.filter(airport => !rightIds.includes(airport.id));
    })();

    const leftSearching = Boolean(leftSearch.trim());
    const leftHasNextPage = leftAirportsResult?.airports.pageInfo.hasNextPage ?? false;
    const rightHasNextPage = rightAirportsResult?.airports.pageInfo.hasNextPage ?? false;

    return (
        <>
            <Typography component="h2" variant="h2">
                {title}
            </Typography>

            <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.root}
            >
                <Grid item xs>
                    <TransferListSide
                        title={choicesHeading}
                        loading={leftLoading}
                        items={leftAirports.map(createListItem)}
                        totalItems={(() => {
                            if (selected === 'ALL' || !leftAirportsResult) {
                                return 0;
                            }

                            if (!leftHasNextPage) {
                                return leftAirports.length;
                            }

                            return leftSearching
                                ? leftAirportsResult.airports.totalCount
                                : leftAirportsResult.airports.totalCount - selected.length;
                        })()}
                        checked={createChecked(leftChecked)}
                        setChecked={checked => {
                            setLeftChecked(
                                checked === 'ALL' && !leftHasNextPage
                                    ? leftAirports
                                    : revertChecked(checked)
                            );
                        }}
                        canCheckAll={!leftSearching || !leftHasNextPage}
                        search={leftSearch}
                        onSearch={value => {
                            setLeftSearch(value);
                            setLeftChecked([]);
                        }}
                        searching={leftSearching && leftLoading}
                        canLoadMore={selected !== 'ALL' && leftHasNextPage && !leftLoading}
                        onLoadMore={() => {
                            const endCursor = leftAirportsResult
                                ? leftAirportsResult.airports.pageInfo.endCursor
                                : null;

                            if (endCursor === null) {
                                throw new Error('End cursor not available');
                            }

                            loadMoreAirportsLeft(endCursor);
                        }}
                    />
                </Grid>

                <Grid item xs={2}>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={() => {
                                onChange(
                                    leftChecked === 'ALL' || selected === 'ALL'
                                        ? 'ALL'
                                        : selected.concat(leftChecked)
                                );

                                setLeftChecked([]);
                                setLeftSearch('');
                                setRightSearch('');
                            }}
                            disabled={leftChecked.length === 0}
                            aria-label={t('components.transferList.aria.moveSelectedRight')}
                        >
                            &gt;
                        </Button>

                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={() => {
                                const newSelected = ((): Airport[] | 'ALL' => {
                                    if (rightChecked === 'ALL') {
                                        return [];
                                    }

                                    if (selected === 'ALL') {
                                        throw new Error('Excluding specific airports from ALL is not supported');
                                    }

                                    return excludeAirports(selected, rightChecked);
                                })();

                                onChange(newSelected);
                                setRightChecked([]);
                                setLeftSearch('');
                                setRightSearch('');
                            }}
                            disabled={rightChecked.length === 0}
                            aria-label={t('components.transferList.aria.moveSelectedLeft')}
                        >
                            &lt;
                        </Button>
                    </Grid>
                </Grid>

                <Grid item xs>
                    <TransferListSide
                        title={chosenHeading}
                        loading={rightLoading}
                        items={rightAirports.map(createListItem)}
                        totalItems={selected === 'ALL' && rightAirportsResult ? rightAirportsResult.airports.totalCount : rightAirports.length}
                        checked={createChecked(rightChecked)}
                        setChecked={checked => {
                            setRightChecked(revertChecked(checked));
                        }}
                        canCheckAll={selected !== 'ALL' || !rightSearch.trim()}
                        disableIndividualItems={selected === 'ALL'}
                        search={rightSearch}
                        onSearch={value => {
                            setRightSearch(value);
                            setRightChecked([]);
                        }}
                        searching={Boolean(rightSearch.trim()) && rightLoading}
                        canLoadMore={Boolean(selected === 'ALL' && rightHasNextPage && !rightLoading)}
                        onLoadMore={() => {
                            const endCursor = rightAirportsResult
                                ? rightAirportsResult.airports.pageInfo.endCursor
                                : null;

                            if (endCursor === null) {
                                throw new Error('End cursor not available');
                            }

                            loadMoreAirportsRight(endCursor);
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default AirportsSelector;
