import { DateTimeField, DateTimeFieldProps } from '@get-e/react-components';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

const UtcDateTimeField: FunctionComponent<
    Omit<DateTimeFieldProps, 'format' | 'utc'>
> = props => {
    const { t } = useTranslation();

    return (
        <DateTimeField
            {...props}
            utc
            format={t('dateFormat.utcDateTime')}
        />
    );
};

export default UtcDateTimeField;
