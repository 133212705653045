import getEnv from './helpers/getEnv';

const config: {
    apiUrl: string;
    copyrightStartYear: number;
    googleMapsKey: string;
} = {
    apiUrl: getEnv('API_URL'),
    copyrightStartYear: 2015,
    googleMapsKey: getEnv('GOOGLE_MAPS_KEY'),
};

export default config;
