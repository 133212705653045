/* eslint-disable @typescript-eslint/no-shadow */
import { Alert, Modal, PrimaryButton, SecondaryButton } from '@get-e/react-components';
import { makeStyles, Typography } from '@material-ui/core';
import { ApolloError } from 'apollo-client';
import React, { FunctionComponent, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import getValue from '../../helpers/getValue';
import report from '../../helpers/report';
import FormError from '../../helpers/validation/FormError';
import getFormErrorMessage from '../../helpers/validation/getFormErrorMessage';
import useFormStyles from '../../styles/useFormStyles';
import useModalStyles from '../../styles/useModalStyles';
import {
    CompleteDisruptionInput,
    CompleteDisruptionResult,
    COMPLETE_DISRUPTION,
} from './CompleteDisruptionDialog.graphql';
import disruptionErrorMessages, { DisruptionError } from './disruptionErrorMessages';

const useStyles = makeStyles({ alert: { marginTop: '1rem' } });

export enum SupportedErrorEnums {
    FormError,
    DisruptionError
}

export type ErrorType = {
    type: SupportedErrorEnums.FormError;
    error: FormError;
} | {
    type: SupportedErrorEnums.DisruptionError;
    error: DisruptionError;
};

const CompleteDisruptionDialog: FunctionComponent<{
    disruptionId: string;
    canComplete: boolean;
    onCompleted: () => void;
    onClose: () => void;
}> = ({ disruptionId, onCompleted, onClose, canComplete }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const modalClasses = useModalStyles();
    const formClasses = useFormStyles();

    const [
        completeDisruption,
        { loading: submitting },
    ] = useMutation<CompleteDisruptionResult, CompleteDisruptionInput>(
        COMPLETE_DISRUPTION,
    );

    const [formError, setFormError] = useState<ErrorType | null>(null);

    async function submit(): Promise<void> {
        try {
            await completeDisruption({ variables: { input: { disruptionId } } });
            onCompleted();
            onClose();
        } catch (error) {
            if (!(error instanceof ApolloError)) {
                report(error);
                return;
            }

            if (error.networkError) {
                setFormError({
                    type: SupportedErrorEnums.FormError,
                    error: FormError.UnexpectedError,
                });
            }

            if (error.graphQLErrors) {
                for (const graphQLError of error.graphQLErrors) {
                    switch (graphQLError.extensions?.code) {
                        case 'DISRUPTION_CANCELLED':
                            setFormError({
                                type: SupportedErrorEnums.DisruptionError,
                                error: DisruptionError.DisruptionCancelled,
                            });
                            break;
                        case 'DISRUPTION_COMPLETED':
                            setFormError({
                                type: SupportedErrorEnums.DisruptionError,
                                error: DisruptionError.DisruptionCompleted,
                            });
                            break;
                        default:
                            setFormError({
                                type: SupportedErrorEnums.FormError,
                                error: FormError.UnexpectedError,
                            });
                    }
                }
            }
        }
    }

    return (
        <Modal
            open={true}
            onClose={onClose}
        >
            <div className={modalClasses.header}>
                <Typography variant="h2" component="h2" className={modalClasses.heading}>
                    {t('pages.disruption.completeDialog.title')}
                </Typography>
            </div>

            <p>
                {t('pages.disruption.completeDialog.description')}
            </p>

            <Alert severity="warning" className={classes.alert}>
                {canComplete
                    ? t('pages.disruption.completeDialog.warning')
                    : t('pages.disruption.completeDialog.cantComplete')
                }
            </Alert>

            {
                formError
                    ? (
                        <Alert severity="error" className={formClasses.mainError}>
                            {getValue(() => {
                                if (formError.type === SupportedErrorEnums.FormError) {
                                    return getFormErrorMessage(formError.error, t);
                                }

                                return disruptionErrorMessages(formError.error, t);
                            })}
                        </Alert>
                    )
                    : null
            }

            <div className={modalClasses.buttonContainer}>
                <span className={modalClasses.primaryButton}>
                    <PrimaryButton
                        onClick={submit}
                        loading={submitting}
                        disabled={!canComplete}
                    >
                        {t('pages.disruption.completeDialog.confirmButton')}
                    </PrimaryButton>
                </span>

                <SecondaryButton
                    onClick={onClose}
                    disabled={submitting}
                >
                    {t('buttons.cancel')}
                </SecondaryButton>
            </div>
        </Modal>
    );
};

export default CompleteDisruptionDialog;
