import {
    TextField, Form,
    PrimaryButton, SecondaryButton, Heading, Alert,
} from '@get-e/react-components';
import { makeStyles, Grid, Link } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { useMutation } from 'react-apollo';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import PhoneNumberField from '../../../components/PhoneNumberField';
import report from '../../../helpers/report';
import allValid from '../../../helpers/validation/allValid';
import FormError from '../../../helpers/validation/FormError';
import getHelperText from '../../../helpers/validation/getHelperText';
import getInputError from '../../../helpers/validation/getInputError';
import InputError from '../../../helpers/validation/InputError';
import isFilledString from '../../../helpers/validation/validators/isFilledString';
import useFormStyles from '../../../styles/useFormStyles';
import { CreateDriverInput, CreateDriverResult, CREATE_DRIVER } from './Content.graphql';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
    row: { '&:hover': { cursor: 'pointer' } },
    form: { marginBottom: '2em' },
    faqBlock: { marginBottom: '1.5em' },
});

const Content: FunctionComponent<{ supplierId: string }> = ({ supplierId }) => {
    const { t } = useTranslation();
    const formClasses = useFormStyles();
    const classes = useStyles();
    const history = useHistory();

    const [name, setName] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');

    const [formError, setFormError] = useState<FormError | null>(null);
    const [nameError, setNameError] = useState<InputError | null>(null);
    const [phoneNumberError, setPhoneNumberError] = useState<InputError | null>(null);

    const [
        createDriver,
        { loading: submitting },
    ] = useMutation<CreateDriverResult, CreateDriverInput>(
        CREATE_DRIVER
    );

    const formErrorElement = (() => {
        if (formError === null) {
            return null;
        }

        const message = (() => {
            switch (formError) {
                case FormError.Unauthorized:
                    return t('errors.forms.unauthorized');
                case FormError.UnexpectedError:
                    return t('errors.forms.unexpected');
                case FormError.UserError:
                    return t('errors.forms.userError');
                default:
                    report(new Error('Unhandled form error'));
                    return t('errors.forms.unexpected');
            }
        })();

        return (
            <Alert severity="error" className={formClasses.mainErrorLimitedWidth}>
                {message}
            </Alert>
        );
    })();

    async function submitForm(): Promise<void> {
        const validated = {
            name: isFilledString(name, InputError.Empty),
            phoneNumber: isFilledString(phoneNumber, InputError.Empty),
        };

        if (!allValid(validated)) {
            setFormError(FormError.UserError);

            setNameError(getInputError(validated.name));
            setPhoneNumberError(getInputError(validated.phoneNumber));

            return;
        }

        setFormError(null);

        try {
            const result = await createDriver({
                variables: {
                    input: {
                        supplierId,
                        name: validated.name.value,
                        phoneNumber: validated.phoneNumber.value,
                    },
                },
            });

            if (!result.data) {
                throw new Error('Mutation did not have result');
            }

            const { id } = result.data.createDriver;

            history.push(`/suppliers/${supplierId}/drivers`);
        } catch (error) {
            report(error);
            setFormError(FormError.UnexpectedError);
        }
    }

    return (
        <div className={classes.container}>

            <Grid item xs={8}>
                <Grid>
                    <Heading level={1}>
                        {t('pages.drivers.headings.addDriver')}
                    </Heading>
                    <Form
                        className={classes.form}
                        onSubmit={() => submitForm()}
                    >
                        <Grid container>
                            <TextField
                                value={name}
                                onChange={event => {
                                    setName(event.target.value);
                                    setNameError(null);
                                }}
                                helperText={getHelperText(nameError, t)}
                                error={nameError !== null}
                                label={t('pages.drivers.labels.name')}
                                required
                            />
                        </Grid>
                        <Grid container>
                            <PhoneNumberField
                                value={phoneNumber}
                                onChange={value => {
                                    setPhoneNumber(value);
                                    setPhoneNumberError(null);
                                }}
                                helperText={getHelperText(phoneNumberError, t)}
                                error={phoneNumberError !== null}
                                label={t('pages.drivers.labels.mobile')}
                                required
                            />
                        </Grid>

                        {formErrorElement}

                        <div className={formClasses.buttons}>
                            <PrimaryButton
                                loading={submitting}
                                onClick={submitForm}
                            >
                                {t('buttons.add')}
                            </PrimaryButton>
                            <SecondaryButton
                                onClick={
                                    () => history.push(`/suppliers/${supplierId}/drivers`)
                                }
                                disabled={submitting}
                            >
                                {t('buttons.cancel')}
                            </SecondaryButton>
                        </div>
                    </Form>
                    <Grid>
                        <Heading level={2}>
                            {t('pages.drivers.faq.faqHeading')}
                        </Heading>
                        <div className={classes.faqBlock}>
                            <Heading level={3}>
                                {t('pages.drivers.faq.addDriver')}
                            </Heading>
                            <div>
                                <Trans i18nKey="pages.drivers.faq.addDriverText">
                                    <Link
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://play.google.com/store/apps/details?id=com.gete.driverapp"
                                    />
                                    <Link
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://apps.apple.com/app/get-e-driver/id1424380150?l=en"
                                    />
                                </Trans>
                                <p>{t('pages.drivers.faq.sharing')}</p>
                            </div>
                        </div>
                        <div className={classes.faqBlock}>
                            <Heading level={3}>
                                {t('pages.drivers.faq.startApp')}
                            </Heading>
                            <div>
                                {t('pages.drivers.faq.startAppText')}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default Content;
