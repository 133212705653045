import gql from 'graphql-tag';

export const CREATE_SUPPLIER = gql`
    mutation createSupplier($input: CreateSupplierInput!) {
        createSupplier(input: $input) {
            id
        }
    }
`;

export interface CreateSupplierInput {
    input: {
        name: string;
        address?: string;
        procedure?: string;
        vatRegistrationNumber?: string;
        cocRegistrationNumber?: string;
        dispatchEmails?: string[];
        receivableEmails?: string[];
        receivablePhoneNumbers?: Array<{
            phoneNumber: string;
        }>;
        dispatchPhoneNumbers?: Array<{
            phoneNumber: string;
        }>;
        servicedAirportIds: string[] | null;
        countryId: string;
        languageId: string;
    };
}

export interface CreateSupplierResult {
    createSupplier: {
        id: string;
    };
}
