import { Tooltip } from '@material-ui/core';
import moment, { Moment } from 'moment';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocaleContext } from '../context/LocaleContext';
import formatRelativeTime from '../helpers/formatRelativeTime';
import getValue from '../helpers/getValue';

const UtcDate: FunctionComponent<{
    date: Moment;
    showYear?: boolean;
    hideSuffix?: boolean;
    shortMonth?: boolean;
}> = ({ date, showYear: showYearProp, hideSuffix, shortMonth }) => {
    const { locale } = useLocaleContext();
    const { t } = useTranslation();

    const showYear = getValue(() => {
        if (typeof showYearProp === 'boolean') {
            return showYearProp;
        }

        return !date.isBetween(
            moment().subtract(1, 'year'),
            moment().add(1, 'year'),
        );
    });

    return (
        <Tooltip
            title={getValue(() => {
                const relativeTime = formatRelativeTime(date, locale, t);

                return (
                    <>
                        {relativeTime}
                        <br />
                        {date.utc().format(t('dateFormat.utcDateTimeFull'))}
                    </>
                );
            })}
        >
            <span>
                {
                    date.utc().format(
                        getValue(() => {
                            if (showYear) {
                                return shortMonth
                                    ? t('dateFormat.dateShortMonth')
                                    : t('dateFormat.date');
                            }

                            return shortMonth
                                ? t('dateFormat.dateShortMonthWithoutYear')
                                : t('dateFormat.dateWithoutYear');
                        })
                    )
                }

                {hideSuffix ? null : ' UTC'}
            </span>
        </Tooltip>
    );
};

export default UtcDate;
