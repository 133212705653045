enum InputError {

    // TODO: Rename to REQUIRED
    Empty,
    InvalidEmail,
    NoMatch,
    InvalidPassword,
}

export default InputError;
