import { Alert, Form, LoadingPage, PrimaryButton, SecondaryButton, Select, SelectOption, TextField } from '@get-e/react-components';
import { Grid, Typography } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Airport } from '../../../../components/AirportsSelector';
import AirportsWhitelistField from '../../../../components/AirportsWhitelistField';
import report from '../../../../helpers/report';
import useLoaderDebounce from '../../../../helpers/useLoaderDebounce';
import allValid from '../../../../helpers/validation/allValid';
import FormError from '../../../../helpers/validation/FormError';
import getFormErrorMessage from '../../../../helpers/validation/getFormErrorMessage';
import getHelperText from '../../../../helpers/validation/getHelperText';
import getInputError from '../../../../helpers/validation/getInputError';
import InputError from '../../../../helpers/validation/InputError';
import isFilledArray from '../../../../helpers/validation/validators/isFilledArray';
import isFilledString from '../../../../helpers/validation/validators/isFilledString';
import isNotNull from '../../../../helpers/validation/validators/isNotNull';
import isNull from '../../../../helpers/validation/validators/isNull';
import or from '../../../../helpers/validation/validators/or';
import useFormStyles from '../../../../styles/useFormStyles';
import { GetCustomerRolesInput, GetCustomerRolesResult, GET_CUSTOMER_ROLES } from '../GetCustomerRoles.graphql';
import { InviteCustomerUserInput, InviteCustomerUserResult, INVITE_CUSTOMER_USER } from './Content.graphql';

const InviteUserForm: FunctionComponent<{ customerId: string }> = ({ customerId }) => {
    const formClasses = useFormStyles();
    const { t } = useTranslation();
    const history = useHistory();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [roleId, setRoleId] = useState<string | null>(null);
    const [airportsWhitelist, setAirportsWhitelist] = useState<Airport[] | null>([]);

    const [nameError, setNameError] = useState<InputError | null>(null);
    const [emailError, setEmailError] = useState<InputError | null>(null);
    const [roleError, setRoleError] = useState<InputError | null>(null);

    const [
        airportsWhitelistError,
        setAirportsWhitelistError,
    ] = useState<InputError | null>(null);

    const [formError, setFormError] = useState<FormError | null>(null);

    const [
        inviteUser,
        { loading: submitting },
    ] = useMutation<InviteCustomerUserResult, InviteCustomerUserInput>(
        INVITE_CUSTOMER_USER
    );

    const {
        data: rolesResult,
        loading: rolesLoading,
        error: rolesError,
    } = useQuery<GetCustomerRolesResult, GetCustomerRolesInput>(GET_CUSTOMER_ROLES, {
        variables: { customerId },
        onError(apolloError) {
            report(apolloError);
        },
    });

    const showLoader = useLoaderDebounce(rolesLoading);

    if (rolesLoading) {
        return showLoader
            ? <LoadingPage />
            : null;
    }

    if (rolesError || !rolesResult) {
        // TODO
        return <>Could not load roles</>;
    }

    if (!rolesResult.customer) {
        // TODO: Handle
        return <>Customer does not exist</>;
    }

    if (rolesResult.customer.roles.pageInfo.hasNextPage) {
        throw new Error('Too many roles');
    }

    const roles = rolesResult.customer.roles.nodes;

    if (roles.length === 0) {
        throw new Error('No roles available');
    }

    async function handleSubmitButtonClick(): Promise<void> {
        const validated = {
            name: isFilledString(name, InputError.Empty),
            email: isFilledString(email, InputError.Empty),
            roleId: isNotNull(roleId, InputError.Empty),
            airportsWhitelist: or(
                isNull(airportsWhitelist, InputError.Empty),
                () => isFilledArray(airportsWhitelist, InputError.Empty),
            ),
        };

        if (!allValid(validated)) {
            setFormError(FormError.UserError);

            setNameError(getInputError(validated.name));
            setEmailError(getInputError(validated.email));
            setRoleError(getInputError(validated.roleId));
            setAirportsWhitelistError(getInputError(validated.airportsWhitelist));

            return;
        }

        try {
            await inviteUser({
                variables: {
                    name: validated.name.value,
                    email: validated.email.value,
                    roleId: validated.roleId.value,
                    airportsWhitelist: airportsWhitelist === null
                        ? null
                        : airportsWhitelist.map(airport => airport.id),
                },
            });

            history.push(`/customers/${customerId}/invites`);
        } catch (apolloError) {
            // TODO: Handle input errors
            report(apolloError);
            setFormError(FormError.UnexpectedError);
        }
    }

    const formErrorElement = (() => {
        if (formError === null) {
            return null;
        }

        return (
            <Alert severity="error" className={formClasses.mainError}>
                {getFormErrorMessage(formError, t)}
            </Alert>
        );
    })();

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
        >
            <Grid item xs={12} md={6}>
                <Typography
                    variant="h2"
                    component="h2"
                >
                    {t('pages.inviteCustomerUser.heading')}
                </Typography>

                <Form
                    autoComplete="off"
                    className={formClasses.form}
                    noValidate
                    onSubmit={() => handleSubmitButtonClick()}
                >
                    <TextField
                        value={name}
                        onChange={event => {
                            setName(event.target.value);
                            setNameError(null);
                        }}
                        error={nameError !== null}
                        helperText={getHelperText(nameError, t)}
                        label={t('pages.users.fields.name')}
                        required
                    />

                    <TextField
                        value={email}
                        onChange={event => {
                            setEmail(event.target.value);
                            setEmailError(null);
                        }}
                        error={emailError !== null}
                        helperText={getHelperText(emailError, t)}
                        inputProps={{ autoCapitalize: 'none' }}
                        label={t('pages.users.fields.email')}
                        type="email"
                        required
                    />

                    <Select
                        label={t('pages.editCustomerUser.fields.role')}
                        value={roleId ?? ''}
                        onChange={newRoleId => {
                            setRoleId(newRoleId);
                            setRoleError(null);
                        }}
                        error={roleError !== null}
                        required
                        helperText={getHelperText(roleError, t)}
                    >
                        <SelectOption value="" empty>
                            {t('fields.emptySelectValue')}
                        </SelectOption>

                        {roles.map(role => (
                            <SelectOption key={role.id} value={role.id}>
                                {role.name}
                            </SelectOption>
                        ))}
                    </Select>

                    <AirportsWhitelistField
                        label={
                            t('pages.inviteCustomerUser.fields.airportsWhitelist.label')
                        }
                        modalTitle={
                            t('pages.inviteCustomerUser.fields.airportsWhitelist'
                                + '.modal.title')}
                        choicesListHeading={
                            t('pages.inviteCustomerUser.fields.airportsWhitelist'
                                + '.modal.choices')}
                        chosenListHeading={
                            t('pages.inviteCustomerUser.fields.airportsWhitelist'
                                + '.modal.chosen')}
                        values={airportsWhitelist}
                        onChange={newValue => {
                            setAirportsWhitelist(newValue);
                            setAirportsWhitelistError(null);
                        }}
                        error={airportsWhitelistError}
                    />

                    {formErrorElement}

                    <div className={formClasses.buttons}>
                        <PrimaryButton
                            loading={submitting}
                            onClick={event => {
                                event.preventDefault();

                                // eslint-disable-next-line max-len
                                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                                handleSubmitButtonClick();
                            }}
                        >
                            {t('pages.inviteCustomerUser.buttons.submit')}
                        </PrimaryButton>

                        <SecondaryButton
                            onClick={() => history.push(`/customers/${customerId}/users`)}
                            disabled={submitting}
                        >
                            {t('buttons.cancel')}
                        </SecondaryButton>
                    </div>
                </Form>
            </Grid>
        </Grid>
    );
};

export default InviteUserForm;
