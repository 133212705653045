interface MopinionMetadata {
    name: string | null,
    email: string | null,
    accountType: string | null,
}

declare global {
    interface Window {
        metadata: MopinionMetadata;
    }
}

export default (metadata: MopinionMetadata): void => {
    window.metadata = metadata
}