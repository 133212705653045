import driverSvg from './driver.svg';

export default function createDriverIconUrl(
    { rotationDegrees, sizePixels }: {
        rotationDegrees: number;
        sizePixels: number;
    }
): Promise<string> {
    const image = new Image();

    image.src = driverSvg;

    const canvas = document.createElement('canvas');

    canvas.width = sizePixels;
    canvas.height = sizePixels;

    const context = canvas.getContext('2d');

    if (context === null) {
        throw new Error('Context should not be null');
    }

    const angle = rotationDegrees * Math.PI / 180;
    const center = sizePixels / 2;

    context.clearRect(0, 0, sizePixels, sizePixels);
    context.save();

    context.translate(center, center);
    context.rotate(angle);
    context.translate(-center, -center);

    return new Promise(resolve => {
        image.onload = () => {
            context.drawImage(image, 0, 0);

            resolve(canvas.toDataURL());
        };
    });
}
