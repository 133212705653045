import gql from 'graphql-tag';

export const UPDATE_AVAILABILITY = gql`
    mutation updateRideAvailability($input: UpdateRideAvailabilityInput!) {
        updateRideAvailability(input: $input) {
            id
        }
    }
`;

export interface UpdateRideAvailabilityInput {
    input: {
        availabilityId: string;
        estimatedArrivalTimeMinutes: number;
        seats: number;
        description?: string;
    };
}

export interface UpdateRideAvailabilityResult {
    addRideAvailabilityFromRequest: {
        id: string;
    };
}

