// TODO: Split up
/* eslint-disable max-lines */
import {
    AutocompleteChip, Form, Modal,
    PrimaryButton, SecondaryButton, TextField,
} from '@get-e/react-components';
import { FormControl, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AirportsSelector, { Airport } from '../../components/AirportsSelector';
import AirportsWhitelistField from '../../components/AirportsWhitelistField';
import delay from '../../helpers/delay';
import useFormStyles from '../../styles/useFormStyles';
import useModalStyles from '../../styles/useModalStyles';

const useStyles = makeStyles({ buttonContainer: { marginTop: '1rem' } });

interface PreferredSupplier {
    id: string;
    name: string;
    airportAvailabilityCount: number;
    airportsWhitelist: Airport[] | null;
}

interface UnassignedSupplier {
    id: string;
    name: string;
    airportAvailabilityCount: number;
    airportsWhitelist: Airport[] | null;
}

// eslint-disable-next-line max-statements, max-lines-per-function
const DisruptionsTab: FunctionComponent = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [saving, setSaving] = useState(false);

    const [airportsWhitelist, setAirportsWhitelist] = useState<Airport[] | null>(null);
    const [preferredSuppliers, setPreferredSuppliers] = useState<PreferredSupplier[]>([]);
    const [unassignedSuppliers, setUnassignedSuppliers] = useState<UnassignedSupplier[]>([]);

    const submitForm = async (): Promise<void> => {
        setSaving(true);

        await delay(2000);

        // TODO: Show notification

        setSaving(false);
    };

    return (
        <Form onSubmit={() => submitForm()}>
            <div>
                <AirportsWhitelistField
                    modalTitle={t('pages.customer.fields.enabledAirports.modal.title')}
                    choicesListHeading={
                        t('pages.customer.fields.enabledAirports.modal.choices')
                    }
                    chosenListHeading={
                        t('pages.customer.fields.enabledAirports.modal.chosen')
                    }
                    label={t('pages.customer.fields.enabledAirports.label')}
                    values={airportsWhitelist}
                    onChange={setAirportsWhitelist}
                    error={null}
                />

                <PreferredSuppliersField
                    suppliers={preferredSuppliers}
                    onChange={setPreferredSuppliers}
                />

                <UnassignedSuppliersField
                    suppliers={unassignedSuppliers}
                    onChange={setUnassignedSuppliers}
                />
            </div>

            <div className={classes.buttonContainer}>
                <PrimaryButton
                    onClick={() => submitForm()}
                    loading={saving}
                    submitsForm
                >
                    {t('buttons.save')}
                </PrimaryButton>
            </div>
        </Form>
    );
};

// TODO: From API
const preferredSuppliers: PreferredSupplier[] = [
    {
        id: '1',
        name: 'Get-e',
        airportsWhitelist: [],
        airportAvailabilityCount: 123,
    },
];

const PreferredSuppliersField: FunctionComponent<{
    suppliers: PreferredSupplier[];
    onChange: (suppliers: PreferredSupplier[]) => void;
    // eslint-disable-next-line max-lines-per-function
}> = ({ suppliers, onChange }) => {
    const { t } = useTranslation();
    const [editingSupplier, setEditingSupplier] = useState<PreferredSupplier | null>(null);
    const modalClasses = useModalStyles();
    const formClasses = useFormStyles();

    // TODO: Automatically remove suppliers with empty airports whitelist

    return (
        <>
            <Modal
                open={Boolean(editingSupplier)}
                onClose={() => setEditingSupplier(null)}
                maxWidth={false}
            >
                <AirportsSelector
                    title={
                        t(
                            'pages.customer.fields.preferredSuppliers.modal.title',
                            {
                                supplierName: editingSupplier?.name
                                    ?? t('pages.customer.fields.preferredSuppliers.modal.supplierNameFallback'),
                            }
                        )
                    }
                    choicesHeading={t('pages.customer.fields.preferredSuppliers.modal.choices')}
                    chosenHeading={t('pages.customer.fields.preferredSuppliers.modal.chosen')}
                    selected={[]}
                    onChange={newValues => {
                        if (!editingSupplier) {
                            throw new Error('Not editing a supplier');
                        }

                        const unsavedSupplier = { ...editingSupplier };
                        const newWhitelist = newValues === 'ALL' ? null : newValues;

                        unsavedSupplier.airportsWhitelist = newWhitelist;

                        setEditingSupplier(unsavedSupplier);
                    }}
                />

                <div className={modalClasses.buttonContainer}>
                    <span className={modalClasses.primaryButton}>
                        <PrimaryButton
                            onClick={() => {
                                const newSuppliers = suppliers.map(supplier => {
                                    if (editingSupplier === null) {
                                        throw new Error('Not editing a supplier');
                                    }

                                    return supplier.id === editingSupplier.id
                                        ? editingSupplier
                                        : supplier;
                                });

                                onChange(newSuppliers);
                                setEditingSupplier(null);
                            }}
                        >
                            {t('buttons.save')}
                        </PrimaryButton>
                    </span>

                    <SecondaryButton
                        onClick={() => {
                            setEditingSupplier(null);
                        }}
                    >
                        {t('buttons.cancel')}
                    </SecondaryButton>
                </div>
            </Modal>

            <FormControl fullWidth>
                <Autocomplete
                    multiple
                    options={preferredSuppliers}
                    value={suppliers}
                    onChange={(_event, newValues, reason, details) => {
                        onChange(newValues);

                        if (reason === 'select-option') {
                            if (!details?.option) {
                                throw new Error('Selection option not available');
                            }

                            setEditingSupplier(details.option);
                        }
                    }}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                        <TextField
                            {...params}
                            required
                            label={t('pages.customer.fields.preferredSuppliers.label')}
                            className={formClasses.autocompleteInput}
                            helperText={
                                t('pages.customer.fields.preferredSuppliers.helperText')
                            }
                            fixedHelperText
                        />
                    )}
                    renderTags={
                        (value, getTagProps) => value.map(
                            (option, index) => {
                                const label = option.airportsWhitelist === null
                                    ? `${option.name} `
                                    + `(all ${option.airportAvailabilityCount} airports)`
                                    : `${option.name} `
                                    + `(${option.airportsWhitelist.length}/${option.airportAvailabilityCount} airports)`;

                                return (
                                    <AutocompleteChip
                                        key={option.id}
                                        label={label}
                                        {...getTagProps({ index })}
                                        clickable
                                        onClick={() => setEditingSupplier(option)}
                                    />
                                );
                            }
                        )
                    }
                />
            </FormControl>
        </>
    );
};

// TODO: From API
const unassignedSuppliers: UnassignedSupplier[] = [
    {
        id: '1',
        name: 'Example supplier',
        airportsWhitelist: [],
        airportAvailabilityCount: 123,
    },
];

const UnassignedSuppliersField: FunctionComponent<{
    suppliers: UnassignedSupplier[];
    onChange: (suppliers: UnassignedSupplier[]) => void;
    // eslint-disable-next-line max-lines-per-function
}> = ({ suppliers, onChange }) => {
    const { t } = useTranslation();
    const [editingSupplier, setEditingSupplier] = useState<UnassignedSupplier | null>(null);
    const modalClasses = useModalStyles();
    const formClasses = useFormStyles();

    // TODO: Automatically remove suppliers with empty airports whitelist

    return (
        <>
            <Modal
                open={Boolean(editingSupplier)}
                onClose={() => setEditingSupplier(null)}
                maxWidth={false}
            >
                <AirportsSelector
                    title={
                        t(
                            'pages.customer.fields.unassignedSuppliers.modal.title',
                            {
                                supplierName: editingSupplier?.name
                                    ?? t(
                                        'pages.customer.fields.unassignedSuppliers'
                                        + '.modal.supplierNameFallback'
                                    ),
                            }
                        )
                    }
                    choicesHeading={t(
                        'pages.customer.fields.unassignedSuppliers'
                        + '.modal.choices'
                    )}
                    chosenHeading={
                        t('pages.customer.fields.unassignedSuppliers'
                            + '.modal.chosen')
                    }
                    selected={[]}
                    onChange={newValues => {
                        if (!editingSupplier) {
                            throw new Error('Not editing a supplier');
                        }

                        const unsavedSupplier = { ...editingSupplier };
                        const newWhitelist = newValues === 'ALL' ? null : newValues;

                        unsavedSupplier.airportsWhitelist = newWhitelist;

                        setEditingSupplier(unsavedSupplier);
                    }}
                />

                <div className={modalClasses.buttonContainer}>
                    <span className={modalClasses.primaryButton}>
                        <PrimaryButton
                            onClick={() => {
                                const newSuppliers = suppliers.map(supplier => {
                                    if (editingSupplier === null) {
                                        throw new Error('Not editing a supplier');
                                    }

                                    return supplier.id === editingSupplier.id
                                        ? editingSupplier
                                        : supplier;
                                });

                                onChange(newSuppliers);
                                setEditingSupplier(null);
                            }}
                        >
                            {t('buttons.save')}
                        </PrimaryButton>
                    </span>

                    <SecondaryButton
                        onClick={() => {
                            setEditingSupplier(null);
                        }}
                    >
                        {t('buttons.cancel')}
                    </SecondaryButton>
                </div>
            </Modal>

            <FormControl fullWidth>
                <Autocomplete
                    multiple
                    options={unassignedSuppliers}
                    value={suppliers}
                    onChange={(_event, newValues, reason, details) => {
                        onChange(newValues);

                        if (reason === 'select-option') {
                            if (!details?.option) {
                                throw new Error('Selection option not available');
                            }

                            setEditingSupplier(details.option);
                        }
                    }}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                        <TextField
                            {...params}
                            required
                            label={t('pages.customer.fields.unassignedSuppliers.label')}
                            className={formClasses.autocompleteInput}
                            helperText={
                                t('pages.customer.fields.unassignedSuppliers.helperText')
                            }
                            fixedHelperText
                        />
                    )}
                    renderTags={
                        (value, getTagProps) => value.map(
                            (option, index) => {
                                const label = option.airportsWhitelist === null
                                    ? option.name
                                    : `${option.name} (${option.airportsWhitelist.length}`
                                    + `/${option.airportAvailabilityCount} airports)`;

                                return (
                                    <AutocompleteChip
                                        key={option.id}
                                        label={label}
                                        {...getTagProps({ index })}
                                        clickable
                                        onClick={() => setEditingSupplier(option)}
                                    />
                                );
                            }
                        )
                    }
                />
            </FormControl>
        </>
    );
};

export default DisruptionsTab;
