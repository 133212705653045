/* eslint-disable max-lines */
/* eslint-disable max-depth */
/* eslint-disable @typescript-eslint/no-shadow */
import {
    Alert,
    Form, Heading, PrimaryButton,
    SignedOutLayout, TextField,
} from '@get-e/react-components';
import { IconButton, InputAdornment, makeStyles, Paper } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { ApolloError } from 'apollo-client';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { ExecutionResult, useApolloClient, useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { tryDecodeBase64 } from '../helpers/base64';
import getProperty from '../helpers/getProperty';
import getValue from '../helpers/getValue';
import isArray from '../helpers/isArray';
import report from '../helpers/report';
import useUrlQuery from '../helpers/useUrlQuery';
import allValid from '../helpers/validation/allValid';
import FormError from '../helpers/validation/FormError';
import getFormErrorMessage from '../helpers/validation/getFormErrorMessage';
import getHelperText from '../helpers/validation/getHelperText';
import getInputError from '../helpers/validation/getInputError';
import InputError from '../helpers/validation/InputError';
import isNotNull from '../helpers/validation/validators/isNotNull';
import {
    AcceptCustomerUserInviteInput,
    AcceptCustomerUserInviteResult,
    ACCEPT_CUSTOMER_USER_INVITE,
} from '../queries/AcceptCustomerUser.graphql';
import {
    AcceptSupplierUserInviteInput,
    AcceptSupplierUserInviteResult,
    ACCEPT_SUPPLIER_USER_INVITE,
} from '../queries/AcceptSupplierUser.graphql';
import { SetPasswordInput, SetPasswordResult, SET_PASSWORD } from '../queries/SetPassword.graphql';
import signIn from '../services/signIn';

enum PageError {
    NoToken,
    NoType,
    NotFound
}

const useStyles = makeStyles({
    container: {
        padding: '2em',
        maxWidth: '320px',
        margin: '0 auto',
    },
    signInButtonContainer: { marginTop: '2rem' },

});

const SetPassword: FunctionComponent = () => {
    const query = useUrlQuery();
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const apolloClient = useApolloClient();
    const encodedToken = query.get('token');
    const token = encodedToken ? tryDecodeBase64(encodedToken) : null;
    const type = query.get('type');

    const autoFocusRef = useRef<HTMLInputElement>();

    useEffect(() => {
        autoFocusRef.current?.focus();
    }, [autoFocusRef]);

    const [showingPassword, setShowingPassword] = useState(false);
    const [showingConfirmPassword, setShowingConfirmPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [formError, setFormError] = useState<FormError | null>(null);
    const [pageError, setPageError] = useState<PageError | null>(null);

    const [
        passwordError,
        setPasswordError,
    ] = useState<InputError | null>(null);

    const [
        confirmPasswordError,
        setConfirmPasswordError,
    ] = useState<InputError | null>(null);

    const [
        acceptSupplierUserInvite,
        { loading: submittingSupplier },
    ] = useMutation<AcceptSupplierUserInviteResult, AcceptSupplierUserInviteInput>(
        ACCEPT_SUPPLIER_USER_INVITE
    );

    const [
        acceptCustomerUserInvite,
        { loading: submittingCustomer },
    ] = useMutation<AcceptCustomerUserInviteResult, AcceptCustomerUserInviteInput>(
        ACCEPT_CUSTOMER_USER_INVITE
    );

    const [
        setPasswordMutation,
        { loading: settingPassword },
    ] = useMutation<SetPasswordResult, SetPasswordInput>(
        SET_PASSWORD
    );

    useEffect(() => {
        if (!token) {
            setPageError(PageError.NoToken);
        }

        if (!type) {
            setPageError(PageError.NoType);
        }
    }, [token, type]);

    // eslint-disable-next-line max-statements
    async function submitForm(): Promise<void> {
        if (password.trim() !== confirmPassword.trim()) {
            setConfirmPasswordError(InputError.NoMatch);
            return;
        }

        const validated = {
            password: isNotNull(password, InputError.Empty),
            token: isNotNull(token, PageError.NoToken),
        };

        if (!allValid(validated)) {
            setPasswordError(getInputError(validated.password));
            return;
        }

        setFormError(null);
        setPageError(null);

        let result:
        ExecutionResult<AcceptSupplierUserInviteResult>
        | ExecutionResult<AcceptCustomerUserInviteResult>
        | ExecutionResult<SetPasswordResult>
        | null = null;

        try {
            switch (type) {
                case 'supplier': {
                    result = await acceptSupplierUserInvite({
                        variables: {
                            input: {
                                token: validated.token.value,
                                password: validated.password.value,
                            },
                        },
                    });

                    if (!result?.data) {
                        throw new Error('Mutation has no result');
                    }

                    const { email } = result.data.acceptSupplierUserInvite;

                    await handleSignIn(email, validated.password.value);
                    return;
                }

                case 'customer': {
                    result = await acceptCustomerUserInvite({
                        variables: {
                            input: {
                                token: validated.token.value,
                                password: validated.password.value,
                            },
                        },
                    });

                    if (!result?.data) {
                        throw new Error('Mutation has no result');
                    }

                    const { email } = result.data.acceptCustomerUserInvite;

                    await handleSignIn(email, validated.password.value);
                    return;
                }

                case 'reset': {
                    result = await setPasswordMutation({
                        variables: {
                            input: {
                                token: validated.token.value,
                                password: validated.password.value,
                            },
                        },
                    });

                    if (!result?.data) {
                        throw new Error('Mutation has no result');
                    }

                    const { email } = result.data.setPassword;

                    await handleSignIn(email, validated.password.value);
                    return;
                }

                default: {
                    setPageError(PageError.NoType);
                    return;
                }
            }
        } catch (error) {
            if (!(error instanceof ApolloError)) {
                report(error);
                return;
            }

            if (error.graphQLErrors) {
                for (const graphQLError of error.graphQLErrors) {
                    switch (graphQLError.extensions?.code) {
                        case 'INVALID_INPUT': {
                            const tokenErrors
                                = getProperty(graphQLError
                                    .extensions, 'inputErrors', 'input', 'token');

                            let notFound = false;

                            if (isArray(tokenErrors)) {
                                notFound
                                    = tokenErrors.some(
                                        gqlErr => getProperty(gqlErr, 'code') === 'NOT_FOUND'
                                    );
                            }

                            if (notFound) {
                                setPageError(PageError.NotFound);
                                break;
                            }

                            setPasswordError(InputError.InvalidPassword);
                            break;
                        }

                        default:
                            setFormError(FormError.UnexpectedError);
                    }
                }
            }
        }
    }

    async function handleSignIn(email: string, internalPassword: string): Promise<void> {
        try {
            const result = await signIn({
                email,
                password: internalPassword,
                apolloClient,
            });

            switch (result.code) {
                case 'COOKIES_SET':
                    history.push('/');
                    break;
                default:
                    throw new Error('Unexpected result code');
            }
        } catch (error) {
            report(error);
            setFormError(FormError.UnexpectedError);
        }
    }

    return (
        <SignedOutLayout>
            <Paper elevation={0} className={classes.container}>
                {pageError === null
                    ? (
                        <Form onSubmit={submitForm}>
                            <Heading level={2}>
                                {t('pages.setPassword.heading')}
                            </Heading>

                            <TextField
                                value={password}
                                onChange={event => setPassword(event.target.value)}
                                label={t('pages.setPassword.inputs.password')}
                                type={showingPassword ? 'text' : 'password'}
                                autoComplete="new-password"
                                autoFocus
                                name="password"
                                error={passwordError !== null}
                                helperText={getHelperText(passwordError, t)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            aria-label={
                                                showingPassword
                                                    ? t('pages.signIn.aria.hidePasswordButton')
                                                    : t('pages.signIn.aria.showPasswordButton')
                                            }
                                            onClick={() => setShowingPassword(!showingPassword)}
                                            onMouseDown={event => event.preventDefault()}
                                        >
                                            {showingPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                                inputRef={autoFocusRef}
                                required
                            />

                            <TextField
                                value={confirmPassword}
                                onChange={event => {
                                    setConfirmPassword(event.target.value);
                                    setConfirmPasswordError(null);
                                }}
                                label={t('pages.setPassword.inputs.confirmPassword')}
                                type={showingConfirmPassword ? 'text' : 'password'}
                                autoComplete="new-password"
                                name="confirmPassword"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            aria-label={
                                                showingConfirmPassword
                                                    ? t('pages.signIn.aria.hidePasswordButton')
                                                    : t('pages.signIn.aria.showPasswordButton')
                                            }
                                            onClick={() => (
                                                setShowingConfirmPassword(!showingConfirmPassword)
                                            )}
                                            onMouseDown={event => event.preventDefault()}
                                        >
                                            {showingConfirmPassword
                                                ? <Visibility />
                                                : <VisibilityOff />
                                            }
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                                error={confirmPasswordError !== null}
                                helperText={getHelperText(confirmPasswordError, t)}
                                required
                            />

                            <p>
                                {t('pages.setPassword.passwordRequirements')}
                            </p>

                            {
                                formError === null
                                    ? null
                                    : (
                                        <Alert
                                            severity="error"
                                        >
                                            {getFormErrorMessage(formError, t)}
                                        </Alert>
                                    )
                            }

                            <div className={classes.signInButtonContainer}>
                                <PrimaryButton
                                    onClick={() => submitForm()}
                                    loading={
                                        submittingSupplier
                                        || submittingCustomer
                                        || settingPassword
                                    }
                                    fullWidth
                                    submitsForm
                                >
                                    {t('pages.setPassword.inputs.setPassword')}
                                </PrimaryButton>
                            </div>
                        </Form>
                    )
                    : (
                        <>
                            <strong>
                                {
                                    getValue(() => {
                                        switch (pageError) {
                                            case PageError.NotFound:
                                                return t(
                                                    'pages.setPassword.errors.notFound'
                                                );
                                            case PageError.NoType:
                                            case PageError.NoToken:
                                                return t(
                                                    'pages.setPassword.errors.wrongLink'
                                                );
                                            default:
                                                return t(
                                                    'pages.setPassword.errors.default'
                                                );
                                        }
                                    })
                                }
                            </strong>
                            <p>{t('pages.setPassword.errors.contact')}</p>
                        </>
                    )
                }

            </Paper>
        </SignedOutLayout>
    );
};

export default SetPassword;
