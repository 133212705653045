export function encodeBase64(value: string): string {
    return btoa(value);
}

export function tryDecodeBase64(value: string): string|null {
    try {
        return atob(value);
    } catch (error: unknown) {
        if (error instanceof Error && error.name === 'InvalidCharacterError') {
            return null;
        }

        throw error;
    }
}

export function decodeBase64(value: string): string {
    const decoded = tryDecodeBase64(value);

    if (decoded === null) {
        throw new Error('Value is not decodable');
    }

    return decoded;
}
