import { Skeleton } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import Retry from '../../../components/Retry';
import { PageDefinition } from '../../../createRoutes';
import getValue from '../../../helpers/getValue';
import report from '../../../helpers/report';
import Page from '../../../layouts/Page';
import Content from './Content';
import {
    GET_AVAILABILITY_REQUEST,
    GetAvailabilityRequestInput,
    GetAvailabilityRequestResult,
    GET_RIDE_AVAILABILITIES,
    GetRideAvailabilityRequestInput,
    GetRideAvailabilityRequestResult,
} from './index.graphql';

const availabilityPage: PageDefinition = {
    path: '/requests/:availabilityRequestId',
    Component({ getParam }) {
        const { t } = useTranslation();
        const availabilityRequestId = getParam('availabilityRequestId');

        const {
            data: dataRides,
            error: errorRides,
            loading: loadingRides,
            refetch: refetchRides,
        } = useQuery<GetRideAvailabilityRequestResult, GetRideAvailabilityRequestInput>(
            GET_RIDE_AVAILABILITIES,
            {
                variables: { id: availabilityRequestId },
                onError(apolloError) {
                    report(apolloError);
                },
            }
        );

        const {
            data: dataAvailabilityRequest,
            loading: loadingAvailabilityRequest,
            error: errorAvailabilityRequest,
            refetch: refetchAvailabilityRequest,
        } = useQuery<GetAvailabilityRequestResult, GetAvailabilityRequestInput>(
            GET_AVAILABILITY_REQUEST,
            {
                variables: { id: availabilityRequestId },
                onError(apolloError) {
                    report(apolloError);
                },
                pollInterval: 60_000,
            }
        );

        const ridesRequest = dataRides?.disruptionRideAvailabilityRequest;
        const hasNextRidesPage = ridesRequest?.rides.pageInfo.hasNextPage;

        const hasNextAvailabilitiesPage = ridesRequest?.rideAvailabilities.pageInfo.hasNextPage;

        useEffect(() => {
            if (hasNextRidesPage || hasNextAvailabilitiesPage) {
                report(new Error(`Too many rides or rideAvailabilities on availabilityRequest - ${availabilityRequestId}`));
            }
        }, [hasNextRidesPage, hasNextAvailabilitiesPage, availabilityRequestId]);

        if (errorAvailabilityRequest || errorRides) {
            return (
                <Page
                    breadcrumbs={{ name: 'Error' }}
                >
                    <Retry onRetry={() => refetchAvailabilityRequest()} />
                </Page>
            );
        }

        const availabilityRequest = dataAvailabilityRequest
            ?.disruptionRideAvailabilityRequest;

        const rideAvailabilities = ridesRequest?.rideAvailabilities;
        const rides = ridesRequest?.rides;

        const notFound = !loadingAvailabilityRequest && !loadingRides
        && (!availabilityRequest || !ridesRequest);

        return (
            <Page
                breadcrumbs={
                    notFound
                        ? { name: t('breadcrumbs.notFound') }
                        : {
                            name: availabilityRequest
                                ? t('breadcrumbs.availabilityRequest')
                                : <Skeleton width="7ch" />,
                            previous: {
                                name: t('breadcrumbs.requests'),
                                path: availabilityRequest
                                    ? `/suppliers/${availabilityRequest.supplier.id}/requests`
                                    : '#',
                            },
                        }
                }
            >
                <Content
                    availabilityRequest={availabilityRequest}
                    refetchAvailabilityRequest={refetchAvailabilityRequest}
                    refetchRideAvailabilities={refetchRides}
                    loading={loadingAvailabilityRequest || loadingRides}
                    rideAvailabilities={rideAvailabilities}
                    rides={rides}
                />
            </Page>
        );
    },
};

export default availabilityPage;
