import {
    TextField, Form, Heading,
    PrimaryButton, SecondaryButton, Alert,
} from '@get-e/react-components';
import { makeStyles, Grid } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import PhoneNumberField from '../../../components/PhoneNumberField';
import delay from '../../../helpers/delay';
import report from '../../../helpers/report';
import allValid from '../../../helpers/validation/allValid';
import FormError from '../../../helpers/validation/FormError';
import getHelperText from '../../../helpers/validation/getHelperText';
import getInputError from '../../../helpers/validation/getInputError';
import InputError from '../../../helpers/validation/InputError';
import isFilledString from '../../../helpers/validation/validators/isFilledString';
import useFormStyles from '../../../styles/useFormStyles';
import {
    Driver, UpdateDriverInput,
    UpdateDriverResult, UPDATE_DRIVER,
} from './Content.graphql';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
    row: { '&:hover': { cursor: 'pointer' } },
    form: { marginBottom: '2em' },
});

const Content: FunctionComponent<{
    supplierId: string;
    driver: Driver;
}> = ({ supplierId, driver }) => {
    const { t } = useTranslation();
    const formClasses = useFormStyles();
    const classes = useStyles();
    const history = useHistory();

    const [name, setName] = useState<string>(driver.name);
    const [phoneNumber, setPhoneNumber] = useState<string>(driver.phoneNumber);

    const [formError, setFormError] = useState<FormError | null>(null);
    const [nameError, setNameError] = useState<InputError | null>(null);
    const [phoneNumberError, setPhoneNumberError] = useState<InputError | null>(null);

    const [
        updateDriver,
        { loading: submitting },
    ] = useMutation<UpdateDriverResult, UpdateDriverInput>(
        UPDATE_DRIVER
    );

    const formErrorElement = (() => {
        if (formError === null) {
            return null;
        }

        const message = (() => {
            switch (formError) {
                case FormError.Unauthorized:
                    return t('errors.forms.unauthorized');
                case FormError.UnexpectedError:
                    return t('errors.forms.unexpected');
                case FormError.UserError:
                    return t('errors.forms.userError');
                default:
                    report(new Error('Unhandled form error'));
                    return t('errors.forms.unexpected');
            }
        })();

        return (
            <Alert severity="error" className={formClasses.mainErrorLimitedWidth}>
                {message}
            </Alert>
        );
    })();

    async function submitForm(): Promise<void> {
        const validated = { name: isFilledString(name, InputError.Empty) };

        if (!allValid(validated)) {
            setFormError(FormError.UserError);
            setNameError(getInputError(validated.name));
            return;
        }

        setFormError(null);

        try {
            const result = await updateDriver({
                variables: {
                    input: {
                        supplierId,
                        driverId: driver.id,
                        name: validated.name.value,
                    },
                },
            });

            if (!result?.data) {
                throw new Error('Mutation has no result');
            }

            history.push(`/suppliers/${supplierId}/drivers`);
        } catch (error) {
            report(error);
            setFormError(FormError.UnexpectedError);
        }
    }

    return (
        <div className={classes.container}>
            <Grid item xs={8}>
                <Grid item xs={12}>
                    <Heading level={1}>
                        Edit driver information
                    </Heading>
                    <Form
                        className={classes.form}
                        onSubmit={() => submitForm()}
                    >
                        <Grid container>
                            <TextField
                                value={name}
                                onChange={event => {
                                    setName(event.target.value);
                                    setNameError(null);
                                }}
                                helperText={getHelperText(nameError, t)}
                                error={nameError !== null}
                                label={t('pages.drivers.labels.name')}
                                required
                            />
                        </Grid>
                        <Grid container>
                            <PhoneNumberField
                                value={phoneNumber}
                                onChange={value => {
                                    setPhoneNumber(value);
                                    setPhoneNumberError(null);
                                }}
                                helperText={t('pages.drivers.inputs.phoneNumberDisabled')}
                                error={phoneNumberError !== null}
                                label={t('pages.drivers.labels.mobile')}
                                disabled
                            />
                        </Grid>

                        {formErrorElement}

                        <div className={formClasses.buttons}>
                            <PrimaryButton
                                loading={submitting}
                                onClick={submitForm}
                            >
                                {t('buttons.save')}
                            </PrimaryButton>
                            <SecondaryButton
                                onClick={
                                    () => history.push(`/suppliers/${supplierId}/drivers`)
                                }
                                disabled={submitting}
                            >
                                {t('buttons.cancel')}
                            </SecondaryButton>
                        </div>
                    </Form>
                </Grid>
            </Grid>
        </div>
    );
};

export default Content;
