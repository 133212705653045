import { Modal, userProfileIcon } from '@get-e/react-components';
import { Grid, Typography } from '@material-ui/core';
import { Moment } from 'moment';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUserContext } from '../context/CurrentUserContext';
import formatPastDate from '../helpers/formatPastDate';
import useModalStyles from '../styles/useModalStyles';
import useUserProfileStyles from '../styles/useUserProfileStyles';
import EditUserForm from './EditUserForm';

const EditUserModal: FunctionComponent<{
    user: {
        id: string;
        name: string;
        email: string;
        joinedOn: Moment;
    };
    onClose: () => void;
}> = ({ user, onClose }) => {
    const { t } = useTranslation();
    const profileClasses = useUserProfileStyles();
    const modalClasses = useModalStyles();
    const currentUser = useCurrentUserContext();

    return (
        <Modal
            open={true}
            onClose={onClose}
        >
            <Grid
                container
                spacing={2}
                justify="center"
                alignItems="center"
                className={modalClasses.header}
            >
                <Grid item>
                    <img
                        src={userProfileIcon}
                        alt={t('pages.backOfficeUsers.alt.userProfileImage')}
                        className={profileClasses.image}
                    />
                </Grid>

                <Grid item xs>
                    <Typography
                        variant="h2"
                        component="h2"
                        className={modalClasses.heading}
                    >
                        {
                            user.id === currentUser.id
                                ? t('pages.backOfficeUsers.edit.myProfileTitle')
                                : t('pages.backOfficeUsers.edit.title')
                        }
                    </Typography>

                    <p>{
                        t(
                            'pages.users.edit.joinedOn',
                            { date: formatPastDate(user.joinedOn, t) }
                        )
                    }</p>
                </Grid>
            </Grid>

            <EditUserForm
                user={user}
                onCancel={onClose}
                onSaved={onClose}
            />
        </Modal>
    );
};

export default EditUserModal;
