import gql from 'graphql-tag';

export const GET_CUSTOMERS = gql`
    query getCustomers(
        $first: Int!
        $filter: CustomerFilter
    ) {
        customers(
            first: $first
            filter: $filter
            orderBy: {
                field: NAME,
                direction: ASC
            }
        ) {
            nodes {
                id
                name
            }
        }
    }
`;

export interface GetCustomersInput {
    first: number;
    filter: null | {
        search: string;
    };
}

export interface GetCustomersResult {
    customers: {
        nodes: Array<{
            id: string;
            name: string;
        }>;
    };
}
