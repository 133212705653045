import { ComponentsProvider, FullScreenLoader, Notification, NotificationSeverity } from '@get-e/react-components';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { ApolloProvider } from 'react-apollo';
import { Router } from 'react-router-dom';
import client from './apollo/client';
import { LocaleContext, SupportedLocale, supportedLocales } from './context/LocaleContext';
import { NotificationContext, Severity } from './context/NotificationContext';
import ErrorBoundary from './ErrorBoundary';
import useEffectAsync from './helpers/useEffectAsync';
import useLoaderDebounce from './helpers/useLoaderDebounce';
import history from './history';
import { setLocale as setI18nLocale } from './i18n';
import Routes from './Routes';
import localeFinder from './services/localeFinder';

interface IeNavigator extends Navigator {
    userLanguage?: string;
    browserLanguage?: string;
}

function detectBestLocale(browserLocales: string[]): SupportedLocale {
    const fallbackLocales = supportedLocales[0];

    const bestLocale = localeFinder(
        browserLocales,
        supportedLocales,
        fallbackLocales
    );

    return bestLocale;
}

const App: FunctionComponent = () => {
    const [i18nSynced, setI18nSynced] = useState(false);
    const [locale, setLocale] = useState<SupportedLocale | null>(null);

    const [notification, setNotification] = useState<{
        message: string;
        color: NotificationSeverity;
    } | null>(null);

    useEffect(() => {
        const ieNavigator: IeNavigator = window.navigator;
        const { userLanguage, browserLanguage } = ieNavigator;

        const navigatorLanguages = window
            .navigator.languages as readonly string[] | undefined;

        const navigatorLanguage = window.navigator.language as string | undefined;

        const uniqueUserLanguages = new Set([
            ...navigatorLanguages ?? [],
            navigatorLanguage ?? '',
            userLanguage ?? '',
            browserLanguage ?? '',
        ]);

        const userLanguages = Array
            .from(uniqueUserLanguages)
            .filter(language => Boolean(language));

        const portalLocale: SupportedLocale = detectBestLocale(userLanguages) ?? 'en-GB';

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        setI18nLocale(portalLocale).then(() => setI18nSynced(true));

        setLocale(portalLocale);
    }, []);

    useEffectAsync(async () => {
        if (locale === null) {
            // Loading
            return;
        }

        await setI18nLocale(locale);
        setI18nSynced(true);
    }, [locale]);

    const showLoader = useLoaderDebounce(!i18nSynced || !locale);

    if (!i18nSynced || !locale) {
        return showLoader
            ? <FullScreenLoader />
            : null;
    }

    if (!locale) {
        throw new Error('Locale should be available');
    }

    const showNotification = (
        message: string,
        severity?: Severity
    ): void => {
        const color = (() => {
            switch (severity) {
                case Severity.Info:
                case undefined:
                    return 'info';
                case Severity.Error:
                    return 'error';
                default:
                    throw new Error('Unsupported severity');
            }
        })();

        setNotification({
            message,
            color,
        });
    };

    const notificationElement = (() => {
        if (notification === null) {
            return null;
        }

        return (
            <Notification
                severity={notification.color}
                onClose={() => setNotification(null)}
            >
                {notification.message}
            </Notification>
        );
    })();

    return (
        <ErrorBoundary
            error={(

                // TODO: Add proper error
                <div>Unexpected error</div>
            )}
        >
            <LocaleContext.Provider
                value={{
                    locale,
                    updateLocale: newLocale => {
                        setLocale(newLocale);
                        setI18nSynced(false);
                    },
                }}
            >
                <Router history={history}>
                    <ComponentsProvider>
                        <NotificationContext.Provider value={{ showNotification }}>
                            {notificationElement}
                            <ApolloProvider client={client}>
                                <Routes />
                            </ApolloProvider>
                        </NotificationContext.Provider>
                    </ComponentsProvider>
                </Router>
            </LocaleContext.Provider>
        </ErrorBoundary>
    );
};

export default App;
