import { LoadingPage, Tab, TabPanel, Tabs } from '@get-e/react-components';
import { Grid, makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useQuery } from 'react-apollo';
import Retry from '../../components/Retry';
import getValue from '../../helpers/getValue';
import useEffectAsync from '../../helpers/useEffectAsync';
import useLoaderDebounce from '../../helpers/useLoaderDebounce';
import {
    GET_SUPPLIER,
    GetSupplierResult,
    GetSupplierInput,
} from './Content.graphql';
import DriverTab from './DriversTab';
import EditTab from './EditTab';
import UserTab, { UsersTabs } from './UsersTab';

const useStyles = makeStyles({ tabContainer: { marginBottom: '1em' } });

export enum SupplierTab {
    EditsTab,
    UsersTab,
    DriversTab,
}

const Content: FunctionComponent<{
    tab: SupplierTab;
    usersTab?: UsersTabs;
    supplierId: string;
}> = ({ tab, supplierId, usersTab }) => {
    const classes = useStyles();
    const paginationLimit = 100;

    const variables = getValue((): GetSupplierInput => (
        {
            id: supplierId,
            first: paginationLimit,
            after: undefined,
        }
    ));

    const {
        data,
        loading,
        error,
        fetchMore,
        refetch,
    } = useQuery<GetSupplierResult, GetSupplierInput>(
        GET_SUPPLIER,
        { variables }
    );

    const showLoader = useLoaderDebounce(loading);

    useEffectAsync(async () => {
        if (data?.supplier?.servicedAirports?.pageInfo.hasNextPage) {
            await fetchMoreAirports(
                data?.supplier?.servicedAirports?.pageInfo.endCursor ?? undefined
            );
        }
    }, [data?.supplier?.servicedAirports?.nodes]);

    if (loading && !data) {
        return showLoader
            ? <LoadingPage />
            : null;
    }

    if (error || !data?.supplier) {
        return <Retry onRetry={() => refetch()} loading={loading} />;
    }

    if (
        data.supplier.receivableEmails.pageInfo.hasNextPage
        || data.supplier.dispatchEmails.pageInfo.hasNextPage
    ) {
        throw new Error('Too many emails');
    }

    const fetchMoreAirports = async (afterCursor: string | undefined): Promise<void> => {
        await fetchMore({
            variables: {
                id: supplierId,
                first: paginationLimit,
                after: afterCursor,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                    return previousResult;
                }

                const {
                    nodes: newNodes,
                    pageInfo,
                } = fetchMoreResult.supplier.servicedAirports;

                return newNodes.length
                    ? {
                        supplier: {
                            ...previousResult.supplier,
                            servicedAirports: {
                                ...previousResult.supplier.servicedAirports,
                                nodes: [
                                    ...previousResult.supplier.servicedAirports.nodes,
                                    ...newNodes,
                                ],
                                pageInfo,
                            },
                        },
                    }
                    : previousResult;
            },
        });
    };

    return (
        <>
            <Grid
                item
                xs={12}
                container
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item className={classes.tabContainer}>
                    <div className="root">
                        <Tabs value={tab}>
                            <Tab
                                label="Organisation"
                                url={`/suppliers/${supplierId}/edit`}
                                index={SupplierTab.EditsTab}
                                selectedIndex={tab}
                            />

                            <Tab
                                label="Users"
                                url={`/suppliers/${supplierId}/users`}
                                index={SupplierTab.UsersTab}
                                selectedIndex={tab}
                            />
                            <Tab
                                label="Drivers"
                                url={`/suppliers/${supplierId}/drivers`}
                                index={SupplierTab.DriversTab}
                                selectedIndex={tab}
                            />
                        </Tabs>
                    </div>
                </Grid>
            </Grid>

            <TabPanel value={SupplierTab.EditsTab} selectedValue={tab}>
                <EditTab
                    supplier={data.supplier}
                    onUpdated={() => refetch()}
                />
            </TabPanel>

            <TabPanel value={SupplierTab.UsersTab} selectedValue={tab}>
                <UserTab
                    tab={usersTab ?? UsersTabs.Active}
                    supplier={data.supplier}
                />
            </TabPanel>

            <TabPanel value={SupplierTab.DriversTab} selectedValue={tab}>
                <DriverTab supplier={data.supplier} />
            </TabPanel>
        </>
    );
};

export default Content;
