export function getRandomElement<T>(array: T[]): T {
    return array[Math.floor(Math.random() * array.length)];
}

export function createRandomFloatBetween(minimum: number, maximum: number): number {
    return Math.random() * (maximum - minimum) + minimum;
}

export function createRandomIntegerBetween(minimum: number, maximum: number): number {
    const min = Math.ceil(minimum);
    const max = Math.floor(maximum);

    return Math.floor(Math.random() * (max - min + 1)) + min;
}
