import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

/*
 * TODO: Order alternates by order
 */

export const getActiveDisruptionsQuery = gql`
    query getActiveDisruptions(
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $maxAlternates: Int,
        $filter: DisruptionFilter
    ) {
        disruptions(
            first: $first,
            after: $after,
            last: $last,
            before: $before,
            orderBy: {field: PICKUP_TIME, direction: ASC},
            filter: $filter
        ) {
            nodes {
                id
                coachSeatsBooked
                coachSeatsRequired
                eu261Deadline
                flight {
                    number
                    departure {
                        airport {
                            id
                            iata
                            name
                        }
                        scheduledDepartureTime
                    }
                    arrival {
                        airport {
                            id
                            iata
                            name
                        }
                        scheduledArrivalTime
                    }
                    passengerCount {
                        total
                    }
                    alternates(first: $maxAlternates) {
                        nodes {
                            airport {
                                id
                                iata
                                name
                            }
                            estimatedTime
                        }
                        totalCount
                        pageInfo {
                            hasNextPage
                        }
                    }
                }
                rideAvailabilities(first: 0) {
                    totalCount
                }
                rides(first: 0) {
                    totalCount
                }
                unconfirmedRides: rides(
                    first: 0
                    filter: {confirmed: false}
                ) {
                    totalCount
                }
                ridesInProgress: rides(
                    first: 0
                    filter: {inProgress: true}
                ) {
                    totalCount
                }
                uncompletedAvailabilityRequests: rideAvailabilityRequests(
                    first: 0,
                    filter: {completed: false}
                ) {
                    totalCount
                }
                completedRides: rides(
                    first: 1,
                    filter: {completed: true}
                ) {
                    nodes {
                        dropOffPoint {
                            trackingTimes {
                                actualArrival
                            }
                        }
                    }
                    totalCount
                }
            }
            totalCount
            pageInfo {
                hasPreviousPage
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`;

export type GetDisruptionsInput = {
    maxAlternates: number;
    filter: { archived: boolean; search?: string };
} & (
    | {
        first: number;
        after?: string;
    }
    | {
        last: number;
        before?: string;
    }
);

export interface GetDisruptionsResult {
    disruptions: {
        nodes: Array<{
            id: string;
            coachSeatsBooked: number;
            coachSeatsRequired: number;
            eu261Deadline: string;
            flight: {
                number: string;
                departure: {
                    airport: {
                        id: string;
                        iata: string;
                        name: string;
                    };
                    scheduledDepartureTime: string;
                };
                arrival: {
                    airport: {
                        id: string;
                        iata: string;
                        name: string;
                    };
                    scheduledArrivalTime: string;
                };
                passengerCount: {
                    total: number;
                };
                alternates: {
                    nodes: Array<{
                        airport: {
                            id: string;
                            iata: string;
                            name: string;
                        };
                        estimatedTime: string | null;
                    }>;
                    totalCount: number;
                    pageInfo: {
                        hasNextPage: boolean;
                    };
                };
            };
            rideAvailabilities: {
                totalCount: number;
            };
            rides: {
                totalCount: number;
            };
            unconfirmedRides: {
                totalCount: number;
            };
            ridesInProgress: {
                totalCount: number;
            };
            uncompletedAvailabilityRequests: {
                totalCount: number;
            };
            completedRides: {
                nodes: Array<{
                    dropOffPoint: {
                        trackingTimes: {
                            actualArrival: string;
                        };
                    };
                }>;
                totalCount: number;
            };
        }>;
        totalCount: number;
        pageInfo: {
            hasPreviousPage: boolean;
            hasNextPage: boolean;
            startCursor: string | null;
            endCursor: string | null;
        };
    };
}
