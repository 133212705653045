// TODO: Split up file
/* eslint-disable max-lines, max-len */
import {
    Alert, Form, IntegerField, PrimaryButton,
    SecondaryButton, TertiaryButton, TextField, UtcTimeField,
} from '@get-e/react-components';
import {
    Checkbox, Fade, FormControlLabel,
    Grid, IconButton, InputAdornment, makeStyles, Typography,
} from '@material-ui/core';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttleOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import CloseIcon from '@material-ui/icons/Close';
import FlightIcon from '@material-ui/icons/Flight';
import FlightLandIcon from '@material-ui/icons/FlightLandOutlined';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoffOutlined';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import clsx from 'clsx';
import moment, { Moment } from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { useApolloClient, useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AirportField, { Airport } from '../../../components/AirportField';
import CustomerSelector, { Customer } from '../../../components/CustomerSelector';
import UtcDateField from '../../../components/UtcDateField';
import UtcDateTimeField from '../../../components/UtcDateTimeField';
import { useCurrentUserContext } from '../../../context/CurrentUserContext';
import delay from '../../../helpers/delay';
import formatFlightNumber from '../../../helpers/formatFlightNumber';
import getIndex from '../../../helpers/getIndex';
import getValue from '../../../helpers/getValue';
import report from '../../../helpers/report';
import tryGetAsync from '../../../helpers/tryGetAsync';
import useEffectAsync from '../../../helpers/useEffectAsync';
import allValid from '../../../helpers/validation/allValid';
import StandardFormError from '../../../helpers/validation/FormError';
import getFormErrorMessage from '../../../helpers/validation/getFormErrorMessage';
import getHelperText from '../../../helpers/validation/getHelperText';
import getInputError from '../../../helpers/validation/getInputError';
import InputError from '../../../helpers/validation/InputError';
import { Validated } from '../../../helpers/validation/Validator';
import isFilledString from '../../../helpers/validation/validators/isFilledString';
import isNotNull from '../../../helpers/validation/validators/isNotNull';
import useFormStyles from '../../../styles/useFormStyles';
import {
    CreateArrivalDisruptionInput,
    CreateArrivalDisruptionResult, CREATE_ARRIVAL_DISRUPTION,
    FindFlightInput,
    FindFlightResult, FIND_FLIGHT,
} from './AddDisruption.graphql';
import FlightFound from './FlightFound';

export enum DiversionType {
    Arrival,
    Departure
}

interface Alternate {
    airport: Airport | null;
    estimatedTime: Moment | null;
    estimatedDate: Moment | null;
}

interface AlternateError {
    airport: InputError | null;
    estimatedTime: InputError | null;
    estimatedDate: InputError | null;
}

type FormError = 'DEPARTURE_DIVERSION_NOT_SUPPORTED';

const useStyles = makeStyles(theme => ({
    pageHeading: { marginBottom: '2rem' },
    sectionHeading: {
        marginTop: '1rem',
        display: 'flex',
        alignItems: 'center',
    },
    sectionHeadingIcon: {
        verticalAlign: 'middle',
        marginRight: '.6rem',
        color: theme.palette.primary.main,
    },
    flightIcon: { transform: 'rotate(90deg)' },
    sectionHeadingText: { verticalAlign: 'middle' },
    addAlternateButton: { marginBottom: '1rem' },
    bookingAuthorizationField: { marginTop: '1rem' },
    buttons: { marginTop: '1.5rem' },
    flightDetectionAlert: { marginBottom: '1rem' },
    landingFlight: { marginLeft: '1rem' },
    autoBookingDisabledAlert: { marginTop: '.5rem' },
    searchButton: { marginBottom: '1rem' },
    removeAlternate: {
        color: theme.palette.grey[200],
        cursor: 'pointer',
    },
    coachSubline: { marginBottom: '1rem' },
}));

function combineDateAndTime(date: Moment, time: Moment | null): Moment;
function combineDateAndTime(date: null, time: Moment): Moment;
function combineDateAndTime(date: Moment | null, time: Moment | null): Moment | null;

function combineDateAndTime(date: Moment | null, time: Moment | null): Moment | null {
    if (date === null) {
        return time;
    }

    if (time === null) {
        return date;
    }

    return moment.utc(
        `${date.format('YYYY-MM-DD')}T${time.format('HH:mm')}`,
        'YYYY-MM-DDTHH:mm',
    );
}

enum AutoBookingDisabledReason {
    MultipleAlternates,
    NoPreferredSuppliers
}

const AddDisruption: FunctionComponent<{
    diversionType: DiversionType;

    // TODO: Split up
    // eslint-disable-next-line max-lines-per-function, max-statements
}> = ({ diversionType }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const formClasses = useFormStyles();
    const history = useHistory();
    const apolloClient = useApolloClient();
    const currentUser = useCurrentUserContext();

    //TODO: Remove after MVP
    const showPreAuth = false;

    const canSelectCustomer = currentUser.isBackOffice || currentUser.managedCustomer === 'MULTIPLE';

    const prefilledCustomer = getValue(() => {
        if (currentUser.isBackOffice || currentUser.managedCustomer === 'MULTIPLE') {
            return null;
        }

        return currentUser.managedCustomer ?? null;
    });

    const [customer, setCustomer] = useState<Customer | null>(prefilledCustomer);

    const [
        customerError,
        setCustomerError,
    ] = useState<InputError | null>(null);

    const [flightNumber, setFlightNumber] = useState('');
    const [flightNumberError, setFlightNumberError] = useState<InputError | null>(null);

    const [
        flightDepartureDate,
        setFlightDepartureDate,
    ] = useState<Moment | null>(moment.utc());

    const [
        flightDepartureDateError,
        setFlightDepartureDateError,
    ] = useState<InputError | null>(null);

    const [
        scheduledDepartureAirport,
        setScheduledDepartureAirport,
    ] = useState<Airport | null>(null);

    const [
        scheduledDepartureAirportError,
        setScheduledDepartureAirportError,
    ] = useState<InputError | null>(null);

    const [
        scheduledDepartureTime,
        setScheduledDepartureTime,
    ] = useState<Moment | null>(null);

    const [
        scheduledDepartureTimeError,
        setScheduledDepartureTimeError,
    ] = useState<InputError | null>(null);

    const [
        scheduledDepartureDate,
        setScheduledDepartureDate,
    ] = useState<Moment | null>(moment.utc());

    const [
        scheduledDepartureDateError,
        setScheduledDepartureDateError,
    ] = useState<InputError | null>(null);

    const [
        departureAlternates,
        setDepartureAlternates,
    ] = useState<Alternate[]>([
        {
            airport: null,
            estimatedTime: null,
            estimatedDate: moment.utc(),
        },
    ]);

    const [
        scheduledArrivalAirport,
        setScheduledArrivalAirport,
    ] = useState<Airport | null>(null);

    const [
        scheduledArrivalAirportError,
        setScheduledArrivalAirportError,
    ] = useState<InputError | null>(null);

    const [
        scheduledArrivalTime,
        setScheduledArrivalTime,
    ] = useState<Moment | null>(null);

    const [
        scheduledArrivalTimeError,
        setScheduledArrivalTimeError,
    ] = useState<InputError | null>(null);

    const [
        scheduledArrivalDate,
        setScheduledArrivalDate,
    ] = useState<Moment | null>(moment.utc());

    const [
        scheduledArrivalDateError,
        setScheduledArrivalDateError,
    ] = useState<InputError | null>(null);

    // TODO: Combine arrivalAlternates and departureAlternates state
    const [arrivalAlternates, setArrivalAlternates] = useState<Alternate[]>([
        {
            airport: null,
            estimatedTime: null,
            estimatedDate: moment.utc(),
        },
    ]);

    const [
        arrivalAlternateErrors,
        setArrivalAlternateErrors,
    ] = useState<AlternateError[]>([]);

    const [
        totalPassengers,
        setTotalPassengers,
    ] = useState<number | null>(null);

    const [
        totalPassengersError,
        setTotalPassengersError,
    ] = useState<InputError | null>(null);

    const [
        infants,
        setInfants,
    ] = useState<number | null>(null);

    const [
        infantsError,
        setInfantsError,
    ] = useState<InputError | null>(null);

    const [
        wheelchairPassengers,
        setWheelchairPassengers,
    ] = useState<number | null>(null);

    const [
        wheelchairPassengersError,
        setWheelchairPassengersError,
    ] = useState<InputError | null>(null);

    const [
        bookAutomatically,
        setBookAutomatically,
    ] = useState(false);

    const [
        formError,
        setFormError,
    ] = useState<StandardFormError | FormError | null>(null);

    const [
        flightDetectionResult,
        setFlightDetectionResult,
    ] = useState<null | { found: false } | {
        found: true;
        departure: {
            airport: Airport;
            time: Moment;
        };
        arrival: {
            airport: Airport;
            time: Moment;
        };
    }>(null);

    const [flightSearchError, setFlightSearchError] = useState(false);
    const [searchingFlight, setSearchingFlight] = useState(false);

    const [
        createArrivalDisruption,
        { loading: submitting },
    ] = useMutation<CreateArrivalDisruptionResult, CreateArrivalDisruptionInput>(
        CREATE_ARRIVAL_DISRUPTION
    );

    const hasPreferredSuppliers = async (airportId: string): Promise<boolean> => {
        // TODO: Implement
        await delay(0);

        // Always return false for MVP
        return false;
    };

    const [
        preferredSuppliersPresent,
        setPreferredSuppliersPresent,
    ] = useState<boolean | null>(false);

    useEffectAsync(async (): Promise<void> => {
        const alternates = diversionType === DiversionType.Arrival
            ? arrivalAlternates
            : departureAlternates;

        const airport = alternates.length > 1
            ? null
            : alternates[0].airport;

        setPreferredSuppliersPresent(
            airport === null
                ? null
                : await hasPreferredSuppliers(airport.id)
        );
    }, [arrivalAlternates, departureAlternates]);

    const autoBookingDisabledReason = ((): AutoBookingDisabledReason | null => {
        const alternates = diversionType === DiversionType.Arrival
            ? arrivalAlternates
            : departureAlternates;

        if (alternates.length > 1) {
            return AutoBookingDisabledReason.MultipleAlternates;
        }

        if (!preferredSuppliersPresent) {
            return AutoBookingDisabledReason.NoPreferredSuppliers;
        }

        return null;
    })();

    const submitForm = async (): Promise<void> => {
        setFormError(null);

        if (diversionType === DiversionType.Departure) {
            setFormError('DEPARTURE_DIVERSION_NOT_SUPPORTED');
            return;
        }

        const validated = {
            customer: isNotNull(customer, InputError.Empty),
            flightSearch: isNotNull(flightDetectionResult),
            flightNumber: isFilledString(flightNumber, InputError.Empty),
            scheduledDepartureAirport: isNotNull(
                scheduledDepartureAirport,
                InputError.Empty
            ),
            scheduledDepartureTime: isNotNull(scheduledDepartureTime, InputError.Empty),
            scheduledDepartureDate: isNotNull(scheduledDepartureDate, InputError.Empty),
            scheduledArrivalAirport: isNotNull(scheduledArrivalAirport, InputError.Empty),

            // TODO: Must be after departure time
            scheduledArrivalTime: isNotNull(scheduledArrivalTime, InputError.Empty),
            scheduledArrivalDate: isNotNull(scheduledArrivalDate, InputError.Empty),
            totalPassengers: isNotNull(totalPassengers, InputError.Empty),
            infants: isNotNull(infants, InputError.Empty),
            wheelchairPassengers: isNotNull(wheelchairPassengers, InputError.Empty),

            arrivalAlternates: (
                (): Validated<
                Alternate[],
                Array<{
                    airport: InputError | null;
                    estimatedTime: InputError | null;
                    estimatedDate: InputError | null;
                }>
                > => {
                    /*
                     * TODO: Assert that alternate times are
                     * before/after scheduled departure/arrival time respectively
                     */

                    if (bookAutomatically) {
                        const [alternate] = arrivalAlternates;
                        const airportEmpty = alternate.airport === null;
                        const estimatedTimeEmpty = alternate.estimatedTime === null;
                        const estimatedDateEmpty = alternate.estimatedDate === null;

                        if (!airportEmpty && !estimatedTimeEmpty && !estimatedDateEmpty) {
                            return {
                                isValid: true,
                                value: arrivalAlternates,
                            };
                        }

                        return {
                            isValid: false,
                            error: [
                                {
                                    airport: airportEmpty
                                        ? InputError.Empty
                                        : null,
                                    estimatedTime: estimatedTimeEmpty
                                        ? InputError.Empty
                                        : null,
                                    estimatedDate: estimatedDateEmpty
                                        ? InputError.Empty
                                        : null,
                                },
                            ],
                        };
                    }

                    const hasEmpty = arrivalAlternates.some(
                        alternate => alternate.airport === null
                    );

                    if (!hasEmpty) {
                        return {
                            isValid: true,
                            value: arrivalAlternates,
                        };
                    }

                    return {
                        isValid: false,
                        error: arrivalAlternates.map(alternate => ({
                            airport: alternate.airport === null
                                ? InputError.Empty
                                : null,
                            estimatedTime: null,
                            estimatedDate: null,
                        })),
                    };
                }
            )(),
        };

        if (!allValid(validated)) {
            setFormError(StandardFormError.UserError);

            setFlightSearchError(!validated.flightSearch.isValid);
            setCustomerError(getInputError(validated.customer));
            setFlightNumberError(getInputError(validated.flightNumber));
            setScheduledDepartureAirportError(
                getInputError(validated.scheduledDepartureAirport)
            );
            setScheduledDepartureTimeError(
                getInputError(validated.scheduledDepartureTime)
            );
            setScheduledDepartureDateError(
                getInputError(validated.scheduledDepartureDate)
            );
            setScheduledArrivalAirportError(
                getInputError(validated.scheduledArrivalAirport)
            );
            setScheduledArrivalTimeError(getInputError(validated.scheduledArrivalTime));
            setScheduledArrivalDateError(getInputError(validated.scheduledArrivalDate));
            setArrivalAlternateErrors(getInputError(validated.arrivalAlternates) ?? []);
            setTotalPassengersError(getInputError(validated.totalPassengers));
            setInfantsError(getInputError(validated.infants));
            setWheelchairPassengersError(getInputError(validated.wheelchairPassengers));

            return;
        }

        setFormError(null);

        try {
            const result = await createArrivalDisruption({
                variables: {
                    input: {
                        flight: {
                            number: validated.flightNumber.value,
                            departure: {
                                airportId: validated.scheduledDepartureAirport.value.id,
                                scheduledDepartureTime: combineDateAndTime(
                                    validated.scheduledDepartureDate.value,
                                    validated.scheduledDepartureTime.value,
                                ).format(),
                            },
                            arrival: {
                                airportId: validated.scheduledArrivalAirport.value.id,
                                scheduledArrivalTime: combineDateAndTime(
                                    validated.scheduledArrivalDate.value,
                                    validated.scheduledArrivalTime.value,
                                ).format(),
                            },
                            passengerCount: {
                                total: validated.totalPassengers.value,
                                infants: validated.infants.value,
                                wheelchair: validated.wheelchairPassengers.value,
                            },
                            alternates: validated.arrivalAlternates.value.map(
                                alternate => {
                                    if (alternate.airport === null) {
                                        throw new Error(
                                            'Alternate airport cannot be null'
                                        );
                                    }

                                    const combined = combineDateAndTime(
                                        alternate.estimatedDate,
                                        alternate.estimatedTime,
                                    );

                                    if (combined === null) {
                                        throw new Error(
                                            'At least either date'
                                            + ' or time must be available'
                                        );
                                    }

                                    return {
                                        airportId: alternate.airport.id,
                                        estimatedTime: combined.format(),
                                    };
                                }
                            ),
                        },
                        coachesAuthorized: autoBookingDisabledReason === null
                            ? bookAutomatically
                            : false,
                        customerId: validated.customer.value.id,
                    },
                },
            });

            if (!result.data) {
                throw new Error('Mutation did not have result');
            }

            history.push(`/disruptions/${result.data.createArrivalDisruption.id}`);
        } catch (error) {
            report(error);
            setFormError(StandardFormError.UnexpectedError);
        }
    };

    const departureAlternateRows = departureAlternates.length > 1
        ? departureAlternates.map((alternate, index) => (
            <AlternateRow
                key={index}
                alternate={alternate}
                number={departureAlternates.length === 1 ? null : index + 1}
                removable={index !== 0}
                onRemove={() => {
                    const newAlternates = [...departureAlternates];

                    newAlternates.splice(index, 1);
                    setDepartureAlternates(newAlternates);
                }}
                onAirportChange={newAirport => {
                    setDepartureAlternates(
                        updateAlternate(departureAlternates, index, 'airport', newAirport)
                    );
                }}
                onDateTimeChange={newTime => {
                    const updatedTime = updateAlternate(
                        departureAlternates,
                        index,
                        'estimatedTime',
                        newTime
                    );

                    const updatedDateAndTime = updateAlternate(
                        updatedTime,
                        index,
                        'estimatedDate',
                        newTime
                    );

                    setDepartureAlternates(updatedDateAndTime);
                }}

                // TODO
                error={null}
            />
        ))

        : (
            <EstimatedAlternateRow
                alternate={departureAlternates[0]}
                onAirportChange={newAirport => {
                    setDepartureAlternates(
                        updateAlternate(departureAlternates, 0, 'airport', newAirport)
                    );
                }}
                onTimeChange={newTime => {
                    setDepartureAlternates(
                        updateAlternate(departureAlternates, 0, 'estimatedTime', newTime)
                    );
                }}
                onDateChange={newDate => {
                    setDepartureAlternates(
                        updateAlternate(departureAlternates, 0, 'estimatedDate', newDate)
                    );
                }}

                // TODO
                error={null}
            />
        );

    const updateAlternate = <T extends keyof Alternate>(
        currentAlternates: Alternate[],
        index: number,
        changedField: T,
        newValue: Alternate[T]
    ): Alternate[] => {
        const newAlternates = [...currentAlternates];

        newAlternates[index] = {
            ...currentAlternates[index],
            [changedField]: newValue,
        };

        return newAlternates;
    };

    const clearAlternateError = <T extends keyof Alternate>(
        currentErrors: AlternateError[],
        index: number,
        field: T,
    ): AlternateError[] => {
        const newErrors = [...currentErrors];

        newErrors[index] = newErrors[index]
            ? {
                ...newErrors[index],
                [field]: null,
            }
            : {
                airport: null,
                estimatedTime: null,
                estimatedDate: null,
            };

        return newErrors;
    };

    const arrivalAlternateRows = arrivalAlternates.length > 1
        ? arrivalAlternates.map((alternate, index) => (
            <AlternateRow
                key={index}
                alternate={alternate}
                number={arrivalAlternates.length === 1 ? null : index + 1}
                removable={index !== 0}
                onRemove={() => {
                    const newAlternates = [...arrivalAlternates];

                    newAlternates.splice(index, 1);
                    setArrivalAlternates(newAlternates);

                    const newErrors = [...arrivalAlternateErrors];

                    newErrors.splice(index, 1);
                    setArrivalAlternateErrors(newErrors);
                }}
                onAirportChange={newAirport => {
                    setArrivalAlternates(
                        updateAlternate(arrivalAlternates, index, 'airport', newAirport)
                    );

                    setArrivalAlternateErrors(
                        clearAlternateError(arrivalAlternateErrors, index, 'airport')
                    );
                }}
                onDateTimeChange={newTime => {
                    const updatedTime = updateAlternate(
                        arrivalAlternates,
                        index,
                        'estimatedTime',
                        newTime
                    );

                    const updatedDateAndTime = updateAlternate(
                        updatedTime,
                        index,
                        'estimatedDate',
                        newTime
                    );

                    setArrivalAlternates(updatedDateAndTime);

                    const clearedTimeError = clearAlternateError(
                        arrivalAlternateErrors,
                        index,
                        'estimatedTime'
                    );

                    const clearedDateAndTimeError = clearAlternateError(
                        clearedTimeError,
                        index,
                        'estimatedDate'
                    );

                    setArrivalAlternateErrors(clearedDateAndTimeError);
                }}
                error={getIndex(arrivalAlternateErrors, index) ?? null}
            />
        ))

        : (
            <EstimatedAlternateRow
                alternate={arrivalAlternates[0]}
                onAirportChange={newAirport => {
                    setArrivalAlternates(
                        updateAlternate(arrivalAlternates, 0, 'airport', newAirport)
                    );

                    setArrivalAlternateErrors(
                        clearAlternateError(arrivalAlternateErrors, 0, 'airport')
                    );
                }}
                onTimeChange={newTime => {
                    setArrivalAlternates(
                        updateAlternate(arrivalAlternates, 0, 'estimatedTime', newTime)
                    );

                    setArrivalAlternateErrors(
                        clearAlternateError(arrivalAlternateErrors, 0, 'estimatedTime')
                    );
                }}
                onDateChange={newDate => {
                    setArrivalAlternates(
                        updateAlternate(arrivalAlternates, 0, 'estimatedDate', newDate)
                    );

                    setArrivalAlternateErrors(
                        clearAlternateError(arrivalAlternateErrors, 0, 'estimatedDate')
                    );
                }}
                error={getIndex(arrivalAlternateErrors, 0) ?? null}
            />
        );

    const formErrorElement = (() => {
        if (formError === null) {
            return null;
        }

        const message = ((): string => {
            if (formError === 'DEPARTURE_DIVERSION_NOT_SUPPORTED') {
                return t('pages.addDisruption.formErrors.departureDiversionUnsupported');
            }

            return getFormErrorMessage(formError, t);
        })();

        return (
            <Alert severity="error" className={formClasses.mainErrorLimitedWidth}>
                {message}
            </Alert>
        );
    })();

    return (
        <Form onSubmit={submitForm}>
            <Typography variant="h2" component="h2" className={classes.pageHeading}>
                {
                    diversionType === DiversionType.Arrival
                        ? t('pages.addDisruption.arrivalHeading')
                        : t('pages.addDisruption.departureHeading')
                }
            </Typography>

            <div>
                {canSelectCustomer
                    ? (
                        <>
                            <Typography
                                variant="h3"
                                component="h3"
                                className={classes.sectionHeading}
                            >
                                <BusinessIcon className={classes.sectionHeadingIcon} />
                                {t('pages.addDisruption.sections.selectCustomer')}
                            </Typography>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="flex-start"
                                spacing={4}
                            >
                                <Grid item xs={4}>
                                    <CustomerSelector
                                        value={customer}
                                        onChange={newValue => {
                                            setCustomer(newValue);
                                            setCustomerError(null);
                                        }}
                                        label={t('pages.addDisruption.fields.customer')}
                                        helperText={getHelperText(customerError, t)}
                                        error={customerError !== null}
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )
                    : null
                }

                <Typography
                    variant="h3"
                    component="h3"
                    className={classes.sectionHeading}
                >
                    <FlightIcon className={clsx(classes.sectionHeadingIcon, classes.flightIcon)} />

                    <span className={classes.sectionHeadingText}>
                        {t('pages.addDisruption.sections.findFlight')}
                    </span>
                </Typography>

                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={4}
                >
                    <Grid item xs={4}>
                        <TextField
                            value={flightNumber}
                            onChange={event => {
                                const newValue = event.target.value;

                                if (formatFlightNumber(newValue) === flightNumber) {
                                    return;
                                }

                                setFlightNumber(event.target.value);
                                setFlightNumberError(null);
                                setFlightDetectionResult(null);
                            }}
                            onBlur={() => setFlightNumber(
                                formatFlightNumber(flightNumber)
                            )}
                            label={t('pages.addDisruption.fields.flightNumber')}
                            required
                            error={flightNumberError !== null}
                            helperText={getHelperText(flightNumberError, t)}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <UtcDateField
                            value={flightDepartureDate}
                            onChange={newValue => {
                                const hasChanged = newValue === null
                                    && flightDepartureDate !== null
                                    || newValue !== null
                                    && !newValue.isSame(flightDepartureDate);

                                if (!hasChanged) {
                                    return;
                                }

                                setFlightDepartureDate(newValue);
                                setFlightDepartureDateError(null);
                                setFlightDetectionResult(null);
                            }}
                            label={t('pages.addDisruption.fields.flightDepartureDate')}
                            required
                            error={flightDepartureDateError !== null}
                            helperText={getHelperText(flightDepartureDateError, t)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        {(() => {
                            if (flightDetectionResult === null) {
                                return null;
                            }

                            if (flightDetectionResult.found) {
                                return (
                                    <FlightFound
                                        title={`${flightNumber} schedule:`}
                                        from={{
                                            name: flightDetectionResult.departure.airport.name,
                                            iata: flightDetectionResult.departure.airport.iata,
                                            time: flightDetectionResult.departure.time,
                                        }}
                                        to={{
                                            name: flightDetectionResult.arrival.airport.name,
                                            iata: flightDetectionResult.arrival.airport.iata,
                                            time: flightDetectionResult.arrival.time,
                                        }}
                                    />
                                );
                            }
                        })()}
                    </Grid>
                </Grid>
                <Grid container alignItems="center">
                    <Grid item xs={2}>
                        <PrimaryButton
                            className={classes.searchButton}
                            loading={searchingFlight}
                            onClick={async (): Promise<void> => {
                                setFlightSearchError(false);

                                const validated = {
                                    flightNumber: isFilledString(
                                        flightNumber,
                                        InputError.Empty
                                    ),
                                    flightDepartureDate: isNotNull(
                                        flightDepartureDate,
                                        InputError.Empty
                                    ),
                                };

                                if (!allValid(validated)) {
                                    setFlightNumberError(
                                        getInputError(validated.flightNumber)
                                    );
                                    setFlightDepartureDateError(
                                        getInputError(validated.flightDepartureDate)
                                    );
                                    return;
                                }

                                setSearchingFlight(true);

                                const result = await tryGetAsync(async () => {
                                    // TODO: Cache
                                    const { data: response } = await apolloClient.query<
                                        FindFlightResult,
                                        FindFlightInput
                                    >({
                                        query: FIND_FLIGHT,
                                        variables: {
                                            flightNumber: validated.flightNumber.value,
                                            departureDate: validated.flightDepartureDate
                                                .value.format(),
                                        },
                                    });

                                    return response.scheduledFlight;
                                });

                                setSearchingFlight(false);

                                if (!result.success) {
                                    setFlightDetectionResult({ found: false });
                                    return;
                                }

                                const scheduledFlight = result.value;

                                if (scheduledFlight === null) {
                                    setFlightDetectionResult({ found: false });
                                    return;
                                }

                                const departureMoment = moment.utc(
                                    scheduledFlight.departure.departureTime
                                );

                                const arrivalMoment = moment.utc(
                                    scheduledFlight.arrival.arrivalTime
                                );

                                setScheduledDepartureAirport(
                                    scheduledFlight.departure.airport
                                );
                                setScheduledDepartureTime(departureMoment);
                                setScheduledDepartureDate(departureMoment);
                                setScheduledArrivalAirport(
                                    scheduledFlight.arrival.airport
                                );
                                setScheduledArrivalTime(arrivalMoment);
                                setScheduledArrivalDate(arrivalMoment);
                                setFlightDetectionResult({
                                    found: true,
                                    departure: {
                                        airport: scheduledFlight.departure.airport,
                                        time: departureMoment,
                                    },
                                    arrival: {
                                        airport: scheduledFlight.arrival.airport,
                                        time: arrivalMoment,
                                    },
                                });
                            }}
                        // TODO: Translate
                        >
                            Search flight
                        </PrimaryButton>
                    </Grid>
                    <Grid item xs={8}>
                        {(() => {
                            if (flightSearchError) {
                                return (
                                    <Alert
                                        severity="error"
                                        inline
                                        className={classes.flightDetectionAlert}
                                    >
                                        Please use the Search button to find a flight.
                                    </Alert>
                                );
                            }

                            if (flightDetectionResult === null) {
                                return null;
                            }

                            if (!flightDetectionResult.found) {
                                return (
                                    <Fade in>
                                        <div>
                                            <Alert
                                                severity="info"
                                                inline
                                                className={classes.flightDetectionAlert}
                                            >
                                                Flight not found. Please enter the flight details manually.
                                            </Alert>
                                        </div>
                                    </Fade>
                                );
                            }
                        })()}
                    </Grid>
                </Grid>
            </div>

            {
                flightDetectionResult === null || flightDetectionResult.found
                    ? null
                    : (
                        <>
                            <div>
                                <Typography
                                    variant="h3"
                                    component="h3"
                                    className={classes.sectionHeading}
                                >
                                    <FlightIcon
                                        className={clsx(classes.sectionHeadingIcon, classes.flightIcon)}
                                    />

                                    <span className={classes.sectionHeadingText}>
                                        Scheduled flight
                                    </span>
                                </Typography>

                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                    spacing={4}
                                >
                                    <Grid item xs={4}>
                                        <AirportField
                                            required
                                            label={t('pages.addDisruption.fields.departureAirport')}
                                            value={scheduledDepartureAirport}
                                            onChange={newValue => {
                                                setScheduledDepartureAirport(newValue);
                                                setScheduledDepartureAirportError(null);
                                            }}
                                            error={scheduledDepartureAirportError !== null}
                                            helperText={getHelperText(scheduledDepartureAirportError, t)}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <UtcTimeField
                                            value={scheduledDepartureTime}
                                            onChange={newValue => {
                                                setScheduledDepartureTime(newValue);
                                                setScheduledDepartureTimeError(null);
                                            }}
                                            required
                                            label={t('pages.addDisruption.fields.scheduledTimeDeparture')}
                                            error={scheduledDepartureTimeError !== null}
                                            helperText={getHelperText(scheduledDepartureTimeError, t)}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <UtcDateField
                                            value={scheduledDepartureDate}
                                            onChange={newValue => {
                                                setScheduledDepartureDate(newValue);
                                                setScheduledDepartureDateError(null);
                                            }}
                                            required
                                            label={t('pages.addDisruption.fields.scheduledDateDeparture')}
                                            error={scheduledDepartureDateError !== null}
                                            helperText={getHelperText(scheduledDepartureDateError, t)}
                                        />
                                    </Grid>
                                </Grid>
                            </div>

                            <div>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                    spacing={4}
                                >
                                    <Grid item xs={4}>
                                        <AirportField
                                            label={
                                                diversionType === DiversionType.Arrival
                                                    ? t('pages.addDisruption.fields.scheduledAirport')
                                                    : t('pages.addDisruption.fields.airport')
                                            }
                                            value={scheduledArrivalAirport}
                                            onChange={newValue => {
                                                setScheduledArrivalAirport(newValue);
                                                setScheduledArrivalAirportError(null);
                                            }}
                                            required
                                            error={scheduledArrivalAirportError !== null}
                                            helperText={getHelperText(scheduledArrivalAirportError, t)}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <UtcTimeField
                                            value={scheduledArrivalTime}
                                            onChange={newValue => {
                                                setScheduledArrivalTime(newValue);
                                                setScheduledArrivalTimeError(null);
                                            }}
                                            required
                                            label={t('pages.addDisruption.fields.scheduledTimeArrival')}
                                            error={scheduledArrivalTimeError !== null}
                                            helperText={
                                                getHelperText(
                                                    scheduledArrivalTimeError,
                                                    t
                                                )
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <UtcDateField
                                            value={scheduledArrivalDate}
                                            onChange={newValue => {
                                                setScheduledArrivalDate(newValue);
                                                setScheduledArrivalDateError(null);
                                            }}
                                            required
                                            label={t('pages.addDisruption.fields.scheduledDateArrival')}
                                            error={scheduledArrivalDateError !== null}
                                            helperText={getHelperText(scheduledArrivalDateError, t)}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </>
                    )
            }

            {
                diversionType === DiversionType.Departure
                    ? (
                        <div>
                            <Typography
                                variant="h3"
                                component="h3"
                                className={classes.sectionHeading}
                            >
                                <FlightTakeoffIcon className={classes.sectionHeadingIcon} />

                                <span className={classes.sectionHeadingText}>
                                    {t('pages.addDisruption.sections.alternateDeparture')}
                                </span>
                            </Typography>
                            <div className={classes.coachSubline}>
                                Coaches will pick up passengers from the alternate airport.
                            </div>


                            {departureAlternateRows}

                            <div>
                                <TertiaryButton
                                    onClick={() => {
                                        setDepartureAlternates([
                                            ...departureAlternates,
                                            {
                                                airport: null,
                                                estimatedTime: null,
                                                estimatedDate: null,
                                            },
                                        ]);
                                    }}
                                    className={classes.addAlternateButton}
                                >
                                    + {t('pages.addDisruption.buttons.addAlternate')}
                                </TertiaryButton>
                            </div>
                        </div>
                    )
                    : (
                        <div>
                            <Typography
                                variant="h3"
                                component="h3"
                                className={classes.sectionHeading}
                            >
                                <FlightLandIcon className={classes.sectionHeadingIcon} />

                                <span className={classes.sectionHeadingText}>
                                    {t('pages.addDisruption.sections.alternateArrival')}
                                </span>
                            </Typography>

                            <div className={classes.coachSubline}>
                                Coaches will pick up passengers from the alternate airport.
                            </div>

                            {arrivalAlternateRows}

                            <div>
                                <TertiaryButton
                                    onClick={() => {
                                        setArrivalAlternates([
                                            ...arrivalAlternates,
                                            {
                                                airport: null,
                                                estimatedTime: null,
                                                estimatedDate: null,
                                            },
                                        ]);
                                    }}
                                    className={classes.addAlternateButton}
                                >
                                    + {t('pages.addDisruption.buttons.addAlternate')}
                                </TertiaryButton>
                            </div>
                        </div>
                    )
            }

            <div>
                <Typography
                    variant="h3"
                    component="h3"
                    className={classes.sectionHeading}
                >
                    <PeopleIcon className={classes.sectionHeadingIcon} />

                    <span className={classes.sectionHeadingText}>
                        {t('pages.addDisruption.sections.passengers')}
                    </span>
                </Typography>

                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={4}
                >
                    <Grid item xs>
                        <IntegerField
                            value={totalPassengers}
                            onBlur={newValue => {
                                setTotalPassengers(newValue);
                                setTotalPassengersError(null);
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Persons</InputAdornment>,
                            }}
                            label={t('pages.addDisruption.fields.totalPassengers')}
                            required
                            error={totalPassengersError !== null}
                            helperText={getHelperText(totalPassengersError, t)}
                        />
                    </Grid>

                    <Grid item xs>
                        <IntegerField
                            value={infants}
                            onBlur={newValue => {
                                setInfants(newValue);
                                setInfantsError(null);
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Infants</InputAdornment>,
                            }}
                            label={t('pages.addDisruption.fields.infants')}
                            required
                            error={infantsError !== null}
                            helperText={getHelperText(infantsError, t)}
                        />
                    </Grid>

                    <Grid item xs>
                        <IntegerField
                            value={wheelchairPassengers}
                            onBlur={newValue => {
                                setWheelchairPassengers(newValue);
                                setWheelchairPassengersError(null);
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Persons</InputAdornment>,
                            }}
                            label={
                                t(
                                    'pages.addDisruption'
                                    + '.fields.restrictedMobilityPassengers'
                                )}
                            required
                            error={wheelchairPassengersError !== null}
                            helperText={getHelperText(wheelchairPassengersError, t)}
                        />
                    </Grid>
                </Grid>
            </div>

            {showPreAuth && (
                <div className={classes.bookingAuthorizationField}>
                    <Typography
                        variant="h3"
                        component="h3"
                        className={classes.sectionHeading}
                    >
                        <AirportShuttleIcon className={classes.sectionHeadingIcon} />

                        <span className={classes.sectionHeadingText}>
                            {t('pages.addDisruption.sections.coaches')}
                        </span>
                    </Typography>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={
                                    autoBookingDisabledReason === null
                                    && bookAutomatically
                                }
                                onChange={
                                    (_event, newValue) => setBookAutomatically(newValue)
                                }
                                disabled={autoBookingDisabledReason !== null}
                            />
                        }
                        label={t('pages.addDisruption.fields.bookCoachesAuthorization')}
                    />

                    {(() => {
                        // TODO: Translate
                        switch (autoBookingDisabledReason) {
                            case AutoBookingDisabledReason.MultipleAlternates:
                                return (
                                    <div>
                                        <Alert
                                            severity="info"
                                            inline
                                            className={classes.autoBookingDisabledAlert}
                                        >
                                            This option is not available with multiple alternates.
                                        </Alert>
                                    </div>
                                );
                            case AutoBookingDisabledReason.NoPreferredSuppliers:
                                return (
                                    <div>
                                        <Alert
                                            severity="info"
                                            inline
                                            className={classes.autoBookingDisabledAlert}
                                        >
                                            This option requires preferred suppliers to be available on the alternate airport.
                                        </Alert>
                                    </div>
                                );
                            case null:
                                return null;
                            default:
                                throw new Error('Unhandled AutoBookingDisabledReason');
                        }
                    })()}
                </div>
            )}

            {formErrorElement}

            <div className={clsx(formClasses.buttons, classes.buttons)}>
                <PrimaryButton onClick={submitForm} submitsForm loading={submitting}>
                    {t('pages.addDisruption.buttons.submit')}
                </PrimaryButton>

                <SecondaryButton onClick={() => history.push('/disruptions')}>
                    {t('buttons.cancel')}
                </SecondaryButton>
            </div>
        </Form>
    );
};

const EstimatedAlternateRow: FunctionComponent<{
    alternate: Alternate;
    onTimeChange: (newTime: Moment | null) => void;
    onDateChange: (newDate: Moment | null) => void;
    onAirportChange: (airport: Airport | null) => void;
    error: AlternateError | null;
}> = ({ alternate, onTimeChange, onDateChange, onAirportChange, error }) => {
    const { t } = useTranslation();

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={4}
        >
            <Grid item xs={4}>
                <AirportField
                    label={t('pages.addDisruption.fields.alternateAirport')}
                    required
                    value={alternate.airport}
                    onChange={onAirportChange}
                    error={error !== null && error.airport !== null}
                    helperText={getHelperText(error?.airport ?? null, t)}
                />
            </Grid>

            <Grid item xs={4}>
                <UtcTimeField
                    value={alternate.estimatedTime}
                    onChange={onTimeChange}
                    required
                    label={t('pages.addDisruption.fields.estimatedArrivalTime')}
                    error={error !== null && error.estimatedTime !== null}
                    helperText={getHelperText(error?.estimatedTime ?? null, t)}
                />
            </Grid>

            <Grid item xs={4}>
                <UtcDateField
                    value={alternate.estimatedDate}
                    onChange={onDateChange}
                    required
                    label={t('pages.addDisruption.fields.estimatedArrivalDate')}
                    error={error !== null && error.estimatedDate !== null}
                    helperText={getHelperText(error?.estimatedDate ?? null, t)}
                />
            </Grid>
        </Grid>
    );
};

const AlternateRow: FunctionComponent<{
    alternate: Alternate;
    number: number | null;
    onAirportChange: (airport: Airport | null) => void;
    onDateTimeChange: (newTime: Moment | null) => void;
    removable: boolean;
    onRemove: () => void;
    error: AlternateError | null;
}> = ({ alternate, number, onAirportChange, onDateTimeChange, removable, onRemove, error }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={4}
        >
            <Grid item xs={4}>
                <AirportField
                    label={
                        number === null
                            ? t('pages.addDisruption.fields.alternateAirport')
                            : t(
                                'pages.addDisruption.fields.alternateAirportNumber',
                                { number }
                            )
                    }
                    required
                    value={alternate.airport}
                    onChange={onAirportChange}
                    error={error !== null && error.airport !== null}
                    helperText={getHelperText(error?.airport ?? null, t)}
                />
            </Grid>

            <Grid item xs={4}>
                <UtcDateTimeField
                    value={
                        alternate.estimatedDate === null
                            || alternate.estimatedTime === null
                            ? null
                            : combineDateAndTime(
                                alternate.estimatedDate,
                                alternate.estimatedTime
                            )
                    }
                    onChange={onDateTimeChange}
                    required={false}
                    label={t('pages.addDisruption.fields.estimatedArrivalTime')}
                    error={error !== null && error.estimatedTime !== null}
                    helperText={getHelperText(error?.estimatedTime ?? null, t)}
                />
            </Grid>

            <Grid item xs={4}>
                {
                    removable
                        ? (
                            <>
                                <IconButton
                                    aria-label={
                                        t('pages.addDisruption.aria.removeAlternateButton')
                                    }
                                    onClick={onRemove}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                                {' '}
                                <span onClick={onRemove} className={classes.removeAlternate}>Remove alternate</span>
                            </>
                        )
                        : null
                }
            </Grid>
        </Grid>
    );
};

export default AddDisruption;
