import { PrimaryButton, SecondaryTab, TabPanel } from '@get-e/react-components';
import { Grid, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useTablePageStyles from '../useTablePageStyles';
import DeleteUserInviteDialog from './Users/DeleteUserInviteDialog';
import RemoveUserDialog from './Users/RemoveUserDialog';
import UserInvitesTab from './Users/UserInvitesTab';
import UsersSubTab from './Users/UsersTab';

export enum UserTab {
    Users,
    Invites
}

const useStyles = makeStyles({ secondaryTab: { marginRight: '1rem' } });

// eslint-disable-next-line max-lines-per-function
const UsersTab: FunctionComponent<{ tab: UserTab; customerId: string }> = ({ tab, customerId }) => {
    const { t } = useTranslation();
    const tableClasses = useTablePageStyles();
    const history = useHistory();
    const classes = useStyles();

    const [removingUser, setRemovingUser] = useState<{
        id: string;
        name: string;
    } | null>(null);

    const [deletingInvite, setDeletingInvite] = useState<{ id: string; userName: string } | null>(null);

    return (

        <div>
            {removingUser !== null && (
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                <RemoveUserDialog
                    user={removingUser}
                    onClose={() => setRemovingUser(null)}
                />
            )}

            {deletingInvite !== null && (
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                <DeleteUserInviteDialog
                    invite={deletingInvite}
                    onClose={() => setDeletingInvite(null)}
                />
            )}

            <Grid
                item
                xs={12}
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item>
                    <SecondaryTab
                        url={`/customers/${customerId}/users`}
                        index={UserTab.Users}
                        selectedIndex={tab}
                        className={classes.secondaryTab}
                    >
                        {t('pages.customer.tabs.activeUsers')}
                    </SecondaryTab>

                    <SecondaryTab
                        url={`/customers/${customerId}/invites`}
                        index={UserTab.Invites}
                        selectedIndex={tab}
                        className={classes.secondaryTab}
                    >
                        {t('pages.users.tabs.invites')}
                    </SecondaryTab>
                </Grid>

                <Grid item className={tableClasses.addButton}>
                    <PrimaryButton
                        onClick={() => {
                            history.push(`/customers/${customerId}/users/invite`);
                        }}
                        icon={<AddIcon />}
                    >
                        {t('pages.users.button.invite')}
                    </PrimaryButton>
                </Grid>
            </Grid>

            <TabPanel value={UserTab.Users} selectedValue={tab}>
                <UsersSubTab
                    onEditUser={user => {
                        history.push(`/customers/${customerId}/users/${user.id}`);
                    }}
                    onRemoveUser={user => setRemovingUser(user)}
                    customerId={customerId}
                />
            </TabPanel>

            <TabPanel value={UserTab.Invites} selectedValue={tab}>
                <UserInvitesTab
                    onDeleteInvite={invite => setDeletingInvite(invite)}
                    customerId={customerId}
                />
            </TabPanel>
        </div>
    );
};

export default UsersTab;
