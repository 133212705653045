import gql from 'graphql-tag';

export const SET_MEETING_POINT = gql`
    mutation SET_MEETING_POINT($input: SetRideAvailabilityRequestMeetingPointInput !) {
        setRideAvailabilityRequestMeetingPoint(
            input: $input
        ) {
            meetingPoint
        }
    }
`;

export interface SetMeetingPointInput {
    input: {
        requestId: string;
        meetingPoint: string;
    };
}

export interface SetMeetingPointResult {
    setRideAvailabilityRequestMeetingPoint: {
        meetingPoint: string;
    };
}
