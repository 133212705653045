import { TFunction } from 'i18next';
import moment from 'moment';
import { MomentLocale } from './formatRelativeTime';

/**
 * Formats route prediction to user friendly string.
 * E.g. "93KM in ±25 min"
 */
export default function formatRoutePrediction(
    durationInSeconds: number,
    distanceInMeters: number,
    t: TFunction,
    locale: MomentLocale,
): string {
    let distanceString = `${distanceInMeters}m`;

    if (distanceInMeters >= 1000) {
        distanceString = `${Math.floor(distanceInMeters / 1000)}km`;
    }

    const durationString = moment
        .duration(durationInSeconds, 'seconds')
        .locale(locale)
        .humanize();

    return t('routePrediction', {
        distance: distanceString,
        duration: durationString,
    });
}
