import gql from 'graphql-tag';

export const ACCEPT_CUSTOMER_USER_INVITE = gql`
    mutation acceptCustomerUserInvite($input: AcceptCustomerUserInviteInput!) {
        acceptCustomerUserInvite(input: $input) {
            name
            email
        }
    }
`;

export interface AcceptCustomerUserInviteInput {
    input: {
        password: string;
        token: string;
    };
}

export interface AcceptCustomerUserInviteResult {
    acceptCustomerUserInvite: {
        name: string;
        email: string;
    };
}
