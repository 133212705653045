/* eslint-disable max-lines */
import { Grid, IconButton, TableCell, TableRow } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import FlashIcon from '@material-ui/icons/FlashOn';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AvailabilityDeleteModal from '../modals/AvailabilityDeleteModal';
import AvailabilityEditModal from '../modals/AvailabilityEditModal';
import AvailabilityStatus from './AvailabilityStatus';
import useRowStyles from './useRowStyles';

export interface AvailabilityRowProps {
    id: string;
    timeInMinutes: number;
    smallTime: string;
    seats: number;
    description?: string;
}

export const TimeCell: FunctionComponent<{
    value: string | number;
    icon: React.ReactNode;
    smallText: string;
}> = ({ value, icon, smallText }) => {
    const rowClasses = useRowStyles();

    return (
        <Grid item className={rowClasses.timeCell}>
            <Grid container alignItems="center"><div>{icon} {' '} {value}</div></Grid>
            <div className={rowClasses.smallTime}>{smallText}</div>
        </Grid>
    );
};

export const AvailabilityRow: FunctionComponent<{
    availability: AvailabilityRowProps;
    refetchRideAvailabilities: () => void;
    hasRides: boolean;
}> = ({ availability, refetchRideAvailabilities, hasRides }) => {
    const rowClasses = useRowStyles();
    const { t } = useTranslation();
    const [openDeleteAvailability, setOpenDeleteAvailability] = useState(false);
    const [openEditAvailability, setOpenEditAvailability] = useState(false);

    return (
        <>
            <TableRow>
                {hasRides && (
                    <TableCell />
                )}
                <TableCell className={rowClasses.statusContainer}>
                    <AvailabilityStatus/>
                </TableCell>
                <TableCell>
                    <TimeCell
                        value={`${availability.timeInMinutes} min`}
                        icon={
                            <FlashIcon
                                className={rowClasses.timeIcon}
                                fontSize="inherit"
                            />
                        }
                        smallText={availability.smallTime}
                    />
                </TableCell>
                <TableCell>
                    {t('pages.availability.persons', { count: availability.seats })}
                </TableCell>
                <TableCell className={rowClasses.description}>
                    {availability.description || '-'}
                </TableCell>
                <TableCell>
                    - <br />
                    -
                </TableCell>
                <TableCell>
                    <IconButton
                        className={rowClasses.root}
                        aria-label={t('pages.availability.aria.editAvailability')}
                        onClick={() => setOpenEditAvailability(true)}
                    >
                        <EditIcon />
                    </IconButton>
                </TableCell>
                <TableCell>
                    <IconButton
                        className={rowClasses.root}
                        aria-label={t('pages.availability.aria.deleteAvailability')}
                        onClick={() => setOpenDeleteAvailability(true)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </TableCell>

            </TableRow>

            <AvailabilityEditModal
                onClose={() => setOpenEditAvailability(false)}
                onEdited={refetchRideAvailabilities}
                open={openEditAvailability}
                availability={availability}
            />

            <AvailabilityDeleteModal
                onClose={() => setOpenDeleteAvailability(false)}
                onDeleted={refetchRideAvailabilities}
                open={openDeleteAvailability}
                availability={availability}
            />
        </>
    );
};
