import { Modal, PrimaryButton, SecondaryButton } from '@get-e/react-components';
import { Typography } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import delay from '../../../helpers/delay';
import useModalStyles from '../../../styles/useModalStyles';

async function uncancelRequest(requestId: string): Promise<void> {
    // TODO: Implement
    // eslint-disable-next-line no-console
    console.log(`Uncancelling availability request ${requestId}`);

    await delay(2000);
}

const UncancelAvailabilityRequestDialog: FunctionComponent<{
    requestId: string;
    supplierName: string;
    onClose: () => void;
}> = ({ requestId, supplierName, onClose }) => {
    const [uncancelling, setUncancelling] = useState(false);
    const modalClasses = useModalStyles();

    async function handleClick(): Promise<void> {
        setUncancelling(true);
        await uncancelRequest(requestId);
        setUncancelling(false);
    }

    // TODO: Add translations

    return (
        <Modal
            open={true}
            onClose={onClose}
        >
            <div className={modalClasses.header}>
                <Typography variant="h2" component="h2" className={modalClasses.heading}>
                    Uncancel availability request
                </Typography>
            </div>

            <p>
                This will re-request availability from <strong>{supplierName}</strong>.
            </p>

            <div className={modalClasses.buttonContainer}>
                <span className={modalClasses.primaryButton}>
                    <PrimaryButton
                        onClick={handleClick}
                        loading={uncancelling}
                    >
                        Uncancel
                    </PrimaryButton>
                </span>

                <SecondaryButton
                    onClick={onClose}
                    disabled={uncancelling}
                >
                    Cancel
                </SecondaryButton>
            </div>
        </Modal>
    );
};

export default UncancelAvailabilityRequestDialog;
