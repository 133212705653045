import { Grid, makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import emptyActive from './empty-active.svg';
import emptyArchive from './empty-archive.svg';

const useStyles = makeStyles(theme => ({
    emptyText: {
        marginTop: theme.spacing(4),
        textAlign: 'center',
        color: theme.palette.grey[200],
    },
}));

export const EmptyActive: FunctionComponent = () => {
    const classes = useStyles();

    return (
        <>
            <img src={emptyActive} alt="No active disruptions" />
            <Grid item className={classes.emptyText}>
                <strong>
                    No active disruptions to display.
                </strong><br />
                See the archive or add a new disruption.
            </Grid>
        </>
    );
};

export const EmptyArchive: FunctionComponent = () => {
    const classes = useStyles();

    return (
        <>
            <img src={emptyArchive} alt="No archived disruptions" />
            <Grid item className={classes.emptyText}>
                <strong>
                    No archived disruptions to display.
                </strong><br />
                Disruptions will be visible in the archive once completed or cancelled.
            </Grid>
        </>
    );
};
