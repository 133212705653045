import { Heading, Modal, PrimaryButton, SecondaryButton } from '@get-e/react-components';
import React, { FunctionComponent, useState } from 'react';
import delay from '../../../helpers/delay';
import useModalStyles from '../../../styles/useModalStyles';

async function cancelRequest(requestId: string): Promise<void> {
    // eslint-disable-next-line no-console
    console.log(`Cancelling availability request ${requestId}`);

    await delay(2000);
}

const CancelAvailabilityRequestDialog: FunctionComponent<{
    requestId: string;
    supplierName: string;
    onClose: () => void;
}> = ({ requestId, supplierName, onClose }) => {
    const [cancelling, setCancelling] = useState(false);
    const modalClasses = useModalStyles();

    async function handleClick(): Promise<void> {
        setCancelling(true);
        await cancelRequest(requestId);
        setCancelling(false);
    }

    // TODO: Add translations

    return (
        <Modal
            open={true}
            onClose={onClose}
        >
            <div className={modalClasses.header}>
                <Heading level={2}>
                    Cancel availability request
                </Heading>
            </div>

            <p>
                This will prevent <strong>{supplierName}</strong> from responding to it.
            </p>

            <div className={modalClasses.buttonContainer}>
                <span className={modalClasses.primaryButton}>
                    <PrimaryButton
                        onClick={handleClick}
                        loading={cancelling}
                        variation="danger"
                    >
                        Cancel request
                    </PrimaryButton>
                </span>

                <SecondaryButton
                    onClick={onClose}
                    disabled={cancelling}
                >
                    Keep request
                </SecondaryButton>
            </div>
        </Modal>
    );
};

export default CancelAvailabilityRequestDialog;
