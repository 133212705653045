import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import WheelchairIcon from '@material-ui/icons/AccessibleRounded';
import FlightIcon from '@material-ui/icons/AirplanemodeActiveOutlined';
import InfantsIcon from '@material-ui/icons/ChildCareOutlined';
import EstimatedIcon from '@material-ui/icons/DirectionsOutlined';
import MeetingPointIcon from '@material-ui/icons/GpsFixed';
import TotalIcon from '@material-ui/icons/PeopleOutlineOutlined';
import NameIcon from '@material-ui/icons/PersonOutlineOutlined';
import FromIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import ToIcon from '@material-ui/icons/RoomOutlined';
import TimerIcon from '@material-ui/icons/Timer';
import moment from 'moment-timezone';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import RelativeTime from '../../../components/RelativeTime';
import { useLocaleContext } from '../../../context/LocaleContext';
import formatRoutePrediction from '../../../helpers/formatRoutePrediction';
import getValue from '../../../helpers/getValue';
import createLocationName from '../../Disruption/helpers/createLocationName';
import { DrivingPoint } from '../../Disruption/useCoachRoutes';
import { AvailabilityRequestProps } from './index.graphql';
import MeetingPointForm from './MeetingPointForm';

const useStyles = makeStyles(theme => createStyles({
    label: { margin: theme.spacing(0, 1, 0, 2) },
    dataRow: { margin: theme.spacing(0.5, 0) },
    dataGroup: {
        width: '100%',
        '&:not(:last-child)': { marginBottom: theme.spacing(2) },
    },
    meetingPointForm: { margin: theme.spacing(2, 0, 0, 4) },
}));

const isArrivalDiversion = true;

const DataRow: FunctionComponent<{
    label: string;
    value: React.ReactNode;
    icon: JSX.Element;
}> = ({ label, value, icon }) => {
    const classes = useStyles();

    return (
        <Grid
            container
            alignItems="center"
            className={classes.dataRow}
        >
            {icon}{' '}
            <strong className={classes.label}>{label}</strong>{' '}
            {value}
        </Grid>
    );
};

const DataGroup: FunctionComponent<{
    children: React.ReactNode;
}> = ({ children }) => {
    const classes = useStyles();

    return (
        <div className={classes.dataGroup}>
            {children}
        </div>
    );
};

const AvailabilityRequestData: FunctionComponent<{
    availabilityRequest: AvailabilityRequestProps;
    hasRides: boolean;
}> = ({ availabilityRequest, hasRides }) => {
    const {
        disruption,
        points,
        routePrediction,
    } = availabilityRequest;

    const from = points.nodes[0].location;
    const to = points.nodes[points.nodes.length - 1].location;

    const via = getValue((): DrivingPoint[] | null => {
        if (!points.nodes.length) {
            return null;
        }

        return points.nodes.slice(1, -1);
    });

    const { t } = useTranslation();
    const classes = useStyles();
    const { locale } = useLocaleContext();

    return (
        <Grid container>
            <DataGroup>
                <DataRow
                    icon={<TimerIcon fontSize="small" />}
                    label={t('pages.availability.labels.pickupTime')}
                    value={
                        <RelativeTime
                            date={moment(availabilityRequest.pickupTime)}
                            timeZone={availabilityRequest.pickupPoint.location.timeZone}
                        />
                    }
                />
            </DataGroup>
            <DataGroup>
                <DataRow
                    icon={<FromIcon fontSize="small" />}
                    label={t('pages.availability.labels.from')}
                    value={createLocationName(from)}
                />
                {
                    via
                        ? (
                            via.map((point, index) => {
                                if (point.location.__typename !== 'Airport') {
                                    return null;
                                }

                                return (
                                    <DataRow
                                        key={index}
                                        icon={<ToIcon fontSize="small" />}
                                        label={t('pages.availability.labels.via')}
                                        value={createLocationName(point.location)}
                                    />
                                );
                            })
                        )
                        : null
                }
                <DataRow
                    icon={<ToIcon fontSize="small" />}
                    label={t('pages.availability.labels.to')}
                    value={createLocationName(to)}
                />
                <DataRow
                    icon={<EstimatedIcon fontSize="small" />}
                    label={t('pages.availability.labels.estimated')}
                    value={formatRoutePrediction(
                        routePrediction.durationInSeconds,
                        routePrediction.distanceInMeters,
                        t,
                        locale
                    )}
                />
            </DataGroup>
            <DataGroup>
                <DataRow
                    icon={<NameIcon fontSize="small" />}
                    label={t('pages.availability.labels.name')}
                    value={`${disruption.customer.name} ${disruption.flight.number}`}
                />
                <DataRow
                    icon={<TotalIcon fontSize="small" />}
                    label={t('pages.availability.labels.total')}
                    value={t(
                        'pages.availability.persons',
                        { count: disruption.flight.passengerCount.total }
                    )}
                />
                <DataRow
                    icon={<InfantsIcon fontSize="small" />}
                    label={t('pages.availability.labels.infants')}
                    value={t(
                        'pages.availability.infants',
                        { count: disruption.flight.passengerCount.infants }
                    )}
                />
                <DataRow
                    icon={<WheelchairIcon fontSize="small" />}
                    label={t('pages.availability.labels.wheelchair')}
                    value={t(
                        'pages.availability.persons',
                        { count: disruption.flight.passengerCount.wheelchair }
                    )}
                />
            </DataGroup>
            <DataGroup>
                <DataRow
                    icon={<FlightIcon fontSize="small" />}
                    label={t('pages.availability.labels.flight')}
                    value={getValue(() => {
                        if (
                            to.__typename === 'Airport'
                            && from.__typename === 'Airport'
                        ) {
                            return t('pages.availability.divertingFlight', {
                                flightNr: disruption.flight.number,
                                from: isArrivalDiversion ? to.iata : from.iata,
                                to: isArrivalDiversion ? from.iata : to.iata,
                            });
                        }

                        throw new Error('Unsupported location');
                    })

                    }
                />
            </DataGroup>
            <DataGroup>
                <DataRow
                    icon={<MeetingPointIcon fontSize="small" />}
                    label={t('pages.availability.labels.meetingPoint')}
                    value={null}
                />
                <div className={classes.meetingPointForm}>
                    <MeetingPointForm
                        disabled={!hasRides}
                        requestId={availabilityRequest.id}
                        initialValue={availabilityRequest.meetingPoint}
                        tooltip={
                            hasRides
                                ? undefined
                                : t('pages.availability.alerts.meetingPointDisabled')
                        }
                    />
                </div>
            </DataGroup>
        </Grid>
    );
};

export default AvailabilityRequestData;
