import gql from 'graphql-tag';

export const GET_AIRPORTS = gql`
query GetAirports($filter: AirportFilter) {
    airports(first: 5, filter: $filter, orderBy: { field: IATA, direction: ASC }) {
        nodes {
            id
            iata
            name
        }
    }
}
`;

export interface GetAirportsInput {
    filter: null | { search: string };
}

export interface GetAirportsResult {
    airports: {
        nodes: Array<{
            id: string;
            iata: string;
            name: string;
        }>;
    };
}
