import i18next, { ResourceLanguage } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { SupportedLocale } from '../context/LocaleContext';
import report from '../helpers/report';
import english from './en-GB.json';
import french from './fr-FR.json';
import dutch from './nl-NL.json';
import polish from './pl-PL.json';

const resources: {
    [locale in SupportedLocale]: ResourceLanguage
} = {
    'en-GB': { translation: english },
    'pl-PL': { translation: polish },
    'nl-NL': { translation: dutch },
    'fr-FR': { translation: french },
};

// eslint-disable-next-line @typescript-eslint/no-floating-promises
const initializing = i18next
    .use(initReactI18next)
    .init({
        resources,
        saveMissing: true,
        missingKeyHandler(_lngs, _ns, key) {
            report(new Error(`Missing key "${key}" for locale ${i18next.language}`));
        },
        parseMissingKeyHandler() {
            return '????';
        },

        // React renderer already escapes text nodes, this prevents double escaping
        interpolation: { escapeValue: false },
    });

export async function setLocale(locale: SupportedLocale): Promise<void> {
    await initializing;
    await i18next.changeLanguage(locale);
}
