/* eslint-disable max-len */
/* eslint-disable no-negated-condition */
import { TextField, TextFieldProps } from '@get-e/react-components';
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles, MenuItem, Select } from '@material-ui/core';
import React, { forwardRef, FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput,
{ CountrySelectComponentProps }
    from 'react-phone-number-input';
import international from 'react-phone-number-input/icons/international-icon-3x2.svg';

const useStyles = makeStyles(() => createStyles({
    inputContainer: {
        width: '100%',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
        },
    },
    selectContainer: {
        width: '80px',
        marginBottom: '27px',
        maxHeight: '56px',
        overflow: 'hidden',
        '& .MuiSelect-root': { paddingBottom: '20px' },
    },
}));

const MuiPhoneInput: FunctionComponent<React.InputHTMLAttributes<HTMLInputElement> & { fieldProps: TextFieldProps; refs: React.Ref<unknown> }> = props => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { refs, fieldProps, ...rest } = props;

    return (
        <TextField
            {...fieldProps}
            inputRef={refs}
            inputProps={{
                ...rest,
                disabled: fieldProps.disabled
            }}
        />
    );
};

const MuiSelect: FunctionComponent<CountrySelectComponentProps> = props => {
    const classes = useStyles();
    const { t } = useTranslation();

    const newProps = props as {
        iconComponent: FunctionComponent<{
            country: unknown;
            label: unknown;
        }>;
    };

    const newOptions = props.options?.map(option => {
        if (!option.value) {
            option.value = 'ZZ';
        }

        return option;
    });

    return (
        <Select
            className={classes.selectContainer}
            value={props.value ?? 'ZZ'}
            variant="filled"
            disabled={props.disabled}
            onChange={event => {
                if (typeof event.target.value === 'string') {
                    props.onChange?.(event.target.value);
                }
            }}
            renderValue={val => {
                if (val !== 'ZZ') {
                    return <newProps.iconComponent country={val} label={val} />;
                }

                return <img src={international} alt={t('components.phoneNumber.international')} />;
            }}
        >
            {
                newOptions?.map(({ value, label }) => (
                    <MenuItem value={value} key={`${value ?? ''}-${label}`}>
                        <>{label}</>
                    </MenuItem>
                ))
            }
        </Select >
    );
};

const forwardedInput = (fieldProps: TextFieldProps): React.ForwardRefExoticComponent<
React.InputHTMLAttributes<HTMLInputElement>
& React.RefAttributes<React.FunctionComponent<React.InputHTMLAttributes<HTMLInputElement>
& {
    fieldProps: TextFieldProps;
    refs: React.Ref<unknown>;
}>>
> => {
    const forwardInput = forwardRef<typeof MuiPhoneInput, React.InputHTMLAttributes<HTMLInputElement>>(
        (props, ref) => <MuiPhoneInput fieldProps={fieldProps} refs={ref} {...props} />
    );

    forwardInput.displayName = 'ForwardedInput';

    return forwardInput;
};

interface PhoneProps {
    onChange: (event: string) => void;
    value: string;
    label: string;
    required?: boolean;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
}

const PhoneNumberField: FunctionComponent<PhoneProps> = ({ onChange, value, label, required, error, helperText, disabled }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const memoizedInput = useMemo(() => forwardedInput({
        label,
        error,
        required,
        helperText,
        disabled,
    }), [
        label,
        error,
        required,
        helperText,
        disabled,
    ]);

    const memoizedSelect = useMemo(
        () => {
            const MemoSelect = (props: CountrySelectComponentProps): JSX.Element => <MuiSelect {...props} disabled={disabled} />;

            return MemoSelect;
        }
        , [disabled]
    );

    return (
        <div className={classes.inputContainer}>
            <PhoneInput
                placeholder={t('components.phoneNumber.placeholder')}
                value={value}
                onChange={onChange}
                inputComponent={memoizedInput}
                countrySelectComponent={memoizedSelect}
                international
            />
        </div>
    );
};

export default PhoneNumberField;
