import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useCurrentUserContext } from '../../../../context/CurrentUserContext';
import {
    BreadcrumbHeadNode, BreadcrumbNode,
    PageDefinition,
} from '../../../../createRoutes';
import report from '../../../../helpers/report';
import Page from '../../../../layouts/Page';
import {
    GET_CUSTOMER_NAME, GetCustomerNameInput,
    GetCustomerNameResult,
} from '../../../../queries/GetCustomerName.graphql';
import Content from './Content';

const inviteCustomerUserPage: PageDefinition = {
    path: '/customers/:id/users/invite',
    // eslint-disable-next-line max-lines-per-function
    Component({ getParam }) {
        const { t } = useTranslation();
        const id = getParam('id');
        const currentUser = useCurrentUserContext();

        const { data, loading, error } = useQuery<GetCustomerNameResult, GetCustomerNameInput>(
            GET_CUSTOMER_NAME,
            {
                variables: { id },
                onError(apolloError) {
                    report(apolloError);
                    throw apolloError;
                },
            }
        );

        const customerName = ((): BreadcrumbNode['name'] => {
            if (loading || error) {
                // Note: In case of an error the user will see the error boundary instead
                return <Skeleton width="7ch" />;
            }

            if (!data || !data.customer) {
                return t('breadcrumbs.unknownCustomer');
            }

            return data.customer.name;
        })();

        // eslint-disable-next-line max-lines-per-function
        const breadcrumbs = ((): BreadcrumbHeadNode => {
            const multipleCustomers = currentUser.isBackOffice
                || currentUser.managedCustomer === 'MULTIPLE';

            if (multipleCustomers) {
                return {
                    name: t('breadcrumbs.inviteCustomerUser'),
                    previous: {
                        name: customerName,
                        path: `/customers/${id}/users`,
                        previous: {
                            name: t('breadcrumbs.customers'),
                            path: '/customers',
                        },
                    },
                };
            }

            return {
                name: t('breadcrumbs.inviteCustomerUser'),
                previous: {
                    name: t('breadcrumbs.customerUsers'),
                    path: `/customers/${id}/users`,
                },
            };
        })();

        return (
            <Page breadcrumbs={breadcrumbs}>
                <Content customerId={id} />
            </Page>
        );
    },
};

export default inviteCustomerUserPage;
