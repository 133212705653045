import { useMutation } from '@apollo/react-hooks';
import {
    Alert,
    Form, PrimaryButton, SecondaryButton,
    Select, SelectOption, TextField,
} from '@get-e/react-components';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Airport } from '../../../../components/AirportsSelector';
import AirportsWhitelistField from '../../../../components/AirportsWhitelistField';
import tryGetAsync from '../../../../helpers/tryGetAsync';
import allValid from '../../../../helpers/validation/allValid';
import FormError from '../../../../helpers/validation/FormError';
import getFormErrorMessage from '../../../../helpers/validation/getFormErrorMessage';
import getHelperText from '../../../../helpers/validation/getHelperText';
import getInputError from '../../../../helpers/validation/getInputError';
import InputError from '../../../../helpers/validation/InputError';
import isFilledArray from '../../../../helpers/validation/validators/isFilledArray';
import isFilledString from '../../../../helpers/validation/validators/isFilledString';
import isNotNull from '../../../../helpers/validation/validators/isNotNull';
import isNull from '../../../../helpers/validation/validators/isNull';
import or from '../../../../helpers/validation/validators/or';
import {
    UpdateUserResult,
    UpdateUserInput,
    UPDATE_USER,
} from '../../../../queries/UpdateUser.graphql';

import useFormStyles from '../../../../styles/useFormStyles';
import { CustomerUser } from './Content';

import {
    UpdateCustomerUserResult,
    UpdateCustomerUserInput,
    UPDATE_CUSTOMER_USER,
} from './EditUserForm.graphql';

const EditUserForm: FunctionComponent<{
    customerId: string;
    user: CustomerUser;
    multipleOrganisations: boolean;
    onCancel: () => void;
    onSaved: () => void;
    // eslint-disable-next-line max-statements, max-lines-per-function
}> = ({ customerId, onCancel, user, multipleOrganisations }) => {
    const formClasses = useFormStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const [name, setName] = useState(user.name);
    const [roleId, setRoleId] = useState(user.customerUserRole);

    const [
        airportWhitelist,
        setAirportWhitelist,
    ] = useState<Airport[] | null>(user.airportWhitelist);

    const [formError, setFormError] = useState<FormError | null>(null);
    const [nameError, setNameError] = useState<InputError | null>(null);
    const [roleError, setRoleError] = useState<InputError | null>(null);

    const [
        airportsWhitelistError,
        setAirportsWhitelistError,
    ] = useState<InputError | null>(null);

    const [
        updateUser,
        { loading: submittingUser },
    ] = useMutation<UpdateUserResult, UpdateUserInput>(
        UPDATE_USER
    );

    const [
        updateCustomerUser,
        { loading: submittingCustomerUser },
    ] = useMutation<UpdateCustomerUserResult, UpdateCustomerUserInput>(
        UPDATE_CUSTOMER_USER
    );

    async function submitForm(): Promise<void> {
        const validated = {
            name: isFilledString(name, InputError.Empty),
            airportWhitelist: or(
                isNull(airportWhitelist, InputError.Empty),
                () => isFilledArray(airportWhitelist, InputError.Empty),
            ),
            customerRoleId: isNotNull(roleId, InputError.Empty),
        };

        if (!allValid(validated)) {
            setFormError(FormError.UserError);
            setAirportsWhitelistError(getInputError(validated.airportWhitelist));
            setNameError(getInputError(validated.name));
            setRoleError(getInputError(validated.customerRoleId));
            return;
        }

        setFormError(null);

        const result = await tryGetAsync(async () => {
            const updateUserPromise = updateUser({
                variables: {
                    input: {
                        id: user.id,
                        name: validated.name.value,
                    },
                },
            });

            const updateCustomerUserPromise = updateCustomerUser({
                variables: {
                    input: {
                        userId: user.id,
                        customerRoleId: validated.customerRoleId.value,
                        airportWhitelist: airportWhitelist?.map(ap => ap.id) ?? null,
                    },
                },
            });

            await Promise.all([updateUserPromise, updateCustomerUserPromise]);
        });

        if (!result.success) {
            setFormError(FormError.UnexpectedError);
            return;
        }

        history.push(`/customers/${customerId}/users`);
    }

    return (
        <Form
            autoComplete="off"
            className={formClasses.form}
            noValidate
            onSubmit={() => submitForm()}
        >
            <TextField
                value={name}
                onChange={event => {
                    setName(event.target.value);
                    setNameError(null);
                }}
                error={nameError !== null}
                helperText={getHelperText(nameError, t)}
                label={t('pages.users.fields.name')}
                disabled={multipleOrganisations}
                required
            />

            <Select
                label={t('pages.editCustomerUser.fields.role')}
                value={roleId}
                onChange={newRoleId => {
                    setRoleId(newRoleId);
                    setRoleError(null);
                }}
                error={roleError !== null}
                helperText={getHelperText(roleError, t)}
            >
                <SelectOption value="" empty>
                    {t('fields.emptySelectValue')}
                </SelectOption>

                {user.customerRoles.map(role => (
                    <SelectOption key={role.id} value={role.id}>
                        {role.name}
                    </SelectOption>
                ))}
            </Select>

            <AirportsWhitelistField
                label={t('pages.editCustomerUser.fields.airportsWhitelist.label')}
                modalTitle={
                    t('pages.editCustomerUser.fields.airportsWhitelist.modal.title')
                }
                choicesListHeading={
                    t('pages.editCustomerUser.fields.airportsWhitelist.modal.choices')
                }
                chosenListHeading={
                    t('pages.editCustomerUser.fields.airportsWhitelist.modal.chosen')
                }
                values={airportWhitelist}
                onChange={newValue => {
                    setAirportWhitelist(newValue);
                    setAirportsWhitelistError(null);
                }}
                error={airportsWhitelistError}
            />

            {
                formError === null
                    ? null
                    : (
                        <Alert
                            severity="error"
                            className={formClasses.mainError}
                        >
                            {getFormErrorMessage(formError, t)}
                        </Alert>
                    )
            }

            <div className={formClasses.buttons}>
                <PrimaryButton
                    loading={submittingUser || submittingCustomerUser}
                    onClick={event => {
                        event.preventDefault();

                        // eslint-disable-next-line max-len
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        submitForm();
                    }}
                >
                    {t('buttons.save')}
                </PrimaryButton>

                <SecondaryButton
                    onClick={onCancel}
                    disabled={submittingUser || submittingCustomerUser}
                >
                    {t('buttons.cancel')}
                </SecondaryButton>
            </div>
        </Form>
    );
};

export default EditUserForm;
