import { Skeleton } from '@material-ui/lab';
import React, { FunctionComponent } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useCurrentUserContext } from '../../../context/CurrentUserContext';
import { BreadcrumbHeadNode } from '../../../createRoutes';
import report from '../../../helpers/report';
import Page from '../../../layouts/Page';
import { GET_CUSTOMER_NAME, GetCustomerNameInput, GetCustomerNameResult } from '../../../queries/GetCustomerName.graphql';
import Content, { UserTab } from './Content';

// This is the non-back office view
const CustomerUsersPage: FunctionComponent<{ customerId: string }> = ({ customerId: id }) => {
    const { t } = useTranslation();
    const currentUser = useCurrentUserContext();

    const { data, loading, error } = useQuery<GetCustomerNameResult, GetCustomerNameInput>(
        GET_CUSTOMER_NAME,
        {
            variables: { id },
            onError(apolloError) {
                report(apolloError);
                throw apolloError;
            },
        }
    );

    const customerName = ((): BreadcrumbHeadNode['name'] => {
        if (loading || error) {
            // Note: In case of an error the user will see the error boundary instead
            return <Skeleton width="7ch" />;
        }

        if (!data || !data.customer) {
            return t('breadcrumbs.unknownCustomer');
        }

        return data.customer.name;
    })();

    const breadcrumbs = ((): BreadcrumbHeadNode => {
        if (currentUser.managedCustomer === 'MULTIPLE') {
            return {
                name: customerName,
                previous: {
                    name: t('breadcrumbs.customers'),
                    path: '/customers',
                },
            };
        }

        return { name: t('breadcrumbs.customerUsers') };
    })();

    return (
        <Page breadcrumbs={breadcrumbs}>
            <Content
                customerId={id}
                tab={UserTab.Users}
            />
        </Page>
    );
};

export default CustomerUsersPage;

