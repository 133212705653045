import gql from 'graphql-tag';

export const GET_DRIVERS = gql`
    query getSupplierDrivers(
        $id: ID!
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $filter: SupplierDriversConnectionFilterInput
    ) {
        supplier(id: $id) {
            id
            drivers(
                first: $first,
                last: $last,
                after: $after,
                before: $before,
                filter: $filter
            ) {
                nodes {
                    id
                    name
                    phoneNumber
                }
                totalCount
                pageInfo {
                    hasPreviousPage
                    hasNextPage
                    startCursor
                    endCursor
                }
            }
        }
    }
`;

export type GetDriversInput = {
    id: string;
    first: number;
    after?: string;
    filter: null | { search: string };
} | {
    id: string;
    last: number;
    before?: string;
    filter: null | { search: string };
};

export interface GetDriversResult {
    supplier: {
        drivers: {
            nodes: Array<{
                id: string;
                name: string;
                phoneNumber: string;
            }>;
            totalCount: number;
            pageInfo: {
                hasPreviousPage: boolean;
                hasNextPage: boolean;
                startCursor: string | null;
                endCursor: string | null;
            };
        };
    };
}
