import { MenuListItem } from '@get-e/react-components';
import { List } from '@material-ui/core';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import BusinessIcon from '@material-ui/icons/Business';
import FlightIcon from '@material-ui/icons/Flight';
import ListIcon from '@material-ui/icons/ListOutlined';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath } from 'react-router-dom';
import driverIcon from '../../pages/Drivers/driverMenuIcon.svg';

function onPage(path: string): boolean {
    return Boolean(matchPath(window.location.pathname, {
        path,
        exact: true,
        strict: true,
    }));
}

const SupplierOnly: FunctionComponent<{
    supplier: { id: string; manageUsers: boolean };
}> = ({ supplier }) => {
    const { t } = useTranslation();
    const { id, manageUsers } = supplier;

    return (
        <>
            <MenuListItem
                icon={<ListIcon fontSize="small" />}
                label={t('navigation.requests')}
                href={`/suppliers/${id}/requests`}
                selected={
                    onPage(`/suppliers/${id}/requests`)
                    || onPage('/requests/*')
                }
            />

            <MenuListItem
                icon={<img src={driverIcon} alt="Drivers" />}
                label={t('navigation.drivers')}
                href={`/suppliers/${id}/drivers`}
                selected={
                    onPage(`/suppliers/${id}/drivers`)
                    || onPage(`/suppliers/${id}/drivers/*`)
                }
            />

            {manageUsers && (
                <MenuListItem
                    icon={<PeopleIcon fontSize="small" />}
                    label={t('navigation.users')}
                    href={`/suppliers/${id}/users`}
                    selected={
                        onPage(`/suppliers/${id}/users`)
                        || onPage(`/suppliers/${id}/users/*`)
                        || onPage(`/suppliers/${id}/invites`)
                    }
                />
            )}
        </>
    );
};

const CustomerOnly: FunctionComponent<{
    customer: {
        id: string;
        name: string;
        customerPermissions: {
            manageUsers: boolean;
        };
    };
}> = ({ customer }) => {
    const { t } = useTranslation();
    const { id, customerPermissions } = customer;

    return (
        <>
            <MenuListItem
                icon={<FlightIcon fontSize="small" />}
                label={t('navigation.overview')}
                href="/disruptions"
                selected={onPage('/disruptions') || onPage('/disruptions/*')}
            />
            {customerPermissions.manageUsers && (
                <MenuListItem
                    icon={<PeopleIcon fontSize="small" />}
                    label={t('navigation.users')}
                    href={`/customers/${id}/users`}
                    selected={
                        onPage(`/customers/${id}/users`)
                        || onPage(`/customers/${id}/invites`)
                        || onPage(`/customers/${id}/users/*`)
                    }
                />
            )}
        </>
    );
};

const CustomersOnly: FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <>
            <MenuListItem
                icon={<FlightIcon fontSize="small" />}
                label={t('navigation.overview')}
                href="/disruptions"
                selected={onPage('/disruptions') || onPage('/disruptions/*')}
            />

            <MenuListItem
                icon={<BusinessIcon fontSize="small" />}
                label={t('navigation.organizations')}
                href="/customers"
                selected={
                    onPage('/customers')
                    || onPage('/customers/*')
                }
            />
        </>
    );
};

const SupplierAndCustomer: FunctionComponent<{
    supplierId: string;
    customerId: string;
}> = ({ supplierId, customerId }) => {
    const { t } = useTranslation();

    return (
        <>
            <MenuListItem
                icon={<FlightIcon fontSize="small" />}
                label={t('navigation.disruptions')}
                href="/disruptions"
                selected={onPage('/disruptions') || onPage('/disruptions/*')}
            />

            <MenuListItem
                icon={<ListIcon fontSize="small" />}
                label={t('navigation.requests')}
                href={`/suppliers/${supplierId}/requests`}
                selected={
                    onPage(`/suppliers/${supplierId}/requests`)
                    || onPage('/requests/*')
                }
            />

            <MenuListItem
                icon={<BusinessIcon fontSize="small" />}
                label={t('navigation.customer')}
                href={`/customers/${customerId}`}
                selected={
                    onPage(`/customers/${customerId}`)
                    || onPage(`/customers/${customerId}/*`)}
            />

            <MenuListItem
                icon={<AirportShuttleIcon fontSize="small" />}
                label={t('navigation.supplier')}
                href={`/suppliers/${supplierId}`}
                selected={
                    onPage(`/suppliers/${supplierId}`)
                    || onPage(`/suppliers/${supplierId}/*`)
                }
            />

            <MenuListItem
                icon={<img src={driverIcon} alt="Drivers" />}
                label={t('navigation.drivers')}
                href={`/suppliers/${supplierId}/drivers`}
                selected={
                    onPage(`/suppliers/${supplierId}/drivers`)
                    || onPage(`/suppliers/${supplierId}/drivers/*`)
                }
            />

            <MenuListItem
                icon={<SettingsIcon fontSize="small" />}
                label="Settings"
                href={`/customers/${customerId}/disruptions`}
                selected={onPage(`/customers/${customerId}/disruptions`)}
            />
        </>
    );
};

const SupplierAndCustomers: FunctionComponent<{
    supplierId: string;
}> = ({ supplierId }) => {
    const { t } = useTranslation();

    return (
        <>
            <MenuListItem
                icon={<FlightIcon fontSize="small" />}
                label={t('navigation.disruptions')}
                href="/disruptions"
                selected={onPage('/disruptions') || onPage('/disruptions/*')}
            />

            <MenuListItem
                icon={<ListIcon fontSize="small" />}
                label={t('navigation.requests')}
                href={`/suppliers/${supplierId}/requests`}
                selected={
                    onPage(`/suppliers/${supplierId}/requests`)
                    || onPage('/requests/*')
                }
            />

            <MenuListItem
                icon={<BusinessIcon fontSize="small" />}
                label={t('navigation.customers')}
                href="/customers"
                selected={onPage('/customers') || onPage('/customers/*')}
            />

            <MenuListItem
                icon={<AirportShuttleIcon fontSize="small" />}
                label={t('navigation.supplier')}
                href={`/suppliers/${supplierId}`}
                selected={onPage(`/suppliers/${supplierId}/*`)}
            />

            <MenuListItem
                icon={<img src={driverIcon} alt="Drivers" />}
                label={t('navigation.drivers')}
                href={`/suppliers/${supplierId}/drivers`}
                selected={
                    onPage(`/suppliers/${supplierId}/drivers`)
                    || onPage(`/suppliers/${supplierId}/drivers/*`)
                }
            />
        </>
    );
};

const DefaultNavigationList: FunctionComponent<{
    customer: null | { id: string; name: string; customerPermissions: { manageUsers: boolean; } } | 'MULTIPLE';
    supplier: null | { id: string; manageUsers: boolean };
}> = ({ customer, supplier }) => {
    const organizationItems = (() => {
        if (customer === null) {
            if (supplier === null) {
                throw new Error('User does not belong to any organization');
            }

            return <SupplierOnly supplier={supplier} />;
        }

        if (supplier === null) {
            return customer === 'MULTIPLE'
                ? <CustomersOnly />
                : <CustomerOnly customer={customer} />;
        }

        return customer === 'MULTIPLE'
            ? <SupplierAndCustomers supplierId={supplier.id} />
            : (
                <SupplierAndCustomer
                    supplierId={supplier.id}
                    customerId={customer.id}
                />
            );
    })();

    if (organizationItems === null) {
        throw new Error('No navigation available');
    }

    return (
        <List role="menu">
            {organizationItems}
        </List>
    );
};

export default DefaultNavigationList;

