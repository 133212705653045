import gql from 'graphql-tag';

export const GET_AIRPORTS = gql`
query GetAirports($filter: AirportFilter, $after: String) {
    airports(
        first: 10,
        filter: $filter,
        orderBy: { field: IATA, direction: ASC },
        after: $after
    ) {
        nodes {
            id
            iata
            name
        }
        totalCount
        pageInfo {
            hasNextPage
            endCursor
        }
    }
}
`;

export interface GetAirportsInput {
    filter: null | { search: string };
    after: string | null;
}

export interface GetAirportsResult {
    airports: {
        nodes: Array<{
            id: string;
            iata: string;
            name: string;
        }>;
        totalCount: number;
        pageInfo: {
            hasNextPage: boolean;
            endCursor: string | null;
        };
    };
}
