import config from '../config';
import hasProperty from '../helpers/hasProperty';

let refreshing: Promise<RefreshCookieResult> | null = null;

type RefreshCookieResult =
    { code: 'SUCCESS' }
    | { code: 'UNAUTHENTICATED' };

export default async function refreshCookies(): Promise<RefreshCookieResult> {
    if (refreshing) {
        return refreshing;
    }

    refreshing = doRefresh();

    const result = await refreshing;

    // eslint-disable-next-line require-atomic-updates
    refreshing = null;

    return result;
}

async function doRefresh(): Promise<RefreshCookieResult> {
    const response = await fetch(`${config.apiUrl}/auth/refresh`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });

    if (response.status >= 200 && response.status < 300) {
        return { code: 'SUCCESS' };
    }

    const errorResponse = await response.json() as unknown;

    if (hasProperty(errorResponse, 'code') && errorResponse.code === 'UNAUTHENTICATED') {
        return { code: 'UNAUTHENTICATED' };
    }

    if (!hasProperty(errorResponse, 'code')) {
        throw new Error(`Unexpected status code: ${response.status}`);
    }

    const { code } = errorResponse;

    switch (code) {
        case 'REFRESH_TOKEN_EXPIRED':
        case 'REFRESH_TOKEN_NOT_FOUND':
        case 'INVALID_TOKEN':
            return { code: 'UNAUTHENTICATED' };
        default:
            throw new Error(`Unexpected status code: ${response.status}`);
    }
}
