import gql from 'graphql-tag';

export const OVERRIDE_DRIVER_STATUS = gql`
    mutation overrideRideDriverStatus($input: OverrideRideDriverStatusInput!) {
        overrideRideDriverStatus(input: $input) {
            updatedRide {
                id
                events {
                    driverDispatchedAt
                    overrideDetails {
                        originalDriverDispatchedAt
                        driverDispatchedAtOverride
                    }
                }
                pickupPoint {
                    trackingTimes {
                        actualArrival
                        actualDeparture
                        overrideDetails {
                            originalArrival
                            arrivalOverride
                            originalDeparture
                            departureOverride
                        }
                    }
                }
                dropOffPoint {
                    trackingTimes {
                        actualArrival
                        overrideDetails {
                            originalArrival
                            arrivalOverride
                        }
                    }
                }
            }
        }
    }
`;

export interface OverrideDriverStatusInput {
    input: {
        rideId: string;
        startedAt: string | null;
        points: Array<{
            arrivedAt?: string | null;
            departedAt?: string | null;
        }>;
    };
}

export interface OverrideDriverStatusResult {
    updatedRide: {
        id: string;
        events: {
            driverDispatchedAt: string;
            overrideDetails: {
                originalDriverDispatchedAt: string;
                driverDispatchedAtOverride: string;
            };
        };
        pickupPoint: {
            trackingTimes: {
                actualArrival: string;
                actualDeparture: string;
                overrideDetails: {
                    originalArrival: string;
                    arrivalOverride: string;
                    originalDeparture: string;
                    departureOverride: string;
                };
            };
        };
        dropOffPoint: {
            trackingTimes: {
                actualArrival: string;
                overrideDetails: {
                    originalArrival: string;
                    arrivalOverride: string;
                };
            };
        };
    };
}
