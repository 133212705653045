import {
    LoadingPage, PrimaryButton, TableBody,
    TableContainer, TableFooter,
    TableHead, TableHeader,
} from '@get-e/react-components';
import {
    createStyles, Grid, makeStyles,
    Table, TableCell, TableRow,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { FetchMoreOptions } from 'apollo-client';
import React, { FunctionComponent, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import report from '../../helpers/report';
import useDebounce from '../../helpers/useDebounce';
import useLoaderDebounce from '../../helpers/useLoaderDebounce';
import useOpenNewTab from '../../helpers/useOpenNewTab';
import useUrlQuery from '../../helpers/useUrlQuery';
import useTablePageStyles from '../useTablePageStyles';
import {
    GetSuppliersInput,
    GetSuppliersResult,
    GET_SUPPLIERS,
    Supplier,
} from './Content.graphql';

const useStyles = makeStyles(theme => createStyles({
    row: { '&:hover': { cursor: 'pointer' } },
    retryButton: { padding: theme.spacing(2, 0) },
}));

const maxIataPreviews = 3;

const Row: FunctionComponent<{ supplier: Supplier }> = ({ supplier }) => {
    const classes = useStyles();
    const handleClick = useOpenNewTab(`/suppliers/${supplier.id}`);
    const totalAirports = supplier.servicedAirports?.totalCount ?? 0;

    const airportsColumn = (() => {
        const iataPreviews = supplier.servicedAirports?.nodes.map(
            airport => airport.iata
        ).join(', ');

        if (supplier.servicedAirports === null) {
            return 'All airports'
        }

        if (iataPreviews?.length === 0) {
            return '0 airports';
        }

        if (totalAirports <= maxIataPreviews) {
            return iataPreviews;
        }

        // TODO: Translate

        return `${iataPreviews} and ${totalAirports - maxIataPreviews} more`;
    })();

    // TODO: Get total users from API

    return (
        <TableRow
            hover
            className={classes.row}
            onClick={handleClick}
        >
            <TableCell>{supplier.name}</TableCell>
            <TableCell>{airportsColumn}</TableCell>
        </TableRow>
    );
};

const Content: FunctionComponent = () => {
    const { t } = useTranslation();
    const tableClasses = useTablePageStyles();
    const classes = useStyles();
    const history = useHistory();
    const query = useUrlQuery();
    const after = query.get('after');
    const before = query.get('before');
    const paginationLimit = 10;

    // Filter
    const [search, setSearch] = useState('');
    const debouncedSearchValue = useDebounce(search, 500);

    const variables = ((): GetSuppliersInput => {
        const supplierFilter: null | { search: string }
            = debouncedSearchValue
                ? { search: debouncedSearchValue }
                : null;

        if (before !== null) {
            return {
                last: paginationLimit,
                before: before || undefined,
                filter: supplierFilter,
                maxIataPreviews,
            };
        }

        return {
            first: paginationLimit,
            after: after ?? undefined,
            filter: supplierFilter,
            maxIataPreviews,
        };
    })();

    const {
        data,
        loading,
        error,
        refetch,
    } = useQuery<GetSuppliersResult>(
        GET_SUPPLIERS,
        {
            variables,
            onError(apolloError) {
                report(apolloError);
            },
        }
    );

    const showLoader = useLoaderDebounce(loading);

    if (loading && !data) {
        return showLoader
            ? <LoadingPage />
            : null;
    }

    if (error || !data) {
        return (
            <>
                Something went wrong! Please try again.
                <div className={classes.retryButton}>
                    <PrimaryButton onClick={() => refetch()}>
                        Retry
                    </PrimaryButton>
                </div>

            </>
        );
    }

    const handleBackToStartClick = (): void => {
        history.push({ search: '' });
    };

    const handlePreviousClick = (): void => {
        const { startCursor } = data.suppliers.pageInfo;

        if (startCursor === null) {
            throw new Error('Page info does not contain start cursor');
        }

        history.push({ search: `?before=${startCursor}` });
    };

    const handleNextClick = (): void => {
        const { endCursor } = data.suppliers.pageInfo;

        if (endCursor === null) {
            throw new Error('Page info does not contain end cursor');
        }

        history.push({ search: `?after=${endCursor}` });
    };

    const handleSkipToEndClick = (): void => {
        history.push({ search: '?before' });
    };

    const suppliers = data.suppliers.nodes;

    return (
        <>
            <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-start"
            >
                <Grid item className={tableClasses.addButton}>
                    <PrimaryButton
                        onClick={() => history.push('/suppliers/add')}
                        icon={<AddIcon />}
                    >
                        Add supplier
                    </PrimaryButton>
                </Grid>
            </Grid>
            <TableContainer className={tableClasses.table}>
                <TableHeader
                    title="Suppliers"
                    onSearchChange={
                        searchValue => setSearch(searchValue)
                    }
                />

                <Table aria-label={t('pages.suppliers.aria.table')}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('pages.suppliers.column.name')}</TableCell>
                            <TableCell>{t('pages.suppliers.column.airports')}</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {suppliers.length > 0
                            ? (suppliers.map(
                                supplier => <Row supplier={supplier} key={supplier.id} />
                            ))
                            : (
                                <TableRow>
                                    <TableCell colSpan={3}>
                                        {debouncedSearchValue.length === 0
                                            ? (
                                                <strong>
                                                    No suppliers at the moment.
                                                </strong>
                                            )
                                            : (
                                                <strong>No suppliers found.</strong>
                                            )
                                        }
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>

                <TableFooter
                    hasPrevious={data.suppliers.pageInfo.hasPreviousPage}
                    hasNext={data.suppliers.pageInfo.hasNextPage}
                    onBackToStartClick={handleBackToStartClick}
                    onPreviousClick={handlePreviousClick}
                    onNextClick={handleNextClick}
                    onSkipToEndClick={handleSkipToEndClick}
                    count={{
                        total: data.suppliers.totalCount,
                        currentPage: data.suppliers.nodes.length,
                    }}
                    loading={loading}
                />
            </TableContainer>
        </>
    );
};

export default Content;
