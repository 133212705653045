import { makeStyles } from '@material-ui/core';

const useDisruptionsStyles = makeStyles({
    flightNumber: {
        fontSize: '1.125rem',
        fontWeight: 'bold',
    },
    originalAirport: { textDecoration: 'line-through' },
    flightArrow: { verticalAlign: 'middle' },
    row: {
        '&:hover': {
            cursor: 'pointer',
            '& > td': { backgroundColor: '#f5f5f5' },
        },
    },
    diversionArrivalRow: { marginTop: '.25rem' },
    columnSubtext: {
        color: '#a3a3a3',
        fontSize: '.875rem',
    },
    deadlineIcon: {
        verticalAlign: 'middle',
        fontSize: '1.125rem',
    },
    deadlinePassedIcon: { color: '#d32f2f' },
    deadlineWarningIcon: { color: '#f86909' },
    withinDeadlineIcon: { color: '#2e7d32' },
    deadlineMessage: { verticalAlign: 'middle' },
});

export default useDisruptionsStyles;
