import gql from 'graphql-tag';

export const GET_DISRUPTION = gql`
    query getDisruption($id: ID!, $alternatesLimit: Int!) {
        disruption(id: $id) {
            id
            completedAt
            cancelledAt
            coachSeatsRequired
            coachSeatsBooked

            flight {
                number
                departure {
                    airport {
                        id
                        iata
                        name
                        coordinates {
                            latitude
                            longitude
                        }
                    }
                    scheduledDepartureTime
                }
                arrival {
                    airport {
                        id
                        iata
                        name
                        coordinates {
                            latitude
                            longitude
                        }
                    }
                    scheduledArrivalTime
                }
                passengerCount {
                    total
                    infants
                    wheelchair
                }
                alternates(first: $alternatesLimit) {
                    nodes {
                        id
                        estimatedTime
                        airport {
                            id
                            iata
                            name
                            coordinates {
                                latitude
                                longitude
                            }
                        }
                    }
                    pageInfo {
                        hasNextPage
                    }
                }
            }
            coachesAuthorized
            accessibleMutations {
                bookRidesFromAvailabilities
                putArrivalDisruption
                completeDisruption
            }
        }
    }
`;

export interface GetDisruptionInput {
    id: string;
    alternatesLimit: number;
}

export interface Airport {
    id: string;
    iata: string;
    name: string;
    coordinates: {
        latitude: number;
        longitude: number;
    };
}

export interface GetDisruptionResult {
    disruption: null | {
        id: string;
        completedAt: string | null;
        cancelledAt: string | null;
        coachSeatsBooked: number;
        coachSeatsRequired: number;

        flight: {
            number: string;
            departure: {
                airport: Airport;
                scheduledDepartureTime: string;
            };
            arrival: {
                airport: Airport;
                scheduledArrivalTime: string;
            };
            passengerCount: {
                total: number;
                infants: number;
                wheelchair: number;
            };
            alternates: {
                nodes: Array<{
                    id: string;
                    estimatedTime: string;
                    airport: Airport;
                }>;
                pageInfo: {
                    hasNextPage: boolean;
                };
            };
        };
        coachesAuthorized: boolean;
        accessibleMutations: {
            bookRidesFromAvailabilities: boolean;
            putArrivalDisruption: boolean;
            completeDisruption: boolean;
        };
    };
}
