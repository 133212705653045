import { PrimaryButton } from '@get-e/react-components';
import { makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({ retryButton: { margin: theme.spacing(2, 0) } }));

const Retry: FunctionComponent<{
    onRetry: () => void;
    loading?: boolean;
}> = ({ onRetry, loading }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div>
            <div>
                {t('errors.retry')}
            </div>
            <PrimaryButton
                className={classes.retryButton}
                onClick={onRetry}
                loading={loading}
            >
                {t('buttons.retry')}
            </PrimaryButton>
        </div>
    );
};

export default Retry;
