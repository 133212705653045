import { TFunction } from 'i18next';
import moment, { Moment } from 'moment';

export default function formatPastDate(
    date: Moment,
    t: TFunction
): string {
    const format = getFormat(date);

    switch (format) {
        case PastDateFormat.Time:
            return date.format(t('dateFormat.time'));
        case PastDateFormat.DayOfWeek:
            return date.format(t('dateFormat.dayOfWeek'));
        case PastDateFormat.Date:
            return date.format(t('dateFormat.date'));
        default:
            throw new Error('Unsupported format');
    }
}

enum PastDateFormat {
    Time,
    DayOfWeek,
    Date
}

function getFormat(dateInPast: Moment): PastDateFormat {
    if (dateInPast.isSameOrAfter(moment())) {
        throw new Error('Date should be in the past');
    }

    if (dateInPast > moment().subtract(1, 'day')) {
        return PastDateFormat.Time;
    }

    if (dateInPast > moment().subtract(1, 'week')) {
        return PastDateFormat.DayOfWeek;
    }

    return PastDateFormat.Date;
}
