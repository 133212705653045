import gql from 'graphql-tag';

export const UNASSIGN_DRIVER = gql`
    mutation unassignDriver($rideId: ID!) {
        removeDriverFromRide(
            input: {
                rideId: $rideId,
            }
        ) {
            id
        }
    }
`;

export interface UnassignDriverInput {
    rideId: string;
}

export interface UnassignDriverResult {
    updateRideDriver: {
        id: string;
    };
}

export const ASSIGN_DRIVER = gql`
    mutation assignDriverToRide($rideId: ID!, $driverId: ID!) {
        assignDriverToRide(
            input: {
                rideId: $rideId,
                driverId: $driverId
            }
        ) {
            id
        }
    }
`;

export interface AssignDriverInput {
    rideId: string;
    driverId: string;
}

export interface AssignDriverResult {
    updateRideDriver: {
        id: string;
    };
}

