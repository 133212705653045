import { Alert, Modal, PrimaryButton, SecondaryButton } from '@get-e/react-components';
import { makeStyles, Typography } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import delay from '../../helpers/delay';
import useModalStyles from '../../styles/useModalStyles';

async function cancelDisruption(disruptionId: string): Promise<void> {
    // TODO: Implement
    // eslint-disable-next-line no-console
    console.log(`Cancelling disruption ${disruptionId}`);

    await delay(2000);
}

const useStyles = makeStyles({ alert: { marginTop: '1rem' } });

const CancelDisruptionDialog: FunctionComponent<{
    disruptionId: string;
    onClose: () => void;
    canCancel: boolean;
}> = ({ disruptionId, onClose, canCancel }) => {
    const { t } = useTranslation();
    const [cancelling, setCancelling] = useState(false);
    const classes = useStyles();
    const modalClasses = useModalStyles();

    async function handleClick(): Promise<void> {
        setCancelling(true);
        await cancelDisruption(disruptionId);
        setCancelling(false);
    }

    return (
        <Modal
            open={true}
            onClose={onClose}
        >
            <div className={modalClasses.header}>
                <Typography variant="h2" component="h2" className={modalClasses.heading}>
                    {t('pages.disruption.cancelDialog.title')}
                </Typography>
            </div>

            <p>
                {t('pages.disruption.cancelDialog.description')}
            </p>

            {/* TODO: Mention cancellation policy */}

            <Alert severity="warning" className={classes.alert}>
                {canCancel
                    ? t('pages.disruption.cancelDialog.warning')
                    : t('pages.disruption.cancelDialog.cantComplete')
                }
            </Alert>

            <div className={modalClasses.buttonContainer}>
                <span className={modalClasses.primaryButton}>
                    <PrimaryButton
                        onClick={handleClick}
                        loading={cancelling}
                        disabled={!canCancel}
                        variation="danger"
                    >
                        {t('pages.disruption.cancelDialog.confirmButton')}
                    </PrimaryButton>
                </span>

                <SecondaryButton
                    onClick={onClose}
                    disabled={cancelling}
                >
                    {t('pages.disruption.cancelDialog.keepButton')}
                </SecondaryButton>
            </div>
        </Modal>
    );
};

export default CancelDisruptionDialog;
