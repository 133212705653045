import { LoadingPage } from '@get-e/react-components';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import Retry from '../../../components/Retry';
import { useCurrentUserContext } from '../../../context/CurrentUserContext';
import { BreadcrumbHeadNode, PageDefinition } from '../../../createRoutes';
import getValue from '../../../helpers/getValue';
import useLoaderDebounce from '../../../helpers/useLoaderDebounce';
import Page from '../../../layouts/Page';
import {
    GetSupplierNameResult,
    GetSupplierNameInput,
    GET_SUPPLIER_NAME,
} from '../../../queries/GetSupplierName.graphql';
import Content from './Content';
import { GetDriverInput, GetDriverResult, GET_DRIVER } from './Content.graphql';

const addDriverPage: PageDefinition = {
    path: '/suppliers/:supplierId/drivers/:driverId',
    Component({ getParam }) {
        const { t } = useTranslation();
        const supplierId = getParam('supplierId');
        const driverId = getParam('driverId');
        const currentUser = useCurrentUserContext();

        const {
            data,
            loading,
            error,
        } = useQuery<GetSupplierNameResult, GetSupplierNameInput>(
            GET_SUPPLIER_NAME,
            { variables: { id: supplierId } }
        );

        const supplierName = ((): BreadcrumbHeadNode['name'] => {
            if (loading || error) {
                // Note: In case of an error the user will see the error boundary instead
                return <Skeleton width="7ch" />;
            }

            if (!data || !data.supplier) {
                return t('breadcrumbs.unknownSupplier');
            }

            return data.supplier.name;
        })();

        const variables = getValue((): GetDriverInput => (
            {
                supplierId,
                id: driverId,
            }
        ));

        const {
            data: driverData,
            loading: loadingDriver,
            error: errorDriver,
            refetch: refetchDriver,
        } = useQuery<GetDriverResult, GetDriverInput>(
            GET_DRIVER,
            { variables }
        );

        const showLoader = useLoaderDebounce(loadingDriver);

        if (loadingDriver) {
            return showLoader
                ? <LoadingPage />
                : null;
        }

        if (currentUser.isBackOffice) {
            return (
                <Page
                    breadcrumbs={{
                        name: t('breadcrumbs.driverEdit'),
                        previous: {
                            name: t('breadcrumbs.drivers'),
                            path: `/suppliers/${supplierId}/drivers`,
                            previous: {
                                name: supplierName,
                                path: `/suppliers/${supplierId}/`,
                                previous: {
                                    name: t('navigation.suppliers'),
                                    path: '/suppliers',
                                },
                            },
                        },
                    }}
                >
                    {
                        (!driverData || errorDriver)
                            ? (
                                <Retry
                                    onRetry={() => refetchDriver()}
                                    loading={loadingDriver}
                                />
                            )
                            : (
                                <Content
                                    driver={driverData.driver}
                                    supplierId={supplierId}
                                />
                            )
                    }
                </Page>
            );
        }

        return (
            <Page
                breadcrumbs={
                    {
                        name: t('breadcrumbs.driverEdit'),
                        previous: {
                            name: t('breadcrumbs.drivers'),
                            path: `/suppliers/${supplierId}/drivers`,
                        },
                    }
                }
            >
                {
                    (!driverData || errorDriver)
                        ? (
                            <Retry
                                onRetry={() => refetchDriver()}
                                loading={loadingDriver}
                            />
                        )
                        : (
                            <Content
                                driver={driverData.driver}
                                supplierId={supplierId}
                            />
                        )
                }
            </Page>
        );
    },
};

export default addDriverPage;
