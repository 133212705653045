import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageDefinition } from '../../../createRoutes';
import Page from '../../../layouts/Page';
import AddDisruption, { DiversionType } from './AddDisruption';

const addArrivalDisruptionPage: PageDefinition = {
    path: '/disruptions/add-arrival',
    Component() {
        const { t } = useTranslation();

        return (
            <Page
                breadcrumbs={{
                    name: t('breadcrumbs.addArrivalDisruption'),
                    previous: {
                        name: t('breadcrumbs.disruptions'),
                        path: '/disruptions',
                    },
                }}
            >
                <AddDisruption diversionType={DiversionType.Arrival} />
            </Page>
        );
    },
};

const addDepartureDisruptionPage: PageDefinition = {
    path: '/disruptions/add-departure',
    Component() {
        const { t } = useTranslation();

        return (
            <Page
                breadcrumbs={{
                    name: t('breadcrumbs.addDepartureDisruption'),
                    previous: {
                        name: t('breadcrumbs.disruptions'),
                        path: '/disruptions',
                    },
                }}
            >
                <AddDisruption diversionType={DiversionType.Departure} />
            </Page>
        );
    },
};

export { addArrivalDisruptionPage, addDepartureDisruptionPage };
