import hasProperty from '../hasProperty';
import isArray from '../isArray';

export default function parseInvalidInputResponse<T extends string>(
    body: unknown,
    fields: T[]
): null | { [Key in T]: Array<{ code: string }> } {
    if (!hasProperty(body, 'code')) {
        return null;
    }

    const errorCode = body.code;

    if (errorCode !== 'INVALID_INPUT') {
        return null;
    }

    if (!hasProperty(body, 'errors')) {
        throw new Error('INVALID_INPUT response did not provide error object');
    }

    const parsed = fields.reduce<{ [key: string]: Array<{ code: string }> }>(
        (result, field) => {
            if (!hasProperty(body.errors, field)) {
                result[field] = [];

                return result;
            }

            const errors = body.errors[field];

            if (!isArray(errors)) {
                throw new Error(`Error field "${field}" is not an array`);
            }

            result[field] = errors.map(error => {
                if (!hasProperty(error, 'code')) {
                    throw new Error(`Error of field "${field}" does not have a code`);
                }

                if (typeof error.code !== 'string') {
                    throw new Error(`Error code for field "${field}" has unknown type`);
                }

                return { code: error.code };
            });

            return result;
        },
        {}
    );

    return parsed as { [Key in T]: Array<{ code: string }> };
}
