import { Tooltip } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

/**
 * Displays a IATA with a tooltip to show the full name.
 * Should not be used when airport name is also displayed.
 */
const Iata: FunctionComponent<{
    airport: { iata: string; name: string };
    className?: string;
}> = ({ airport, className }) => (
    <Tooltip title={airport.name}>
        <span className={className}>
            {airport.iata}
        </span>
    </Tooltip>
);

export default Iata;
