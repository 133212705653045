import gql from 'graphql-tag';

export const SEARCH_LANGUAGE = gql`
    query GetLanguages($filter: LanguageFilter) {
        languages(first: 10, filter: $filter) {
            nodes {
                id
                name
                locale
            }
        }
    }
`;

export interface SearchLanguageInput {
    filter: null | { search: string };
}

export interface SearchLanguageResult {
    languages: {
        nodes: Array<{
            id: string;
            name: string;
            locale: string;
        }>;
    };
}
