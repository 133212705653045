import { Spinner, TextField } from '@get-e/react-components';
import { makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { FunctionComponent, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import useDebounce from '../helpers/useDebounce';
import {
    SEARCH_DRIVER,
    SearchDriverInput,
    SearchDriverResult,
} from './DriverSelector.graphql';

export interface Driver {
    id: string;
    name: string;
    phoneNumber: string;
}

const useStyles = makeStyles({ spinner: { marginBottom: '1rem' } });

const DriverSelector: FunctionComponent<{
    value: Driver | null;
    inputValue: string;
    onChange: (newValue: Driver | null) => void;
    onFocus: () => void;
    onInputChange: (vale: string) => void;
    label: string;
    required?: boolean;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
    supplierId: string;
}> = ({
    value, onChange, label, required, error: errorProp,
    helperText, disabled, supplierId, onInputChange, inputValue,
    onFocus
}) => {
    const [open, setOpen] = useState(false);
    const debouncedSearchValue = useDebounce(inputValue, 500);
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        data,
        loading: queryLoading,
        error,
    } = useQuery<SearchDriverResult, SearchDriverInput>(
        SEARCH_DRIVER,
        {
            variables: {
                supplierId,
                filter: debouncedSearchValue.trim()
                    ? { search: debouncedSearchValue }
                    : null,
            },
        }
    );

    const loading = queryLoading || inputValue !== debouncedSearchValue;

    return (
        <Autocomplete
            id="driver-field"
            value={value}
            onChange={(_event, newValue) => {
                onChange(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(_event, newValue, reason) => {
                if (reason !== 'reset') {
                    onInputChange(newValue);
                }
            }}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onFocus={() => onFocus()}
            getOptionSelected={(option, newValue) => option.id === newValue.id}
            getOptionLabel={option => `${option.name} - ${option.phoneNumber}`}
            options={data?.supplier.drivers.nodes ?? []}
            loading={loading}
            clearOnBlur={false}
            loadingText={t('components.driverSearch.loading')}
            noOptionsText={error
                ? t('components.driverSearch.error')
                : t('components.driverSearch.noResults', { searchValue: inputValue })
            }
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    required={required}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: <>
                            {
                                loading && open
                                    ? <Spinner size={20} className={classes.spinner} />
                                    : null
                            }
                            {params.InputProps.endAdornment}
                        </>,
                    }}
                    helperText={helperText}
                    error={errorProp}
                />
            )}
        />
    );
};

export default DriverSelector;
