import { MenuListItem } from '@get-e/react-components';
import { List } from '@material-ui/core';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import BusinessIcon from '@material-ui/icons/Business';
import FlightIcon from '@material-ui/icons/Flight';
import ListIcon from '@material-ui/icons/ListOutlined';
import PeopleIcon from '@material-ui/icons/People';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath } from 'react-router-dom';

function onPage(path: string): boolean {
    return Boolean(matchPath(window.location.pathname, {
        path,
        exact: true,
        strict: true,
    }));
}

const BackOfficeNavigationList: FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <List role="menu" >
            <MenuListItem
                icon={<FlightIcon fontSize="small" />}
                label={t('navigation.disruptions')}
                href="/disruptions"
                selected={onPage('/disruptions') || onPage('/disruptions/*')}
            />
            <MenuListItem
                icon={<BusinessIcon fontSize="small" />}
                label={t('navigation.customers')}
                href="/customers"
                selected={onPage('/customers') || onPage('/customers/*')}
            />
            <MenuListItem
                icon={<AirportShuttleIcon fontSize="small" />}
                label={t('navigation.suppliers')}
                href="/suppliers"
                selected={onPage('/suppliers') || onPage('/suppliers/*')}
            />
            <MenuListItem
                icon={<PeopleIcon fontSize="small" />}
                label={t('navigation.backOfficeUsers')}
                href="/back-office/users"
                selected={onPage('/back-office/*')}
            />
        </List>
    );
};

export default BackOfficeNavigationList;

