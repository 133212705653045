import { Heading, TableSkeleton } from '@get-e/react-components';
import { Moment } from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Airport } from '../../components/AirportField';
import LoadingFade from '../../components/LoadingFade';
import createUrlSearchParams from '../../helpers/createUrlSearchParams';
import getValue from '../../helpers/getValue';
import useLoaderDebounce from '../../helpers/useLoaderDebounce';
import HotelRoomsList from '../Disruption/Hotels/HotelRoomsList';
import SearchBar from './SearchBar';

const HotelResultsPage: FunctionComponent<{
    input: {
        airport: Airport;
        checkIn: Moment;
        checkOut: Moment;
    };
}> = ({ input }) => {
    const history = useHistory();

    const [searching, setSearching] = useState(true);

    useEffect(() => {
        const handle = setTimeout(() => {
            setSearching(false);
        }, 2000);

        return () => clearTimeout(handle);
    }, []);

    const showLoader = useLoaderDebounce(searching);

    return (
        <>
            <Heading level={1}>Find hotels</Heading>

            <SearchBar
                initialValues={input}
                onChange={newInput => {
                    if (newInput === null) {
                        return;
                    }

                    const searchString = createUrlSearchParams({
                        airportId: newInput.airport.id,
                        checkIn: newInput.checkIn.format('YYYY-MM-DD'),
                        checkOut: newInput.checkOut.format('YYYY-MM-DD'),
                    });

                    history.push(`/hotels/search/results?${searchString}`);
                }}
            />

            {getValue(() => {
                if (searching) {
                    return showLoader
                        ? (
                            <LoadingFade>
                                <div>
                                    <TableSkeleton columns={6} />
                                </div>
                            </LoadingFade>
                        )
                        : null;
                }

                return <HotelRoomsList />;
            })}
        </>
    );
};

export default HotelResultsPage;
