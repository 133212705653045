const demoRoomTypes: string[] = [
    'Single room',
    'Double room',
    'Triple room',
    'Quad room',
    'Queen room',
    'King room',
    'Twin room',
    'Accessible double room',
];

export default demoRoomTypes;
