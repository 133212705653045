import { MenuButtonItem, OptionsTableCell } from '@get-e/react-components';
import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import clsx from 'clsx';
import moment from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router';
import UtcTime from '../../../components/UtcTime';
import { useCurrentUserContext } from '../../../context/CurrentUserContext';
import getIndex from '../../../helpers/getIndex';
import getValue from '../../../helpers/getValue';
import { Availability, AvailabilityRequest, Ride } from '../useCoachRoutes';
import CancelAvailabilityRequestDialog from './CancelAvailabilityRequestDialog';
import UncancelAvailabilityRequestDialog from './UncancelAvailabilityRequestRow';

const useStyles = makeStyles({
    availabilityRequestSpinner: { verticalAlign: 'middle' },
    availabilityIcon: {
        marginRight: '.5rem',
        verticalAlign: 'middle',
        fontSize: '1.5em',
    },
    statusCircle: {
        display: 'inline-block',
        verticalAlign: 'middle',
        marginRight: '.5rem',
        width: 12,
        height: 12,
        borderRadius: 12,
        backgroundColor: 'black',
    },
    statusCircleCancelled: { backgroundColor: '#b0bec5' },
    statusCircleOpen: { backgroundColor: '#2e7d32' },
    statusText: { verticalAlign: 'middle' },
});

const AvailabilityRequestRow: FunctionComponent<{
    request: AvailabilityRequest;
    availabilities: Availability[];
    rides: Ride[];
    canChangeCancellation: boolean;
    onAvailabilityAdded: () => void;
    remainingSeatsRequired: number;
}> = ({
    request, availabilities, canChangeCancellation,
    onAvailabilityAdded, rides, remainingSeatsRequired,
}) => {
    const classes = useStyles();
    const history = useHistory();
    const currentUser = useCurrentUserContext();

    const [menuOpen, setMenuOpen] = useState(false);
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [uncancelDialogOpen, setUncancelDialogOpen] = useState(false);

    // eslint-disable-next-line max-lines-per-function, max-statements
    const customerMenuItems = getValue((): JSX.Element[] => {
        if (request.cancelledAt !== null) {
            if (!canChangeCancellation) {
                return [];
            }

            return [
                <MenuButtonItem
                    key="uncancel"
                    onClick={() => {
                        setUncancelDialogOpen(true);
                        setMenuOpen(false);
                    }}
                    dangerous
                >
                    Uncancel request
                </MenuButtonItem>,
            ];
        }

        const cancel = (
            <MenuButtonItem
                key="cancel"
                onClick={() => {
                    setCancelDialogOpen(true);
                    setMenuOpen(false);
                }}
                dangerous
            >
                Cancel request
            </MenuButtonItem>
        );

        if (canChangeCancellation) {
            return [cancel];
        }

        return [];
    });

    return (
        <>
            {(() => {
                if (cancelDialogOpen) {
                    return (
                        <CancelAvailabilityRequestDialog
                            requestId={request.id}
                            supplierName={request.supplier.name}
                            onClose={() => setCancelDialogOpen(false)}
                        />
                    );
                }

                if (uncancelDialogOpen) {
                    return (
                        <UncancelAvailabilityRequestDialog
                            requestId={request.id}
                            supplierName={request.supplier.name}
                            onClose={() => setUncancelDialogOpen(false)}
                        />
                    );
                }

                return null;
            })()}

            <TableRow>
                <TableCell>
                    <UtcTime time={moment(request.createdAt)} />
                </TableCell>

                <TableCell>
                    {request.supplier.name}
                </TableCell>

                <TableCell>
                    {getValue(() => {
                        const availabilitiesCount = availabilities.reduce((result, availability) => {
                            const matches = request.supplier.id === availability.supplier.id && request.points.every((requestPoint, index) => {
                                const availabilityPoint = getIndex(availability.points, index);

                                return availabilityPoint && requestPoint.location.id === availabilityPoint.location.id;
                            });

                            return matches
                                ? result + 1
                                : result;
                        }, 0);

                        const ridesCount = rides.reduce((result, ride) => {
                            const matches = request.supplier.id === ride.supplier.id && request.points.every((requestPoint, index) => {
                                const ridePoint = getIndex(ride.points, index);

                                return ridePoint && requestPoint.location.id === ridePoint.location.id;
                            });

                            return matches
                                ? result + 1
                                : result;
                        }, 0);

                        const totalCount = availabilitiesCount + ridesCount;

                        if (totalCount === 0 && !request.cancelledAt) {
                            return request.completedAt
                                ? <span>None</span>
                                : <span>Pending</span>;
                        }

                        return (
                            <span>
                                <AirportShuttleIcon className={classes.availabilityIcon} />
                                {totalCount} added
                            </span>
                        );
                    })}
                </TableCell>

                <TableCell>
                    {getValue(() => {
                        if (request.cancelledAt !== null) {
                            return (
                                <span>
                                    <div className={clsx(classes.statusCircle, classes.statusCircleCancelled)} />
                                    <span className={classes.statusText}>Cancelled</span>
                                </span>
                            );
                        }

                        if (!request.completedAt) {
                            return (
                                <span>
                                    <div className={clsx(classes.statusCircle, classes.statusCircleOpen)} />
                                    <span className={classes.statusText}>Open</span>
                                </span>
                            );
                        }

                        if (!request.hasAvailabilities) {
                            return (
                                <span>
                                    <div className={clsx(classes.statusCircle, classes.statusCircleCancelled)} />
                                    <span className={classes.statusText}>No availability</span>
                                </span>
                            );
                        }

                        return (
                            <span>
                                <div className={classes.statusCircle} />
                                <span className={classes.statusText}>Closed</span>
                            </span>
                        );
                    })}
                </TableCell>

                {getValue(() => {
                    // TODO: Show when implemented
                    const showCustomerMenuItems = false;

                    if (!showCustomerMenuItems && !currentUser.isBackOffice) {
                        return null;
                    }

                    return (
                        <OptionsTableCell menuOpen={menuOpen} setMenuOpen={setMenuOpen}>
                            {getValue(() => {
                                const menuItems = showCustomerMenuItems ? customerMenuItems : [];

                                if (currentUser.isBackOffice) {
                                    menuItems.unshift(
                                        <MenuButtonItem
                                            key="open-request"
                                            onClick={() => history.push(`/requests/${request.id}`)}
                                        >
                                            Show request
                                        </MenuButtonItem>
                                    );
                                }

                                return menuItems;
                            })}
                        </OptionsTableCell>
                    );
                })}

                {}
            </TableRow>
        </>
    );
};

export default AvailabilityRequestRow;
