import { makeStyles, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import React, { ComponentType, FunctionComponent, ReactNode } from 'react';

const useStyles = makeStyles({
    root: {
        borderRadius: '.25rem',
        padding: '.4rem .8rem',
    },
    title: {
        marginBottom: '.2em',
        fontWeight: 600,
    },

    underControlTitle: { color: '#174f1b' },
    actionRequiredTitle: { color: '#843805' },
    alertTitle: { color: '#861515' },
    inactiveTitle: { color: '#4f4f4f' },

    underControl: { backgroundColor: '#c8e6c9' },
    actionRequired: { backgroundColor: '#fbebcc' },
    alert: { backgroundColor: '#f1d3d3' },
    inactive: { backgroundColor: '#eeeeee' },

    details: { fontSize: '.875rem' },
    detailsIcon: { verticalAlign: 'middle' },
    detailsText: { verticalAlign: 'middle' },
});

const Status: FunctionComponent<{
    type: 'under-control' | 'action-required' | 'alert' | 'inactive';
    title: string;
    description: ReactNode;
    tooltip: string;
    icon?: ComponentType<{
        fontSize: 'small';
        className: string;
    }>;
    // eslint-disable-next-line @typescript-eslint/naming-convention
}> = ({ type, title, description, icon: Icon, tooltip }) => {
    const classes = useStyles();

    return (
        <Tooltip title={tooltip}>
            <div
                className={clsx(classes.root, {
                    [classes.underControl]: type === 'under-control',
                    [classes.actionRequired]: type === 'action-required',
                    [classes.alert]: type === 'alert',
                    [classes.inactive]: type === 'inactive',
                })}
            >
                <div
                    className={clsx(classes.title, {
                        [classes.underControlTitle]: type === 'under-control',
                        [classes.actionRequiredTitle]: type === 'action-required',
                        [classes.alertTitle]: type === 'alert',
                        [classes.inactiveTitle]: type === 'inactive',
                    })}
                >
                    {title}
                </div>
                <div className={classes.details}>
                    {
                        Icon
                            ? (
                                <>
                                    <Icon fontSize="small" className={classes.detailsIcon} />
                                    {' '}
                                </>
                            )
                            : null
                    }
                    <span className={classes.detailsText}>
                        {description}
                    </span>
                </div>
            </div>
        </Tooltip>
    );
};

export default Status;
