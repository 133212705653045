import {
    AutocompleteChip, Modal, PrimaryButton,
    SecondaryButton, TextField,
} from '@get-e/react-components';
import { FormControl } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getHelperText from '../helpers/validation/getHelperText';
import InputError from '../helpers/validation/InputError';
import useFormStyles from '../styles/useFormStyles';
import useModalStyles from '../styles/useModalStyles';
import AirportsSelector, { Airport } from './AirportsSelector';

const AirportsWhitelistField: FunctionComponent<{
    modalTitle: string;
    choicesListHeading: string;
    chosenListHeading: string;
    label: string;
    values: Airport[] | null;
    onChange: (whitelist: Airport[] | null) => void;
    error: InputError | null;
    // eslint-disable-next-line max-lines-per-function
}> = ({
    modalTitle, choicesListHeading, chosenListHeading,
    label, values, onChange, error,
}) => {
    const { t } = useTranslation();
    const [editing, setEditing] = useState(false);
    const [unsavedWhitelist, setUnsavedWhitelist] = useState(values);
    const modalClasses = useModalStyles();
    const formClasses = useFormStyles();

    useEffect(() => {
        setUnsavedWhitelist(values);
    }, [values]);

    return (
        <>
            <Modal
                open={editing}
                onClose={() => setEditing(false)}
                maxWidth={false}
            >
                <AirportsSelector
                    title={modalTitle}
                    choicesHeading={choicesListHeading}
                    chosenHeading={chosenListHeading}
                    selected={unsavedWhitelist ?? 'ALL'}
                    onChange={newValues => {
                        setUnsavedWhitelist(newValues === 'ALL' ? null : newValues);
                    }}
                />

                <div className={modalClasses.buttonContainer}>
                    <span className={modalClasses.primaryButton}>
                        <PrimaryButton
                            onClick={() => {
                                onChange(unsavedWhitelist);
                                setEditing(false);
                            }}
                        >
                            {t('buttons.save')}
                        </PrimaryButton>
                    </span>

                    <SecondaryButton
                        onClick={() => {
                            setUnsavedWhitelist(values);
                            setEditing(false);
                        }}
                    >
                        {t('buttons.cancel')}
                    </SecondaryButton>
                </div>
            </Modal>

            <FormControl fullWidth>
                <Autocomplete
                    multiple
                    open={false}
                    options={[] as Array<Airport | 'ALL'>}
                    value={values ?? ['ALL']}
                    onChange={(_event, newValues) => {
                        onChange(newValues[0] === 'ALL' ? null : newValues as Airport[]);
                    }}
                    renderInput={params => (
                        <TextField
                            {...params}
                            required
                            onClick={() => setEditing(true)}
                            label={label}
                            className={formClasses.autocompleteInput}
                            error={error !== null}
                            helperText={getHelperText(error, t)}
                        />
                    )}
                    onOpen={() => setEditing(true)}
                    renderTags={
                        (value, getTagProps) => value.map(
                            (option, index) => (
                                <AutocompleteChip
                                    key={option === 'ALL' ? option : option.id}

                                    // TODO: Translate
                                    label={option === 'ALL' ? 'All airports' : option.iata}
                                    {...getTagProps({ index })}
                                />
                            )
                        )
                    }
                />
            </FormControl>
        </>
    );
};

export default AirportsWhitelistField;
