// TODO: Split up
/* eslint-disable max-len */
/* eslint-disable max-lines */
import {
    Alert, Heading, LoadingPage, MenuButton,
    MenuButtonItem, MessageDialog, PrimaryButton, Spinner,
} from '@get-e/react-components';
import { Divider, Fade, Grid, makeStyles, Typography } from '@material-ui/core';
import FlightLandIcon from '@material-ui/icons/FlightLandOutlined';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoffOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import moment, { Moment } from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import LoadingFade from '../../components/LoadingFade';
import LocalDateTime from '../../components/LocalDateTime';
import UtcTime from '../../components/UtcTime';
import { useCurrentUserContext } from '../../context/CurrentUserContext';
import { useLocaleContext } from '../../context/LocaleContext';
import formatNumber from '../../helpers/formatNumber';
import getValue from '../../helpers/getValue';
import report from '../../helpers/report';
import useLoaderDebounce from '../../helpers/useLoaderDebounce';
import useUrlQuery from '../../helpers/useUrlQuery';
import CancelDisruptionDialog from './CancelDisruptionDialog';
import CoachesStatus from './CoachesStatus';
import CoachRoute from './CoachRoute';
import CompleteDisruptionDialog from './CompleteDisruptionDialog';
import { GetDisruptionInput, GetDisruptionResult, GET_DISRUPTION } from './Content.graphql';
import HotelRoomsList from './Hotels/HotelRoomsList';
import useCoachRoutes, {
    AvailabilityRequest, CoachRoute as CoachRouteInterface,
    DriverStatus, DrivingPoint,
} from './useCoachRoutes';

const useStyles = makeStyles(theme => ({
    flightSection: { '&:not(:last-child)': { marginBottom: '1.5rem' } },
    flightSectionIcon: {
        width: '1.5rem',
        marginRight: '1rem',
        color: theme.palette.primary.main,
    },
    flightSubsection: { '&:not(:last-child)': { marginBottom: '1.5rem' } },
    flightDate: {
        color: '#788B9B',
        marginLeft: '.5rem',
    },
    pageHeading: {
        '& h1': {
            display: 'inline-block',
            verticalAlign: 'middle',
        },
    },
    diversionType: { marginTop: '.5rem' },
    editButton: { marginRight: '1rem' },
    pageHeader: { marginBottom: '2rem' },
    flightDetails: { marginBottom: '2rem' },
    coaches: { marginTop: '2rem' },
    coachesHeading: {
        display: 'inline-block',
        verticalAlign: 'middle',
        marginBottom: 0,
        marginRight: '1rem',
    },
    coachesHeader: { marginBottom: '1rem' },
    coachesAuthorizedWarning: {
        padding: '2px 12px',
        verticalAlign: 'middle',
    },
    eu261Delay: { marginTop: '.5rem' },
    coachesStatus: { marginBottom: '2rem' },
    divider: {
        marginTop: '1.5rem',
        marginBottom: '1.5rem',
    },
}));

// eslint-disable-next-line max-lines-per-function, max-statements, complexity
const Content: FunctionComponent<{ id: string }> = ({ id }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const { locale } = useLocaleContext();
    const currentUser = useCurrentUserContext();

    const [menuOpen, setMenuOpen] = useState(false);
    const [completeDialogOpen, setCompleteDialogOpen] = useState(false);
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [requestNotFoundDialogOpen, setRequestNotFoundDialogOpen] = useState(false);

    // TODO: Remove after demo
    const [respondingToRequests, setRespondingToRequests] = useState<false | {
        requests: AvailabilityRequest[];
        route: CoachRouteInterface;
    }>(false);

    const [bookedRide, setBookedRide] = useState<null | {
        id: string;
        supplierId: string;
        pickupTime: Moment;
        points: DrivingPoint[];
    }>(null);

    const {
        data: disruptionData,
        loading: disruptionLoading,
        error: disruptionError,
        refetch: refetchDisruption,
    } = useQuery<GetDisruptionResult, GetDisruptionInput>(
        GET_DISRUPTION,
        {
            variables: {
                id,
                alternatesLimit: 5,
            },
            onError(apolloError) {
                report(apolloError);
            },
            fetchPolicy: 'cache-and-network',
        }
    );

    // TODO: Detect from query
    const isArrivalDiversion = true;

    // TODO: Based on current user and availability requests


    // TODO: Check customer permission
    const hasCustomerEditPermission = true;
    const canEdit = hasCustomerEditPermission || currentUser.isBackOffice;

    const {
        coachRoutes,
        loading: routesLoading,
        error: routesError,
        reload: reloadRoutes,
    } = useCoachRoutes(id, { onError: report });

    //TODO: Disable hotels for now
    const showHotel = false;
    const showDisruptionLoader = useLoaderDebounce(disruptionLoading);
    const showRoutesLoader = useLoaderDebounce(routesLoading);

    const urlRequestId = useUrlQuery().get('request');
    const [urlRequestHandled, setUrlRequestHandled] = useState(false);
    const allRides = coachRoutes?.flatMap(route => route.rides) ?? [];

    const ridesInProgress
        = allRides.some(ride => ride.driverStatus === DriverStatus.OnBoard);

    const ridesCompleted
        = allRides.some(ride => ride.driverStatus === DriverStatus.DroppedOff);

    useEffect(() => {
        if (urlRequestHandled || !coachRoutes || !urlRequestId) {
            return;
        }

        setUrlRequestHandled(true);

        const found = getValue((): null | [AvailabilityRequest, CoachRouteInterface] => {
            for (const route of coachRoutes) {
                for (const request of route.availabilityRequests) {
                    if (request.id === urlRequestId) {
                        return [request, route];
                    }
                }
            }

            return null;
        });

        if (!found) {
            setRequestNotFoundDialogOpen(true);
            return;
        }

        setRespondingToRequests({
            requests: [found[0]],
            route: found[1],
        });
    }, [coachRoutes, urlRequestId, urlRequestHandled]);

    if (disruptionLoading) {
        return showDisruptionLoader
            ? <LoadingPage />
            : null;
    }

    if (disruptionError || !disruptionData) {
        // TODO
        return <>Error</>;
    }

    if (disruptionData.disruption === null) {
        // TODO
        return <>Disruption not found</>;
    }

    if (disruptionData.disruption.flight.alternates.pageInfo.hasNextPage) {
        // TODO: Handle
        throw new Error('Too many alternates');
    }

    const { flight } = disruptionData.disruption;
    const formatFlightDate = (date: string): string => moment(date).format(t('dateFormat.dateShortMonth'));

    const alternateSections = flight.alternates.nodes.map((alternate, index) => (
        <section
            key={index}
            className={classes.flightSubsection}
        >
            <Heading level={4}>
                {(() => {
                    if (flight.alternates.nodes.length > 1) {
                        return `Alternate ${index + 1}`;
                    }

                    return 'Diverted to';
                })()}
            </Heading>

            <div>{alternate.airport.iata} - {alternate.airport.name}</div>

            {
                alternate.estimatedTime === null
                    ? null
                    : (
                        <div>
                            <UtcTime time={moment(alternate.estimatedTime)} estimate />

                            <span className={classes.flightDate}>
                                {formatFlightDate(alternate.estimatedTime)}
                            </span>
                        </div>
                    )
            }
        </section>
    ));

    if (flight.alternates.nodes.length === 0) {
        // TODO: Handle
        throw new Error('No alternates');
    }

    return (
        <>
            {
                requestNotFoundDialogOpen
                    ? (
                        <MessageDialog
                            title="Invalid link"
                            onClose={() => setRequestNotFoundDialogOpen(false)}
                        >
                            Make sure that you are signed into the correct account
                            or contact us if you believe this to be an error.
                        </MessageDialog>
                    )
                    : null
            }

            {
                cancelDialogOpen
                    ? (
                        <CancelDisruptionDialog
                            disruptionId={id}
                            onClose={() => setCancelDialogOpen(false)}
                            canCancel={!ridesInProgress || !ridesCompleted}
                        />
                    )
                    : null
            }

            {
                completeDialogOpen
                    ? (
                        <CompleteDisruptionDialog
                            disruptionId={id}
                            onCompleted={() => refetchDisruption()}
                            onClose={() => setCompleteDialogOpen(false)}
                            canComplete={!ridesInProgress}
                        />
                    )
                    : null
            }

            <Grid container justify="space-between" className={classes.pageHeader}>
                <Grid item xs>
                    <div className={classes.pageHeading}>
                        <Heading level={1}>
                            {flight.number}
                        </Heading>
                    </div>

                    <div className={classes.diversionType}>
                        {
                            isArrivalDiversion
                                ? t('pages.disruption.arrivalDiversion')
                                : t('pages.disruption.departureDiversion')
                        }
                    </div>
                </Grid>

                <Grid item xs="auto">
                    {(() => {
                        if (disruptionData.disruption.cancelledAt || disruptionData.disruption.completedAt) {
                            const message = (() => {
                                if (disruptionData.disruption.cancelledAt) {
                                    return (
                                        <>
                                            {'Cancelled at '}
                                            <LocalDateTime date={moment(disruptionData.disruption.cancelledAt)} />
                                        </>
                                    );
                                }

                                if (disruptionData.disruption.completedAt) {
                                    return (
                                        <>
                                            {'Completed at '}
                                            <LocalDateTime date={moment(disruptionData.disruption.completedAt)} />
                                        </>
                                    );
                                }

                                return null;
                            })();

                            if (message === null) {
                                return null;
                            }

                            return (
                                <Alert
                                    severity="info"
                                    inline
                                >
                                    {message}
                                </Alert>
                            );
                        }

                        if (!canEdit) {
                            return null;
                        }

                        return (
                            <>
                                {disruptionData.disruption.accessibleMutations.putArrivalDisruption && (

                                    <PrimaryButton
                                        onClick={() => history.push(`/disruptions/${id}/edit`)}
                                        className={classes.editButton}
                                    >
                                        {t('pages.disruption.buttons.editDisruption')}
                                    </PrimaryButton>
                                )}

                                {disruptionData.disruption?.accessibleMutations.completeDisruption
                                    ? (
                                        <MenuButton
                                            open={menuOpen}
                                            onClick={() => setMenuOpen(true)}
                                            onClose={() => setMenuOpen(false)}
                                            menuItems={getValue(() => {
                                                const complete = (
                                                    <MenuButtonItem
                                                        key="complete"
                                                        onClick={() => {
                                                            setCompleteDialogOpen(true);
                                                            setMenuOpen(false);
                                                        }}
                                                    >
                                                        Complete
                                                    </MenuButtonItem>
                                                );

                                                const cancel = (
                                                    <MenuButtonItem
                                                        key="cancel"
                                                        onClick={() => {
                                                            setCancelDialogOpen(true);
                                                            setMenuOpen(false);
                                                        }}
                                                        dangerous
                                                    >
                                                        Cancel
                                                    </MenuButtonItem>
                                                );

                                                if (disruptionData.disruption?.accessibleMutations.completeDisruption) {
                                                    return [complete];
                                                }

                                                return [cancel];
                                            })}
                                        >
                                            <MoreVertIcon />
                                        </MenuButton>
                                    )
                                    : null
                                }
                            </>
                        );
                    })()}
                </Grid>
            </Grid >

            <Grid container className={classes.flightDetails}>
                <Grid item xs={4}>
                    <Grid container className={classes.flightSection}>
                        <Grid item>
                            <FlightTakeoffIcon className={classes.flightSectionIcon} />
                        </Grid>

                        <Grid item xs>
                            <section className={classes.flightSubsection}>
                                <Typography
                                    variant="h4"
                                    component="h4"
                                >
                                    Departure
                                </Typography>

                                <div>
                                    {flight.departure.airport.iata} - {flight.departure.airport.name}
                                </div>

                                <div>
                                    <UtcTime time={moment(flight.departure.scheduledDepartureTime)} />

                                    <span className={classes.flightDate}>
                                        {formatFlightDate(flight.departure.scheduledDepartureTime)}
                                    </span>
                                </div>

                                {
                                    isArrivalDiversion
                                        ? null
                                        : alternateSections
                                }
                            </section>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={4}>
                    <Grid container className={classes.flightSection}>
                        <Grid item>
                            <FlightLandIcon className={classes.flightSectionIcon} />
                        </Grid>

                        <Grid item xs>
                            <section className={classes.flightSubsection}>
                                <Typography
                                    variant="h4"
                                    component="h4"
                                >
                                    Arrival
                                </Typography>

                                <div>
                                    {flight.arrival.airport.iata} - {flight.arrival.airport.name}
                                </div>

                                <div>
                                    <UtcTime time={moment(flight.arrival.scheduledArrivalTime)} />

                                    <span className={classes.flightDate}>
                                        {formatFlightDate(flight.arrival.scheduledArrivalTime)}
                                    </span>
                                </div>
                            </section>

                            {
                                isArrivalDiversion
                                    ? alternateSections
                                    : null
                            }
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={4}>
                    <Grid container className={classes.flightSection}>
                        <Grid item>
                            <PeopleIcon className={classes.flightSectionIcon} />
                        </Grid>

                        <Grid item xs>
                            <Typography
                                variant="h4"
                                component="h4"
                            >
                                Passengers
                            </Typography>

                            <div>Total: {formatNumber(flight.passengerCount.total, locale)}</div>
                            <div>Infants (0-2 yrs): {formatNumber(flight.passengerCount.infants, locale)}</div>
                            <div>Restricted mobility: {formatNumber(flight.passengerCount.wheelchair, locale)}</div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Divider className={classes.divider} />

            <div className={classes.coaches}>
                <div className={classes.coachesHeader}>
                    <Typography
                        variant="h2"
                        component="h2"
                        className={classes.coachesHeading}
                    >
                        Coaches
                    </Typography>

                    {
                        disruptionData.disruption.coachesAuthorized
                            ? (
                                <Alert
                                    severity="info"
                                    inline
                                    className={classes.coachesAuthorizedWarning}
                                >
                                    Booked automatically
                                </Alert>
                            )
                            : null
                    }
                </div>

                {(() => {
                    if (routesLoading) {
                        if (showRoutesLoader) {
                            return (
                                <Fade in>
                                    <Spinner size={32} color="secondary" />
                                </Fade>
                            );
                        }

                        if (!coachRoutes) {
                            return null;
                        }

                        // TODO: Show spinner somewhere
                    }

                    if (routesError || !coachRoutes) {
                        // TODO: Proper UI
                        return <>Error</>;
                    }

                    if (coachRoutes.length === 0) {
                        // TODO: Improve UI & translate
                        return <>No suppliers available</>;
                    }

                    return (
                        <LoadingFade>
                            <div>
                                <CoachesStatus
                                    className={classes.coachesStatus}
                                    requiredSeats={disruptionData.disruption.coachSeatsRequired}
                                    availabilities={coachRoutes.flatMap(route => route.availabilities)}
                                    rides={coachRoutes.flatMap(route => route.rides)}
                                />

                                {
                                    coachRoutes.map((route, index) => {
                                        if (disruptionData.disruption === null) {
                                            throw new Error('Disruption is null');
                                        }

                                        return (
                                            <CoachRoute
                                                key={index}
                                                route={route}
                                                automaticBookings={disruptionData.disruption?.coachesAuthorized ?? false}
                                                showCustomerActions={canEdit}
                                                onReload={() => reloadRoutes()}
                                                totalSeatsNeeded={disruptionData.disruption.coachSeatsRequired}
                                            />
                                        );
                                    })
                                }
                            </div>
                        </LoadingFade>
                    );
                })()}
            </div>

            {
                showHotel
                    ? (
                        <>
                            <Divider className={classes.divider} />

                            <div>
                                <Heading level={2}>
                                    Hotel rooms
                                </Heading>

                                <HotelRoomsList />
                            </div>
                        </>
                    )
                    : null
            }
        </>
    );
};

export default Content;
