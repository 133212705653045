import { Alert, Form, PrimaryButton, SecondaryButton, TextField } from '@get-e/react-components';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import delay from '../helpers/delay';
import FormError from '../helpers/validation/FormError';
import getFormErrorMessage from '../helpers/validation/getFormErrorMessage';
import getHelperText from '../helpers/validation/getHelperText';
import InputError from '../helpers/validation/InputError';
import useFormStyles from '../styles/useFormStyles';

const EditUserForm: FunctionComponent<{
    user: {
        id: string;
        name: string;
        email: string;
    };
    onCancel: () => void;
    onSaved: () => void;
}> = ({ user, onCancel }) => {
    const formClasses = useFormStyles();
    const { t } = useTranslation();

    const [submitting, setSubmitting] = useState(false);

    const [name, setName] = useState(user.name);
    const [email, setEmail] = useState(user.email);

    const [formError, setFormError] = useState<FormError | null>(null);
    const [nameError, setNameError] = useState<InputError | null>(null);
    const [emailError, setEmailError] = useState<InputError | null>(null);

    const submitForm = async (): Promise<void> => {
        setSubmitting(true);

        // TODO: Implement
        await delay(2000);

        setFormError(FormError.UserError);
        setNameError(InputError.Empty);
        setEmailError(InputError.Empty);

        setSubmitting(false);
    };

    return (
        <Form
            autoComplete="off"
            className={formClasses.form}
            onSubmit={() => submitForm()}
        >
            <TextField
                value={name}
                onChange={event => setName(event.target.value)}
                error={nameError !== null}
                helperText={getHelperText(nameError, t)}
                label={t('pages.users.fields.name')}
                required
            />

            <TextField
                value={email}
                onChange={event => setEmail(event.target.value)}
                error={emailError !== null}
                helperText={getHelperText(emailError, t)}
                inputProps={{ autoCapitalize: 'none' }}
                label={t('pages.users.fields.email')}
                type="email"
                required
            />

            {(() => {
                if (formError === null) {
                    return null;
                }

                return (
                    <Alert severity="error" className={formClasses.mainError}>
                        {getFormErrorMessage(formError, t)}
                    </Alert>
                );
            })()}

            <div className={formClasses.buttons}>
                <PrimaryButton
                    loading={submitting}
                    onClick={event => {
                        event.preventDefault();

                        return submitForm();
                    }}
                >
                    {t('buttons.save')}
                </PrimaryButton>

                <SecondaryButton onClick={onCancel} disabled={submitting}>
                    {t('buttons.cancel')}
                </SecondaryButton>
            </div>
        </Form>
    );
};

export default EditUserForm;
