import InputError from './InputError';

export default function parseInputErrorCode(code: string): InputError {
    switch (code) {
        case 'INVALID_EMAIL':
            return InputError.InvalidEmail;
        case 'REQUIRED':
            return InputError.Empty;
        default:
            throw new Error(`Unhandled input error code "${code}"`);
    }
}
