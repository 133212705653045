import { createContext, useContext } from 'react';

export const supportedLocales = ['en-GB', 'pl-PL', 'nl-NL', 'fr-FR'] as const;

export type SupportedLocale = typeof supportedLocales[number];

export const isSupportedLocale = (value: string): value is SupportedLocale => {
    const locales = supportedLocales as readonly string[];

    return locales.includes(value);
};

export interface LocaleContextProps {
    locale: SupportedLocale;
    updateLocale: (locale: SupportedLocale) => void;
}

export const LocaleContext = createContext<LocaleContextProps | null>(null);

export const useLocaleContext = (): LocaleContextProps => {
    const localeContext = useContext(LocaleContext);

    if (localeContext === null) {
        throw new Error('LocaleContext should not be null');
    }

    return localeContext;
};
