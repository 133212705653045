import gql from 'graphql-tag';

export const UPDATE_RIDE_DESCRIPTION = gql`
    mutation setRideDescription($input: SetRideDescriptionInput!) {
        setRideDescription(input: $input) {
            id
        }
    }
`;

export interface UpdateRideDescriptionResult {
    setRideDescription: {
        id: string;
    };
}

export interface UpdateRideDescriptionInput {
    input: {
        rideId: string;
        description?: string;
    };
}
