import { Modal, PrimaryButton, SecondaryButton } from '@get-e/react-components';
import { Typography } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import delay from '../../../helpers/delay';
import useModalStyles from '../../../styles/useModalStyles';

async function deleteInvite(inviteId: string): Promise<void> {
    console.log(`Deleting invite ${inviteId}`);

    await delay(2000);
}

const DeleteUserInviteDialog: FunctionComponent<{
    invite: {
        id: string;
        userName: string;
    };
    onClose: () => void;
}> = ({ invite, onClose }) => {
    const { t } = useTranslation();
    const [deleting, setDeleting] = useState(false);
    const modalClasses = useModalStyles();

    async function handleClick(): Promise<void> {
        setDeleting(true);
        await deleteInvite(invite.id);
        setDeleting(false);
    }

    return (
        <Modal
            open={true}
            onClose={onClose}
        >
            <div className={modalClasses.header}>
                <Typography variant="h2" component="h2" className={modalClasses.heading}>
                    {t('pages.users.deleteInviteDialog.title')}
                </Typography>
            </div>

            <p>
                {t('pages.users.deleteInviteDialog.description', { userName: invite.userName })}
            </p>

            <div className={modalClasses.buttonContainer}>
                <span className={modalClasses.primaryButton}>
                    <PrimaryButton
                        onClick={handleClick}
                        loading={deleting}
                        variation="danger"
                    >
                        {t('pages.users.button.deleteInvite')}
                    </PrimaryButton>
                </span>

                <SecondaryButton
                    onClick={onClose}
                    disabled={deleting}
                >
                    {t('buttons.cancel')}
                </SecondaryButton>
            </div>
        </Modal>
    );
};

export default DeleteUserInviteDialog;
