export default function formatFlightNumber(input: string): string {
    let result = '';
    let lastWasLetter = false;

    for (const character of input) {
        if (isLetter(character)) {
            if (!lastWasLetter) {
                result += ' ';
            }

            result += character.toLocaleUpperCase();
            lastWasLetter = true;
        } else if (isNumber(character)) {
            if (lastWasLetter) {
                result += ' ';
            }

            result += character;
            lastWasLetter = false;
        }
    }

    return result.trim();
}

function isLetter(character: string): boolean {
    return (/^[a-zA-Z]+$/).test(character);
}

function isNumber(character: string): boolean {
    return (/^\d+$/).test(character);
}
