import { IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import clsx from 'clsx';
import { Moment } from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import nonNull from '../../../../helpers/nonNull';
import useTableStyles from '../../../../styles/useTableStyles';
import { DriverStatus, DrivingPoint } from '../../../Disruption/useCoachRoutes';
import AvailabilityEditDescriptionModal from '../modals/AvailabilityEditDescription';
import { TimeCell } from './AvailabilityRow';
import CoachRideEdit from './CoachEdit';
import RideStatus from './RideStatus';
import useRowStyles from './useRowStyles';

export interface RideRowProps {
    status?: string;
    id: string;
    time: number | string;
    timeInMinutes: number;
    smallTime: string;
    seats: number;
    description?: string;
    events: {
        driverDispatchedAt: Moment | null;
        cancelledAt: Moment | null;
        declinedAt: Moment | null;
        confirmedAt: Moment | null;
        createdAt: Moment;
        availableAt: Moment;
    };
    driver: null | {
        id: string;
        name: string;
        phoneNumber: string;
    };
    meetingPoint: string;
    busNumber: string;
    pickupPoint: {
        timeZone: string;
        trackingTimes: {
            estimatedArrival: Moment | null;
            actualArrival: Moment | null;
            estimatedDeparture: Moment | null;
            actualDeparture: Moment | null;
        };
    };
    dropOffPoint: {
        trackingTimes: {
            estimatedArrival: Moment | null;
            actualArrival: Moment | null;
        };
    };
    driverStatus: DriverStatus | null;
    points: {
        nodes: DrivingPoint[];
        pageInfo: {
            hasNextPage: boolean;
        };
    };
    supplier: {
        id: string;
    };
}

const RideRow: FunctionComponent<{
    ride: RideRowProps;
    refetchRideAvailabilities: () => void;
    open: boolean;
    onOpen: () => void;
    onClose: () => void;
    bookedAutomatically: boolean;
    requestCreatedAt: Moment;
}> = props => {
    const {
        ride,
        refetchRideAvailabilities,
        onOpen,
        onClose,
        open,
        bookedAutomatically,
        requestCreatedAt,
    } = props;

    const [openEditRide, setOpenEditRide] = useState(false);
    const [openDeleteRide, setOpenDeleteRide] = useState(false);
    const rowClasses = useRowStyles();
    const tableClasses = useTableStyles();
    const { t } = useTranslation();

    return (
        <>
            <TableRow>
                <TableCell className={rowClasses.toggleCell}>
                    <IconButton
                        aria-label={open
                            ? t('pages.availability.aria.collapseRow')
                            : t('pages.availability.aria.expandRow')
                        }
                        size="small"
                        onClick={() => (open ? onClose() : onOpen())}
                    >
                        {open
                            ? <KeyboardArrowUpIcon />
                            : <KeyboardArrowDownIcon />
                        }
                    </IconButton>
                </TableCell>
                <TableCell className={rowClasses.statusContainer}>
                    <RideStatus
                        cancelled={Boolean(ride.events.cancelledAt)}
                        driverAssigned={ride.driver !== null}
                        confirmed={Boolean(ride.events.confirmedAt)}
                        pickupTime={
                            nonNull(
                                ride.pickupPoint.trackingTimes
                                    .estimatedArrival?.toDate()
                            )
                        }
                        dropOffTime={
                            ride.dropOffPoint.trackingTimes
                                .estimatedArrival?.toDate() ?? null
                        }

                        // TODO: Stopovers
                        points={[
                            {
                                arrived: Boolean(
                                    ride.pickupPoint
                                        .trackingTimes.actualArrival
                                ),
                                departed: Boolean(
                                    ride.pickupPoint
                                        .trackingTimes.actualDeparture
                                ),
                            }, {
                                arrived: Boolean(
                                    ride.dropOffPoint
                                        .trackingTimes.actualArrival
                                ),
                                departed: false,
                            },
                        ]}
                        driverDispatched={Boolean(ride.events.driverDispatchedAt)}
                    />
                </TableCell>
                <TableCell>
                    <TimeCell
                        value={ride.time}
                        icon={
                            <EventAvailableIcon
                                className={rowClasses.timeIcon}
                                fontSize="inherit"
                            />
                        }
                        smallText={ride.smallTime}
                    />
                </TableCell>
                <TableCell>
                    {t('pages.availability.persons', { count: ride.seats })}
                </TableCell>
                <TableCell className={rowClasses.description}>
                    {ride.description}
                </TableCell>
                <TableCell>
                    {
                        ride.driver
                            ? (
                                <>
                                    <div>{ride.driver.name}</div>
                                    <div>{ride.driver.phoneNumber}</div>
                                </>
                            )
                            : <div>-</div>
                    }

                    <div>{ride.busNumber ?? '-'}</div>
                </TableCell>
                <TableCell>
                    <IconButton
                        className={rowClasses.root}
                        aria-label={t('pages.availability.aria.editAvailability')}
                        onClick={() => setOpenEditRide(true)}
                    >
                        <EditIcon />
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Tooltip
                        title={t('pages.availability.alerts.disabledRideDelete') ?? ''}
                    >
                        <span>
                            <IconButton
                                className={rowClasses.root}
                                aria-label={
                                    t('pages.availability.aria.deleteAvailability')
                                }
                                onClick={() => setOpenDeleteRide(true)}
                                disabled
                            >
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </TableCell>

            </TableRow>
            <TableRow>
                <TableCell
                    colSpan={8}
                    className={clsx(
                        tableClasses.collapsableCell,
                        { [tableClasses.collapsedCell]: !open }
                    )}
                >
                    <CoachRideEdit
                        open={open}
                        ride={ride}
                        bookedAutomatically={bookedAutomatically}
                        requestCreatedAt={requestCreatedAt}
                    />
                </TableCell>
            </TableRow>
            <AvailabilityEditDescriptionModal
                open={openEditRide}
                onClose={() => setOpenEditRide(false)}
                onEdited={refetchRideAvailabilities}
                ride={ride}
            />
        </>
    );
};

export default RideRow;

