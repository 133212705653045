import { ApolloError } from 'apollo-client';
import { DocumentNode } from 'graphql';
import { useQuery } from 'react-apollo';

export type CustomQueryResult<T> = (
    { reload: () => void }
    & {
        loading: boolean;
        error?: ApolloError;
        result?: T;
    }
    & (
        { loading: true }
        | { loading: false; error: ApolloError }
        | { loading: false; result: T }
    )
);

export default function useCustomQuery<TQueryResult, TQueryInput, TResult>(
    query: DocumentNode,
    { variables, mapResult, onError, skip }: {
        variables: TQueryInput;
        mapResult: (queryResult: TQueryResult) => TResult;
        onError: (error: ApolloError) => void;
        skip?: boolean;
    }
): CustomQueryResult<TResult> {
    const { data, loading, error, refetch: reload } = useQuery<TQueryResult, TQueryInput>(
        query,
        {
            variables,
            onError,
            notifyOnNetworkStatusChange: true,
            skip,
        }
    );

    if (loading) {
        return {
            loading,
            reload,
        };
    }

    if (error) {
        return {
            loading,
            error,
            reload,
        };
    }

    if (!data) {
        throw new Error('Query did not return any data');
    }

    return {
        loading,
        result: mapResult(data),
        reload,
    };
}
