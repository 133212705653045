import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageDefinition } from '../../../createRoutes';
import report from '../../../helpers/report';
import useTitle from '../../../helpers/useTitle';
import Page from '../../../layouts/Page';
import useDisruptionName from '../../../queries/useDisruptionName';
import Content from './Content';

const editDisruptionPage: PageDefinition = {
    path: '/disruptions/:id/edit',
    Component({ getParam }) {
        const { t } = useTranslation();
        const disruptionId = getParam('id');

        const { result: data, loading } = useDisruptionName(
            disruptionId,
            {
                onError(apolloError) {
                    report(apolloError);
                },
            }
        );

        useTitle(data?.disruptionFlightNumber ?? null);

        return (
            <Page
                breadcrumbs={{
                    name: t('breadcrumbs.editDisruption'),
                    previous: {
                        name: loading
                            ? <Skeleton width="7ch" />
                            : data?.disruptionName ?? t('breadcrumbs.unknownDisruption'),
                        path: `/disruptions/${disruptionId}`,
                        previous: {
                            name: t('breadcrumbs.disruptions'),
                            path: '/disruptions',
                        },
                    },
                }}
            >
                <Content id={disruptionId} />
            </Page>
        );
    },
};

export default editDisruptionPage;
