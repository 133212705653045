import {
    Heading, SecondaryTab, TableBody,
    TableContainer, TableHead, TabPanel,
} from '@get-e/react-components';
import { IconButton, makeStyles, Table, TableCell, TableRow } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import moment from 'moment';
import { Moment } from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UtcDate from '../../components/UtcDate';
import UtcDateRange from '../../components/UtcDateRange';
import UtcDateTime from '../../components/UtcDateTime';
import CreateDateRangeReportForm from './CreateDateRangeReportForm';
import CreateSingleFlightReportForm from './CreateSingleFlightReportForm';

export enum Tab {
    DateRange,
    SingleFlight,
}

const useStyles = makeStyles({
    root: {
        maxWidth: 700,
        margin: '0 auto',
    },
    recentReports: { marginTop: '2rem' },

    actionColumnHeading: { textAlign: 'center' },

    // TODO: Move to useTableStyles
    actionsCell: {
        padding: '.25rem',
        textAlign: 'center',
    },
});

const Content: FunctionComponent<{ tab: Tab }> = ({ tab }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const recentExports: Array<{
        type: 'SINGLE_FLIGHT';
        exportId: string;
        createdAt: Moment;
        flight: {
            number: string;
            departureDate: Moment;
        };
    } | {
        type: 'DATE_RANGE';
        exportId: string;
        createdAt: Moment;
        range: {
            from: Moment;
            to: Moment;
        };
    }> = [
        {
            type: 'SINGLE_FLIGHT',
            exportId: '1',
            createdAt: moment.utc()
                .startOf('day')
                .add({
                    hours: 10,
                    minutes: 7,
                }),
            flight: {
                number: 'FR 1813',
                departureDate: moment.utc().subtract(1, 'day'),
            },
        }, {
            type: 'DATE_RANGE',
            exportId: '2',
            createdAt: moment.utc()
                .subtract(1, 'day')
                .startOf('day')
                .add({
                    hours: 9,
                    minutes: 40,
                }),
            range: {
                from: moment.utc()
                    .subtract(1, 'month')
                    .startOf('month'),
                to: moment.utc()
                    .subtract(1, 'month')
                    .endOf('month'),
            },
        },
    ];

    return (
        <div className={classes.root}>
            <Heading level={1}>Create report</Heading>

            <div>
                <SecondaryTab
                    url={'/reports/date-range'}
                    index={Tab.DateRange}
                    selectedIndex={tab}
                >
                    Date range
                </SecondaryTab>

                <SecondaryTab
                    url={'/reports/single-flight'}
                    index={Tab.SingleFlight}
                    selectedIndex={tab}
                >
                    Single flight
                </SecondaryTab>
            </div>

            <div>
                <TabPanel value={Tab.DateRange} selectedValue={tab}>
                    <CreateDateRangeReportForm />
                </TabPanel>

                <TabPanel value={Tab.SingleFlight} selectedValue={tab}>
                    <CreateSingleFlightReportForm />
                </TabPanel>
            </div>

            <div className={classes.recentReports}>
                <Heading level={2}>Recent reports</Heading>

                <TableContainer noFooter>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Created on</TableCell>
                                <TableCell>Flight</TableCell>
                                <TableCell>Date</TableCell>

                                <TableCell className={classes.actionColumnHeading}>
                                    Download
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                recentExports.map(recentExport => (
                                    <TableRow key={recentExport.exportId}>
                                        <TableCell>
                                            <UtcDateTime date={recentExport.createdAt} />
                                        </TableCell>

                                        <TableCell>
                                            {
                                                recentExport.type === 'SINGLE_FLIGHT'
                                                    ? recentExport.flight.number
                                                    : 'Multiple'
                                            }
                                        </TableCell>

                                        <TableCell>
                                            {
                                                recentExport.type === 'SINGLE_FLIGHT'
                                                    ? <UtcDate date={recentExport.flight.departureDate} />
                                                    : (
                                                        <UtcDateRange
                                                            start={recentExport.range.from}
                                                            end={recentExport.range.to}
                                                        />
                                                    )
                                            }
                                        </TableCell>

                                        <TableCell className={classes.actionsCell}>
                                            <IconButton
                                                aria-label="Download report"
                                            >
                                                <DownloadIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default Content;
