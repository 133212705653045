/* eslint-disable max-lines */

import {
    TableContainer, TableHead,
    TableBody, TableHeader,
} from '@get-e/react-components';
import { Table, TableCell, TableRow } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TFunction } from 'i18next';
import moment, { Moment } from 'moment-timezone';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useLocaleContext } from '../../../../context/LocaleContext';
import formatRelativeTime, { MomentLocale } from '../../../../helpers/formatRelativeTime';
import offsetCoordinate from '../../../../helpers/offsetCoordinate';
import { stringToMoment } from '../../../../helpers/stringToMoment';
import { DriverStatus } from '../../../Disruption/useCoachRoutes';
import { Ride, RideAvailability } from '../index.graphql';
import {
    AvailabilityRowProps,
    AvailabilityRow,
} from './AvailabilityRow';
import EmptyVehicles from './CoachesEmpty';

import RideRow, { RideRowProps } from './RideRow';

const useStyles = makeStyles(theme => createStyles(
    {
        availabilitiesFooter: { border: 'none' },
        tableContainer: { overflow: 'hidden' },
    }
));

function getDriverStatus(events: {
    onTheWay: boolean;
    arrivedAtPickup: boolean;
    departedFromPickup: boolean;
    arrivedAtDropOff: boolean;
}): DriverStatus | null {
    if (!events.onTheWay) {
        return null;
    }

    if (!events.arrivedAtPickup) {
        return DriverStatus.OnTheWay;
    }

    if (!events.departedFromPickup) {
        return DriverStatus.AtPickup;
    }

    if (!events.arrivedAtDropOff) {
        return DriverStatus.OnBoard;
    }

    return DriverStatus.DroppedOff;
}

const formatRideAvailability = (
    avail: RideAvailability,
    t: TFunction
): AvailabilityRowProps => (
    {
        id: avail.id,
        timeInMinutes: avail.estimatedArrivalTimeMinutes,
        smallTime: t('pages.availability.labels.smallTime'),
        seats: avail.seats,
        description: avail.description,
    }
);

const formatRide = (
    ride: Ride,
    locale: MomentLocale,
    t: TFunction,
): RideRowProps => (
    {
        id: ride.id,
        time: formatRelativeTime(
            moment.tz(
                ride.estimatedArrivalTime,
                ride.pickupPoint.location.timeZone
            ),
            locale,
            t
        ),
        timeInMinutes: 0,
        smallTime: moment.tz(
            ride.estimatedArrivalTime,
            ride.pickupPoint.location.timeZone
        ).format(t('dateFormat.timeDateShortMonth')),
        seats: ride.vehicle.seats,
        description: ride.description,
        meetingPoint: ride.meetingPoint,
        busNumber: ride.busNumber,
        driver: ride.driver,
        pickupPoint: {
            timeZone: ride.pickupPoint.location.timeZone,
            trackingTimes: {
                estimatedArrival: moment(ride.pickupPoint.trackingTimes.estimatedArrival),
                actualArrival:
                    stringToMoment(ride.pickupPoint.trackingTimes.actualArrival),
                estimatedDeparture:
                    stringToMoment(ride.pickupPoint.trackingTimes.estimatedDeparture),
                actualDeparture:
                    stringToMoment(ride.pickupPoint.trackingTimes.actualDeparture),
            },
        },
        dropOffPoint: {
            trackingTimes: {
                estimatedArrival:
                    stringToMoment(ride.dropOffPoint.trackingTimes.estimatedArrival),
                actualArrival:
                    stringToMoment(ride.dropOffPoint.trackingTimes.actualArrival),
            },
        },
        events: {
            availableAt: moment(ride.events.availableAt),
            createdAt: moment(ride.events.createdAt),
            cancelledAt: stringToMoment(ride.events.customerCancelledAt),
            confirmedAt: stringToMoment(ride.events.supplierConfirmedAt),
            declinedAt: stringToMoment(ride.events.supplierDeclinedAt),
            driverDispatchedAt: stringToMoment(ride.events.driverDispatchedAt),
        },
        points: ride.points,
        driverStatus: getDriverStatus({
            onTheWay: ride.events.driverDispatchedAt !== null,
            arrivedAtPickup: ride.pickupPoint.trackingTimes.actualArrival !== null,
            departedFromPickup: ride.pickupPoint.trackingTimes.actualDeparture !== null,
            arrivedAtDropOff: ride.dropOffPoint.trackingTimes.actualArrival !== null,
        }),
        supplier: { id: ride.supplier.id },
    }
);

interface CoachesTableProps {
    rideAvailabilities: RideAvailability[] | undefined;
    rides: Ride[] | undefined;
    total: number;
    refetchRideAvailabilities: () => void;
    bookedAutomatically: boolean;
    requestCreatedAt: Moment;
}

const CoachesTable: FunctionComponent<CoachesTableProps> = (
    {
        rideAvailabilities,
        total,
        refetchRideAvailabilities,
        rides,
        bookedAutomatically,
        requestCreatedAt,
    }
) => {
    const [open, setOpen] = useState<null | string>(null);
    const { t } = useTranslation();
    const { locale } = useLocaleContext();
    const classes = useStyles();

    const hasAvailabilities = rideAvailabilities && rideAvailabilities?.length !== 0;
    const hasRides = rides && rides?.length !== 0;

    const personsAvailable = rideAvailabilities
        ?.map(vehicle => vehicle.seats)
        .reduce((seats, count) => seats + count, 0) ?? 0;

    const personsBooked = rides
        ?.map(vehicle => vehicle.vehicle.seats)
        .reduce((seats, count) => seats + count, 0) ?? 0;

    const totalPersons = personsAvailable + personsBooked;

    const formattedRides = rides?.map(ride => formatRide(ride, locale, t)) ?? [];

    const formattedRideAvailabilites = rideAvailabilities
        ?.map(avail => formatRideAvailability(avail, t)) ?? [];

    return (
        <TableContainer>
            <TableHeader
                title={t('pages.availability.coachesHeader')}
            />
            <Table className={classes.tableContainer}>
                <TableHead>
                    <TableRow>
                        {hasRides && (
                            <TableCell />
                        )}
                        <TableCell>
                            {t('pages.availability.labels.status')}
                        </TableCell>
                        <TableCell>
                            {t('pages.availability.labels.time')}
                        </TableCell>
                        <TableCell>
                            {t('pages.availability.labels.capacity')}
                        </TableCell>
                        <TableCell>
                            {t('pages.availability.labels.description')}
                        </TableCell>
                        <TableCell>
                            {t('pages.availability.labels.driverAndVehicle')}
                        </TableCell>
                        <TableCell />
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {hasAvailabilities || hasRides
                        ? (
                            <>
                                {formattedRideAvailabilites.map(availability => (
                                    <AvailabilityRow
                                        key={availability.id}
                                        availability={availability}
                                        refetchRideAvailabilities={
                                            refetchRideAvailabilities
                                        }
                                        hasRides={hasRides ?? false}
                                    />
                                ))}
                                {formattedRides.map(ride => (
                                    <RideRow
                                        key={ride.id}
                                        ride={ride}
                                        refetchRideAvailabilities={
                                            refetchRideAvailabilities
                                        }
                                        onOpen={() => setOpen(ride.id)}
                                        onClose={() => setOpen(null)}
                                        open={ride.id === open}
                                        bookedAutomatically={bookedAutomatically}
                                        requestCreatedAt={requestCreatedAt}
                                    />
                                ))}
                                <TableRow>
                                    <TableCell
                                        className={classes.availabilitiesFooter}
                                        colSpan={8}
                                    >
                                        <Trans
                                            i18nKey="pages.availability.availabilitiesFooter"
                                            count={totalPersons}
                                        >
                                            <strong></strong>
                                        </Trans>{' / '}
                                        <Trans
                                            i18nKey="pages.availability.availabilitiesFooter2"
                                            count={total}
                                        >
                                            <strong></strong>
                                        </Trans>
                                    </TableCell>
                                </TableRow>
                            </>
                        )
                        : <EmptyVehicles />
                    }
                </TableBody>

            </Table>
        </TableContainer>
    );
};

export default CoachesTable;
