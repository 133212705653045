import { Moment } from 'moment';
import React, { FunctionComponent } from 'react';
import UtcDate from './UtcDate';

const UtcDateRange: FunctionComponent<{
    start: Moment;
    end: Moment;
}> = ({ start, end }) => (
    <>
        <UtcDate
            date={start}
            showYear={start.year() !== end.year()}
            hideSuffix
            shortMonth
        />

        {' - '}

        <UtcDate
            date={end}
            shortMonth
            showYear
        />
    </>
);

export default UtcDateRange;
