import { userProfileIcon } from '@get-e/react-components';
import { Grid, Typography } from '@material-ui/core';
import { Moment } from 'moment';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Airport } from '../../../../components/AirportsSelector';
import { useCurrentUserContext } from '../../../../context/CurrentUserContext';
import formatPastDate from '../../../../helpers/formatPastDate';
import useUserProfileStyles from '../../../../styles/useUserProfileStyles';
import EditUserForm from './EditUserForm';

export interface CustomerUser {
    id: string;
    name: string;
    email: string;
    customerUserRole: string;
    airportWhitelist: Airport[] | null;
    customerRoles: Array<{
        id: string;
        name: string;
    }>;
    joinedOn: Moment;
}

const Content: FunctionComponent<{
    customerId: string;
    userId: string;
    user: CustomerUser;
    // eslint-disable-next-line max-lines-per-function
}> = ({ customerId, user }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const profileClasses = useUserProfileStyles();
    const currentUser = useCurrentUserContext();

    const multipleOrganisations = currentUser.managedSupplier !== null
        || currentUser.managedCustomer === 'MULTIPLE';

    const myProfile = Boolean(false);

    return (
        <Grid
            container
            justify="flex-start"
            alignItems="stretch"
        >
            <Grid
                item
                xs={12}
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
            >
                <Grid item xs={12} md={6}>
                    <Grid
                        container
                        spacing={2}
                        justify="center"
                        alignItems="center"
                        className={profileClasses.header}
                    >
                        <Grid item>
                            <img
                                src={userProfileIcon}
                                alt={t('pages.editCustomerUser.alt.userProfileImage')}
                                className={profileClasses.image}
                            />
                        </Grid>

                        <Grid item xs>
                            <Typography
                                variant="h2"
                                component="h2"
                                className={profileClasses.heading}
                            >
                                {
                                    myProfile
                                        ? t('pages.backOfficeUsers.edit.myProfileTitle')
                                        : user.name
                                }
                            </Typography>

                            <p className={profileClasses.joinedOn}>{
                                t(
                                    'pages.users.edit.joinedOn',
                                    {
                                        email: user.email,
                                        date: formatPastDate(user.joinedOn, t),
                                    }
                                )
                            }</p>
                        </Grid>
                    </Grid>

                    <EditUserForm
                        customerId={customerId}
                        multipleOrganisations={multipleOrganisations}
                        user={user}
                        onSaved={() => history.push(`/customers/${customerId}/users`)}
                        onCancel={() => {
                            history.push(`/customers/${customerId}/users`);
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Content;
