import { Alert, Form, PrimaryButton, TextField } from '@get-e/react-components';
import { Grid } from '@material-ui/core';
import moment, { Moment } from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UtcDateField from '../../components/UtcDateField';
import formatFlightNumber from '../../helpers/formatFlightNumber';
import hasProperty from '../../helpers/hasProperty';
import allValid from '../../helpers/validation/allValid';
import FormError from '../../helpers/validation/FormError';
import getFormErrorMessage from '../../helpers/validation/getFormErrorMessage';
import getHelperText from '../../helpers/validation/getHelperText';
import getInputError from '../../helpers/validation/getInputError';
import InputError from '../../helpers/validation/InputError';
import and from '../../helpers/validation/validators/and';
import isAfter from '../../helpers/validation/validators/isAfter';
import isFilledString from '../../helpers/validation/validators/isFilledString';
import isNotNull from '../../helpers/validation/validators/isNotNull';
import useFormStyles from '../../styles/useFormStyles';

const CreateSingleFlightReportForm: FunctionComponent = () => {
    const formClasses = useFormStyles();
    const { t } = useTranslation();

    const [flightNumber, setFlightNumber] = useState<string>('');
    const [flightDepartureDate, setFlightDepartureDate] = useState<Moment | null>(moment());
    const [flightNumberError, setFlightNumberError] = useState<InputError | null>(null);
    const [flightDepartureDateError, setFlightDepartureDateError] = useState<InputError | null>(null);

    const [formError, setFormError] = useState<FormError | { message: string } | null>(null);
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = async (): Promise<void> => {
        setFormError(null);

        const validated = {
            flightNumber: isFilledString(flightNumber, InputError.Empty),
            endDate: isNotNull(flightDepartureDate, InputError.Empty),
        };

        if (!allValid(validated)) {
            setFormError(FormError.UserError);

            setFlightNumberError(getInputError(validated.flightNumber));
            setFlightDepartureDateError(getInputError(validated.endDate));

            return;
        }

        setSubmitting(true);

        // TODO: From API
        const url = 'https://gete-files.s3.eu-central-1.amazonaws.com/accounts/75/8f1660c0e149c079c565644f391827aee8af75e7646f4a9ba689799e166d9a63.xlsx';

        window.open(url, '_blank');

        setSubmitting(false);
    };

    return (
        <Form onSubmit={handleSubmit}>
            <p>{t('pages.reports.singleFlight.description')}</p>

            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <TextField
                        value={flightNumber}
                        onChange={event => {
                            const newValue = event.target.value;

                            if (formatFlightNumber(newValue) === flightNumber) {
                                return;
                            }

                            setFlightNumber(event.target.value);
                            setFlightNumberError(null);
                        }}
                        onBlur={() => setFlightNumber(
                            formatFlightNumber(flightNumber)
                        )}
                        label={t('pages.reports.singleFlight.inputs.flightNumber')}
                        required
                        error={flightNumberError !== null}
                        helperText={getHelperText(flightNumberError, t)}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <UtcDateField
                        label={t('pages.reports.singleFlight.inputs.flightDepartureDate')}
                        value={flightDepartureDate}
                        onChange={newDate => {
                            setFlightDepartureDate(newDate);
                            setFlightDepartureDateError(null);
                        }}
                        required
                        error={flightDepartureDateError !== null}
                        helperText={getHelperText(flightDepartureDateError, t)}
                    />
                </Grid>
            </Grid>

            {
                formError === null
                    ? null
                    : (
                        <Alert
                            severity="error"
                            className={formClasses.mainErrorLimitedWidth}
                        >
                            {
                                hasProperty(formError, 'message')
                                    ? formError.message
                                    : getFormErrorMessage(formError, t)
                            }
                        </Alert>
                    )
            }

            <div className={formClasses.buttons}>
                <PrimaryButton
                    onClick={() => handleSubmit()}
                    loading={submitting}
                    submitsForm
                >
                    {t('pages.reports.buttons.submit')}
                </PrimaryButton>
            </div>
        </Form>
    );
};

export default CreateSingleFlightReportForm;
