import {
    LoadingPage, TableBody, TableContainer,
    TableFooter, TableHead, TableHeader,
} from '@get-e/react-components';
import { makeStyles, Table, TableCell, TableRow } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCurrentUserContext } from '../../context/CurrentUserContext';
import report from '../../helpers/report';
import useLoaderDebounce from '../../helpers/useLoaderDebounce';
import useUrlQuery from '../../helpers/useUrlQuery';
import useTablePageStyles from '../useTablePageStyles';
import { GET_CUSTOMERS, GetCustomersInput, GetCustomersResult } from './Content.graphql';

const useStyles = makeStyles({ row: { '&:hover': { cursor: 'pointer' } } });

const paginationLimit = 10;
const maxIataPreviews = 3;

const Row: FunctionComponent<{
    id: string;
    name: string;
    iataPreviews: string[];
    totalAirports: number;
    totalUsers: number;
}> = ({ id, name, iataPreviews, totalAirports, totalUsers }) => {
    const history = useHistory();
    const classes = useStyles();

    const airportsColumn = (() => {
        const combinedPreviews = iataPreviews.join(', ');

        if (totalAirports <= maxIataPreviews) {
            return combinedPreviews;
        }

        // TODO: Translate

        return `${combinedPreviews} and ${totalAirports - maxIataPreviews} more`;
    })();

    return (
        <TableRow
            hover
            className={classes.row}
            onClick={() => history.push(`/customers/${id}`)}
        >
            <TableCell>{name}</TableCell>
            <TableCell>{airportsColumn}</TableCell>
            <TableCell>{totalUsers}</TableCell>
        </TableRow>
    );
};

const Content: FunctionComponent = () => {
    const history = useHistory();
    const currentUser = useCurrentUserContext();

    if (!currentUser.isBackOffice && currentUser.managedCustomer !== 'MULTIPLE') {
        if (currentUser.managedCustomer === null) {
            // TODO: Not found page
            throw new Error('User does not belong to a customer');
        }

        // User belongs to only one customer, take them there instantly
        history.push(`/customers/${currentUser.managedCustomer.id}`);

        return null;
    }

    return <Page />;
};

// eslint-disable-next-line max-statements, max-lines-per-function
const Page: FunctionComponent = () => {
    const { t } = useTranslation();
    const classes = useTablePageStyles();

    const query = useUrlQuery();
    const after = query.get('after');
    const before = query.get('before');

    const history = useHistory();

    const variables = ((): GetCustomersInput => {
        if (before !== null) {
            return {
                last: paginationLimit,
                before: before || undefined,
            };
        }

        return {
            first: paginationLimit,
            after: after ?? undefined,
        };
    })();

    const {
        data,
        loading,
        error,
    } = useQuery<GetCustomersResult, GetCustomersInput>(
        GET_CUSTOMERS,
        {
            variables,
            onError(apolloError) {
                report(apolloError);
            },
        }
    );

    const showLoader = useLoaderDebounce(loading);

    if (loading && !data) {
        return showLoader
            ? <LoadingPage />
            : null;
    }

    if (error || !data) {
        // TODO
        return <>Error</>;
    }

    if (data.customers.nodes.length === 0) {
        // TODO
        return <>No customers found</>;
    }

    const handleBackToStartClick = (): void => {
        history.push({ search: '' });
    };

    const handlePreviousClick = (): void => {
        const { startCursor } = data.customers.pageInfo;

        if (startCursor === null) {
            throw new Error('Page info does not contain start cursor');
        }

        history.push({ search: `?before=${startCursor}` });
    };

    const handleNextClick = (): void => {
        const { endCursor } = data.customers.pageInfo;

        if (endCursor === null) {
            throw new Error('Page info does not contain end cursor');
        }

        history.push({ search: `?after=${endCursor}` });
    };

    const handleSkipToEndClick = (): void => {
        history.push({ search: '?before' });
    };

    return (
        <>
            <TableContainer className={classes.table}>
                <TableHeader
                    title="Customers"
                    onSearchChange={searchValue => console.log(`Searching: ${searchValue}`)}
                />

                <Table aria-label={t('pages.customers.aria.table')}>
                    <TableHead loading={loading} columns={3}>
                        <TableRow>
                            <TableCell>{t('pages.customers.column.name')}</TableCell>
                            <TableCell>{t('pages.customers.column.airports')}</TableCell>

                            <TableCell>
                                {t('pages.customers.column.totalUsers')}
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody loading={loading}>
                        {data.customers.nodes.map(customer => (
                            <Row
                                key={customer.id}
                                id={customer.id}
                                name={customer.name}

                                // TODO: Query this data
                                iataPreviews={['AMS', 'RTM', 'EIN']}
                                totalAirports={3}
                                totalUsers={8}
                            />
                        ))}
                    </TableBody>
                </Table>

                <TableFooter
                    hasPrevious={data.customers.pageInfo.hasPreviousPage}
                    hasNext={data.customers.pageInfo.hasNextPage}
                    count={{
                        total: data.customers.totalCount,
                        currentPage: data.customers.nodes.length,
                    }}
                    onBackToStartClick={handleBackToStartClick}
                    onPreviousClick={handlePreviousClick}
                    onNextClick={handleNextClick}
                    onSkipToEndClick={handleSkipToEndClick}
                    loading={loading}
                />
            </TableContainer>
        </>
    );
};

export default Content;
