import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageDefinition } from '../../../createRoutes';
import Page from '../../../layouts/Page';
import Content from './Content';

const supplierAddPage: PageDefinition = {
    path: '/suppliers/add',
    Component() {
        const { t } = useTranslation();

        return (
            <Page
                breadcrumbs={{
                    name: t('breadcrumbs.suppliersAdd'),
                    previous: {
                        name: t('breadcrumbs.suppliers'),
                        path: '/suppliers',
                    },
                }}
            >
                <Content />
            </Page>
        );
    },
};

export default supplierAddPage;
