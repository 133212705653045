import { makeStyles, createStyles } from '@material-ui/core';

const useRowStyles = makeStyles(theme => createStyles({
    root: {
        padding: '10px',
        margin: '-10px -15px',
        color: theme.palette.common.black,
        '& *': {
            width: '1.5rem',
            height: '1.5rem',
        },
    },
    rideStatusButton: {
        marginLeft: '0.5rem',
        marginBottom: '4px',
        color: theme.palette.common.black,
        '& *': {
            width: '1.2rem',
            height: '1.2rem',
        },
    },
    smallTime: {
        fontSize: '.75em',
        color: '#788B9B',
        fontWeight: 700,
    },
    statusContainer: { width: '140px' },
    statusBadge: {
        boxSizing: 'border-box',
        width: '140px',
    },
    description: { maxWidth: '180px' },
    timeCell: { maxWidth: '120px' },
    driverCell: { maxWidth: '180px' },
    toggleCell: { paddingRight: 0 },
    collapseBlock: { minHeight: '10em' },
    timeIcon: {
        width: '1.2rem',
        height: '1.2rem',
        marginBottom: '-4px',
    },
}));

export default useRowStyles;
