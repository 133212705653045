
export default function offsetCoordinate(
    coordinates: {
        latitude: number;
        longitude: number;
    },
    offsetMeters: {
        horizontal: number;
        vertical: number;
    }
): { latitude: number; longitude: number } {
    const radiusEarth = 6378;

    return {
        latitude:
            coordinates.latitude
            + (offsetMeters.vertical / 1000 / radiusEarth)
            * (180 / Math.PI),
        longitude:
            coordinates.longitude
            + (offsetMeters.horizontal / 1000 / radiusEarth)
            * (180 / Math.PI) / Math.cos(coordinates.latitude * Math.PI / 180),
    };
}
