import { TableBody, TableContainer, TableFooter, TableHead, TableHeader } from '@get-e/react-components';
import { IconButton, makeStyles, Table, TableCell, TableRow } from '@material-ui/core';
import moment, { Moment } from 'moment';
import React, { FunctionComponent } from 'react';
import Currency from '../../components/Currency';
import UtcDate from '../../components/UtcDate';
import UtcDateRange from '../../components/UtcDateRange';
import pdfIcon from './downloadPdf.svg';
import spreadsheetIcon from './downloadSpreadsheet.svg';

const useStyles = makeStyles({
    pdfIcon: {
        height: 34,
        verticalAlign: 'middle',
    },
    spreadsheetIcon: {
        height: 30,
        verticalAlign: 'middle',
    },
    downloadButtonsCell: { padding: '.25rem' },
});

interface Invoice {
    id: string;
    number: string;
    createdAt: Moment;
    dueDate: Moment;
    servicePeriod: {
        from: Moment;
        to: Moment;
    };
    billedAmount: {
        value: number;
        currency: string;
    };
}

const Content: FunctionComponent = () => {
    const classes = useStyles();

    const createFakeInvoice = (number: number, amount: number): Invoice => ({
        id: number.toString(),
        number: `SI21DA000${number + 4}`,
        createdAt: moment.utc()
            .subtract(number - 1, 'months')
            .startOf('month'),
        dueDate: moment.utc()
            .subtract(number - 1, 'months')
            .startOf('month')
            .add(30, 'days'),
        servicePeriod: {
            from: moment.utc()
                .subtract(number, 'months')
                .startOf('month'),
            to: moment.utc()
                .subtract(number, 'months')
                .endOf('month'),
        },
        billedAmount: {
            value: amount,
            currency: 'EUR',
        },
    });

    // TODO: Implement from API

    const invoices: Invoice[] = [
        createFakeInvoice(1, 4445.75),
        createFakeInvoice(2, 2100.32),
        createFakeInvoice(3, 2750.1),
    ];

    // TODO: Translate

    return (
        <TableContainer>
            <TableHeader
                title="Invoices"
                onSearchChange={value => console.log(`Searching "${value}"`)}
            />

            <Table aria-label="Collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell>Invoice number</TableCell>
                        <TableCell>Created on</TableCell>
                        <TableCell>Due on</TableCell>
                        <TableCell>Service period</TableCell>
                        <TableCell>Billed amount</TableCell>
                        <TableCell>Download</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        invoices.map(invoice => (
                            <TableRow key={invoice.id}>
                                <TableCell>
                                    {invoice.number}
                                </TableCell>

                                <TableCell>
                                    <UtcDate
                                        date={invoice.createdAt}
                                        shortMonth
                                        showYear
                                    />
                                </TableCell>

                                <TableCell>
                                    <UtcDate
                                        date={invoice.dueDate}
                                        shortMonth
                                        showYear
                                    />
                                </TableCell>

                                <TableCell>
                                    <UtcDateRange
                                        start={invoice.servicePeriod.from}
                                        end={invoice.servicePeriod.to}
                                    />
                                </TableCell>

                                <TableCell>
                                    <Currency
                                        amount={invoice.billedAmount.value}
                                        code={invoice.billedAmount.currency}
                                    />
                                </TableCell>

                                <TableCell className={classes.downloadButtonsCell}>
                                    <IconButton
                                        component="span"
                                        title="Download PDF"
                                    >
                                        <img
                                            src={pdfIcon}
                                            className={classes.pdfIcon}
                                            alt="PDF"
                                        />
                                    </IconButton>

                                    <IconButton
                                        component="span"
                                        title="Download spreadsheet"
                                    >
                                        <img
                                            src={spreadsheetIcon}
                                            className={classes.spreadsheetIcon}
                                            alt="Spreadsheet"
                                        />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>

            <TableFooter
                count={{
                    currentPage: 3,
                    total: 3,
                }}
                hasNext={false}
                hasPrevious={false}
                onBackToStartClick={() => console.log('Back to start')}
                onPreviousClick={() => console.log('Previous')}
                onNextClick={() => console.log('Next')}
                onSkipToEndClick={() => console.log('Skip to end')}
                loading={false}
            />
        </TableContainer>
    );
};

export default Content;
