/* eslint-disable max-len */
// TODO: Split up
/* eslint-disable max-lines */
import { TableBody, TableContainer, TableFooter, TableHead, TableHeader } from '@get-e/react-components';
import { Grid, makeStyles, Table, TableCell, TableRow } from '@material-ui/core';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import PeopleIcon from '@material-ui/icons/PeopleAltOutlined';
import clsx from 'clsx';
import moment, { Moment } from 'moment';
import React, { Fragment, FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Iata from '../../components/Iata';
import UtcTime from '../../components/UtcTime';
import useTablePageStyles from '../useTablePageStyles';
import Status from './Status';
import useDisruptionsStyles from './useDisruptionsStyles';

interface Disruption {
    id: string;
    coachSeatsBooked: number;
    coachSeatsRequired: number;
    arrivedCoaches: number;
    latestCoachArrival: Moment | null;
    eu261Deadline: string;
    completedAt?: string | null;
    cancelledAt?: string | null;
    moreAvailabilityExpected: boolean;
    flight: {
        number: string;
        departure: {
            airport: {
                iata: string;
                name: string;
            };
            scheduledDepartureTime: string;
        };
        arrival: {
            airport: {
                iata: string;
                name: string;
            };
            scheduledArrivalTime: string;
        };
        alternates: {
            nodes: Array<{
                airport: {
                    iata: string;
                    name: string;
                };
                estimatedTime: string | null;
            }>;
            totalCount: number;
            pageInfo: {
                hasNextPage: boolean;
            };
        };
    };
    availabilities: number;
    rides: number;
    unconfirmedRides: number;
    ridesInProgress: number;
}

const useStyles = makeStyles(theme => ({
    statusColumn: { [theme.breakpoints.up('md')]: { width: 280 } },
    emptyContainer: { margin: theme.spacing(4, 0) },
}));

const DisruptionsList: FunctionComponent<{
    title: string;
    disruptions: Disruption[];
    pagination: {
        totalCount: number;
        hasNext: boolean;
        hasPrevious: boolean;
        onBackToStartClick: () => void;
        onPreviousClick: () => void;
        onNextClick: () => void;
        onSkipToEndClick: () => void;
        loading: boolean;
    };
    noResults?: ReactNode;
    setSearchValue: (query: string) => void;
    hideFooter?: boolean;
// eslint-disable-next-line @typescript-eslint/naming-convention
}> = ({ title, disruptions, pagination, setSearchValue, noResults, hideFooter }) => {
    const tablePageClasses = useTablePageStyles();
    const { t } = useTranslation();
    const classes = useStyles();
    const isEmpty = disruptions.length === 0;

    return (
        <TableContainer className={tablePageClasses.table}>
            <TableHeader
                title={title}
                onSearchChange={searchValue => setSearchValue(searchValue)}
            />

            <Table aria-label={t('pages.users.aria.usersTable')}>
                <colgroup>
                    <col />
                    <col />
                    <col />
                    <col className={classes.statusColumn} />
                </colgroup>

                <TableHead loading={pagination.loading} columns={4}>
                    <TableRow>
                        <TableCell>{t('pages.disruptions.columns.flight')}</TableCell>

                        <TableCell>
                            {t('pages.disruptions.columns.scheduledFlight')}
                        </TableCell>

                        <TableCell>
                            {t('pages.disruptions.columns.disruption')}
                        </TableCell>

                        <TableCell>{t('pages.disruptions.columns.status')}</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody loading={pagination.loading}>
                    {isEmpty
                        ? (
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <Grid
                                        className={classes.emptyContainer}
                                        container
                                        justify="center"
                                        alignItems="center"
                                        direction="column"
                                    >
                                        {noResults ?? 'No results'}
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        )
                        : disruptions.map(disruption => (
                            <DisruptionRow
                                key={disruption.id}
                                disruption={disruption}
                            />
                        ))}
                </TableBody>
            </Table>

            {!hideFooter && (
                <TableFooter
                    count={{
                        total: pagination.totalCount,
                        currentPage: disruptions.length,
                    }}
                    hasPrevious={pagination.hasPrevious}
                    hasNext={pagination.hasNext}
                    onBackToStartClick={pagination.onBackToStartClick}
                    onPreviousClick={pagination.onPreviousClick}
                    onNextClick={pagination.onNextClick}
                    onSkipToEndClick={pagination.onSkipToEndClick}
                    loading={pagination.loading}
                />
            )}
        </TableContainer >
    );
};

const DisruptionRow: FunctionComponent<{ disruption: Disruption }> = ({ disruption }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const classes = useDisruptionsStyles();

    // TODO: Detect from props
    const isArrivalDiversion = true;

    if (disruption.flight.alternates.pageInfo.hasNextPage) {
        throw new Error(`Disruption ${disruption.id} has too many alternates`);
    }

    const alternatesList = disruption.flight.alternates.nodes.map(
        (alternate, index) => (
            <Fragment key={index}>
                {index === 0 ? null : ' / '}
                <Iata airport={alternate.airport} />
            </Fragment>
        )
    );

    const time = moment.utc(
        disruption.flight.alternates.nodes[0].estimatedTime
    );

    const isInPast = time.isBefore(moment());

    return (
        <TableRow
            key={disruption.id}
            onClick={() => history.push(`/disruptions/${disruption.id}`)}
            className={classes.row}
        >
            <TableCell>
                <div className={classes.flightNumber}>{disruption.flight.number}</div>

                <div className={classes.columnSubtext}>
                    {(() => {
                        const timeString = isArrivalDiversion
                            ? disruption.flight.arrival.scheduledArrivalTime
                            : disruption.flight.departure.scheduledDepartureTime;

                        // TODO: Add Yesterday/Today/Tomorrow

                        return moment(timeString).format(t('dateFormat.dateShortMonth'));
                    })()}
                </div>
            </TableCell>

            <TableCell>
                <div>
                    <Iata
                        airport={disruption.flight.departure.airport}
                        className={clsx(
                            { [classes.originalAirport]: !isArrivalDiversion }
                        )}
                    />

                    <ArrowRightIcon className={classes.flightArrow} />

                    <Iata
                        airport={disruption.flight.arrival.airport}
                        className={
                            clsx({ [classes.originalAirport]: isArrivalDiversion })
                        }
                    />
                </div>

                <div className={classes.columnSubtext}>
                    <UtcTime
                        time={moment(disruption.flight.departure.scheduledDepartureTime)}
                        hideSuffix
                    />

                    {' - '}

                    <UtcTime
                        time={moment(disruption.flight.arrival.scheduledArrivalTime)}
                    />
                </div>
            </TableCell>

            <TableCell>
                <div>
                    {(() => {
                        if (isArrivalDiversion) {
                            return isInPast
                                ? 'Arrived at'
                                : 'Arriving at';
                        }

                        return isInPast
                            ? 'Departed from'
                            : 'Departs from';
                    })()}
                    {' '}
                    {alternatesList}
                </div>

                {(() => {
                    const alternateTimeUnknown = disruption
                        .flight.alternates.totalCount > 1
                        || disruption.flight.alternates
                            .nodes[0].estimatedTime === null;

                    if (alternateTimeUnknown) {
                        return (
                            <div className={classes.columnSubtext}>
                                {
                                    isArrivalDiversion
                                        ? 'Landing time unknown'
                                        : 'Departure time unknown'
                                }
                            </div>
                        );
                    }

                    // TODO: Translate

                    return (
                        <div className={classes.columnSubtext}>
                            {(() => (
                                isInPast
                                    ? null
                                    : 'ETA'
                            ))()}

                            {' '}

                            <UtcTime estimate time={time} />
                        </div>
                    );
                })()}
            </TableCell>
            <TableCell>
                {(() => {
                    const requiredSeats = disruption.coachSeatsRequired;
                    const bookedSeats = disruption.coachSeatsBooked;
                    const availableCoaches = disruption.availabilities;
                    const bookedCoaches = disruption.rides;
                    const unconfirmedCoaches = disruption.unconfirmedRides;

                    if (disruption.cancelledAt) {
                        return (
                            <Status
                                type="inactive"
                                title="Cancelled"
                                tooltip="All requests and coaches cancelled"
                                description={
                                    <>
                                        At <UtcTime time={moment(disruption.cancelledAt)} />
                                    </>
                                }
                            />
                        );
                    }

                    if (disruption.completedAt) {
                        return (
                            <Status
                                type="under-control"
                                title="Completed"
                                tooltip="Disruption was marked as completed"
                                description={
                                    <>
                                        At <UtcTime time={moment(disruption.completedAt)} />
                                    </>
                                }
                            />
                        );
                    }

                    const enoughSeatsBooked = bookedSeats >= requiredSeats;

                    if (enoughSeatsBooked) {
                        const allConfirmed = unconfirmedCoaches === 0;

                        if (!allConfirmed) {
                            const confirmedCoaches = bookedCoaches - disruption.unconfirmedRides;

                            return (
                                <Status
                                    type="under-control"
                                    title="Pending supplier confirmation"
                                    tooltip="Waiting for the supplier to confirm the booked coaches and add driver details."
                                    description={
                                        `${confirmedCoaches} / ${bookedCoaches} confirmed`
                                    }
                                    icon={AirportShuttleIcon}
                                />
                            );
                        }

                        const allArrived = disruption.arrivedCoaches >= bookedCoaches;

                        if (allArrived) {
                            if (disruption.latestCoachArrival === null) {
                                throw new Error('Latest coach arrival is unknown');
                            }

                            return (
                                <Status
                                    type="under-control"
                                    title="Completed"
                                    tooltip="All rides completed"
                                    description={<>At <UtcTime time={disruption.latestCoachArrival} /></>}
                                />
                            );
                        }

                        const ridesInProgress = disruption.ridesInProgress !== 0;

                        if (ridesInProgress) {
                            return (
                                <Status
                                    type="under-control"
                                    title="In progress"
                                    tooltip="Ride in progress"
                                    description={
                                        `${disruption.arrivedCoaches} / ${bookedCoaches} arrived`
                                    }
                                    icon={AirportShuttleIcon}
                                />
                            );
                        }

                        return (
                            <Status
                                type="under-control"
                                title="Confirmed"
                                tooltip="Enough coaches are scheduled and confirmed by the supplier."
                                description={`${bookedCoaches} coaches`}
                                icon={AirportShuttleIcon}
                            />
                        );
                    }

                    if (availableCoaches > 0) {
                        return (
                            <Status
                                type="action-required"
                                title={
                                    availableCoaches === 1
                                        ? `${availableCoaches} coach available`
                                        : `${availableCoaches} coaches available`
                                }
                                tooltip="Book the available coaches to continue"
                                description={
                                    `${bookedSeats} / ${requiredSeats} seats booked`
                                }
                                icon={PeopleIcon}
                            />
                        );
                    }

                    if (!disruption.moreAvailabilityExpected) {
                        return (
                            <Status
                                type="alert"
                                title="Not enough availability"
                                tooltip="All suppliers have replied to their requests and advised there is no more availability."
                                description={
                                    `${bookedSeats} / ${requiredSeats} seats booked`
                                }
                                icon={PeopleIcon}
                            />
                        );
                    }

                    return (
                        <Status
                            type="under-control"
                            title="Waiting for availability"
                            tooltip="Requests have been sent to the suppliers, waiting for their response."
                            description={`${bookedSeats} / ${requiredSeats} seats booked`}
                            icon={PeopleIcon}
                        />
                    );
                })()}
            </TableCell>
        </TableRow>
    );
};

export default DisruptionsList;
