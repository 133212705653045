import { TFunction } from 'i18next';
import moment, { Moment } from 'moment';
import { SupportedLocale } from '../context/LocaleContext';

export type MomentLocale = SupportedLocale;

/**
 * Formats time relative to now.
 * E.g. "25 minutes ago" or "in 6 days".
 */
export default function formatRelativeTime(
    value: Moment,
    locale: MomentLocale,
    t: TFunction,
): string {
    const now = moment();
    const difference = moment.duration(Math.abs(value.diff(now)));

    if (difference.asMinutes() <= 1) {
        return value.locale(locale).fromNow();
    }

    const format = (relativeTime: string): string => (
        value.isAfter(now.clone())
            ? t('dateFormat.relative.future', { relativeTime })
            : t('dateFormat.relative.past', { relativeTime })
    );

    const minutes = Math.floor(difference.asMinutes() % 60);

    if (difference.asMinutes() < 60) {
        const formatted = t('dateFormat.units.minutes', { count: minutes });

        return format(formatted);
    }

    const hours = Math.floor(difference.asHours());
    const hoursFormatted = t('dateFormat.units.hours', { count: hours });

    if (hours < 3 && minutes !== 0) {
        const minutesFormatted = t(
            'dateFormat.units.minutesAbbreviated',
            { count: minutes }
        );

        return format(
            t('dateFormat.units.combined.hoursAndMinutes', {
                hours: hoursFormatted,
                minutes: minutesFormatted,
            })
        );
    }

    if (hours < 42) {
        return format(hoursFormatted);
    }

    const days = Math.floor(difference.asDays());
    const daysFormatted = t('dateFormat.units.days', { count: days });
    const hoursAdjusted = hours % 24;

    if (days <= 5 && hoursAdjusted !== 0) {
        const adjustedHoursFormatted = t(
            'dateFormat.units.hours',
            { count: hoursAdjusted }
        );

        return format(
            t('dateFormat.units.combined.daysAndHours', {
                days: daysFormatted,
                hours: adjustedHoursFormatted,
            })
        );
    }

    return value.locale(locale).fromNow();
}
