import gql from 'graphql-tag';

export const ADD_AVAILABILITY = gql`
    mutation addAvailability($input: AddRideAvailabilityFromRequestInput!) {
        addRideAvailabilityFromRequest(input: $input) {
            id
        }
    }
`;

export interface AddAvailabilityInput {
    input: {
        availabilityRequestId: string;
        estimatedArrivalTimeMinutes: number;
        seats: number;
        description?: string;
    };
}

export interface AddAvailabilityResult {
    addRideAvailabilityFromRequest: {
        id: string;
    };
}
