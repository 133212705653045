import { MenuButtonItem, OptionsTableCell, TableBody, TableContainer, TableFooter, TableHead, TableHeader } from '@get-e/react-components';
import { Table, TableCell, TableRow } from '@material-ui/core';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import formatPastDate from '../../helpers/formatPastDate';
import useTablePageStyles from '../useTablePageStyles';
import BackOfficeUser from './BackOfficeUser';

const users: BackOfficeUser[] = [
    {
        id: '1',
        name: 'Duncan Lukkenaer',
        email: 'john.doe@get-e.com',
        lastSeen: moment(),
        joinedOn: moment().subtract(3, 'weeks'),
    },
    {
        id: '2',
        name: 'Steff Missot',
        email: 'jane.doe@get-e.com',
        lastSeen: moment().subtract(14, 'hours'),
        joinedOn: moment().subtract(3, 'weeks'),
    },
];

const Row: FunctionComponent<{
    user: BackOfficeUser;
    onEditClick: () => void;
    onRemoveClick: () => void;
}> = ({ user, onEditClick, onRemoveClick }) => {
    const { t } = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);

    const lastSeenText = user.lastSeen
        ? formatPastDate(moment(user.lastSeen), t)
        : t('pages.users.lastSeenNever');

    const handleEditClick = (): void => {
        setMenuOpen(false);
        onEditClick();
    };

    const handleRemoveClick = (): void => {
        setMenuOpen(false);
        onRemoveClick();
    };

    // TODO: Prevent removing yourself

    return (
        <TableRow>
            <TableCell>{user.name}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>{lastSeenText}</TableCell>

            <OptionsTableCell menuOpen={menuOpen} setMenuOpen={setMenuOpen}>
                <MenuButtonItem onClick={handleEditClick}>
                    {t('pages.users.button.editUser')}
                </MenuButtonItem>

                <MenuButtonItem
                    onClick={handleRemoveClick}
                    dangerous
                >
                    {t('pages.users.button.removeUser')}
                </MenuButtonItem>
            </OptionsTableCell>
        </TableRow>
    );
};

const UsersTab: FunctionComponent<{
    onUpdate?: (event: { total: number }) => void;
    onEditUser: (user: BackOfficeUser) => void;
    onRemoveUser: (user: BackOfficeUser) => void;
}> = ({ onUpdate, onEditUser, onRemoveUser }) => {
    const { t } = useTranslation();
    const classes = useTablePageStyles();
    const loading = false;

    function handleBackToStartClick(): void {
        // TODO: Implement
        // eslint-disable-next-line no-console
        console.log('Back to start');
    }

    function handlePreviousClick(): void {
        // TODO: Implement
        // eslint-disable-next-line no-console
        console.log('Previous');
    }

    function handleNextClick(): void {
        // TODO: Implement
        // eslint-disable-next-line no-console
        console.log('Next');
    }

    function handleSkipToEndClick(): void {
        // TODO: Implement
        // eslint-disable-next-line no-console
        console.log('Skip to end');
    }

    useEffect(() => {
        onUpdate?.({ total: users.length });
    }, [onUpdate]);

    return (
        <TableContainer className={classes.table}>
            <TableHeader
                title="Users"
                onSearchChange={searchValue => console.log(`Searching: "${searchValue}"`)}
            />

            <Table aria-label={t('pages.users.aria.usersTable')}>
                <TableHead loading={loading}>
                    <TableRow>
                        <TableCell>{t('pages.users.column.name')}</TableCell>
                        <TableCell>{t('pages.users.column.email')}</TableCell>
                        <TableCell>{t('pages.users.column.lastSeen')}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody loading={loading}>
                    {users.map(user => (
                        <Row
                            user={user}
                            key={user.email}
                            onEditClick={() => onEditUser(user)}
                            onRemoveClick={() => onRemoveUser(user)}
                        />
                    ))}
                </TableBody>
            </Table>

            <TableFooter
                count={{
                    total: users.length,
                    currentPage: users.length,
                }}
                hasPrevious={false}
                hasNext={false}
                onBackToStartClick={handleBackToStartClick}
                onPreviousClick={handlePreviousClick}
                onNextClick={handleNextClick}
                onSkipToEndClick={handleSkipToEndClick}
                loading={loading}
            />
        </TableContainer>
    );
};

export default UsersTab;
