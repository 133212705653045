/* eslint-disable max-lines */
import gql from 'graphql-tag';
import { DrivingPoint } from '../../Disruption/useCoachRoutes';

export interface Ride {
    id: string;
    vehicle: {
        seats: number;
    };
    meetingPoint: string;
    estimatedArrivalTime: number;
    driver: null | {
        id: string;
        name: string;
        phoneNumber: string;
    };
    supplier: {
        id: string;
    };
    events: {
        driverDispatchedAt: string | null;
        customerCancelledAt: string | null;
        supplierDeclinedAt: string | null;
        supplierConfirmedAt: string | null;
        createdAt: string;
        availableAt: string;
    };
    busNumber: string;
    description?: string;
    pickupPoint: {
        location: {
            timeZone: string;
        };
        trackingTimes: {
            estimatedArrival: string | null;
            actualArrival: string | null;
            estimatedDeparture: string | null;
            actualDeparture: string | null;
        };
    };
    dropOffPoint: {
        trackingTimes: {
            estimatedArrival: string | null;
            actualArrival: string | null;
        };
    };
    points: {
        nodes: DrivingPoint[];
        pageInfo: {
            hasNextPage: boolean;
        };
    };
}

export interface RideAvailability {
    id: string;
    estimatedArrivalTimeMinutes: number;
    seats: number;
    createdAt: string;
    description?: string;
}

export interface AvailabilityRequestProps {
    id: string;
    createdAt: string;
    meetingPoint: string | null;
    supplierCompletedAt: string | null;
    customerCancelledAt: string | null;
    pickupTime: string | null;
    pickupPoint: {
        location: {
            timeZone: string;
        };
    };
    routePrediction: {
        distanceInMeters: number;
        durationInSeconds: number;
    };
    points: {
        nodes: DrivingPoint[];
        pageInfo: {
            hasNextPage: boolean;
        };
    };
    disruption: {
        id: string;
        customer: {
            name: string;
        };
        coachesAuthorized: boolean;
        flight: {
            number: string;
            arrival: {
                scheduledArrivalTime: string;
            };
            passengerCount: {
                total: number;
                infants: number;
                wheelchair: number;
            };
        };
    };
    rides: {
        totalCount: number;
    };
    assignedRides: {
        totalCount: number;
    };
    confirmedRides: {
        totalCount: number;
    };
    completedRides: {
        totalCount: number;
    };
    ridesInProgress: {
        totalCount: number;
    };
    ridesInProgressWithoutTracking: {
        totalCount: number;
    };
    ridesWithPositionUpdates: {
        totalCount: number;
    };
    rideAvailabilities: {
        totalCount: number;
    };
    supplier: {
        id: string;
    };
}

export const GET_RIDE_AVAILABILITIES = gql`
    query getRideAvailabilities($id: ID!) {
        disruptionRideAvailabilityRequest(id: $id) {
            id
            rides(first: 50) {
                pageInfo {
                    hasNextPage
                }
                nodes {
                    id
                    vehicle {
                        seats
                    }
                    description
                    estimatedArrivalTime
                    driver {
                        id
                        name
                        phoneNumber
                    }
                    busNumber
                    supplier {
                        id
                    }
                    events {
                        driverDispatchedAt
                        customerCancelledAt
                        supplierDeclinedAt
                        supplierConfirmedAt
                        createdAt
                        availableAt
                    }
                    pickupPoint {
                        location {
                            id
                            timeZone
                        }
                        trackingTimes {
                            estimatedArrival
                            actualArrival
                            estimatedDeparture
                            actualDeparture
                        }
                    }
                    dropOffPoint {
                        trackingTimes {
                            estimatedArrival
                            actualArrival
                        }
                    }
                    points(
                        first: 10,
                        orderBy: { field: DRIVING_ORDER, direction: ASC }
                    ) {
                        nodes {
                            location {
                                __typename
                                id
                                coordinates {
                                    latitude
                                    longitude
                                }
                                ... on Airport {
                                    iata
                                    name
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                        }
                    }
                }
            }
            rideAvailabilities(first: 50) {
                pageInfo {
                    hasNextPage
                }
                nodes {
                    id
                    estimatedArrivalTimeMinutes
                    seats
                    createdAt
                    description
                }
            }
        }
    }
`;

export const GET_AVAILABILITY_REQUEST = gql`
    query getAvailability($id: ID!) {
        disruptionRideAvailabilityRequest(id: $id) {
            id
            createdAt
            meetingPoint
            supplierCompletedAt
            customerCancelledAt
            pickupTime
            pickupPoint {
                location {
                    id
                    timeZone
                }
            }
            routePrediction {
                distanceInMeters
                durationInSeconds
            }
            rides(first: 0) {
                totalCount
            }
            assignedRides: rides(
                first: 0
                filter: {
                    assigned: true
                }
            ) {
                totalCount
            }
            confirmedRides: rides(
                first: 0
                filter: {
                    confirmed: true
                }
            ) {
                totalCount
            }
            completedRides: rides(
                first: 0
                filter: {
                    completed: true
                }
            ) {
                totalCount
            }
            ridesInProgress: rides(
                first: 0
                filter: {
                    inProgress: true
                }
            ) {
                totalCount
            }
            ridesInProgressWithoutTracking: rides(
                first: 0
                filter: {
                    inProgress: true
                    hasPositionUpdates: false
                }
            ) {
                totalCount
            }
            ridesWithPositionUpdates: rides(
                first: 0
                filter: {
                    hasPositionUpdates: true
                }
            ) {
                totalCount
            }
            rideAvailabilities(first: 0) {
                totalCount
            }
            points(
                first: 10,
                orderBy: { field: DRIVING_ORDER, direction: ASC }
            ) {
                nodes {
                    location {
                        __typename
                        id
                        coordinates {
                            latitude
                            longitude
                        }
                        ... on Airport {
                            iata
                            name
                        }
                    }
                }
                pageInfo {
                    hasNextPage
                }
            }
            disruption {
                id
                customer {
                    name
                }
                coachesAuthorized
                flight {
                    number
                    arrival {
                        scheduledArrivalTime
                    }
                    passengerCount {
                        total
                        infants
                        wheelchair
                    }
                }
            }
            supplier {
                id
            }
        }
    }
`;

export interface GetRideAvailabilityRequestInput {
    id: string;
}

export interface GetRideAvailabilityRequestResult {
    disruptionRideAvailabilityRequest: null | {
        rides: {
            pageInfo: {
                hasNextPage: boolean;
            };
            nodes: Ride[];
        };
        rideAvailabilities: {
            pageInfo: {
                hasNextPage: boolean;
            };
            nodes: RideAvailability[];
        };
    };
}

export interface GetAvailabilityRequestInput {
    id: string;
}

export interface GetAvailabilityRequestResult {
    disruptionRideAvailabilityRequest: AvailabilityRequestProps;
}
