import gql from 'graphql-tag';

export const GET_RIDE_DATA = gql`
    query getRideData($id: ID!) {
        ride(id: $id) {
            id
            busNumber
            driver {
                id
                name
                phoneNumber
            }
        }
    }
`;

export interface GetRideDataInput {
    id: string;
}

export interface GetRideDataResult {
    ride: {
        id: string;
        busNumber: string;
        driver: null | {
            id: string;
            name: string;
            phoneNumber: string;
        };
    };
}
