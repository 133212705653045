import { Tooltip } from '@material-ui/core';
import moment, { Moment } from 'moment-timezone';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocaleContext } from '../context/LocaleContext';
import formatRelativeTime from '../helpers/formatRelativeTime';
import getValue from '../helpers/getValue';

const LocalTime: FunctionComponent<{
    time: Moment;
    estimate?: boolean;
    timeZone: string;
}> = ({ time, estimate, timeZone }) => {
    const { locale } = useLocaleContext();
    const { t } = useTranslation();
    const local = moment.tz(time, timeZone);

    return (
        <Tooltip
            title={getValue(() => {
                const relativeLocalTime = formatRelativeTime(local, locale, t);

                return (
                    <>
                        {estimate
                            ? t('dateFormat.estimated', { dateTime: relativeLocalTime })
                            : relativeLocalTime
                        }
                        <br />
                        {local.format(t('dateFormat.local'))}
                        <br />
                        {time.utc().format(t('dateFormat.utcDateTimeFull'))}
                    </>
                );
            })}
        >
            <span>
                {estimate ? '±' : null}
                {local.format('HH:mm')}
            </span>
        </Tooltip>
    );
};

export default LocalTime;
