import gql from 'graphql-tag';

export const GET_CUSTOMER_ROLES = gql`
    query getCustomerRoles($customerId: ID!) {
        customer(id: $customerId) {
            id
            roles(first: 10) {
                nodes {
                    id
                    name
                }
                pageInfo {
                    hasNextPage
                }
            }
        }
    }
`;

export interface GetCustomerRolesInput {
    customerId: string;
}

export interface GetCustomerRolesResult {
    customer: null | {
        id: string;
        roles: {
            nodes: Array<{
                id: string;
                name: string;
            }>;
            pageInfo: {
                hasNextPage: boolean;
            };
        };
    };
}
