import { ArrowButton, Copyable, MessageDialog } from '@get-e/react-components';
import { Link, makeStyles, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { openZendeskLiveChat } from '../../services/zendesk';

const useStyles = makeStyles({
    subtitle: { margin: '-.75rem 0 1.5rem 0 !important' },
    section: { marginBottom: '1rem' },
    sections: { marginBottom: '2rem' },
});

const SupportDialog: FunctionComponent<{
    onClose: () => void;
}> = ({ onClose }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const email = t('pages.support.email.value');
    const phoneNumber = t('pages.support.phoneNumber.value');

    return (
        <MessageDialog
            title={t('pages.support.title')}
            onClose={onClose}
        >
            <p className={classes.subtitle}>
                {t('pages.support.subtitle')}
            </p>

            <div className={classes.sections}>
                <section className={classes.section}>
                    <Typography variant="h4" component="h4">
                        {t('pages.support.liveChat.label')}
                    </Typography>

                    <ArrowButton
                        onClick={() => {
                            onClose();
                            openZendeskLiveChat();
                        }}
                    >
                        {t('pages.support.liveChat.button')}
                    </ArrowButton>
                </section>

                <section className={classes.section}>
                    <Typography variant="h4" component="h4">
                        {t('pages.support.email.label')}
                    </Typography>

                    <Copyable value={email}>
                        <Link href={`mailto:${email}`}>
                            {email}
                        </Link>
                    </Copyable>
                </section>

                <section className={classes.section}>
                    <Typography variant="h4" component="h4">
                        {t('pages.support.phoneNumber.label')}
                    </Typography>

                    <Copyable value={phoneNumber}>
                        <Link href={`tel:${phoneNumber.replaceAll(' ', '')}`}>
                            {phoneNumber}
                        </Link>
                    </Copyable>
                </section>
            </div>
        </MessageDialog>
    );
};

export default SupportDialog;
