import React from 'react';
import { useHistory } from 'react-router-dom';

const useOpenNewTab = (
    link: string
): React.MouseEventHandler => {
    const history = useHistory();

    return (event: React.MouseEvent) => {
        event.ctrlKey || event.metaKey
            ? window.open(link)
            : history.push(link);
    };
};

export default useOpenNewTab;
