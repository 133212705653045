import gql from 'graphql-tag';

export const CANCEL_RIDE = gql`
    mutation CANCEL_RIDE($input: CancelRideInput!) {
        cancelRide(
            input: $input
        ) {
            ride {
                id
            }
        }
    }
`;

export interface CancelRideInput {
    input: {
        rideId: string;
    };
}

export interface CancelRideResult {
    ride: {
        id: string;
    };
}
