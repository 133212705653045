import {
    MenuButtonItem, OptionsTableCell, TableBody,
    TableContainer, TableFooter, TableHead, TableHeader,
} from '@get-e/react-components';
import { Table, TableCell, TableRow } from '@material-ui/core';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import formatPastDate from '../../helpers/formatPastDate';
import useTablePageStyles from '../useTablePageStyles';
import BackOfficeInvite from './BackOfficeInvite';

const Row: FunctionComponent<{
    invite: BackOfficeInvite;
    onDeleteClick: () => void;
}> = ({ invite, onDeleteClick }) => {
    const { t } = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);

    const lastSeenText = formatPastDate(invite.createdAt, t);

    function handleDeleteClick(): void {
        setMenuOpen(false);
        onDeleteClick();
    }

    return (
        <TableRow>
            <TableCell>{invite.name}</TableCell>
            <TableCell>{invite.email}</TableCell>
            <TableCell>{lastSeenText}</TableCell>

            <OptionsTableCell menuOpen={menuOpen} setMenuOpen={setMenuOpen}>
                <MenuButtonItem
                    key="delete"
                    onClick={handleDeleteClick}
                    dangerous
                >
                    {t('pages.users.button.deleteInvite')}
                </MenuButtonItem>
            </OptionsTableCell>
        </TableRow>
    );
};

export interface UserInvitesTabProps {
    onUpdate?: (event: { total: number }) => void;
    onDeleteInvite: (invite: BackOfficeInvite) => void;
}

const invites: BackOfficeInvite[] = [
    {
        id: '5',
        name: 'David van der Wel',
        email: 'david@get-e.com',
        createdAt: moment(),
    },
];

const UserInvitesTab: FunctionComponent<UserInvitesTabProps> = ({
    onUpdate,
    onDeleteInvite,
}) => {
    const { t } = useTranslation();
    const classes = useTablePageStyles();
    const loading = false;

    function handleBackToStartClick(): void {
        // TODO: Implement
        // eslint-disable-next-line no-console
        console.log('Back to start');
    }

    function handlePreviousClick(): void {
        // TODO: Implement
        // eslint-disable-next-line no-console
        console.log('Previous');
    }

    function handleNextClick(): void {
        // TODO: Implement
        // eslint-disable-next-line no-console
        console.log('Next');
    }

    function handleSkipToEndClick(): void {
        // TODO: Implement
        // eslint-disable-next-line no-console
        console.log('Skip to end');
    }

    useEffect(() => {
        onUpdate?.({ total: invites.length });
    }, [onUpdate]);

    return (
        <TableContainer className={classes.table}>
            <TableHeader
                title="Invited users"
                onSearchChange={searchValue => console.log(`Searching: "${searchValue}"`)}
            />

            <Table aria-label={t('pages.users.aria.invitesTable')}>
                <TableHead loading={loading}>
                    <TableRow>
                        <TableCell>{t('pages.users.column.name')}</TableCell>
                        <TableCell>{t('pages.users.column.email')}</TableCell>
                        <TableCell>{t('pages.users.column.invitedOn')}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody loading={loading}>
                    {invites.map(invite => (
                        <Row
                            invite={invite}
                            key={invite.email}
                            onDeleteClick={() => onDeleteInvite(invite)}
                        />
                    ))}
                </TableBody>
            </Table>

            <TableFooter
                count={{
                    total: invites.length,
                    currentPage: invites.length,
                }}
                hasPrevious={false}
                hasNext={false}
                onBackToStartClick={handleBackToStartClick}
                onPreviousClick={handlePreviousClick}
                onNextClick={handleNextClick}
                onSkipToEndClick={handleSkipToEndClick}
                loading={loading}
            />
        </TableContainer>
    );
};

export default UserInvitesTab;
