import { TFunction } from 'i18next';
import report from '../../helpers/report';

export enum DisruptionError {
    RideCancelled,
    DisruptionCancelled,
    DisruptionCompleted,
    UnexpectedError
}

export default function getDisruptionErrorMessage(
    error: DisruptionError,
    t: TFunction
): string {
    switch (error) {
        case DisruptionError.RideCancelled:
            return t('pages.disruption.errors.rideCancelled');
        case DisruptionError.DisruptionCancelled:
            return t('pages.disruption.errors.disruptionCancelled');
        case DisruptionError.DisruptionCompleted:
            return t('pages.disruption.errors.disruptionCompleted');
        case DisruptionError.UnexpectedError:
            return t('errors.forms.unexpected');
        default:
            report(new Error('Unhandled disruption error'));
            return t('errors.forms.unexpected');
    }
}
