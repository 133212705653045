import { FullScreenLoader } from '@get-e/react-components';
import { ApolloError } from 'apollo-client';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useLayoutEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { GetCurrentUserResult, GET_CURRENT_USER } from './App.graphql';
import { CurrentUserContext } from './context/CurrentUserContext';
import { useLocaleContext } from './context/LocaleContext';
import getValue from './helpers/getValue';
import report, { setUserContext, UserContext } from './helpers/report';
import useLoaderDebounce from './helpers/useLoaderDebounce';
import mopinionMetadataConfig from "./helpers/mopinionMetadataConfig";

function isAuthenticationError(apolloError: ApolloError): boolean {
    for (const graphQlError of apolloError.graphQLErrors) {
        const code = graphQlError.extensions?.code as unknown;

        if (code === 'UNAUTHENTICATED') {
            return true;
        }
    }

    return false;
}

const CurrentUserProvider: FunctionComponent = ({ children }) => {
    const history = useHistory();
    const { updateLocale, locale } = useLocaleContext();
    const [checkingLanguage, setCheckingLanguage] = useState(true);
    const [waitingForEnglish, setWaitingForEnglish] = useState(false);

    useEffect(() => {
        if (waitingForEnglish && locale === 'en-GB') {
            setWaitingForEnglish(false);
        }
    }, [locale]);

    const {
        data,
        loading,
        error,
    } = useQuery<GetCurrentUserResult>(
        GET_CURRENT_USER,
        {
            onError(apolloError) {
                if (!isAuthenticationError(apolloError)) {
                    report(apolloError);
                }
            },
        }
    );

    useEffect(() => {
        if (!data?.me.id || waitingForEnglish) {
            return;
        }

        setUserContext({
            userId: data.me.id,
            emailAddress: data.me.email,
            isBackOffice: data.me.isBackOffice,
            supplier: data.me.supplier,
            customers: data.me.customerRoles.nodes.map(role => role.customer),
        });

        mopinionMetadataConfig({
            name: data.me.name,
            email: data.me.email,
            accountType: getValue(() => {
                if (data.me.isBackOffice) {
                    return 'BACKOFFICE';
                }

                if (data.me.customerRoles.totalCount > 1) {
                    return 'MULTIPLE';
                }

                if (data.me.customerRoles.totalCount === 1) {
                    return 'CUSTOMER';
                }

                if (data.me.supplier) {
                    return 'SUPPLIER';
                }

                return null;
            }),
        })

        const shouldBeEnglish = data.me.isBackOffice
            || data.me.customerRoles.totalCount > 0;

        if (shouldBeEnglish && locale !== 'en-GB') {
            setWaitingForEnglish(true);
            updateLocale('en-GB');
        }

        setCheckingLanguage(false);
    }, [data?.me.id]);

    const showLoader = useLoaderDebounce(loading);

    if (loading || checkingLanguage || waitingForEnglish) {
        return showLoader
            ? <FullScreenLoader />
            : null;
    }

    if (error) {
        if (isAuthenticationError(error)) {
            history.push('/sign-in');
            return null;
        }

        // TODO: Proper UI
        return <>Cannot get current user</>;
    }

    if (!data) {
        throw new Error('Current user query did not return response');
    }

    return (
        <CurrentUserContext.Provider
            value={{
                id: data.me.id,
                name: data.me.name,
                email: data.me.email,
                joinedOn: moment(data.me.joinedAt),
                isBackOffice: data.me.isBackOffice,
                managedCustomer: getValue(() => {
                    if (data.me.customerRoles.totalCount === 0) {
                        return null;
                    }

                    if (data.me.customerRoles.totalCount !== 1) {
                        return 'MULTIPLE';
                    }

                    const {
                        customer,
                        permissions: customerPermissions,
                    } = data.me.customerRoles.nodes[0];

                    return {
                        id: customer.id,
                        name: customer.name,
                        customerPermissions,
                    };
                }),
                managedSupplier: getValue(() => {
                    if (!data.me.supplier) {
                        return null;
                    }

                    return {
                        ...data.me.supplier,
                        manageUsers:
                            data.me.supplierEdge?.permissions.manageUsers ?? false,
                    };
                }),
            }}
        >
            {children}
        </CurrentUserContext.Provider>
    );
};

export default CurrentUserProvider;
