import { TextField } from '@get-e/react-components';
import { Autocomplete } from '@material-ui/lab';
import React, { FunctionComponent, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import report from '../helpers/report';
import useDebounce from '../helpers/useDebounce';
import { GetCustomersInput, GetCustomersResult, GET_CUSTOMERS } from './CustomerSelector.graphql';

export interface Customer {
    id: string;
    name: string;
}

const CustomerSelector: FunctionComponent<{
    value: Customer | null;
    onChange: (newValue: Customer | null) => void;
    label: string;
    required?: boolean;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
}> = ({ value, onChange, label, required, error: errorProp, helperText, disabled }) => {
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 500);

    const { t } = useTranslation();

    const {
        data,
        loading: queryLoading,
        error,
    } = useQuery<GetCustomersResult, GetCustomersInput>(
        GET_CUSTOMERS,
        {
            variables: {
                first: 5,
                filter: debouncedSearchValue.trim()
                    ? { search: debouncedSearchValue }
                    : null,
            },
            onError(apolloError) {
                report(apolloError);
            },
        }
    );

    const loading = queryLoading || searchValue !== debouncedSearchValue;

    if (error) {
        // TODO
        return <>Error</>;
    }

    const customers: Customer[] = data?.customers.nodes ?? [];

    return (
        <Autocomplete
            id="customer-field"
            value={value}
            onChange={(_event, newValue) => onChange(newValue)}
            inputValue={searchValue}
            onInputChange={(_event, newValue) => setSearchValue(newValue)}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionSelected={(option, newValue) => option.id === newValue.id}
            getOptionLabel={option => option.name}
            loading={loading}
            loadingText={t('components.customerSearch.loading')}
            options={customers}
            noOptionsText={t('components.customerSearch.noResults')}
            disabled={disabled}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    required={required}
                    helperText={helperText}
                    error={errorProp}
                />
            )}
        />
    );
};

export default CustomerSelector;
