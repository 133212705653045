import {
    CompactButton, Form, PrimaryButton, SecondaryButton,
    UppercaseField,
} from '@get-e/react-components';
import { makeStyles } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import useFormStyles from '../../styles/useFormStyles';
import {
    SetBusNumberInput,
    SetBusNumberResult, SET_BUS_NUMBER,
} from './BusNumberForm.graphql';

const useStyles = makeStyles({ changeButton: { marginTop: '.5rem' } });

const BusNumberForm: FunctionComponent<{
    rideId: string;
    initialValue?: string;
    onSaved?: () => void;
    // eslint-disable-next-line max-lines-per-function
}> = ({ rideId, initialValue, onSaved }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const formClasses = useFormStyles();

    const [value, setValue] = useState(initialValue ?? '');
    const [changing, setChanging] = useState(false);
    const [unsavedValue, setUnsavedValue] = useState(value ?? '');

    const [
        setBusNumber,
        { loading: saving },
    ] = useMutation<SetBusNumberResult, SetBusNumberInput>(
        SET_BUS_NUMBER
    );

    const submitForm = async (): Promise<void> => {
        // TODO: Handle errors
        const result = await setBusNumber({
            variables: {
                input: {
                    rideId,
                    busNumber: unsavedValue,
                },
            },
        });

        if (!result.data) {
            throw new Error('Mutation result has no data');
        }

        const newValue = result.data.setRideBusNumber.busNumber;

        setValue(newValue);
        setUnsavedValue(newValue);
        onSaved?.();
        setChanging(false);
    };

    if (!changing) {
        if (value === null || value.trim().length === 0) {
            return (
                <CompactButton onClick={() => setChanging(true)}>
                    Add bus number
                </CompactButton>
            );
        }

        return (
            <>
                <div>{value}</div>

                <CompactButton
                    onClick={() => setChanging(true)}
                    className={classes.changeButton}
                >
                    Change
                </CompactButton>
            </>
        );
    }

    return (
        <Form onSubmit={submitForm}>
            <UppercaseField
                label="New bus number"
                value={unsavedValue}
                onBlur={newValue => setUnsavedValue(newValue)}
            />

            <div className={formClasses.compactButtons}>
                <PrimaryButton
                    onClick={submitForm}
                    submitsForm
                    loading={saving}
                    small
                >
                    {t('buttons.save')}
                </PrimaryButton>

                <SecondaryButton
                    onClick={() => {
                        setChanging(false);
                        setUnsavedValue(value ?? '');
                    }}
                    disabled={saving}
                    small
                >
                    {t('buttons.cancel')}
                </SecondaryButton>
            </div>
        </Form>
    );
};

export default BusNumberForm;
