/* eslint-disable max-lines */
import {
    Heading, MessageDialog, PrimaryButton,
    TableBody, TableContainer, TableHead,
} from '@get-e/react-components';
import {
    Checkbox, Grid, makeStyles,
    Table, TableCell, TableRow, Tooltip,
} from '@material-ui/core';
import { Moment } from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { useCurrentUserContext } from '../../context/CurrentUserContext';
import { useLocaleContext } from '../../context/LocaleContext';
import formatNumber from '../../helpers/formatNumber';
import getValue from '../../helpers/getValue';
import useModalStyles from '../../styles/useModalStyles';
import useTableStyles from '../../styles/useTableStyles';
import BookModal from './BookModal';
import { ReactComponent as BusStopIcon } from './busStop.svg';
import AvailabilityRequestRow from './CoachRows/AvailabilityRequestRow';
import AvailabilityRow from './CoachRows/AvailabilityRow';
import RideRow from './CoachRows/RideRow';
import { CoachRoute as CoachRouteInterface } from './useCoachRoutes';

const useStyles = makeStyles({
    route: { '&:not(:last-child)': { marginBottom: '4rem' } },
    availabilityRequestsSection: { marginTop: '2rem' },
    ridesHeader: { marginBottom: '.5rem' },
    tableContainer: { marginTop: '1rem' },
});

const CoachRoute: FunctionComponent<{
    route: CoachRouteInterface;
    automaticBookings: boolean;
    showCustomerActions: boolean;
    onReload: () => void;
    totalSeatsNeeded: number;
    // eslint-disable-next-line max-lines-per-function
}> = ({
    route, automaticBookings,
    showCustomerActions, onReload, totalSeatsNeeded,
}) => {
    const currentUser = useCurrentUserContext();
    const classes = useStyles();
    const tableClasses = useTableStyles();
    const modalClasses = useModalStyles();
    const [addAvailabilityModalOpen, setAddAvailabilityModalOpen] = useState(false);
    const [bookModalOpen, setBookModalOpen] = useState(false);
    const { locale } = useLocaleContext();

    const [bookedRide, setBookedRide] = useState<null | {
        id: string;
        supplierId: string;
        pickupTime: Moment;
    }>(null);

    const [selectedAvailabilities, setSelectedAvailabilities] = useState<Array<{
        id: string;
        seats: number;
        estimatedArrivalTimeMinutes: number;
    }>>([]);

    const allAvailabilitySelected = route.availabilities.every(
        ({ id }) => selectedAvailabilities.some(
            selected => selected.id === id
        )
    );

    // TODO: Suppliers won't have all rides
    const seatsBooked = route.rides.reduce((result, ride) => {
        if (ride.events.cancelledAt !== null || ride.events.declinedAt !== null) {
            return result;
        }

        return result + ride.vehicle.seats;
    }, 0);

    const remainingSeatsRequired = Math.max(0, totalSeatsNeeded - seatsBooked);

    return (
        <div className={classes.route}>
            {getValue(() => {
                if (bookModalOpen) {
                    if (selectedAvailabilities.length === 0) {
                        return (
                            <MessageDialog
                                title="No coaches selected"
                                onClose={() => setBookModalOpen(false)}
                            >
                                Please first choose which coaches you would like to book in the list.
                            </MessageDialog>
                        );
                    }

                    return (
                        <BookModal
                            availabilities={selectedAvailabilities}
                            onClose={() => setBookModalOpen(false)}
                            onBooked={() => {
                                setBookModalOpen(false);
                                onReload();
                            }}
                        />
                    );
                }

                if (automaticBookings) {
                    if (remainingSeatsRequired <= 0) {
                        return (
                            <MessageDialog
                                title="No more coaches required"
                                onClose={() => setAddAvailabilityModalOpen(false)}
                            >
                                <p className={modalClasses.paragraph}>
                                    Enough coaches are booked to transport all {formatNumber(totalSeatsNeeded, locale)} passengers.
                                </p>

                                <p className={modalClasses.paragraph}>
                                    You will receive an email if more coaches are needed.
                                </p>
                            </MessageDialog>
                        );
                    }
                }
            })}

            <Grid container className={classes.ridesHeader}>
                <Grid item xs>
                    <Heading level={3}>
                        {'Rides from '}

                        {(() => {
                            const pickupLocation = route.points[0].location;

                            if (pickupLocation.__typename !== 'Airport') {
                                throw new Error('Unsupported location');
                            }

                            return (
                                <Tooltip title={pickupLocation.name}>
                                    <span>{pickupLocation.iata}</span>
                                </Tooltip>
                            );
                        })()}

                        {' to '}

                        {(() => {
                            const dropOffLocation = route.points[route.points.length - 1].location;

                            if (dropOffLocation.__typename !== 'Airport') {
                                throw new Error('Unsupported location');
                            }

                            return (
                                <Tooltip title={dropOffLocation.name}>
                                    <span>{dropOffLocation.iata}</span>
                                </Tooltip>
                            );
                        })()}

                        {/* TODO: Add via */}
                    </Heading>

                    <div>{formatNumber(seatsBooked, locale)} of {formatNumber(totalSeatsNeeded, locale)} seats booked</div>
                </Grid>

                <Grid item xs="auto">
                    {getValue(() => {
                        if (showCustomerActions) {
                            if (route.availabilities.length === 0) {
                                return null;
                            }

                            if (selectedAvailabilities.length === 0) {
                                return (
                                    <PrimaryButton
                                        onClick={() => setBookModalOpen(true)}
                                        variation="greyed-out"
                                    >
                                        Select to book
                                    </PrimaryButton>
                                );
                            }

                            return (
                                <PrimaryButton
                                    onClick={() => setBookModalOpen(true)}
                                >
                                    {getValue(() => {
                                        if (allAvailabilitySelected) {
                                            return selectedAvailabilities.length === 1
                                                ? 'Book coach'
                                                : 'Book all';
                                        }

                                        return `Book ${selectedAvailabilities.length} selected`;
                                    })}
                                </PrimaryButton>
                            );
                        }

                        throw new Error('Showing neither supplier nor customer actions');
                    })}
                </Grid>
            </Grid>

            <TableContainer noFooter className={classes.tableContainer}>
                <Table aria-label="Collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={tableClasses.buttonColumn}>
                                {
                                    showCustomerActions && route.availabilities.length > 0
                                        ? (
                                            <Checkbox
                                                checked={allAvailabilitySelected}
                                                onChange={(_, checked) => {
                                                    setSelectedAvailabilities(
                                                        checked
                                                            ? route.availabilities.map(
                                                                availability => ({
                                                                    id: availability.id,
                                                                    seats: availability.vehicle.seats,
                                                                    estimatedArrivalTimeMinutes: availability.estimatedArrivalTimeMinutes,
                                                                })
                                                            )
                                                            : []
                                                    );
                                                }}
                                            />
                                        )
                                        : null
                                }
                            </TableCell>

                            <TableCell>Status</TableCell>
                            <TableCell>Supplier</TableCell>
                            <TableCell>Capacity</TableCell>
                            <TableCell>Bus number</TableCell>
                            {showCustomerActions && (
                                <TableCell />
                            )}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            route.availabilities.length === 0 && route.rides.length === 0
                                ? (
                                    <TableRow>
                                        <TableCell
                                            colSpan={7}
                                            className={tableClasses.tableEmptyCell}
                                        >
                                            <BusStopIcon
                                                fill="#767676"
                                                className={tableClasses.tableEmptyIcon}
                                            />

                                            <span className={tableClasses.tableEmptyText}>
                                                Waiting for coaches to be added
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                )
                                : (
                                    <>
                                        {
                                            route.rides.map(ride => (
                                                <RideRow
                                                    key={ride.id}
                                                    ride={ride}
                                                    bookedAutomatically={automaticBookings}
                                                    showCustomerActions={showCustomerActions}
                                                    onReload={onReload}
                                                />
                                            ))
                                        }

                                        {
                                            route.availabilities.map(availability => (
                                                <AvailabilityRow
                                                    key={availability.id}
                                                    supplierName={availability.supplier.name}
                                                    seats={availability.vehicle.seats}
                                                    estimatedArrivalTimeMinutes={availability.estimatedArrivalTimeMinutes}
                                                    selected={selectedAvailabilities.some(({ id }) => id === availability.id)}
                                                    onSelect={() => {
                                                        setSelectedAvailabilities([
                                                            ...selectedAvailabilities,
                                                            {
                                                                id: availability.id,
                                                                seats: availability.vehicle.seats,
                                                                estimatedArrivalTimeMinutes: availability.estimatedArrivalTimeMinutes,
                                                            },
                                                        ]);
                                                    }}
                                                    onUnselect={() => {
                                                        setSelectedAvailabilities(
                                                            selectedAvailabilities.filter(
                                                                selected => selected.id !== availability.id
                                                            )
                                                        );
                                                    }}
                                                />
                                            ))
                                        }
                                    </>
                                )
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            {getValue(() => {
                if (route.availabilityRequests.length === 0) {
                    return null;
                }

                // TODO: Always show once customer menu items are implemented
                const showMenuColumn = currentUser.isBackOffice;

                return (
                    <div className={classes.availabilityRequestsSection}>
                        <Heading level={3}>Coach requests</Heading>

                        <TableContainer noFooter className={classes.tableContainer}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Requested at</TableCell>
                                        <TableCell>Supplier</TableCell>
                                        <TableCell>Availability</TableCell>
                                        <TableCell>Status</TableCell>
                                        {showMenuColumn ? <TableCell/> : null}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        route.availabilityRequests.map(request => (
                                            <AvailabilityRequestRow
                                                key={request.id}
                                                request={request}
                                                availabilities={route.availabilities}
                                                rides={route.rides}
                                                canChangeCancellation={showCustomerActions}
                                                onAvailabilityAdded={() => onReload()}
                                                remainingSeatsRequired={remainingSeatsRequired}
                                            />
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                );
            })}
        </div>
    );
};

export default CoachRoute;
