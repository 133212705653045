import { createStyles, makeStyles } from '@material-ui/core';

const useUserProfileStyles = makeStyles(() => createStyles({
    image: {
        alignItems: 'center',
        display: 'flex',
        fontSize: '4em',
    },
    header: { marginBottom: '1rem' },
    heading: { margin: 0 },
    joinedOn: { margin: 0 },
}));

export default useUserProfileStyles;
