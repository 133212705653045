import gql from 'graphql-tag';
import { Airport } from '../../../../components/AirportsSelector';

export const GET_CUSTOMER_USER = gql`
    query getCustomerUser(
        $customerId: ID!,
        $userId: ID!
    ) {
        customer(id: $customerId) {
            id
            roles(first:10) {
                nodes {
                    id
                    name
                }
            }
            userEdge(userId: $userId) {
                airportWhitelist(first: 100) {
                    nodes {
                        id
                        iata
                        name
                    }
                }
                customerRole {
                    id
                }
                node {
                    name
                    email
                    joinedAt
                }
            }
        }
    }
`;

export interface GetCustomerUserInput {
    customerId: string;
    userId: string;
}

export interface GetCustomerUserResult {
    customer: {
        id: string;
        roles: {
            nodes: Array<{
                id: string;
                name: string;
            }>;
        };
        userEdge: {
            airportWhitelist: {
                nodes: Airport[] | null;
            };
            customerRole: {
                id: string;
            };
            node: {
                name: string;
                email: string;
                joinedAt: string;
            };
        };
    };
}
