/* eslint-disable @typescript-eslint/prefer-for-of */
import nonNull from '../helpers/nonNull';
import replaceAll from '../helpers/replaceAll';

function findBestLocale<T extends readonly string[]>(
    userLocales: string[],
    supportedLocales: T,
    defaultLocale?: T[number],
): T[number] {
    for (let index = 0; index < userLocales.length; index++) {
        const locale = userLocales[index];
        const match = findMatchingLocale(nonNull(locale), supportedLocales);

        if (match) {
            return match;
        }
    }

    if (defaultLocale) {
        return defaultLocale;
    }

    const firstSupportedLocale = supportedLocales[0];

    if (typeof firstSupportedLocale === 'undefined') {
        throw new Error('No supported locales provided');
    }

    return firstSupportedLocale;
}

function findMatchingLocale<T extends readonly string[]>(
    userLocale: string,
    supportedLocales: T,
): T[number] | null {
    // Look for exact match.
    // E.g. user wants en_GB and en_GB is supported.
    for (let index = 0; index < supportedLocales.length; index++) {
        const supportedLocale = nonNull(supportedLocales[index]);

        if (localesMatchExactly(userLocale, supportedLocale)) {
            return supportedLocale;
        }
    }

    // Check if base locale is supported.
    // E.g. user wants es_ES, but es is supported.
    const userBaseLocale = getBaseLocale(userLocale);

    if (userBaseLocale !== null) {
        for (let index = 0; index < supportedLocales.length; index++) {
            const supportedLocale = nonNull(supportedLocales[index]);

            if (localesMatchExactly(userBaseLocale, supportedLocale)) {
                return supportedLocale;
            }
        }
    }

    // Look for other regions with same base locale.
    // E.g. nl_BE user can recieve nl_NL SMS.
    for (let index = 0; index < supportedLocales.length; index++) {
        const supportedLocale = nonNull(supportedLocales[index]);
        const supportedBaseLocale = getBaseLocale(supportedLocale);

        if (supportedBaseLocale === null) {
            continue;
        }

        if (
            localesMatchExactly(
                userBaseLocale ?? userLocale,
                supportedBaseLocale,
            )
        ) {
            return supportedLocale;
        }
    }

    return null;
}

function localesMatchExactly(localeA: string, localeB: string): boolean {
    return normalizeLocale(localeA) === normalizeLocale(localeB);
}

function normalizeLocale(locale: string): string {
    return replaceAll(locale.toLowerCase(), '-', '_');
}

function getBaseLocale(locale: string): string | null {
    // eslint-disable-next-line no-param-reassign
    locale = normalizeLocale(locale);

    const underscoreIndex = locale.indexOf('_');

    if (underscoreIndex < 0) {
        // Locale is base locale
        return null;
    }

    return locale.substring(0, underscoreIndex);
}

export default findBestLocale;
