import { Modal, PrimaryButton, SecondaryButton, Heading } from '@get-e/react-components';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import useModalStyles from '../../../../styles/useModalStyles';

const useStyles = makeStyles(theme => createStyles({
    textBlock: { padding: theme.spacing(1, 0) },
}));

const RequestDeclineModal: FunctionComponent<{
    open: boolean;
    onClose: () => void;
    onDecline: () => void;
    declining: boolean;
}> = ({ open, onClose, onDecline, declining }) => {
    const { t } = useTranslation();
    const modalClasses = useModalStyles();
    const classes = useStyles();

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <div className={modalClasses.header}>
                <Heading level={2}>
                    {t('pages.availability.modals.declineRequest.header')}
                </Heading>
            </div>

            <p className={classes.textBlock}>
                {t('pages.availability.modals.declineRequest.confirm')}
            </p>
            <p className={classes.textBlock}>
                {t('pages.availability.modals.declineRequest.context')}
            </p>

            <div className={modalClasses.buttonContainer}>
                <span className={modalClasses.primaryButton}>
                    <PrimaryButton
                        onClick={onDecline}
                        loading={declining}
                        variation="danger"
                    >
                        {t('buttons.decline')}
                    </PrimaryButton>
                </span>

                <SecondaryButton
                    onClick={onClose}
                >
                    {t('buttons.cancel')}
                </SecondaryButton>
            </div>
        </Modal>
    );
};

export default RequestDeclineModal;
