import { LoadingPage } from '@get-e/react-components';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageDefinition } from '../../createRoutes';
import report from '../../helpers/report';
import useLoaderDebounce from '../../helpers/useLoaderDebounce';
import useUrlQuery from '../../helpers/useUrlQuery';
import Page from '../../layouts/Page';
import useAirport from '../../queries/useAirport';
import FindHotelsPage from './FindHotelsPage';
import HotelResultsPage from './HotelResultsPage';

const hotelSearchPage: PageDefinition = {
    path: '/hotels/search',
    Component() {
        const { t } = useTranslation();

        return (
            <Page breadcrumbs={{ name: t('breadcrumbs.hotelSearch') }} >
                <FindHotelsPage />
            </Page>
        );
    },
};

const hotelSearchResultsPage: PageDefinition = {
    path: '/hotels/search/results',
    Component() {
        const { t } = useTranslation();
        const query = useUrlQuery();

        const airportId = query.get('airportId');
        const checkIn = query.get('checkIn');
        const checkOut = query.get('checkOut');

        const { result: airport, loading, error } = useAirport(airportId ?? '', {
            skip: !airportId,
            onError(apolloError) {
                report(apolloError);
            },
        });

        const showLoader = useLoaderDebounce(loading);

        if (loading) {
            return showLoader
                ? (
                    <Page
                        breadcrumbs={{
                            name: <Skeleton width="7ch" />,
                            previous: {
                                name: t('breadcrumbs.hotelSearch'),
                                path: '/hotels/search',
                            },
                        }}
                    >
                        <LoadingPage />
                    </Page>
                )
                : null;
        }

        if (!airportId || !checkIn || !checkOut) {
            // TODO: UI
            return <>Not found</>;
        }

        if (error) {
            // TODO: UI
            return <>Error</>;
        }

        const checkInMoment = moment(checkIn, 'YYYY-MM-DD', true);
        const checkOutMoment = moment(checkOut, 'YYYY-MM-DD', true);

        if (!checkInMoment.isValid() || !checkOutMoment.isValid() || !airport) {
            // TODO: UI
            return <>Invalid request</>;
        }

        const name = `${airport.iata} for`
            + ` ${checkInMoment.format(t('dateFormat.dateShortMonthWithoutYear'))}`
            + `-${checkOutMoment.format(t('dateFormat.dateShortMonthWithoutYear'))}`;

        return (
            <Page
                breadcrumbs={{
                    name,
                    previous: {
                        name: t('breadcrumbs.hotelSearch'),
                        path: '/hotels/search',
                    },
                }}
            >
                <HotelResultsPage
                    input={{
                        airport,
                        checkIn: checkInMoment,
                        checkOut: checkOutMoment,
                    }}
                />
            </Page>
        );
    },
};

export { hotelSearchPage, hotelSearchResultsPage };

