import {
    Form, TextField,
    PrimaryButton, IntegerField, Alert,
} from '@get-e/react-components';
import { Box, InputAdornment } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import React, { FunctionComponent, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useNotificationContext } from '../../../../context/NotificationContext';
import delay from '../../../../helpers/delay';
import report from '../../../../helpers/report';
import allValid from '../../../../helpers/validation/allValid';
import FormError from '../../../../helpers/validation/FormError';
import getFormErrorMessage from '../../../../helpers/validation/getFormErrorMessage';
import getHelperText from '../../../../helpers/validation/getHelperText';
import getInputError from '../../../../helpers/validation/getInputError';
import InputError from '../../../../helpers/validation/InputError';
import isNotNull from '../../../../helpers/validation/validators/isNotNull';
import {
    AddAvailabilityInput,
    AddAvailabilityResult,
    ADD_AVAILABILITY,
} from './CoachAddForm.graphql';

const useStyles = makeStyles(theme => createStyles({
    alert: {
        marginBottom: '1em',
        justifyContent: 'center',
        fontWeight: 700,
    },
    errorContainer: {
        flexBasis: '100%',
        margin: theme.spacing(1, 0),
    },
    borderBox: {
        display: 'flex',
        alignItems: 'center',
        '& > *': { boxSizing: 'border-box' },
    },
    availabilityContainer: { marginBottom: '2.25em' },
    requestContainer: { padding: '1em' },
    inputBlock: { paddingRight: '1em' },
    submitButton: {
        marginBottom: '1.5em',
        whiteSpace: 'nowrap',
    },
}));

const VehicleAddForm: FunctionComponent<{
    availabilityRequestId: string;
    onAdded: () => void;
}> = ({ availabilityRequestId, onAdded }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { showNotification } = useNotificationContext();

    const [formError, setFormError] = useState<FormError | null>(null);
    const [description, setDescription] = useState<string>('');
    const [seats, setSeats] = useState<number | null>(null);

    const [
        leadTimeMinutes,
        setLeadTimeMinutes,
    ] = useState<number | null>(null);

    const [
        leadTimeError,
        setLeadTimeError,
    ] = useState<InputError | null>(null);

    const [seatsError, setSeatsError] = useState<InputError | null>(null);

    const [
        addAvailability,
        { loading: submitting },
    ] = useMutation<AddAvailabilityResult, AddAvailabilityInput>(
        ADD_AVAILABILITY
    );

    const hasSeats = seats && seats > 0;
    const hasLeadTimeMinutes = leadTimeMinutes && leadTimeMinutes > 0;
    const canSubmit = Boolean(hasSeats) && Boolean(hasLeadTimeMinutes);

    async function submitForm(): Promise<void> {
        const validated = {
            seats: isNotNull(seats, InputError.Empty),
            leadTimeMinutes: isNotNull(leadTimeMinutes, InputError.Empty),
        };

        if (!allValid(validated)) {
            setSeatsError(getInputError(validated.seats));
            setLeadTimeError(getInputError(validated.leadTimeMinutes));

            return;
        }

        setFormError(null);

        try {
            await addAvailability({
                variables: {
                    input: {
                        availabilityRequestId,
                        estimatedArrivalTimeMinutes: validated.leadTimeMinutes.value,
                        seats: validated.seats.value,
                        description,
                    },
                },
            });

            onAdded();
            showNotification(t('pages.availability.notifications.coachAdded'));
        } catch (error) {
            report(error);
            setFormError(FormError.UnexpectedError);
        }
    }

    return (
        <>
            <Form onSubmit={submitForm}>
                <div className={classes.borderBox}>
                    <Box className={classes.inputBlock} width="20%">
                        <IntegerField
                            value={leadTimeMinutes}
                            label={t('pages.availability.labels.leadTime')}
                            helperText={getHelperText(leadTimeError, t)}
                            onBlur={newLeadTimeMinutes => {
                                setLeadTimeMinutes(newLeadTimeMinutes);
                                setLeadTimeError(null);
                            }}
                            InputProps={
                                {
                                    inputProps: { min: '0' },
                                    startAdornment:
                                        <InputAdornment position="start">
                                            {t('pages.availability.labels.leadTimeAdornment')}
                                        </InputAdornment>,
                                }
                            }
                            required
                        />
                    </Box>
                    <Box className={classes.inputBlock} width="20%">
                        <IntegerField
                            value={seats}
                            label={t('pages.availability.labels.seats')}
                            helperText={getHelperText(seatsError, t)}
                            onBlur={newSeats => {
                                setSeats(newSeats);
                                setSeatsError(null);
                            }}
                            InputProps={
                                {
                                    inputProps: { min: '0' },
                                    startAdornment:
                                        <InputAdornment position="start">
                                            {t('pages.availability.labels.seatsAdornment')}
                                        </InputAdornment>,
                                }
                            }
                            required
                        />
                    </Box>
                    <Box width="40%">
                        <TextField
                            type="text"
                            value={description}
                            label={t('pages.availability.labels.descriptionNotReq')}
                            onChange={event => setDescription(event.target.value)}
                        />
                    </Box>
                    <Box width="20%" textAlign="right">
                        <PrimaryButton
                            className={classes.submitButton}
                            icon={<AddIcon />}
                            onClick={submitForm}
                            loading={submitting}
                            disabled={!canSubmit}
                            submitsForm
                        >
                            {t('pages.availability.buttons.addCoach')}
                        </PrimaryButton>
                    </Box>
                </div>
            </Form>
            {(() => {
                if (formError === null) {
                    return null;
                }

                return (
                    <Alert severity="error" className={classes.errorContainer}>
                        {getFormErrorMessage(formError, t)}
                    </Alert>
                );
            })()}
        </>
    );
};

export default VehicleAddForm;
