import gql from 'graphql-tag';

/*
 * TODO: Add orderBy for availability requests
 * TODO: Add orderBy for rides
 * TODO: Filter requests by `isCompleted: false`
 */

export const GET_COACHES = gql`
    query getCoaches($disruptionId: ID!, $limitPerType: Int!) {
        disruption(id: $disruptionId) {
            id
            flight {
                alternates(first: 1) {
                    nodes {
                        estimatedTime
                    }
                    totalCount
                }
            }
            rideAvailabilityRequests(first: $limitPerType) {
                nodes {
                    id
                    points(
                        first: 10,
                        orderBy: {field: DRIVING_ORDER, direction: ASC}
                    ) {
                        nodes {
                            id
                            location {
                                __typename
                                id
                                coordinates {
                                    latitude
                                    longitude
                                }
                                ... on Airport {
                                    name
                                    iata
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                        }
                    }
                    supplier {
                        id
                        name
                    }
                    createdAt
                    customerCancelledAt
                    supplierCompletedAt
                    rideAvailabilities(first: 0) {
                        totalCount
                    }
                }
                pageInfo {
                    hasNextPage
                }
            }
            rideAvailabilities(first: $limitPerType) {
                nodes {
                    id
                    points(
                        first: 10,
                        orderBy: {field: DRIVING_ORDER, direction: ASC}
                    ) {
                        nodes {
                            id
                            location {
                                __typename
                                id
                                coordinates {
                                    latitude
                                    longitude
                                }
                                ... on Airport {
                                    name
                                    iata
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                        }
                    }
                    supplier {
                        id
                        name
                    }
                    estimatedArrivalTimeMinutes
                    seats
                    createdAt
                }
                pageInfo {
                    hasNextPage
                }
            }
            rides(first: $limitPerType) {
                nodes {
                    id
                    number
                    supplier {
                        id
                        name
                    }
                    driver {
                        id
                        name
                        phoneNumber
                    }
                    meetingPoint
                    estimatedArrivalTime
                    busNumber
                    vehicle {
                        seats
                    }
                    pickupPoint {
                        trackingTimes {
                            estimatedArrival
                            actualArrival
                            estimatedDeparture
                            actualDeparture
                        }
                    }
                    dropOffPoint {
                        trackingTimes {
                            estimatedArrival
                            actualArrival
                        }
                    }
                    points(
                        first: 10,
                        orderBy: { field: DRIVING_ORDER, direction: ASC }
                    ) {
                        nodes {
                            location {
                                __typename
                                id
                                coordinates {
                                    latitude
                                    longitude
                                }
                                ... on Airport {
                                    iata
                                    name
                                }
                            }
                        }
                        pageInfo {
                            hasNextPage
                        }
                    }
                    events {
                        driverDispatchedAt
                        customerCancelledAt
                        supplierDeclinedAt
                        supplierConfirmedAt
                        createdAt
                        availableAt
                    }
                }
                pageInfo {
                    hasNextPage
                }
            }
        }
    }
`;

export interface GetCoachesInput {
    disruptionId: string;
    limitPerType: number;
}

interface Location {
    __typename: string;
    id: string;
    coordinates: {
        latitude: number;
        longitude: number;
    };
    timeZone: string;
}

interface Airport extends Location {
    __typename: 'Airport';
    iata: string;
    name: string;
    timeZone: string;
}

/*
 * Note: Place just exists to enforce __typename checks,
 * it's not returned by the API at the moment.
 */
interface Place extends Location {
    __typename: never;
}

interface RideAvailabilityRequest {
    id: string;
    points: {
        nodes: Array<{
            location: Airport | Place;
        }>;
        pageInfo: {
            hasNextPage: boolean;
        };
    };
    supplier: {
        id: string;
        name: string;
    };
    createdAt: string;
    customerCancelledAt: string | null;
    supplierCompletedAt: string | null;
    rideAvailabilities: {
        totalCount: number;
    };
}

interface RideAvailability {
    id: string;
    points: {
        nodes: Array<{
            location: Airport | Place;
        }>;
        pageInfo: {
            hasNextPage: boolean;
        };
    };
    supplier: {
        id: string;
        name: string;
    };
    estimatedArrivalTimeMinutes: number;
    seats: number;
    createdAt: string;
}

interface Ride {
    id: string;
    number: string;
    supplier: {
        id: string;
        name: string;
    };
    driver: null | {
        name: string;
        phoneNumber: string;
    };
    meetingPoint: string | null;
    estimatedArrivalTime: string;
    busNumber: string;
    vehicle: {
        seats: number;
    };
    points: {
        nodes: Array<{
            location: Airport | Place;
        }>;
        pageInfo: {
            hasNextPage: boolean;
        };
    };
    pickupPoint: {
        trackingTimes: {
            estimatedArrival: string;
            actualArrival: string | null;
            estimatedDeparture: string | null;
            actualDeparture: string | null;
        };
    };
    dropOffPoint: {
        trackingTimes: {
            estimatedArrival: string | null;
            actualArrival: string | null;
        };
    };
    events: {
        driverDispatchedAt: string | null;
        customerCancelledAt: string | null;
        supplierDeclinedAt: string | null;
        supplierConfirmedAt: string | null;
        createdAt: string;
        availableAt: string;
    };
}

export interface GetCoachesResult {
    disruption: null | {
        id: string;
        flight: {
            alternates: {
                nodes: Array<{
                    estimatedTime: string | null;
                }>;
                totalCount: number;
            };
        };
        rideAvailabilityRequests: {
            nodes: RideAvailabilityRequest[];
            pageInfo: {
                hasNextPage: boolean;
            };
        };
        rideAvailabilities: {
            nodes: RideAvailability[];
            pageInfo: {
                hasNextPage: boolean;
            };
        };
        rides: {
            nodes: Ride[];
            pageInfo: {
                hasNextPage: boolean;
            };
        };
    };
}
