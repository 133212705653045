import gql from 'graphql-tag';

export const UPDATE_DRIVER = gql`
    mutation UPDATE_DRIVER($input: UpdateDriverInput!) {
        updateDriver(
            input: $input
        ) {
            id
        }
    }
`;

export interface UpdateDriverInput {
    input: {
        supplierId: string;
        driverId: string;
        name: string;
    };
}

export interface UpdateDriverResult {
    createDriver: {
        id: string;
    };
}

export interface Driver {
    id: string;
    name: string;
    phoneNumber: string;
}

export const GET_DRIVER = gql`
    query GET_DRIVER($id: ID!, $supplierId: ID!) {
        driver(
            id: $id,
            supplierId: $supplierId
        ) {
            id
            name
            phoneNumber
        }
    }
`;

export interface GetDriverInput {
    id: string;
    supplierId: string;
}

export interface GetDriverResult {
    driver: Driver;
}
