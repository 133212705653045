import gql from 'graphql-tag';

export const SEARCH_DRIVER = gql`
    query GetDrivers(
        $supplierId: ID!,
        $filter: SupplierDriversConnectionFilterInput
    ) {
        supplier(id: $supplierId) {
            id
            drivers(first:5, filter: $filter) {
                nodes {
                    id
                    name
                    phoneNumber
                }
            }
        }
    }
`;

export interface SearchDriverInput {
    supplierId: string;
    filter: null | { search: string };
}

export interface SearchDriverResult {
    supplier: {
        id: string;
        drivers: {
            nodes: Array<{
                id: string;
                name: string;
                phoneNumber: string;
            }>;
        };
    };
}
