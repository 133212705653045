import { Heading } from '@get-e/react-components';
import { TableCell, TableRow } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => createStyles(
    { emptyState: { color: theme.palette.grey[200] } }
));

const EmptyVehicles: FunctionComponent = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <TableRow >
            <TableCell
                colSpan={6}
                className={classes.emptyState}
            >
                <Heading level={2}>
                    {t('pages.availability.empty.header')}
                </Heading>
                <div>
                    <Trans i18nKey="pages.availability.empty.leadTime">
                        <strong></strong>
                    </Trans>
                </div>
                <div>
                    <Trans i18nKey="pages.availability.empty.capacity">
                        <strong></strong>
                    </Trans>
                </div>
                <div>
                    <Trans i18nKey="pages.availability.empty.description">
                        <strong></strong>
                    </Trans>
                </div>
            </TableCell>
        </TableRow>
    );
};

export default EmptyVehicles;
