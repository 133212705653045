import { makeStyles } from '@material-ui/core';

const useModalStyles = makeStyles({
    buttonContainer: { marginTop: '1rem' },
    warningContainer: { marginTop: '1rem' },
    header: { marginBottom: '1rem' },
    heading: { margin: 0 },
    primaryButton: { marginRight: '.5rem' },
    paragraph: { '&:not(:first-child)': { marginTop: '1rem' } },
    centerButton: {
        marginBottom: '1rem',
        marginLeft: '0.5rem',
    },
});

export default useModalStyles;
